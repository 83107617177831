import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln2 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 2: Variables & Control Flow (Solution)</h1>
        <hr />

        <h3>Tracing</h3>
        <p><code>SENTINEL</code> definites the value that will cause us to end looping; when the user enters -1, the
            program ends. <code>a</code> and <code>b</code> don't change after the user enters them before the while loop. However,
            <code>y</code> gets changed every time <code> y = a * x + b</code> executes within the loop, and <code>x</code> is
            changed every time <code> x = int(input("Enter a value for x: "))</code> executes within the loop.</p>

        <p>Conceptually, this is slope intercept form: <code>y = ax + b</code>, where <code>x</code> represents some
            x-value, <code>a</code> is the slope, <code>b</code> is the y-intercept, and <code>y</code> is the output
            y-value we calculate.</p>

        <h3>Dog Years</h3>
        <PythonHighlighter code={dog_years} />

        <h3>Finding Factors</h3>
        <PythonHighlighter code={factor} />

        <h3>Finding Factors, Bonus</h3>
        <PythonHighlighter code={factor_bonus} />
    </>
}

const dog_years = `SENTINEL = 0


def main():
    while True:
        years = int(input('Enter an age in human years (enter 0 to exit): '))
        if years == SENTINEL:
            break
        if years < 0:
            print('Sorry, please enter a positive number or 0 to exit')
        else:
            print('The age in dog years is ' + str(7 * years))
            

if __name__ == "__main__":
    main()`


const factor = `SENTINEL = 0


def print_factors(num):
    """
    This function takes in an integer num and prints
    its factors.
    """
    # loop from 1 up to and not including num + 1
    for i in range(1, num + 1):
        if num % i == 0:
            print(i)


def main():
    num = int(input('Enter a number to factor: '))
    while num != SENTINEL:
        if num < 0:
            print('Please input a positive number')
        else:
            print_factors(num)
        num = int(input('Enter a number to factor: '))


if __name__ == "__main__":
    main()`


const factor_bonus = `SENTINEL = 0


def print_factors(num):
    """
    This function takes in an integer num and prints
    its factors. If the number is prime, it prints
    that as well.
    """
    # start factor counter at 0
    factor_count = 0
    # loop from 1 up to and not including num + 1
    for i in range(1, num + 1):
        if num % i == 0:
            print(i)
            factor_count += 1
    # two factors: the number itself and 1
    if factor_count == 2:
        print(str(num) + ' is prime!')


def main():
    num = int(input('Enter a number to factor: '))
    while num != SENTINEL:
        if num < 0:
            print('Please input a positive number')
        else:
            print_factors(num)
        num = int(input('Enter a number to factor: '))


if __name__ == "__main__":
    main()`   