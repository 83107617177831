
import { courseInfo } from 'cs106a/courseInfo'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { HorizontalSpeechBubble } from 'components/duolessons/lessontypes/SpeechBubbles'
import Pyramid from '../../handouts/img/assn2/pyramid.png'
import Breakout from '../../handouts/img/assn3/breakout_start.png'
import Sand from '../../handouts/img/assn4/homework-sand-screen.png'
import InfiniteStory from '../../handouts/img/assn5/infinitestory.png'
import Diagnostic1 from '../../handouts/img/diagnostic1.png'
import Diagnostic2 from '../../handouts/img/diagnostic2.png'
import Bajillion from '../../handouts/img/bajillion.png'

export const Announcements = () => {
  return <>
    {/* <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Final Exam Grades Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We have released final exam grades on <a href="https://www.gradescope.com/courses/538920">Gradescope</a>.
          You must log in with your Stanford email to see your score. The
          median score was 85% (136/160 pts) - we saw lots of improvement since the midterm!
        </p>
        <p>We try to grade as consistently as possible, but we are human and we might make mistakes. If you feel
          like one of your problems was misgraded, please take a look at
          the <Link to="/final-soln"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Final Solutions Handout</Link>, and then file a
          regrade request on Gradescope. Regrade requests are open now and will stay open for 24 hours on Gradescope until
          Wednesday, June 14th at 9am PST. If you submit a regrade request, we do reserve the right to regrade the entire problem
          and make any necessary corrections. After this deadline passes, please reach out directly to the course instructors with
          any grading questions.</p>

        <p>Congrats on finishing CS106A! 🎉🎉🎉</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment Retractions and Retroactive Citations</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We described our honor code policy at the beginning of the quarter, but we wanted to revisit our rules on
          homework collaboration. Essentially, you are welcome to exchange ideas with other students and the staff, but
          then you should take those insights and use them to write your own code. If you feel you made a mistake over
          the course of the quarter, please read this <Link to="/citations"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>retroactive citation and retraction handout</Link> detailing some next
          steps. You can fill out the <a href="https://forms.gle/74T8K1PtABzc39Yn6">this form</a> if you feel that you have violated the honor code while
          working on an assignment this quarter.</p>
        <p>Please submit any retroactive citations by 3pm on Wednesday, June 14th.</p>
      </div>
    </div>


    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Week 10 Study Resources</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all, this week we'll have a few live sessions where you can ask questions and see more problem solving strategies as
          you study for the final.
        </p>
        <p>We'll be hosting a <b>problem solving session</b> in Durand 303 on Tuesday, from 3:00-4:30pm. You can bring in
          any problems you'd like to talk through with a section leader, or just come with conceptual questions. This session will
          not be recorded.
        </p>
        <p>We'll be hosting a <b>final review session</b> on <a href="https://stanford.zoom.us/j/96350213592?pwd=RVljOERPWHk4azdiTFFLRWJVQ1FZQT09">Zoom</a> on
          Wednesday, from 5:30-7pm. In the first hour, Elyse will
          walk through a few problems like the ones you might see on the exam, and in the final half hour, we'll do Q&A. This session
          has been <a href="https://stanford.zoom.us/rec/share/1Wna_Xi9iECZvX2CRyP4aQt5oFt2dd27cblwaBFq84HE9Dg8Kdtscv7jOD-oOEIs.obPhQ65z-4Ysfw9y">recorded</a>. Here are the <a href="https://docs.google.com/presentation/d/1WF5BrzE9R-HpplZon-MNzJi0HrEMTFLvIcY4_lxTDFU/edit?usp=sharing">review session slides</a> and
          here's the <a href={`${process.env.PUBLIC_URL}/final_review_spr23.zip`}>starter code and solutions</a>.</p>
      </div>
    </div>


    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Submit the End of Quarter Eval</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>This is your last call to submit the <a href="https://forms.gle/sarteRw3PoZ8YWKS8">end of quarter evaluation</a>. On this form, you can share your
          thoughts on section, LaIR, and other aspects of the course. We'll be closing the form on Tuesday night at midnight, and it should take about 15 minutes to fill out.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Final YEAH Hours on Tuesday</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We'll have YEAH hours for our final assignment, Bajillion, on Tuesday from <s>11:30am to 12:30pm</s> 3 to 4pm. It
          will be at the same <a href="https://stanford.zoom.us/j/99819015893?pwd=SFdiUzZVdnQ3MWxlVHVLYi82WXRsZz09">zoom link</a> as before, and
          will be recorded and posted to Canvas.
        </p>
      </div>
    </div>


    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">No LaIR Hours Sunday</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We will not be holding LaIR on Sunday, May 28th in observance of Memorial Day on Monday. LaIR
          will resume as usual Monday night.
        </p>
      </div>
    </div>

    

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Chris and Elyse OH</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Chris and Elyse will be out of town later this week, meaning that Elyse's Thursday office hours
          and Chris' Friday office hours will be canceled. Please stop by Elyse's Tuesday office hours, or
          reach out via email to find another time to meet earlier in the week.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Bias Bars YEAH Hours</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>CS106A YEAH Hours (Your Early Assignment Help Hours) are back this week! Stop by YEAH Hours if you:</p>
        <ol>
          <li>Feel overwhelmed by the assignment handout</li>
          <li>Want some help navigating the starter code</li>
          <li>Don't know know where to start the assignment</li>
        </ol>

        <p>Please note that this is not a place for code debugging; instead, we'll talk about high-level strategies
          for approaching the assignment.
        </p>

        <p>We'll host YEAH Hours virtually on <b>Friday from 3-4pm</b> at this <a href="https://stanford.zoom.us/j/99819015893?pwd=SFdiUzZVdnQ3MWxlVHVLYi82WXRsZz09">zoom link</a>.
          If you are unable to join this Friday's session on Bias Bars, we'll zoom recording available on Canvas under "Files".</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Mehran's OH Canceled</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Mehran will not be having office hours this week, as he is out of town. If you'd like to meet
          with Mehran this week, please reach out to him via email to schedule a time to talk after Friday's class.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 5 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released Assignment 5: Bias Bars, which you can find under the 'Assignments'
          dropdown above. It's due Friday, May 26th.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Grades Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We have released the grades for the Midterm Exam on <a href="https://www.gradescope.com/courses/538920">Gradescope</a>.
          You must log in with your Stanford email to see your score. Solutions can be found on
          the <Link to="/midterm-soln"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Midterm Solutions Handout</Link>.</p>
        <p>
          The median score was a 68% (82/120 pts), and we felt that this was a challenging exam. <b>This class will be
            graded on a curve</b>, so a lower median doesn't mean that everyone will be receiving lower grades. This
          exam is intended to be a diagnostic, so please take a look at what went well and how you can improve, rather than
          worrying too much about the absolute score.
        </p>
        <p>We try to grade as consistently as possible, but we are human and we might make mistakes. If you feel
          like one of your problems was misgraded, please take a look at the solution handout and then file a
          regrade request on Gradescope. Regrade requests are open now and will stay open on Gradescope until
          Sunday, May 21st at 11:59pm. <b>Note that your SL cannot regrade your exam.</b> We want you to have the
          credit you deserve, but filing a formal request helps us make sure that your request goes to the
          right person. If you submit a regrade request, we do reserve the right to regrade the entire problem
          and make any necessary corrections.</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">HW4 Doctest Typo</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p> Hi everyone, there was a typo in one of the doctests for <code>check_move</code>. If you downloaded the
          starter code after Tuesday at 12pm noon, this issue is already fixed. If not, you can change the <code>x2</code> location
          to <code>3</code> for the third doctest in <code>check_move</code> as follows (changes shown in <i>italics</i>):</p>
        <p>
          <code>{'>'}{'>'}{'>'} check_move(grid, 0, 0, <i>3</i>, 1)  # x2 = <i>3</i> is out of bounds</code>
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">No Class Friday!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p> Since we had an out of class midterm this week, there will be no CS106A lecture on
          Friday 5/12! See you all again next week :)
        </p>
        <p>We also won't have any YEAH hours this Friday, but we've got last week's recording for Assignment 4
          posted on Canvas under "Files".
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Still In Progress</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>To those who have completed the midterm diagnostic, congrats! 🎉🎉🎉</p>
        <p> Some students will be taking the exam later in the week, so <b>please do not discuss the exam with other
          students until next week.</b> We will be grading the midterm exam over the weekend, and hope to get your
          grades back to you by early next week.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Bring Your Own Exam Notes to Reference</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, as you prepare for the midterm diagnostic, we wanted to remind you that <b>we will not be providing a reference
          sheet of functions on the exam</b>. Instead, we are allowing you to bring up to <b>5 pages</b> of letter paper, double-sided, with handwritten or typed
          notes. These notes may include anything from class (lecture slides, handouts, etc.) or your own code and notes. You
          don't <i>need</i> to bring 5 pages - fewer or none is fine, too!</p>

        <p>More details on the midterm exam and good reference resources to draw from can be found on the <Link to="/practice-midterm"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Practice Midterm Exam</Link> handout.</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Review Materials!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Happy Friday! We're having a review session today (Friday) from 2:30-3:30pm in Gates B03. This session will be
          recorded and posted to Canvas under "Panopto Course Videos" on Friday afternoon. Here are
          the <a href="https://docs.google.com/presentation/d/1J5iQJifPPCztzMuYujt677hVu7-nMSyPk-EvW-vYNCY/edit?usp=sharing">review session slides</a> that one of our section leaders, Selaine, has put together.</p>
        <p>We'll also have a problem
          solving session on Monday (not recorded) in STLC 114 from 4:30-5:50pm - bring any problems you'd like to work through, and
          we'll have section leaders available to answer your questions. Here's a <a href="https://docs.google.com/presentation/d/14vZFMDYeeUCgrQ0bFC52KMbNOW5SNQw1IRY61CKXDGk/edit?usp=sharing">list of practice problems</a> that one
          of our section leaders, Ananya, has compiled.
        </p>
        <p>Finally, we'll post a YEAH Hours (Your Early Assignment Help Hours) recording over the weekend. The first 2 problems on Assignment 4
          will also be great practice as you study. </p>
        <p>See the announcement below for midterm diagnostic exam logistics!</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Diagnostic Next Week</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all! We'll be having our midterm diagnostic exam next Tuesday, May 9th from 7-9pm.</p>
        <p>Here are the room assignments:
        </p>
        <ul>
          <li>First names A-P in Hewlett 200</li>
          <li>First names Q-Z in Hewlett 201</li>
        </ul>
        <p>Students with OAE exam accommodations should have received an email from Elyse with their
          location and timing details.</p>
        <p>Important exam logistics:</p>
        <ul>
          <li>The exam will be on paper, so please bring a pencil or pen.</li>
          <li>You may bring up to 5 pages of notes on letter paper to reference during the exam. You can use both sides of the paper.</li>
          <li>The exam will cover everything up through Lecture 14: Strings, but will not include doctests or images.</li>
        </ul>
        <p>Study resources:</p>
        <ul>
          <li>Recorded review session on Friday, 5/5 from 2:30-3:30pm in Gates B03.</li>
          <li>Problem solving session on Monday, 5/8 from 4:30-5:50pm in STLC 114.</li>
          <li><Link to="/practice-midterm"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Practice Midterm Exam</Link>.</li>
          <li>Section problems.</li>
          <li><Link to="/spr23/code"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Online IDE</Link>.</li>
        </ul>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 4 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released Assignment 4: Sand, which you can find under the 'Assignments'
          dropdown above. It's due <b> 2pm (Pacific Time) on Monday, May 13th</b> (after the midterm) but the first two sandcastles will
          give you practice with lists of lists and strings, which will be on the midterm.
          <div className='w-100 d-flex justify-content-center'>
            <img style={{maxWidth:400}} src={Sand}/>
          </div>
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">YEAH Hours</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>CS106A YEAH Hours are Your Early Assignment Help Hours, a weekly session intended to help students
          get started on the CS106A assignments. We'll break the assignment handout into manageable chunks, go over
          useful resources, and discuss approaches for major milestones of the assignment. Stop by YEAH Hours if you:</p>
        <ol>
          <li>Feel overwhelmed by the assignment handout</li>
          <li>Want some help navigating the starter code</li>
          <li>Don't know know where to start the assignment</li>
        </ol>

        <p>Please note that this is not a place for code debugging; instead, we'll talk about high-level strategies
          for approaching the assignment.
        </p>

        <p>We'll host YEAH Hours virtually <b>each Friday from 3-4pm</b> at this <a href="https://stanford.zoom.us/j/99819015893?pwd=SFdiUzZVdnQ3MWxlVHVLYi82WXRsZz09">zoom link</a>.
          If you were unable to join last Friday's session on Breakout, we have the <a href="https://drive.google.com/file/d/1M6qwr57Jw-39OfqClKQ08mr08xpZDqSV/view?usp=sharing">recorded YEAH session</a> available
          for viewing.</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Week 4 Handouts</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all, we've released some new handouts, which you can find under the 'Course' tab.
          These will be helpful as you begin working on Assignment 3!</p>
        <ul>
          <li><Link to="/graphics"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Graphics Reference Sheet</Link> </li>
          <li> <Link to="/interactive-programs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Interactive Programs Reference Sheet</Link></li>
          <li><Link to="/style"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Style Guide</Link></li>
        </ul>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 3 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Happy Week 4! We've just released Assignment 3, which you can find under the 'Assignments' dropdown above.
          It will be due on Wednesday, May 3rd at 11am.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 2 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We have released Assignment 2, which you can find under the 'Assignments' dropdown above. It will be due
          on Monday, April 24th at 11am.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Section Assignments Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We have released section assignments - look for a "Your CS106A Section Assignment"
          email in your inbox. If you can no longer make your assigned section or forgot to sign
          up for a section, please visit the <a href="https://cs198.stanford.edu/cs198/">CS198
            website</a> to join a section with open spots that works for you.
          If you absolutely cannot make any of the sections that have availability, please reach out
          to <a href="mailto: cornwall@stanford.edu">Elyse</a>.
        </p>
        <p>If you cannot attend your assigned section on a given week, you're welcome to attend another section
          (<a href="https://cs198.stanford.edu/cs198/auth/section/ViewSections.aspx?class=1">all times/locations listed here</a>) and let that section leader know you're visiting. Virtual
          sections are reserved for SCPD students.
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Sections Starting this Week</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Section assignments will come out today (Tuesday) at 5pm.</p>
        <p>If you missed the section signup window, you'll be able to sign up for a section that has availability
          once section assignments come out at 5pm. You can join a section (or switch sections if you'd like)
          on the <a href="https://cs198.stanford.edu/cs198/">CS198
            website</a>. Sections
          start this week, so be sure to sign up for a section in time to attend.</p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Final Exam Grades Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We have released final exam grades on <a href="https://www.gradescope.com/courses/538920">Gradescope</a>.
          You must log in with your Stanford email to see your score. The
          median score was 80%!!
        </p>
        <p>We try to grade as consistently as possible, but we are human and we might make mistakes. If you feel
          like one of your problems was misgraded, please take a look at
          the <Link to="/final-soln"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Final Solutions Handout</Link>, and then file a
          regrade request on Gradescope. If you submit a regrade request, we do reserve the right to regrade the entire problem
          and make any necessary corrections. After this deadline passes, please reach out directly to the course instructors with
          any grading questions.</p>

        <p>Congrats on finishing CS106A! 🎉🎉🎉</p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">TeachNow</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone! Research shows that one great way to study for your final is to teach others. We have built a tool for CS106A students to do just that! You can check out more about the tool <Link to="cs106a/teachnowhome">here.</Link></p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Final Review Session!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all, we'll be hosting a final review session on Monday (6/3) right after lecture at 3:30 pm in Hewlett 200. We'll practice some problems from Section Handouts and Final Prep Handout!</p>
      </div>
    </div>


    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Final Exam Logistics & Study Materials</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hello everyone! We'll have our final exam on <b>Friday, June 7th from 8:30am-11:30am</b>.</p>
        <p>Here are the room assignments (same as the Diagnostic):
        </p>
        <ul>
          <li>First names A-P in Hewlett 200</li>
          <li>First names Q-Z in Hewlett 201</li>
        </ul>
        <p>Students with OAE exam accommodations should have received an email from Ngoc with their
          location and timing details.</p>

        <p>Important exam logistics:</p>
        <ul>
          <li>The exam will be on paper, so please bring a pencil or pen.</li>
          <li>You may bring up to 10 pages of notes on letter paper to reference during the exam. You can use both sides of the paper.</li>
          <li>The exam will be cumulative, but will NOT cover the following topics:</li>

          <ul>
            <li>Karel</li>
            <li>Tuples</li>
            <li>The internet and servers</li>
            <li>List comprehensions and <code>matplotlib</code></li>
            <li>You won't need to write your own classes, but you should be able to understand and follow the logic in programs that use Classes {"("}i.e. tracing{")"}.</li>
          </ul>
        </ul>

        <p>You may not use a laptop or any other devices during the exam, unless
          you have academic accommodations to use such devices.
        </p>

        <p>Here are some resources you might reference as you study:</p>
        <p>
          <ul>
            <li> <a href="https://drive.google.com/file/d/10RB48bZivXMLwrqnaXln_igDnSBf0tw9/view?usp=sharing">Final reference</a> - a list of CS106A functions, created by our own Kieran Barrett</li>
            <li> <Link to="/final-prep"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Final prep handout</Link> - a bank of practice problems from each category</li>
            <li> <Link to="/practice-final"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Pracitce Final</Link></li>
            <li> <a href="https://cs.stanford.edu/people/nick/py/">Nick Parlante's Python guide</a></li>
          </ul>
        </p>

      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">CS106A Contest</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi! The CS106A Contest is due this Friday, May 31st at 11:59 pm. You can find more details on
          the <Link to="/contest"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>CS106A Contest Handout</Link>.
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 6: Bajillion YEAH Hours</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone! We hope you had a great Memorial Day Weekend! Jack and Gunnar will be leading YEAH for Assignment 6 this Wednesday (5/29) right after class in the same lecture room! Come get some hints and tips for Assignment 6!</p>
      </div>
    </div>
    
<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 6 Released!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released <Link to="/assn6">Assignment 6: Bajillion</Link>! It's due <b> 2pm (Pacific Time) on Wednesday, June 5th</b>.
          <div className='w-100 d-flex justify-content-center'>
            <img className="w-100" src={Bajillion}/>
          </div>
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Infinite Story Contest</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>We hope you are having fun with the new Infinite Story assignment! We'll be giving away prizes to the top stories and extensions! Share your stories on the <a href="https://edstem.org/us/courses/57479/discussion/4983323">Ed Discussion Forum</a>. 
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">CS106A Contest</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>The CS106A Contest is now open - here's a chance for you to demonstrate some of the skills you've learned
          this quarter, while having some fun and being creative! You can find more details on
          the <Link to="/contest"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>CS106A Contest Handout</Link>.
        </p>
      </div>
    </div>
    
<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 5 Released!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released <Link to="/assn5">Assignment 5: Infinite Story</Link>! It's due <b> 2pm (Pacific Time) on Friday, May 24th</b>, and it's a brand new assignment, so we highly recommend it you start it early! We'll have YEAH for this assignment on Wednesday, May 15th right after class lead by Jack and Gunnar! 
          <div className='w-100 d-flex justify-content-center'>
            <img className="w-100" src={InfiniteStory}/>
          </div>
        </p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Diagnostic Released!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released the Diagnostic grades on Gradescope. You will have one week from today to make regrade requests, and we reserve the right to regrade your entire exam if you do make a regrade request. Check out the statistics below!
          <div className='w-100 d-flex justify-content-center'>
            <img className='w-100' src={Diagnostic1}/>
          </div>
          <div className='w-100 d-flex justify-content-center'>
            <img className='w-100' src={Diagnostic2}/>
          </div>
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Mehran's Office Hours Moved Today</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, due to a one-time conflict, Mehran's office hours this Wednesday (May 8) are moved back an hour to 4:30pm-6:30pm.   They will still be in the same place: Gates building, room 274.
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">LaIR CLOSED this Thursday (5/9)</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>LaIR will be CLOSED this Thurday (5/9)!</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 4: Sand YEAH</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone! We hope the Diagnostic went well for you. Don't forget we are having YEAH for Assignment 4 this Wednesday (5/8) right after class in the same lecture room! Come get started on Assignment 4!</p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Diagnostic Next Week</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all! We'll be having our midterm diagnostic exam next Tuesday, May 7th from 7-9pm.</p>
        <p>Here are the room assignments:
        </p>
        <ul>
          <li>First names A-P in Hewlett 200</li>
          <li>First names Q-Z in Hewlett 201</li>
        </ul>
        <p>Students with OAE exam accommodations should have received an email from Ngoc with their
          location and timing details.</p>
        <p>Important exam logistics:</p>
        <ul>
          <li>The exam will be on paper, so please bring a pencil or pen.</li>
          <li>You may bring up to 5 pages of notes on letter paper to reference during the exam. You can use both sides of the paper.</li>
          <li>The exam will cover everything up through Lecture 14: Strings, but will not include doctests or images.</li>
        </ul>
      </div>
      </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 4 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released <Link to="/assn4">Assignment 4: Sand</Link>. It's due <b> 2pm (Pacific Time) on Monday, May 13th</b> (after the Diagnostic) but the first two sandcastles will
          give you practice with lists of lists and strings, which will be on the Diagnostic, so we recommend you complete the lists and strings Sandcastles. <b>For this assignment, you have to complete the anonymous <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdE-cjOadBMYGZiw3T1Fb9-uAkK0u2xhOhNBAAvWlF1LrlePg/viewform">Mid-Quarter Class Survey</Link> in order to get the Starter Code</b>. 
          <div className='w-100 d-flex justify-content-center'>
            <img style={{maxWidth:400}} src={Sand}/>
          </div>
        </p>
      </div>
    </div>


<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 3 Breakout</div>
          <div className="newsfeedTime">
          </div>
        </div>
        We have released <Link to="/assn3">Assignment 3: Breakout</Link>.  It will be due
          on Wednesday, May 1st at 2pm. It is a fun product you can shared with your loved ones. And! It will help you develop your computer science. Enjoy, go above and beyond. And get started early!<br />
          <div className='w-100 d-flex justify-content-center'>
            <img style={{maxWidth:400}} src={Breakout}/>
          </div>
        
      </div>
    </div>



    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Some Floors of Durand CLOSED</div>
          <div className="newsfeedTime">
          </div>
        </div>
        Durand is doing its bi-annual carpet cleaning!<br />
        Floors 3 and 4 will be CLOSED during LaIR on <b>Tuesday, April 9th</b><br />
        Floor 2 will be CLOSED during LaIR on <b>Wednesday, April 10th</b><br />
        Floor 1 will be CLOSED during LaIR on <b>Thursday, April 11th</b><br /><br />
        Thanks for your cooperation!

      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 1 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        We have released <Link to="/assn1">Assignment 1</Link>, which you can find under the 'Assignments' dropdown above. It will be due
        on Friday, April 12th at 2pm:<br />
        <div className='w-100 d-flex justify-content-center'>
          <HorizontalSpeechBubble text="Let's learn together!" />
          {/* <img src="/karel192.png" style={{width:100}}></img>
        </div>

      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">LaIR Starting this Sunday</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Now that <Link to="/assn1">Assignment 1</Link> is underway, we'll be offering LaIR hours. LaIR is an assignment help resource that allows
          students to ask debugging or conceptual questions to a section leader one-on-one.</p>

        <p>LaIR will be open school nights, <b>Sunday through Thursday from 7pm-11pm PT</b> on the 3rd floor of the <a
          href="https://www.google.com/maps/place/Durand+Building,+496+Lomita+Mall,+Stanford,+CA+94305/@37.4268738,-122.1754608,17z/data=!3m1!4b1!4m5!3m4!1s0x808fbb2aa1a2e6c7:0x150c778bd3b558cf!8m2!3d37.4268696!4d-122.1732721">Durand Building</a>.
          LaIR is in-person, but you'll use this <a href="https://cs198.stanford.edu/lair">LaIR signup link</a> (also linked in the "resources" section
          on the right of the course website) to get in the LaIR queue and chat with a section leader.
        </p>

        <p>Note that <b>LaIR is in person</b> unless you are an SCPD student. If you would like to request temporary virtual
          LaIR access (reserved for special circumstances like COVID or injury) please reach out to <a href="mailto: ngocng@stanford.edu">Ngoc</a> via email.</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">PyCharm Installation Help</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, if you're having trouble with <b>Python/PyCharm installation</b> as you get started on HW1, there are many
          resources available to you to help debug these issues. <b>The key is to start early</b> because you might not be able
          to get support if you start the homework the day it's due.
        </p>
        <ul>
          <li><Link to="/installing-pycharm"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Installing PyCharm handout</Link> - Make
            sure you've carefully followed these instructions when installing both Python 3.11 and PyCharm.</li>
          <li><Link to="/pycharm-bugs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>PyCharm Bugs handout</Link> -
            See if the bug you're experiencing is listed here.</li>
          <li><a href="https://edstem.org/us/courses/57479/discussion/">CS106A Ed Forum</a> - Check if someone else has posted
            about your issue, and if not, make a post yourself! If you post any code, make the post private.</li>
          <li><b>PyCharm help hours</b> - Ngoc and some section leaders will be hosting help hours in Durand 303 after class on Friday, Apri 5th from 3:30-5pm. Come by to work through any persistent Python/PyCharm bugs!
          </li>
          <li><b>Ngoc's office hours and LaIR</b> - You're welcome to bring any installation bugs to our usual
            help resources as well, or talk to us after class!</li>
        </ul>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Section Signups</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Section signups will open on the <a href="https://cs198.stanford.edu/cs198/auth/section/SectionSignup.aspx?class=1">CS198 website</a> on Thursday, April 4th at 5pm PT. Please note that
          signups are <b>not</b> first-come-first-serve, so you have until the form closes on Sunday, April 9th at 5pm PT to fill in your preferences. </p>
        <p>There are two virtual sections, which are reserved for SCPD students. You may only rank these sections if you are SCPD (but SCPD students are welcome
          to rank in-person sections if they're local).</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Apply for CS100A</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>CS100A is a supplementary class for those wishing to gain extra practice with CS106A material and build a stronger
          foundation. The mission of ACE
          is to provide students, particularly those from <b>under-resourced and/or underrepresented backgrounds</b>, with
          the opportunities and support to succeed in their course and in their major. Undergraduate
          students participating in ACE are required to attend an <i>additional</i> weekly section and enroll in CS100A for one
          unit.</p>
        <p>Applications are accepted on a rolling basis until Friday, April 5th! There are only a few spots left. <a href="https://docs.google.com/forms/d/e/1FAIpQLSeNjD2moXeiqtq35tblinE7LWHlUZ4cQU8cUzXwBSjEMPcISw/viewform">Apply to CS100A here</a>.</p>
        <p>The class is on Tuesdays from 1:30 - 3:20 pm starting Week 2.</p>
      </div>
    </div>

    */}
<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Final Exam Logistics</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hello everyone! We'll have our final exam on <b>Friday, August 16th from 3:30pm - 6:30pm</b>. We will be in Hewlett 200.</p> 
        <p>Students with OAE exam accommodations should have received an email from Ngoc with their
          location and timing details.</p>

        <p>Important exam logistics:</p>
        <ul>
          <li>The exam will be on paper, so please bring a pencil or pen.</li>
          <li>You may bring up 1 page of notes on letter paper to reference during the exam. You can use both sides of the paper.</li>
          <li>The exam will be cumulative up to and including sorting, but will NOT cover the following topics:</li>

          <ul>
            <li>Karel</li>
            <li>Images</li>
            <li>The Internet and anything after</li>
          </ul>
        </ul>

        <p>You may not use a laptop or any other devices during the exam, unless
          you have academic accommodations to use such devices.
        </p>

        <p>Here is the <Link to="https://docs.google.com/document/d/1mrFV0-xcT73tLHLes5Q18YUZCRxRjfFY4sAEgF6LXVg/edit">Final Prep Handout</Link>.</p>

      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 5 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released <Link to="/assn6">Assignment 5: Bajillion</Link>! It's due on Monday, August 12th at 11:59pm PT. <b>We will not be allowing late
            days on this assignment, even if you haven't used up your late days yet. All submissions submitted past the due date will not receive credit.
          </b>
        </p>
      </div>
    </div>
  
  <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Grades Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all, we've just released the Midterm grades on <a href="https://www.gradescope.com/">Gradescope</a>. Please see Clinton's announcement on <a href="https://edstem.org/us/courses/60442/discussion/5136756">Ed</a> for more details about the Midterm. 
        </p>
      </div>
    </div>
  
  <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">CS106A Contest</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>The CS106A Contest is now open - here's a chance for you to demonstrate some of the skills you've learned
          this quarter, while having some fun and being creative! You can find more details on
          the  <Link to="/contest"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>CS106A Contest Handout</Link>.
        </p>
      </div>
    </div>

  
  <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 4 Released!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released <Link to="/assn5">Assignment 4: Infinite Story</Link>! It's due Friday, August 2nd at 11:59pm. We highly recommend it you start it early! You can find the starter code on  <a href="https://edstem.org/us/courses/60442/discussion/5126072">Ed</a>.
          <div className='w-100 d-flex justify-content-center'>
            <img className="w-100" src={InfiniteStory}/>
          </div>
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Next Week</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi all! We'll be having our midterm exam next Thursday, July 25th from 1:30pm-3:30pm during class time in NVIDIA Auditorium, our usual lecture room.</p>

        <p>Students with OAE exam accommodations will receive an email from Ngoc with their location and timing details this weekend.</p>
        <p>Important exam logistics:</p>
        <ul>
          <li>The exam will be on paper, so please bring a pencil or pen.</li>
          <li>You may bring 1 page of notes on letter paper to reference during the exam. You can use both sides of the paper.</li>
          <li>The exam will cover everything up through and including Lecture 11, except nested dictionaries.</li>
        </ul>
        <p>Exam Prep:</p>
        <ul>
          <li>Check out the <a href="https://docs.google.com/document/d/1megUtMXHPLXPYidLtmfH2vH3xbPON_6YWy0AelumbKQ/edit">Midterm Prep Handout</a>!</li>
          <li>Check out the Midterm Review Session</li>
          <li>Section problems are a good way to practice as well</li>
          <li>Come to Ngoc's and Clinton's OH or LaIR to clarify concepts</li>
        </ul>
      </div>
      </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Midterm Review Session!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p> Hi everyone! We will be having our Midterm Review Session this Friday, July 19th at 3 pm right after lecture in Building 200-002. </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 3 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, we've just released <Link to="/assn3">Assignment 3: Sand</Link>. It's due <b> 11:59pm (Pacific Time) on Monday, July 22nd</b>. 
        <p></p>
        <p><b>Update (07/15): </b>There is a missing <code>simpleimage.py</code> file. You can either follow the directions on the <a href="https://cs106a.stanford.edu/images">Images Handout</a> to get the simpleimage.py file into your Assignment 3 folder or redownload the starter code on the <a href="https://cs106a.stanford.edu/assn3">Assignment 3 Handout</a>!</p>
          <div className='w-100 d-flex justify-content-center'>
            <img style={{maxWidth:400}} src={Sand}/>
          </div>
        </p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 2 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        We have released <Link to="/assn2">Assignment 2: Khansole Academy & Computing Interest</Link>.  It will be due
        on Friday, July 12th at 11:59pm.<br />
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">LaIR Tonight</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, LaIR tonight (7/3) will be in Durand 023!</p>
      </div>
    </div>
  
  <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Sections This Week</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, if you have a section on Thursday, July 4th this week, please attend a make-up section on Wednesday or Friday. You can find all the section times and locations in this <a href="https://edstem.org/us/courses/60442/discussion/5076016">Ed Post</a>.</p>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">PyCharm Installation Help</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Hi everyone, if you're having trouble with <b>Python/PyCharm installation</b> as you get started on HW1, there are many
          resources available to you to help debug these issues. <b>The key is to start early</b> because you might not be able
          to get support if you start the homework the day it's due.
        </p>
        <ul>
          <li><Link to="/installing-pycharm"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Installing PyCharm handout</Link> - Make
            sure you've carefully followed these instructions when installing both Python 3.11 and PyCharm.</li>
          <li><Link to="/pycharm-bugs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>PyCharm Bugs handout</Link> -
            See if the bug you're experiencing is listed here.</li>
          <li><a href="https://edstem.org/us/courses/57479/discussion/">CS106A Ed Forum</a> - Check if someone else has posted
            about your issue, and if not, make a post yourself! If you post any code, make the post private.</li>
          <li><b>PyCharm help hours</b> - Ngoc and some section leaders will be hosting help hours in Durand 303 after class on Friday, June 28th from 3-4:30pm. Come by to work through any persistent Python/PyCharm bugs!
          </li>
          <li><b>Ngoc's office hours and LaIR</b> - You're welcome to bring any installation bugs to our usual
            help resources as well, or talk to us after class!</li>
        </ul>
      </div>
    </div>

<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Assignment 1 Released</div>
          <div className="newsfeedTime">
          </div>
        </div>
        We have released <Link to="/assn1">Assignment 1</Link>, which you can find under the 'Assignments' dropdown above. It will be due
        on Friday, July 5th at 11:59pm:<br />
        <div className='w-100 d-flex justify-content-center'>
          <HorizontalSpeechBubble text="Let's learn together!" />
           
        </div>

      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">LaIR Starting this Thursday</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Now that <Link to="/assn1">Assignment 1</Link> is underway, we'll be offering LaIR hours starting this Thursday. LaIR is an assignment help resource that allows
          students to ask debugging or conceptual questions to a section leader one-on-one.</p> 

        <p>LaIR will be open school nights, <b>Sunday through Thursday from 5pm-9pm PT</b> on the 3rd floor of the <a
          href="https://www.google.com/maps/place/Durand+Building,+496+Lomita+Mall,+Stanford,+CA+94305/@37.4268738,-122.1754608,17z/data=!3m1!4b1!4m5!3m4!1s0x808fbb2aa1a2e6c7:0x150c778bd3b558cf!8m2!3d37.4268696!4d-122.1732721">Durand Building</a>.
          LaIR is in-person, but you'll use this <a href="https://cs198.stanford.edu/lair">LaIR signup link</a> (also linked in the "resources" section
          on the right of the course website) to get in the LaIR queue and chat with a section leader.
        </p>

        <p>Note that <b>LaIR is in person</b> unless you are an SCPD student. If you would like to request temporary virtual
          LaIR access (reserved for special circumstances like COVID or injury) please reach out to <a href="mailto: ngocng@stanford.edu">Ngoc</a> via email.</p>
      </div>
    </div>


<div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Section Signups</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Section signups will open on the <a href="https://cs198.stanford.edu/cs198/auth/section/SectionSignup.aspx?class=1">CS198 website</a> on Friday, June 21st at 5pm PT. Please note that
          signups are <b>not</b> first-come-first-serve, so you have until the form closes on Tuesday, June 25th at 5pm PT to fill in your preferences. </p>
        <p>There are two virtual sections, which are reserved for SCPD students. <b>You may only rank these sections if you are SCPD</b> (but SCPD students are welcome
          to rank in-person sections if they're local).</p>
      </div>
    </div>

    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">First Lecture Soon!</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>The first CS106A Summer 2024 lecture is at 1:30 PM on Tuesday, June 25th in {courseInfo.location}: </p>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.3782624195314!2d-122.17686982388416!3d37.428167972074824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fbb2ac97723cb%3A0xa6e8fc1a7a5f2c29!2sNVIDIA%20Auditorium!5e0!3m2!1sen!2sus!4v1718954487958!5m2!1sen!2sus" width="100%" height="450" style={{ border: 0 }} loading="lazy" ></iframe>
      </div>
    </div>


    <div className="card announcement">
      <div className="card-body">
        <div className="newsfeedHeader">
          <div className="newsfeedTitle">Welcome</div>
          <div className="newsfeedTime">
          </div>
        </div>
        <p>Welcome to CS106A! We hope you are ready to learn some python, and the foundational ideas behind of programming. We can't wait to teach you all and are looking forward to a fun quarter. Here are some docs to help you learn about the course: </p>
        <p> The <Link to="/syllabus"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Syllabus</Link> page has details on course logistics.
          <br />

          The <Link to="schedule"><span className="fa fa-calendar-o mr-sm-1" aria-hidden="true"></span>Schedule</Link> page has a list of all topics that we will cover in CS106A.
        </p>

        <p></p>

        <h3>🌱🚀🎉</h3>

      </div>
    </div>
  </>

}