import PyodideProvider from "components/pyodide/PyodideProvider";
import { Outlet } from "react-router";
import { CS106ANavbar } from "./Navbar";
import { ProfileContext, ProfileProvider } from "contexts/ProfileContext";
import { useContext } from "react";
import styled from "styled-components";

export const CoursePage = () => {
  return <ProfileProvider>
    
    <CoursePageInner />
  </ProfileProvider>
}

const CoursePageInner = () => {

  // set the page title to be cs106a
  document.title = "CS106A | Summer";
  return <CoursePageContainer>
    <CS106ANavbar />
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  </CoursePageContainer>
}

const CoursePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`

const ContentContainer = styled.main`
width: 100%;
overflow-y: auto;
overflow-x: hidden;
display: flex;
flex-grow: 1;
flex-basis: 0;
`

export default CoursePage;