import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan2 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 2: Variables & Control Flow</h1>
        <hr />

        <p>This section, we're getting into some real Python code (goodbye for now, Karel) and practicing control flow with
            user input and printing, which will be great preparation for Assignment 2! The last problem will have students
            write and use a helper function that takes in a parameter, which is another new topic from this week.
        </p>

        <h3>Section flow</h3>
        <ol>
            <li><b>5 min: Check in.</b> You might spend a few minutes at the begining of every section
                checking in with your sectionees about how the course and life in general is going. You could do a
                short icebreaker, ask if students have any questions... we just want everyone to get comfortable and engaged
                before diving into the section content.</li>
            <li><b>15 min: Tracing.</b> This is a good way to confirm that everyone can follow the execution of a while loop. I'd ask
                students to talk with a neighbor about what's going on here before asking people to share out in a big group. Explain to
                your sectionees why tracing is a valuable skill - so valuable they might see it on the homework and exams!</li>
            <li><b>30 min: Choose one larger problem.</b> There are two more problems on the handout which both explore a similar
                concept: doing some calculation with the user's input, until the user enters a sentinel value. For Dog Years,
                we're turning a human age into a dog age. In a slightly tricker problem, Finding Factors, we're printing all the
                factors of some positive integer, and using a helper function <code>print_factors(num)</code> to do the printing
                for us. You might prep both and see how your section is feeling after the tracing problem, or just pick the
                one you like better ahead of time!</li>
        </ol>

        <h3>Section tips</h3>

        <p>Get students practice with using the both the conditional While loops and While True loops!</p>
        <p>Parameters and returns (although we're not doing returns in section) are always conceptually tricky for students. In
            Monday's lecture, Chris really emphasized that <code>input()</code> from the user is not the same as parameters,
            and <code>print()</code> is not the same as returning. You should discuss this in section too: we have a way of
            sending values in and out of functions and this is different from the way we get and show data
            in the terminal with <code>input()/print()</code>.</p>

        <p>If your section was quieter last week, see if students are more talkative in small groups! For the tracing problem,
            have them turn to a neighbor before coming back and analyzing the code as a group. If you do the Finding Factors problem,
            you could have students implement the <code>print_factors(num)</code> helper function
            in small groups. It's great to show that there are different ways to solve the same problem, and talk about the functional
            and stylistic tradeoffs of different approaches.
        </p>

        <p>As you may have seen when preparing for LaIR, these section problems look a lot like this week's homework assignment.
            This section handout is a good resource as students work through Assignment 2, and
            the problems you don't get to in section will be great for midterm review!
        </p>
    </>
}