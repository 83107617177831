import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from "react-router-dom"


export const FinalPrepSoln = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Final Prep Solutions</h1>
        <hr />

        <h2>Full Programs</h2>
        <h3>Rock, Paper, Scissors</h3>

        <PythonHighlighter code={rps} />

        <h3>Estimate Pi</h3>

        <PythonHighlighter code={pi} />

        <h2>Graphics</h2>

        <h3>Expanding Circles</h3>

        <PythonHighlighter code={circles} />

        <h3>Magic Wand</h3>

        <PythonHighlighter code={magic_wand} />

        <h2>Lists</h2>

        <h3>Interesting Lists</h3>

        <PythonHighlighter code={interesting} />

        <h3>Collapse</h3>

        <PythonHighlighter code={collapse} />

        <h3>Rotate</h3>

        <PythonHighlighter code={rotate} />

        <h2>Lists of Lists</h2>

        <h3>Average Temps (Again)</h3>

        <PythonHighlighter code={temps} />

        <h3>Fireworks</h3>

        <PythonHighlighter code={explode} />

        <h2>Images</h2>

        <h3>Darken</h3>

        <PythonHighlighter code={darken} />

        <h3>RGB Channels</h3>

        <PythonHighlighter code={channels} />

        <h2>Strings</h2>

        <h3>Make Password</h3>

        <PythonHighlighter code={password} />

        <h3>Longest Consonant</h3>

        <PythonHighlighter code={cons} />

        <h2>Dictionaries</h2>

        <h3>Grade Boost</h3>

        <PythonHighlighter code={boost} />

        <h3>Animal Counts</h3>

        <PythonHighlighter code={animal} />

        <h2>Nested Dictionaries</h2>

        <h3>List2Dict</h3>

        <PythonHighlighter code={friends} />

        <h3>Find Grandchildren</h3>

        <PythonHighlighter code={grand} />

        <h2>Classes</h2>
        <PythonHighlighter code={trace} />


        

    </>
}

const trace = `Neigh
Woof
Meow
None
None
Woof
None
Neigh`

const rps = `import random 

NUM_ROUNDS = 5

def main():
    num_human_wins = 0

    for i in range(NUM_ROUNDS):
        user_choice = int(input("Your move (1, 2, or 3): "))
        computer_choice = random.randint(1, 3)
        
        if computer_choice == user_choice:
            print("It's a tie!")
        else:
            if user_choice == 1:
                if computer_choice == 3:
                    print("You win! Rock crushes scissors")
                    num_human_wins += 1
                else:
                    print("You lose! Paper covers rock")

            if user_choice == 3:
                if computer_choice == 2:
                    print("You win! Scissors cuts paper")
                    num_human_wins += 1
                else:
                    print("You lose! Rock crushes scissors")
            
            if user_choice == 2:
                if computer_choice == 1:
                    print("You win! Paper covers rock")
                    num_human_wins += 1
                else:
                    print("You lose! Scissors cuts paper")

    print("You won " + str(num_human_wins) + " rounds!")

if __name__ == "__main__":
    main()`

const pi = `import random

NUM_POINTS = 100_000

def main():
    """
    Estimates the value of π by randomly choosing points in the square and 
    calculating the percentage of points that lie in the circle. Prints out
    the estimated value of π.
    """
    num_in_circle = 0 # Counter variable

    for i in range(NUM_POINTS):
        """
        Pick a random point in the square. To do this, we need to pick a random
        x coordinate and random y coordinate, each in the interval [-1, 1].
        """
        x = random.uniform(-1, 1)
        y = random.uniform(-1, 1)

        """
        The formula for a circle is x^2 + y^2 = r^2. In this case, since the
        circle has radius 1, it can be described by x^2 + y^2 = 1. Points that
        lie *inside* the circle have x^2 + y^2 <= 1.
        """
        if x ** 2 + y ** 2 <= 1:
            # (x, y) is inside the circle
            num_in_circle += 1

    """
    The area of the square is 4, the area of the circle is π. Thus, the
    percentage of points in the circle is approximately π / 4.
    """
    percent_in_circle = num_in_circle / NUM_POINTS # ~ π / 4
    π = percent_in_circle * 4

    print("π is roughly " + str(π))


if __name__ == '__main__':
    main()`

const interesting = `def make_interesting(num_list): 
    result = [] 
    for value in num_list: 
        if value >= 0: 
            if (value % 2) == 1: # Check if value is odd 
                result.append(value * 10) 
            else: 
                result.append(value) 
    return result`

const collapse = `def collapse(nums):
    result = []
    for i in range(len(nums)//2):
      # since we're going pairs at a time, we only need
      # to go as many as times as half the number of elements
        result.append(nums[i * 2] + nums[i * 2 + 1])
  
    if len(nums) % 2 == 1:
        result.append(nums[-1])
    return result`

const rotate = `def rotate_list_right(numbers, n):
    output_list = []
    for i in range(len(numbers) - n, len(numbers)):
        output_list.append(numbers[i])

    for i in range(0, len(numbers) - n):
        output_list.append(numbers[i])

    return output_list`


const darken = `def darken(filename):
    image = SimpleImage(filename)
    for pixel in image:
        pixel.red = pixel.red / 2
        pixel.green = pixel.green / 2
        pixel.blue = pixel.blue / 2

    return image`

const channels = `def tinted(filename):
    image = SimpleImage(filename)
    for pixel in image:
        x = pixel.x

        if x < image.width / 3:
            # red tint
            pixel.blue = 0
            pixel.green = 0
        elif x < image.width * 2 / 3:
            # green tint
            pixel.red = 0
            pixel.blue = 0
        else:
            # blue tint
            pixel.red = 0
            pixel.green = 0`

const password = `def make_password(str):
    result = ''
    for char in str:
        low = char.lower()
        if low == 'a':
            result += '@'
        elif low == 'i':
            result += '!'
        elif low == 'o':
            result += '0'
        else:
            result += char
    return result`


const cons = `def longest_consonants(str):
    best_count = 0
    count = 0
    for char in str:
        if char.isalpha() and char.upper() not in 'AEIOU':
            count += 1
            if count > best_count:
                best_count = count
        else:
            count = 0
    return best_count`

const boost = `def grade_boost(students, letter):
    for student in students:
        if student[0] == letter:
            students[student] += 5
    return students`

const friends = `def list_to_dict(friends_lst):
    friends_dict = {}
    curr_friend = ''
    for elem in friends_lst:
        if elem.isalpha():
            curr_friend = elem
            friends_dict[curr_friend] = []
        else:
            friends_dict[curr_friend].append(elem)
    return friends_dict`

const grand = `def find_grandchildren(parents_to_children):
    result = {}
    for parent in parents_to_children:
        all_grandchildren = []
        # get a list of this person's children
        children = parents_to_children[parent]
        # check if each child has any children themselves
        for child in children:
            if child in parents_to_children:
                # if so, add each child as a grandchild
                grandchildren = parents_to_children[child]
                for grandchild in grandchildren:
                    all_grandchildren.append(grandchild)

        # only add to result dict if there were grandchildren
        if len(all_grandchildren) > 0:
            result[parent] = all_grandchildren

    return result`

const animal = `def get_animal_counts(filename):
    counts = {}
    with open(filename) as f:
        for line in f:
            line = line.strip()
            parts = line.split(':')
            animal = parts[0].lower()
            count = int(parts[1])
            if animal not in counts:
                counts[animal] = count
            else:
                counts[animal] += count

    return counts`

const temps = `def annual_temps(nested):
    result = []
    for lst in nested:
        total = 0
        for temp in lst:
            total += temp
        avg = total / 12
        result.append(avg)

    return result`

const explode = `def explode(grid, x, y):
    """
    Helper function, explodes the firework at
    location x, y
    """
    grid[y][x] = None
    if x > 0:
        grid[y][x-1] = 's'
    if x < len(grid[0]) - 1:
        grid[y][x+1] = 's'
    if y > 0:
        grid[y-1][x] = 's'
    if y < len(grid) - 1:
        grid[y+1][x] = 's'

def explode_fireworks(grid):
    for y in range(len(grid)):
        for x in range(len(grid[0])):
            if grid[y][x] == 'f':
                explode(grid, x, y)
    return grid`

const magic_wand = `from graphics import Canvas
import time

WAND_WIDTH = 5
WAND_HEIGHT = 50

SPARKLE_RADIUS = 5
SPARKLE_Y_VEL = 10

DELAY = 1/60


def main():
    canvas = Canvas()
    width = canvas.get_width()
    height = canvas.get_height()

    wand = canvas.create_rectangle(0, 0, WAND_WIDTH, WAND_HEIGHT)
    sparkles = []

    while True:

        # bottom right of wand is attached to mouse
        wand_left_x = canvas.get_mouse_x() - WAND_WIDTH
        wand_top_y = canvas.get_mouse_y() - WAND_HEIGHT
        canvas.moveto(wand, wand_left_x, wand_top_y)

        # move down all the existing sparkles
        for sparkle in sparkles:
            canvas.move(sparkle, 0, -SPARKLE_Y_VEL)
        
        # new sparkle
        sparkle = canvas.create_oval(wand_left_x + WAND_WIDTH / 2 - SPARKLE_RADIUS, wand_top_y - SPARKLE_RADIUS,
                                    wand_left_x + WAND_WIDTH / 2 + SPARKLE_RADIUS, wand_top_y + SPARKLE_RADIUS,
                                    'yellow')
        sparkles.append(sparkle)

        time.sleep(DELAY)
        canvas.update()


if __name__ == '__main__':
    main()`

const circles = `from graphics import Canvas
import time

STARTING_RADIUS = 10
GROWTH_RATE = 5
DELAY = 1/60


def main():
    canvas = Canvas()
    width = canvas.get_width()
    height = canvas.get_height()

    cur_radius = STARTING_RADIUS
    color = 'black'

    while True:
        canvas.create_oval(width / 2 - cur_radius, height / 2 - cur_radius,
                           width / 2 + cur_radius, height / 2 + cur_radius,
                           color)

        cur_radius += GROWTH_RATE

        if cur_radius >= width / 2:
            cur_radius = STARTING_RADIUS
            if color == 'white':
                color = 'black'
            else:
                color = 'white'

        time.sleep(DELAY)
        canvas.update()


if __name__ == '__main__':
    main()`

const houses = `def num_rooms(tup):
    return tup[1]

def price(tup):
    return tup[2]

def price_per_room(tup):
    return tup[2]/tup[1]

houses = [('main st.', 4, 4000), ('elm st.', 1, 1200), ('pine st.', 2, 1600)]
# In ascending order by number of rooms
print(sorted(houses, key=num_rooms))
# In descending order of price
print(sorted(houses, key=price, reverse=True))
# In ascending order of price-per-room
print(sorted(houses, key=price_per_room))`

const class_class = `# File: Class.py

class Class:

    def __init__(self):
        """
        Creates a new instance of the Class class
        """
        self.students = {}   # instance variable to keep track of the student/id pairs

    def add_student(self, name, id):
        """
        Adds a student by name (string) and id (int) to the class
        """
        self.students[name] = id

    def get_id(self, name):
        """
        Returns the id of the student with this name, or -1 if this
        student isn't in the class
        """
        if name in self.students:
            return self.students[name]
        return -1

    def get_class_list(self):
        """
        Returns a list of the names of all students enrolled in the class
        """
        student_lst = []
        for student in self.students:
            student_lst.append(student)
        return student_lst`

const library_class = `# File: library.py

class Library:

    def __init__(self):
        """
        Creates a new instance of the Library class
        """
        self.library = []   # instance variable to keep track of the book titles

    def return_book(self, title):
        """
        Returns a book with the given title to the library
        """
        self.library.append(title)

    def check_out(self, title):
        """
        Removes a book with the given title from the library, or prints
        'No book with that title' if that book isn't found
        """
        if title in self.library:
            self.library.remove(title)
        else:
            print('No book with that title')

    def get_catalog(self):
        """
        Returns a list of all books currently in the library, sorted
        alphabetically
        """
        return sorted(self.library)
`

const pizza = `# File: pizza.py

class Pizza:

    def __init__(self):
        """
        Creates a new instance of the Pizza class
        """
        self.num_slices = 8
        self.toppings = []

    def take_slice(self):
        """
        Takes a slice from the pizza, reducing its number of slices by 1
        """
        if self.num_slices > 0:
            self.num_slices -= 1
        else:
            print('No slices left')

    def get_topping_list(self):
        """
        Returns a list of all toppings currently on the pizza
        """
        return self.toppings

    def add_topping(self, topping):
        """
        Adds the topping (which is a string) to the pizza, so that all future
        calls to get_topping_list include this topping
        """
        self.toppings.append(topping)`