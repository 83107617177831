import { doc, getFirestore, updateDoc, collection, addDoc, getDoc, setDoc, serverTimestamp, query, getDocs, orderBy, limit } from "firebase/firestore";
import { useCollection, useCollectionData, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import { useState, useEffect } from "react";
import { getTeachNowTrainingVideoStoragePath } from "../application/sectionLeaderApplication/teachingDemo/uploadVideo"
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";



export const logRejectionFeedbdack = async (nudgeId, data, courseId) => {
    const rejectionFeedbackPath = `teachnow/${courseId}/nudges/${nudgeId}`;
    const db = getFirestore();
    const docRef = doc(db, rejectionFeedbackPath);
    await setDoc(docRef, {
      rejectReasonInfo: {
          ...data,
          accepted: false,
          nudgeResponseTimestamp: serverTimestamp()
      }
    }, {merge: true});
};

export async function logPuzzleError(userId, courseId, puzzleProblem, errorMessage, puzzleId, puzzleLogDocId) {
    const errorData = {
        errorMessage,
        puzzleId,
        timestampSubmitted: serverTimestamp(),
        userId,
        courseId
    };
    const errorLogsPath = `teacherPuzzles/${puzzleProblem}/errorLogs`;
    const db = getFirestore();
    const errorLogsRef = collection(db, errorLogsPath);
    const docRef = await addDoc(errorLogsRef, errorData);
    return docRef.id;
}

// This function logs the start time of a puzzle for a user and returns the document ID
export async function logPuzzleStartTime(userId, courseId, puzzleId, armType='teacherPuzzlesGPT') {
    const db = getFirestore();

    const logsPath = `teachNowTraining/${armType}/${courseId}/${userId}/logs`;
    const logsRef = collection(db, logsPath);

    try {
        // Create a new document with start time and puzzle ID
        const docRef = await addDoc(logsRef, {
            startTime: serverTimestamp(),
            startPuzzleId: puzzleId
        });
        return docRef.id;  // Return the document ID
    } catch (error) {
        console.error('Error in logPuzzleStartTime: ', error);
        return null;  // Return null if there's an error
    }
}


// This function updates the list of seen puzzles and logs the puzzle data.
export async function handlePuzzleSubmission(userId, courseId, puzzleType, puzzleData, logDocId) {
    const armType = 'teacherPuzzlesGPT'; // Assuming this is static for now, but need to update when users can be in more than one arm
    const db = getFirestore();


    const dbPath = `teachNowTraining/${armType}/${courseId}/${userId}`;
    const userTrainingDataRef = doc(db, dbPath);
    const logsPath = `${dbPath}/logs`;

    const puzzleId = puzzleData.puzzleInfo.puzzleId

    // We want to update this, but there should already be a doc here with the user's start time
    const puzzleLogDocRef = doc(db, `${logsPath}/${logDocId}`); // Reference to the specific puzzle log document

    try {
        // Fetch current user training data
        const docSnapshot = await getDoc(userTrainingDataRef);
        if (!docSnapshot.exists()) { // If it doesn't exist yet, create a document and add the puzzle
            // Set default structure for new user data
            const userTrainingData = {
                seenPuzzles: {}
            };
            userTrainingData.seenPuzzles[puzzleType] = [puzzleId];
            // Initialize the document with default data
            await setDoc(userTrainingDataRef, userTrainingData);
        } else { // If it exists, update the list of seen puzzles
            const userTrainingData = docSnapshot.data();
            const seenPuzzles = userTrainingData?.seenPuzzles || {};

            if (!seenPuzzles[puzzleType]) {
                seenPuzzles[puzzleType] = [];
            }

            if (!seenPuzzles[puzzleType].includes(puzzleId)) {
                seenPuzzles[puzzleType].push(puzzleId);
                await updateDoc(userTrainingDataRef, { seenPuzzles });
            }
        }

        // In either case, log the puzzle
        await setDoc(puzzleLogDocRef, {
            type: puzzleType,
            data: puzzleData,
            timestampSubmitted: serverTimestamp()
        }, { merge: true });

    } catch (error) {
        console.error('Error in handlePuzzleSubmission: ', error);
    }
}

export async function loadPuzzles(puzzleProblem, courseId, userId, puzzleType) {
    const db = getFirestore();
    const puzzlesPath = `teacherPuzzles/${puzzleProblem}/${courseId}`;
    const userTrainingDataPath = `teachNowTraining/teacherPuzzlesGPT/${courseId}/${userId}`;

    try {
        const userTrainingDataRef = doc(db, userTrainingDataPath);
        const userDoc = await getDoc(userTrainingDataRef);
        let orderedPuzzles = userDoc.exists() ? userDoc.data().orderedPuzzles?.[puzzleType] : null;

        if (orderedPuzzles) {
            // If the ordered puzzles already exist, return them without overwriting
            return orderedPuzzles;
        } else {
            // Fetch all puzzles for the problem and type
            const puzzlesQuery = query(collection(db, puzzlesPath));
            const querySnapshot = await getDocs(puzzlesQuery);
            const allPuzzles = querySnapshot.docs.map(doc => doc.id);

            // Store the ordered puzzles in the Firestore document
            await setDoc(userTrainingDataRef, { orderedPuzzles: { [puzzleType]: allPuzzles } }, { merge: true });

            return allPuzzles;
        }
    } catch (error) {
        console.error('Error loading puzzles:', error);
        return [];  // Return an empty list in case of error
    }
}

export async function getNextPuzzle(userId, puzzleProblem, courseId, puzzleType) {
    const db = getFirestore();
    const userTrainingDataPath = `teachNowTraining/teacherPuzzlesGPT/${courseId}/${userId}`;

    try {
        const userTrainingDataRef = doc(db, userTrainingDataPath);
        const userDoc = await getDoc(userTrainingDataRef);

        if (!userDoc.exists()) {
            console.error('No user data available.');
            return null;
        }

        const seenPuzzles = userDoc.data().seenPuzzles?.[puzzleType] || [];
        const orderedPuzzleIds = userDoc.data().orderedPuzzles?.[puzzleType] || [];

        // Find the first unseen puzzle's docId
        const nextPuzzleId = orderedPuzzleIds.find(puzzleId => !seenPuzzles.includes(puzzleId));
        if (!nextPuzzleId) {
            console.log('No more unseen puzzles.');
            return null;  // No more unseen puzzles
        }

        // Retrieve the full puzzle data from the corresponding document
        const puzzleRef = doc(db, `teacherPuzzles/${puzzleProblem}/${courseId}/${nextPuzzleId}`);
        const puzzleDoc = await getDoc(puzzleRef);

        return puzzleDoc.exists() ? { id: puzzleDoc.id, ...puzzleDoc.data() } : null;
    } catch (error) {
        console.error('Error getting next puzzle:', error);
        return null;  // Return null in case of error
    }
}


export async function loadTrueShortLabels(courseId, puzzleProblem, puzzleId) {
    const db = getFirestore();
    const puzzlesPath = `teacherPuzzles/${puzzleProblem}/${courseId}/${puzzleId}`;

    try {
        const puzzleRef = doc(db, puzzlesPath);
        const docSnapshot = await getDoc(puzzleRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            // Check if trueShortLabels exists in the document
            if (data.trueShortLabels) {
                return data.trueShortLabels;
            } else {
                console.error('True short labels are not found in the document');
                return null;  // or you can return an empty array or default object depending on your needs
            }
        } else {
            console.error('No document found for the specified puzzle');
            return null;  // or you can return an empty array or default object depending on your needs
        }

    }
    catch (error) {
        console.error('Error loading true bugs:', error);
        return null;  // Return null or any default value in case of error
    }
}


export const useNumTeacherPuzzlesCompleted = (puzzleType, userId, courseId) => {
    const db = getFirestore();
    const trackablesPath = `/users/${userId}/${courseId}`
    const teachNowTeacherPuzzlesDocRef = doc(db, `${trackablesPath}/teachNowTeacherPuzzlesProgress`);

    const [teachNowTeacherPuzzlesProgress, loading, error] = useDocumentData(teachNowTeacherPuzzlesDocRef);

    if (loading || error) {
        return [ 0, loading, error ]
    }

    let numCompleted = 0; // Default to 0


    if (teachNowTeacherPuzzlesProgress && teachNowTeacherPuzzlesProgress[puzzleType] !== undefined) {
        numCompleted = teachNowTeacherPuzzlesProgress[puzzleType];
    }

    return [numCompleted, loading, error]
};


// Functions below this point are for the experienced student logic and experiment 

export const useUploadedVideo = (userId, path) => {
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Only run the effect if path is not null and is a valid string
        if (path !== null && path !== '') {
            setIsLoading(true); // Ensure loading state is true when starting the fetch
            const storage = firebase.storage();
            const storageRef = storage.ref();
            const videoRef = storageRef.child(path);

            videoRef.getDownloadURL()
                .then((url) => {
                    setVideoUrl(url);
                    setIsLoading(false); // Set loading false when fetch is successful
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false); // Set loading false also on error
                });
        } else if (path === '') {
            // Handle empty path explicitly if needed
            setIsLoading(false);
        }
    }, [path]); // Depend on path to re-run the effect when path changes

    // Handle the scenario when path is null indicating still loading from async function
    if (path === null) {
        return [null, true, null]; // Video URL is null, isLoading is true, error is null
    }

    return [videoUrl, isLoading, error]; // Return the current state of the video URL, loading status, and any error
};


export const copyVideo = async (uid, timestamp) => {
    const storage = firebase.storage();
    const originalPath = getTeachNowTrainingVideoStoragePath(uid)
    const originalRef = storage.ref(originalPath);
    try {
        // Download the file
        const url = await originalRef.getDownloadURL();
        const response = await fetch(url);
        const blob = await response.blob();

        const path = `teachNowTrainingVideos/submitted/${uid}/${timestamp}`;
        const newRef = storage.ref(path);

        // Upload the file to the new path
        await newRef.put(blob);

        console.log('Video copied successfully!');
        return path;
    } catch (error) {
        console.error('Error copying video: ', error);
        return null;
    }
};

export const getMostRecentVideoUrl = async (uid) => {
    const storage = firebase.storage();
    const directoryPath = `teachNowTrainingVideos/submitted/${uid}/`;
    const directoryRef = storage.ref(directoryPath);

    try {
        // List all files in the directory
        const result = await directoryRef.listAll();
        const files = result.items;

        if (files.length === 0) {
            console.log('No videos found for the specified UID.');
            return '';
        }

        // Sort files by their timestamp (assumed to be part of the file name)
        const sortedFiles = files.sort((a, b) => {
            const timestampA = parseInt(a.name.split('/').pop());
            const timestampB = parseInt(b.name.split('/').pop());
            return timestampB - timestampA; // Sort in descending order
        });

        // Get the download URL of the most recent file
        const mostRecentFile = sortedFiles[0];
        // just need the file path 
        return mostRecentFile.fullPath;
    } catch (error) {
        console.error('Error retrieving videos: ', error);
        return '';
    }
};

export const getMostRecentSubmissionDocId = async (courseId, userId) => {
    const db = getFirestore();
    const submissionPath = `teachNowTraining/studentSubmittedApplications/${courseId}/${userId}/submissions`;
    const submissionsRef = collection(db, submissionPath);
    // Sort by 'submittedAt' in descending order and return the most recent docId 
    const currQuery = query(submissionsRef, orderBy('submittedAt', 'desc'), limit(1));
    const querySnapshot = await getDocs(currQuery);
    const mostRecentSubmission = querySnapshot.docs[0];
    const mostRecentDocId = mostRecentSubmission?.id || null;
    return mostRecentDocId;
};

export const useStudentApplicationStatus = (courseId, userId) => {
    const db = getFirestore();
    const studentRef = doc(db, `teachNowTraining/studentSubmittedApplications/${courseId}/${userId}`);
    const [studentData, studentLoading, studentError] = useDocumentData(studentRef);

    const applicationStatus = studentData?.applicationStatus || null;
    return [applicationStatus, studentLoading, studentError];
};

export const studentConfirmTrainingComplete = async (userId, courseId, displayName, videoPath) => {
    const db = getFirestore();
    const studentPath = `teachNowTraining/studentSubmittedApplications/${courseId}/${userId}`;
    const studentRef = doc(db, studentPath);

    try {
        const docSnap = await getDoc(studentRef);

        if (docSnap.exists()) {
            // If the document exists, update the applicationStatus
            await updateDoc(studentRef, {
                applicationStatus: 'submitted'
            });
        } else {
            // If the document does not exist, create a new document
            await setDoc(studentRef, {
                displayName: displayName,
                userId: userId,
                applicationStatus: 'submitted'  // Assuming you want to set it as 'submitted' immediately
            });
        }

        // Create a new document in the submissions collection
        const submissionsRef = collection(db, studentPath, "submissions");
        await addDoc(submissionsRef, {
            userId: userId,
            courseId: courseId,
            displayName: displayName,
            videoPath: videoPath,
            submittedAt: serverTimestamp()
        });

        console.log("Operation completed successfully");
    } catch (error) {
        console.error("Error handling the training completion:", error);
    }
};


export const useStudentsSubmittedTN = (courseId) => {
    const db = getFirestore();
    const studentsPath = `teachNowTraining/studentSubmittedApplications/${courseId}`;
    const studentsRef = collection(db, studentsPath);
    const [students, studentsLoading, error] = useCollectionData(studentsRef);
    return [students, studentsLoading, error]
}

export const claimReview = async (courseId, userId, reviewerId, reviewerName, status) => {
    const db = getFirestore();
    const studentRef = doc(db, `teachNowTraining/studentSubmittedApplications/${courseId}/${userId}`);
    const now = serverTimestamp();
    await updateDoc(studentRef, { 
        reviewed: status,
        applicationStatus: 'in_review', // Update application status to 'in_review' when review is claimed
        reviewerId: reviewerId, // Nullify when review is complete or released
        reviewerName: reviewerName, // Nullify when review is complete or released
        claimTimestamp: now, // Record when review was claimed
    }, { merge: true });
};

export const submitStudentEvalTN = async (courseId, userId, reviewerId, reviewerName, evalData, readyToTeachStatus, submissionDocId) => {
    const db = getFirestore();
    const path = `teachNowTraining/studentSubmittedApplications/${courseId}/${userId}/submissions/${submissionDocId}`;
    const submitData = {
        ...evalData,
        userId,
        reviewerId,
        reviewerName,
        readyToTeachStatus,
        timestampSubmitted: serverTimestamp()
    }
    // update this foc with the feedback 
    const submissionRef = doc(db, path);
    await updateDoc(submissionRef, {
        'feedbackFromTeachingTeam': submitData
    }, { merge: true });
}

export const updateDecisionStatus = async (courseId, userId, readyToTeachStatus, applicationStatus) => {
    const db = getFirestore();
    const studentRef = doc(db, `teachNowTraining/studentSubmittedApplications/${courseId}/${userId}`);

    await updateDoc(studentRef, 
        { 
            applicationStatus: applicationStatus,
            readyToTeachStatus: readyToTeachStatus, 
            reviewed: 'completed'
        }, { merge: true });
}




export const useStudentPuzzles = () => {
    const courseId = useCourseId();
    const db = getFirestore()
    const puzzlesPath = `teacherPuzzles/khansole/${courseId}`
    const puzzlesRef = collection(db, puzzlesPath)
    const [puzzles, loading, error] = useCollection(puzzlesRef)
    const [puzzleData, setPuzzleData] = useState(null)
    const [puzzleDataLoading, setPuzzleDataLoading] = useState(true)

    useEffect(() => {
        if(loading) return
        const data = puzzles.docs.map(doc => {
            return {data: doc.data(), id: doc.id}
        })
        const buildPuzzleData = {} 
        data.forEach(puzzle => {
            buildPuzzleData[puzzle.id] = puzzle.data
        })
        setPuzzleData((_) => {
            return buildPuzzleData
        })
        setPuzzleDataLoading(false)

    }, [loading, puzzles])

    return [puzzleData, puzzleDataLoading, error]

}



export const useStudentPuzzleDataDoc = () => {
    const courseId = useCourseId();
    const userId = useUserId();
    const db = getFirestore()
    const [userPuzzlesDoc, userPuzzlesDocLoading, userPuzzlesDocError] = useDocument(doc(db, "teachNowTraining", "students", courseId, userId));
    return [userPuzzlesDoc, userPuzzlesDocLoading, userPuzzlesDocError]
}


// This function updates the list of seen puzzles and logs the puzzle data.
export async function handleStudentPuzzleSubmission(userId, courseId, puzzleType, puzzleIndex, logDocId, studentResponse, studentNoticing) {
    const armType = 'students'; 
    const db = getFirestore();


    const dbPath = `teachNowTraining/${armType}/${courseId}/${userId}`;
    const userTrainingDataRef = doc(db, dbPath);
    const logsPath = `${dbPath}/logs`;

    // We want to update this, but there should already be a doc here with the user's start time
    const puzzleLogDocRef = doc(db, `${logsPath}/${logDocId}`); // Reference to the specific puzzle log document

    try {
        // Fetch current user training data
        const docSnapshot = await getDoc(userTrainingDataRef);
        if (!docSnapshot.exists()) { // If it doesn't exist yet, create a document and add the puzzle
            // Set default structure for new user data
            const userTrainingData = {
                seenPuzzles: [puzzleIndex]
            };
            // Initialize the document with default data
            await setDoc(userTrainingDataRef, userTrainingData);
        } else { // If it exists, update the list of seen puzzles
            const userTrainingData = docSnapshot.data();
            const seenPuzzles = userTrainingData?.seenPuzzles || [];

            if (!seenPuzzles.includes(puzzleIndex)) {
                seenPuzzles.push(puzzleIndex);
                await updateDoc(userTrainingDataRef, { seenPuzzles });
            }
        }

        // In either case, log the puzzle
        await setDoc(puzzleLogDocRef, {
            type: puzzleType,
            puzzleIndex,
            studentNoticing,
            studentResponse,
            timestampSubmitted: serverTimestamp()
        }, { merge: true });

    } catch (error) {
        console.error('Error in handlePuzzleSubmission: ', error);
    }
}
