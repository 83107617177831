import { FaCheck, FaPause, FaPython, FaRocket } from "react-icons/fa"
import { Link } from "react-router-dom"

export const TodoList = () => {
  return <Week7/>
}

const Week1 = () => {
  return <>
    <p className="subtleHeading">Week 1 TODOs</p>
    <FaRocket className="sidebaricon" /><Link to="https://cs198.stanford.edu/cs198/auth/default.aspx">Attend your first section!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/1">First lecture!</Link><br />
    <FaPython className="sidebaricon" /><Link to="https://forms.gle/R2RtGxSic2ZSpMdQ8">Assn #0</Link><br />
    <FaPython className="sidebaricon" /><Link to="/assn1">Assn #1</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/2">Second lecture!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/3">Third lecture!</Link><br />

    </>
}


const Week2 = () => {
  return <>
    <p className="subtleHeading">Week 2 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn1">Finish Assn #1!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/4">More Python</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/5">Intro to Python, 3</Link><br />
    <FaPython className="sidebaricon" /><Link to="/assn2">Start Assn #2</Link><br />


    </>
}

const Week3 = () => {
  return <>
    <p className="subtleHeading">Week 3 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn3">Assn 3!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/6">Text Processing</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/7">More Strings</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/8">Lists and Tuples</Link><br />
    {/* <FaRocket className="sidebaricon" /><Link to="/lecture/9">Ninth lecture!</Link><br /> */}

    </>
}

const Week4 = () => {
  return <>
    <p className="subtleHeading">Week 4 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn3">Assn 3!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/9">Grids and Images</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/10">Dictionaries, Sets, and Files</Link><br />
    <FaRocket className="sidebaricon" /><Link to="https://docs.google.com/document/d/1megUtMXHPLXPYidLtmfH2vH3xbPON_6YWy0AelumbKQ/edit#heading=h.n0ytc5op8j16">Study for the Midterm!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/11">Nested Structures</Link><br />




    </>
}

const Week5 = () => {
  return <>
    <p className="subtleHeading">Week 5 TODOs</p>
    <FaRocket className="sidebaricon" /><Link to="/lecture/12">Nested Structures Review, Intro to APIs</Link><br />
    <FaRocket className="sidebaricon" /><Link to="https://docs.google.com/document/d/1megUtMXHPLXPYidLtmfH2vH3xbPON_6YWy0AelumbKQ/edit#heading=h.n0ytc5op8j16">Midterm!</Link><br />
    </>
}

const Week6 = () => {
  return <>
    <p className="subtleHeading">Week 6 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn5">Infinite Story!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/14">More Classes Lecture!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/15">Midterm Retro, Classes!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/16">Indexing and Searching!</Link><br />
    <FaPython className="sidebaricon" /><Link to="/assn6">Start Bajillion!</Link><br />




    </>
}

const Week7 = () => {
  return <>
    <p className="subtleHeading">Week 7 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn6">Bajillion!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/17">Program Efficiency and Many Cool Algorithms</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/18">The Internet</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/19">Cool Stuff</Link><br />




    </>
}

const Week8 = () => {
  return <>
    <p className="subtleHeading">Week 8 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn5">Finish Infinite Story!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/21">21st lecture!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/22">22nd lecture!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/23">23rd lecture!</Link><br />
    <FaPython className="sidebaricon" /><Link to="/assn6">Start Bajillion!</Link><br />




    </>
}

const Week9 = () => {
  return <>
    <p className="subtleHeading">Week 9 TODOs</p>
    <FaPython className="sidebaricon" /><Link to="/assn6">Finish Bajillion!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/24">24th lecture!</Link><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/25">25th lecture!</Link><br />
    </>
}

const Week10 = () => {
  return <>
    <p className="subtleHeading">Week 10 TODOs</p>
    <FaCheck className="sidebaricon" /><a href="/assn6">Finish Bajillion!</a><br />
    <FaCheck className="sidebaricon" /><a href="/practice-final">View exam logistics</a><br />
    <FaCheck className="sidebaricon" /><a href="/final-prep">Study for the final!</a><br />
    <FaRocket className="sidebaricon" /><Link to="/lecture/26">26th lecture!</Link><br />


  </>
}