import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./PrivatePostSplash.css";

export function PrivatePostSplash(props: {}) {
  return (
    <div className="postSplash">
      <FontAwesomeIcon
        icon={faLock}
        className="fa-10x"
        color="rgb(89, 64, 203)"
      />
      <div className="spiel">
        <h1>This post is private!</h1>
        <h2>Select another post on the left</h2>
      </div>
    </div>
  );
}
