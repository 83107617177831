import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
// @ts-ignore
import SandStart from "./img/assn4/sand1.png"
// @ts-ignore
import SandDown from "./img/assn4/sand2.png"
// @ts-ignore
import CornerRule from "./img/assn4/corner.png"
// @ts-ignore
import Campfire from "./img/assn4/campfire.jpeg"
// @ts-ignore
import CampfireGray from "./img/assn4/campfire_gray.png"
// @ts-ignore
import SandDemo from "./img/assn4/sand_demo.mov"

import { Link } from "react-router-dom";


export const Assn4 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Assignment 4: Lists of Lists, Strings, Images, and Sand</h1>
        <hr />

        <p><b>Due: 2pm (Pacific Time) on Monday, May 13th</b></p>

        <i>Based on problems by Nick Parlante and Eric Roberts.</i>

        <br />
        <br />


        <p>This assignment consists of a few different programs to give you practice with an assortment of topics
            we've seen recently in lecture: list of lists
            (2-dimensional lists), strings, and images in Python. We'll finish with a longer program called
            Sand, which uses lists of lists to form a grid through which grains of sand can fall. In order to download the starter code,
            please fill out the anonymous <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdE-cjOadBMYGZiw3T1Fb9-uAkK0u2xhOhNBAAvWlF1LrlePg/viewform">Mid-Quarter Class Survey.</Link> The starter code will be shown on the confirmation page AFTER you submit the survey.</p>

        <p>The first two sandcastles will be great practice for the midterm diagnostic, as lists of lists
            and strings will be covered on the exam. While we've got an image sandcastle, images will not be
            on the midterm.
        </p>

        <h2>Part 1: Sandcastles</h2>

        <h3>1. Zip Lists</h3>

        <p>In the file <code>ziplists.py</code>, implement the function <code>zip2lists(list1, list2)</code>. This
            function is passed two lists of strings (<code>list1</code> and <code>list2</code>), and you can
            assume that both lists have the same length (number of elements). The function should return a
            new list that "zips" together the two lists passed in. That is, the result should be a list that
            contains lists that are pairs of elements, one from each of the original lists, in order.
            For example:</p>

        <PythonHighlighter code={zip_lists_run} />

        <p>The original lists passed in should <b>not</b> be changed. If this function is passed two empty
            lists, it should just return an empty list, since there would be no lists (of pairs) in the result.
            In other words:</p>

        <PythonHighlighter code={zip_lists_empty} />

        <p>You can think of that as a list that contains no lists as elements.</p>

        <p>Doctests are provided in the starter code for you to test your <code>zip2lists</code> function.
            You can run them by right-clicking on the doctests and then clicking "Run Doctest …", or by
            running <code>python3 -m doctest ziplists.py -v</code> in
            the terminal (replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows
            computers). Feel free to write additional doctests if you would like to test your code more thoroughly.</p>

        <h3>2. Expand Encoded Strings</h3>

        <p>One way of compressing text with many repeated characters is to use something known as <i>run-length encoding</i>.
            The idea is that rather than representing every character in a string explicitly, we instead have each character
            immediately followed by a digit which denotes how many times that character should be repeated.</p>

        <p>For example, the string <code>'B4'</code> would be the encoding for the string <code>'BBBB'</code> as the
            character B is to be repeated 4 times. Similarly, the string <code>'m1e2t1'</code> would
            represent <code>'meet'</code>. Thus, the general
            format for run-length encoded strings is a series of pairs, where each pair contains a single character
            followed immediately by a single digit (1 through 9 only, and the digit will never be 0). The digit denotes
            the number of consecutive occurrences of the character immediately before it in the encoded string.</p>

        <p>In the file <code>encoded_string.py</code>, implement the function <code>expand_encoded_string(encoded)</code> that
            takes as a parameter <code>encoded</code>, which is a string representing the run-length encoded text, and returns a
            string which is the expanded version of the text.</p>

        <PythonHighlighter code={encoded_string_run} />

        <p>We've included doctests in the starter code for you to test your <code>expand_encoded_string</code> function.
            You can run them by right-clicking on the doctests and then clicking "Run Doctest …", or by
            running <code>python3 -m doctest encoded_string.py -v</code> in
            the terminal (replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows
            computers). Feel free to write additional doctests if you would like to test your code more thoroughly.</p>

        <h3>3. Finding Forest Fires</h3>

        <p><i>Important setup note:</i> Before you get started on this part of the assignment, make sure that you have
            followed the Pillow installation instructions, which can be found on
            the <Link to="/images"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Image Reference</Link> handout.
            If you cannot get Pillow installed successfully, please come to LaIR or post on the forum for help.</p>

        <p>We're going to write a function called <code>highlight_fires</code> in the
            file <code>forestfire.py</code> that highlights the areas where a forest fire is active. You're given a
            satellite image of the 2018 "Camp Fire" wildfire in California (credit: NASA), and your job is to detect
            all of the "sufficiently red" pixels
            in the image, which are indicative of where fires are burning.</p>

        <p>As we did in class with the "redscreening" example (see lecture 12 code), we consider a pixel "sufficiently red"
            if its red value is greater than or equal to the average
            of the pixel's three RGB values times some intensity threshold. In this case, we have provided you with
            an appropriate intensity threshold of 1.35 via a constant named <code>INTENSITY_THRESHOLD</code> in the
            file <code>forestfire.py</code>. Note that this is a different intensity threshold value than we used
            in class for the "redscreening" example, as different applications often require different intensity
            thresholds.</p>

        <p>When you detect a "sufficiently red" pixel in the original image, set its red value to 255 and its green
            and blue values to 0. This will highlight the pixel by making it entirely red. For all other pixels
            (i.e., those that are not "sufficiently red"), you should convert them to their grayscale equivalent,
            so that we can more easily see where the fire is burning. You can grayscale a pixel by
            summing together its red, green, and blue values and dividing by three (finding the average), and then
            setting the pixel's red, green, and blue values to all have this same "average" value. Once you
            highlight the areas that are on fire in the image (and grayscale all the remaining pixels), you
            should see an image like the one shown below on the right.</p>

        <div className="img-fluid mx-auto d-block">
            <img src={Campfire} width="50%"
                alt="aerial view of forest fire and smoke" />
            <img src={CampfireGray} width="50%"
                alt="aerial view of forest fire, which has been grayscaled except the fire which is now colored red" />
        </div>

        <br />

        <p>You can run your code by entering <code>python3 forestfire.py</code> in the terminal
            (replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows computers).</p>

        <p>For a helpful reminder about the <code>SimpleImage</code> library and tips on working with images, you
            can use the <Link to="/images"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Image Reference</Link> handout.</p>

        <h2>Part 2: Sand</h2>

        <p>For the final part of this assignment, you will write a program in <code>sand.py</code>, that uses 2-dimensional
            lists to to implement a kind of 2-dimensional (grid) world of sand. When the program is working, it's pretty fun
            to play around with.</p>

        <center>
            <video controls width="50%">
                <source src={SandDemo} className="img-fluid mx-auto d-block" type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </video>
        </center>
        <br />

        <p>This is a longer program, and we want the advantage of using decomposition to solve this problem
            in bite-sized chunks. So, we will build the program up as a series of decomposed functions. We can
            leverage doctests, testing each function in isolation before moving on to the larger functions.</p>

        <p>The Sand world is represented using a 2-dimensional list (i.e., list of lists or grid) that keeps
            track of the elements in the Sand world. Each element (which you can think of as a square) in the
            Sand world holds one (and only one) of three things:</p>

        <ul>
            <li>Sand, represented by the string <code>'s'</code></li>
            <li>Rock, represented by the string <code>'r'</code></li>
            <li>Empty, represented by <code>None</code> (this is Python's <code>None</code>, not a string)</li>
        </ul>

        <p>Here is a visual representation of a 3x2 grid (i.e., list of lists) with some rocks and one sand.
            The sand <code>'s'</code> is in the 0th row, 1st column (equivalently, <code>x = 1, y = 0</code>). Recall
            that <code>y</code> represents the row number and <code>x</code> is the column number. The 0th row
            (<code>y = 0</code>) is the top row.</p>

        <img src={SandStart} className="img-fluid mx-auto d-block" width="50%"
            alt="3x2 grid with rocks ('r') in the leftmost and rightmost columns, one sand 's' at the top of the middle column" />

        <p>This grid can be represented by a list of lists, as follows:</p>

        <PythonHighlighter code={sand_start} />

        <p>If we move the sand (<code>'s'</code>) down from row 0, column 1 (<code>x = 1, y = 0</code>) to row 1,
            column 1 (<code>x = 1, y = 1</code>) the grid would looks like this:</p>

        <img src={SandDown} className="img-fluid mx-auto d-block" width="50%"
            alt="3x2 grid with rocks ('r') in the leftmost and rightmost columns, one sand 's' at the bottom of the middle column" />

        <p>This grid can be represented by a list of lists, as follows:</p>

        <PythonHighlighter code={sand_end} />

        <p>Moving sand down by one square like this is the central operation of this game, and moving down each piece of
            sand one square at a time creates the effect of falling sand you saw in the demo.</p>

        <p>You should write your code in the following five stages, which accord with helper functions that you'll want to
            implement:</p>

        <h3>1. Moving Elements in the Grid</h3>

        <p>Function: <code>do_move(grid, x1, y1, x2, y2)</code></p>

        <p>Write the code for the <code>do_move</code> function. This function is passed a grid (list of lists,
            representing the Sand world, as described above) and two coordinate
            pairs: <code>x1, y1</code> and <code>x2, y2</code>. The function should update the grid by moving the
            contents of the grid at location <code>x1, y1</code> into the grid at location <code>x2, y2</code>.
            That means you should also update the element at
            position <code>x1, y1</code> to reflect that this cell in the grid is now empty. You can assume that your function
            is only called with parameters for "legal" moves, that is, you can assume that all coordinates given to you
            are in the bounds of the grid and that the element at position <code>x2, y2</code> starts empty. Your
            function should return the updated <code>grid</code>.</p>

        <p>You may be wondering why your function needs to return the updated grid if changes you make
            to the grid parameter will persist after this function is done. The reason is that we provide doctests
            for your function and these tests work by examining what is returned by the function. So, for the doctests
            to work, your function needs to return the updated grid. You should use these doctests to
            test that your implementation works before you move on.</p>

        <p>The code for <code>do_move</code> is short, but it is a good example of using doctests to try out test cases.
            Two doctests are provided. Feel free to add others to more thoroughly test your code. Doctest pro-tip:
            you can run a doctest by right-clicking on the doctest text and selecting the "Run Doctest …" option from
            the menu that pops up. Running a doctest again and again is common as you debug each function.</p>

        <p>The first doctest we've provided looks just like the diagrams we explored above:</p>

        <PythonHighlighter code={do_move_doctest} />

        <p>Here is what the three lines of the doctest do:</p>

        <ol>
            <li><code>[['r', 's', 'r'], ['r', None, 'r']]</code> - This sets up a <code>grid</code> variable for the next 2 lines. The grid is tiny:
                width is 3 and height is 2, just enough to write a little test.</li>
            <li><code>do_move(grid, 1, 0, 1, 1)</code> - This line calls your <code>do_move</code> function, moving the <code>'s'</code> at
                location (1,0) to location (1,1). The function returns the changed grid.</li>
            <li><code>[['r', None, 'r'], ['r', 's', 'r']]</code> - This shows what the grid should look like post-move.
                The doctest machinery verifies that the result returned by the <code>do_move</code> function matches
                this expected output. Notice that the expected return is the only line that doesn't begin with <code>{">"}{">"}{">"}</code>.</li>
        </ol>

        <p><i>IMPORTANT NOTE:</i> Recall that when you access elements of a list of lists, the first index represents the
            row, which is the y-coordinate. The second index represents the column, which is the x-coordinate. That's in
            reverse order of what we usually think of when dealing with (x, y) coordinates. To be more explicit,
            to access the element at location (<code>x = 1, y = 0</code>), you would do <code>grid[0][1]</code>. It's important
            to keep this point clear while you are developing your code for this assignment.</p>

        <h3>2. Checking Legal Moves</h3>

        <p>Function: <code>check_move(grid, x1, y1, x2, y2)</code></p>

        <p>Write the code for the <code>check_move</code> function. This function is given a
            starting (<code>x1, y1</code>) location and destination (<code>x2, y2</code>) location for a potential move, and
            returns <code>True</code> if the move is okay, or <code>False</code> otherwise. <i>The grid is not changed by this operation.</i></p>

        <p>We'll call location (<code>x1, y1</code>) the "start" of the move, and (<code>x2, y2</code>) the "destination"
            of the move. In the Sand world, there are five possible moves relative to the starting location:
            left, right, down, down-left, and down-right. Here are the rules for a legal move:</p>

        <ol>
            <li>The destination must be in bounds.</li>
            <p>Three doctests for this rule are provided. These tests build a 3 column by 2 row grid, checking that a
                few out-of-bounds moves return <code>False</code>.</p>
            <PythonHighlighter code={bounds_doctest} />
            <li>The destination square in the grid must be empty (that is, it should contain <code>None</code>).</li>
            <p>Here are some doctests we've provided to confirm that your function returns <code>True</code> or <code>False</code> depending
                on whether the destination square is empty.</p>
            <PythonHighlighter code={empty_doctest} />
            <li>For a diagonal down-left or down-right move, the "corner" must be empty (it should contain <code>None</code>).</li>
            <p>Consider the down-left and down-right diagonal moves of a piece of sand at location (<code>x=1, y=0</code>) in
                a 3 column, 2 row grid:</p>

            <img src={CornerRule} className="img-fluid mx-auto d-block" width="80%"
                alt="3x2 grid with a rock at (2, 0) and sand at (1,0). The sand cannot move down-right because its right corner
                (2, 0) is blocked." />

            <p>The "corner rule" states that for a down-left or down-right move, the square above the destination must also
                be empty. So, in the picture above, the down-left is okay since location (0, 0) is empty, but down-right
                is bad due to the <code>'r'</code> (rock) at location (2, 0). Sand ('s') at the same location (2, 0) would
                also block the move.</p>

            <p>Note that the "corner rule" isn't referring to the four corners of the grid; any diagonal move within the grid has a
                "corner" (the square above the destination), even if it isn't in the corner of the grid.
            </p>

            <p>We've provided two doctests for the corner rule, which match the diagram shown above:</p>

            <PythonHighlighter code={corner_doctest} />
        </ol>

        <p>There are many reasonable ways to structure your code for the <code>check_move</code> function. Our solution
            has a single <code>return True</code> at the bottom of the function, and
            many <code>if ... : return False</code> statements detecting the various cases where the move is bad. Use the
            doctests as you work out the code - you'll want these passing before you move on to the next section!
        </p>

        <p>Note that it's fine to use <code>==</code> and <code>!=</code> in comparisons like this:</p>

        <PythonHighlighter code={is_none} />

        <p>Disregard warnings PyCharm gives you about the line above. The warnings, in this case, are misguided.
            PyCharm is pretty good, but it's not perfect.</p>

        <h3>3. Implementing Gravity</h3>

        <p>Function: <code>do_gravity(grid, x, y)</code></p>

        <p>Write the code for the <code>do_gravity</code> function. This function simulates the effect of gravity
            on a <i>single piece of sand</i> in the Sand world. Here's the basic set-up. Consider an (<code>x,y</code>) location
            in the grid (these are passed in as parameters to <code>do_gravity</code>). This function does
            one "gravity" move if there is sand at that (<code>x,y</code>) location as described below. In the gravity algorithm,
            the moves should be attempted in a specific order:</p>

        <ol>
            <li>If there is not a sand <code>'s'</code> at location (<code>x,y</code>), do nothing, the move is over.</li>
            <li>If the sand can move down, do it, this ends the move.</li>
            <li>Otherwise, if the sand can move down-left, do it, this ends the move.</li>
            <li>Otherwise, if the sand can move down-right, do it, this ends the move.</li>
        </ol>

        <p>Note that these cases are <i>mutually exclusive</i>, meaning that only one move, if any, should occur.
            In all cases, return the grid when the function is done. As we talked about earlier in this assignment,
            you need to return the grid here so that the doctests for this function work appropriately.</p>

        <p><i>Use the helper functions you wrote in the previous steps</i> to do the work - that is the key to this function.
            How do you know if it's okay for sand to move from one location to another? You've got a
            helper function for that!
            We provide a set of doctests for this function, but you are always free to add more doctests if you like.</p>

        <h3>4. Looping Through the Whole Grid</h3>

        <p>Function: <code>do_whole_grid(grid, brownian)</code></p>

        <p>The next step is to write the code for the <code>do_whole_grid</code> function. For the moment, ignore
            the <code>brownian</code> parameter, which is handled in a later step.</p>

        <p>To create the effect of falling sand, call <code>do_gravity</code> once for every (<code>x,y</code>) location
            in <code>grid</code>. The function should return the grid when it is done (again, the reason for this
            is to have some result returned from the function for doctests to compare against).</p>

        <p><i>Important note on looping through the grid:</i> The standard y/x nested for loops to loop through
            the coordinates of a grid from the top row downward are usually fine for working with grids. However,
            in this case, it is important to <i>reverse the y-direction</i> to have the loop go bottom-up. That is,
            you should visit the bottom row (which is at <code>y = height - 1</code>) first and the top row
            (which is at <code>y = 0</code>) last. In this situation, it will be helpful to use
            the <code>reversed</code> function. The <code>reversed</code> function can be applied to a range
            to generate the elements of that range in reverse order. Here is an example of using
            the <code>reversed</code> function with <code>range</code> in a for loop:</p>

        <PythonHighlighter code={reversed_range} />

        <p>The code above would produce the following output:</p>

        <PythonHighlighter code={reversed_output} />

        <p>What's wrong with the regular top-down order of going through rows? Suppose the loops went top-down, and at the
            top row (<code>y=0</code>), a sand moved from row 0 to row 1 by gravity.
            Then, when the loop got to <code>y=1</code>, that same sand would get to move again. Since behind the scenes,
            we're animating our Sand graphics using a similar technique to the animation loop we've seen before,
            we wouldn't want a single piece of sand to move more than once in a single timestep! Going bottom-up avoids this problem.</p>

        <p>The <code>do_whole_grid</code> function just does one pass over the whole grid in the Sand world,
            calling <code>do_gravity</code> (and later, after you implement the next step, <code>do_brownian</code>) once
            for each square. The provided graphics code calls your <code>do_whole_grid</code> function again and
            again to run the whole simulation.</p>

        <h3><i>Milestone</i>: Run sand.py!</h3>

        <p>With your functions tested, you can try running the whole program by
            entering <code>python3 sand.py</code> (replace <code>python3</code> with <code>py</code> or <code>python</code> on Windows computers)
            into your terminal.
            Gravity should work, but "Brownian" is not
            done yet. Normally when a program runs the first time, there are many problems. But here we have leaned on
            decomposition and testing of the pieces as they were built, so there is a chance your code will work perfectly
            the first time. If your program works the first time, try to remember the moment. More typically, new code is
            not so well tested and so exhibits some bugs when run the first time.</p>


        <p>When the Sand window comes up, you can click (and hold the mouse) anywhere in the window to start generating
            "sand" in the simulation. The radio-buttons for "Sand", "Rock", "Erase", and "Big Erase" allow you to generate
            different effects when you select them and the click in the window (allowing you to create rocks and also
            erase existing sand/rocks). You can turn on/off Gravity and Brownian motion using the respective checkboxes.
            Right now, Brownian should appear to do nothing.</p>

        <h3>5. Create Brownian Motion</h3>

        <p>Function: <code>do_brownian(grid, x, y, brownian)</code></p>

        <p>You're nearly there! <a href="https://en.wikipedia.org/wiki/Brownian_motion">Brownian motion</a> is a real
            physical process, documented first by Robert Brown, who observed tiny pollen grains jiggling around on his
            microscope slide.</p>

        <p>We'll say that doing a "Brownian" move for an (<code>x,y</code>) location means that there is a probability
            that sand at that location will randomly move just a little bit — one square left or right.
            The <code>brownian</code> parameter is an integer in the range 0 to 100 inclusive: 0 means never do a Brownian move, 100 means always
            do a Brownian move. This value is taken in real-time from the little slider at the top of the Sand
            application window. This function does not have tests (randomness and tests are an awkward combination).</p>

        <p>Here are the steps for implementing Brownian motion in the Sand world:</p>

        <ol>
            <li>Check if the square is sand. Proceed to the next steps only if it is
                sand. If it is not sand, the square cannot have Brownian motion.</li>
            <li>Create a random number in the range 0 to 99. Note that the Python
                function <code>random.randrange(n)</code> (from the <code>random</code> library) returns a random
                number uniformly distributed in the range from 0 to <code>n - 1</code>, inclusive. So, you could use
                the following call:
                <PythonHighlighter code={randrange} />
                Proceed to step #3 below only
                if <code>num {"<"} brownian</code> (recall that <code>brownian</code> is a parameter to this function).
                In this way, for example, if brownian is 50, we'll do Brownian motion about 50% of the time.</li>
            <li>To decide whether to move left or right, "flip a coin" by randomly generating a 0 or 1 like
                this:
                <PythonHighlighter code={randcoin} />
            </li>
            <li>If <code>coin</code> is 0, try to move the sand at the current (<code>x,y</code>) location one square
                to the left. If <code>coin</code> is 1, try to move the sand at the current (<code>x,y</code>) location
                one square right. Use your helper functions to check if the move is possible and then move the sand
                if so. Don't try both directions; if you flip a 0 and find that you cannot move left,
                do not try moving right.</li>
        </ol>

        <p>After you have implemented the <code>do_brownian</code> function, you should edit the loop body
            in <code>do_whole_grid</code> so that after calling <code>do_gravity</code> for an (<code>x,y</code>) location,
            it also calls <code>do_brownian</code> for that (<code>x,y</code>) location.</p>

        <p>Now, try running the Sand program from the terminal as you did before, and make sure the Brownian
            checkbox in the application is switched on. Hopefully, you'll see a lively, less artificial look.
            Play around with the slider (next to the Brownian checkbox) to create different levels of Brownian motion.</p>

        <h3>Wrapping Up</h3>
        <p>Give yourself a pat on the back - you've learned a ton over the course of this assignment! Check out these sections
            to learn some more about your wonderful new Sand program.
        </p>

        <h4>Running with Different Sizes</h4>

        <p>You can provide two command line arguments (numbers) to specify the number of squares wide and high
            that the grid should be in the Sand simulation. The following line would
            run the Sand simulation using a grid that is 100 by 50 squares:</p>

        <PythonHighlighter code={sand_window_size} />

        <p>An optional third command line argument specifies how many pixels wide each square should be. For example,
            the following line would run the Sand simulation with a 100 by 50 square grid, where each square was
            just 4 pixels:</p>

        <PythonHighlighter code={sand_square_size} />

        <h4>Lots of Computation!</h4>

        <p>The Sand program may be pretty demanding on your computer - it's a lot of computation run continuously.
            You may notice the fans on your laptop spinning up. At the upper right of the window is a little gray number,
            which is the frames-per-second (fps) the program is achieving in the sand animation at that moment.
            The animation has a nice, fluid look when the fps is higher.</p>

        <p>The more grid squares there are, and the more grains of sand there are, the slower the program runs.
            For each gravity round, your code needs to at least glance at every square and every grain of sand,
            and we want to do that 40 times per second. Play around with different grid and pixel sizes to try
            out the different aesthetics.</p>

        <h3>Extensions</h3>

        <p>Once you've completed all the required parts of the assignment, you might want to
            consider adding an <i>optional</i> extension. Remember not to modify your completed assignment files, but
            instead put the extension in a new file like <code>extension.py</code>.</p>

        <p>While we aren't providing any guidelines for extensions, some ideas you might try out are:</p>

        <ul>
            <li>Write a program that does the opposite of <code>encoded_string.py</code> - that is, it turns
                regular text into its run-length encoding.</li>
            <li>Create a different image manipulation program.</li>
            <li>Create a version of Sand with different physical properties.</li>
        </ul>

        <h3>Submitting your code</h3>
        <p>All assignments are submitted electronically through the <a href="https://cs198.stanford.edu/paperless/">Paperless
            website</a>. Since the submission's timestamp is based on the time that the server receives the assignment, we recommend
            submitting with at least a few minutes to spare, particularly if you discover that your computer's clock is
            running slow.</p>

        <p>Click on the link to Paperless above. Select the assignment you wish to submit (in this case, Assignment 4:
            Sand.
            You should upload all of the appropriate files for the chosen assignment. Please do not rename the files
            when you download them. Here are the files to submit for this assignment:</p>

        <ul>
            <li><code>ziplists.py</code></li>
            <li><code>encoded_string.py</code></li>
            <li><code>forestfire.py</code></li>
            <li><code>sand.py</code></li>
        </ul>

        <p>If you did an optional extension on the assignment, you should submit:</p>

        <ul>
            <li><code>extension.py</code></li>
            <li>Any additional files required for extension</li>
        </ul>

        <p>Once they have been uploaded and you have verified that you've included all of the files
            outlined in the assignment handout, hit the "Submit Assignment" button. You should always check the contents
            of your submission to make sure the code files are up-to-date and correct.
            To inspect the submission, click on the specific assignment and use the dropdown to view each file's contents.</p>

        <p>And that's it! You can submit each assignment as many times
            as you would like by following the instructions outlined in this document. However, your section leader will
            grade only the most recent submission.</p>
    </>

}

const zip_lists_run = `# calling this function:
zip2lists(['a', 'b', 'c'], ['d', 'e', 'f'])
# should return the following new list (of lists):
[['a', 'd'], ['b', 'e'], ['c', 'f']]`

const zip_lists_empty = `# calling this function:
zip2lists([],[])
# should return the following empty list:
[]`

const encoded_string_run = `# calling this function:
expand_encoded_string('B1o2k2e2p1e1r1!3')
# should return the following string:
'Bookkeeper!!!'`

const sand_start = `[['r', 's', 'r'], ['r', None, 'r']]`

const sand_end = `[['r', None, 'r'], ['r', 's', 'r']]`

const do_move_doctest = `>>> grid = [['r', 's', 'r'], ['r', None, 'r']]
>>> do_move(grid, 1, 0, 1, 1) 
[['r', None, 'r'], ['r', 's', 'r']]`

const bounds_doctest = `>>> grid = [[None, 's', 'r'], [None, None, None]]
>>> check_move(grid, 0, 0, -1, 0) # x2 = -1 is out of bounds
False
>>> check_move(grid, 0, 0, 0, 4)  # y2 = 4 is out of bounds
False
>>> check_move(grid, 0, 0, 3, 1)  # x2 = 3 is out of bounds
False`

const empty_doctest = `>>> grid = [[None, 's', 'r'], [None, None, None]]
>>> # check of left move from (1,0)
>>> check_move(grid, 1, 0, 0, 0)  # left ok
True
>>> # check of right move from (1,0)
>>> check_move(grid, 1, 0, 2, 0)  # right blocked
False
>>> # check of down move from (1,0)
>>> check_move(grid, 1, 0, 1, 1)  # down, ok
True`

const corner_doctest = `>>> grid = [[None, 's', 'r'], [None, None, None]]
>>> # check of down-left move from (1,0)
>>> check_move(grid, 1, 0, 0, 1)  # down-left ok, corner rule
True
>>> # check of down-right move from (1,0)
>>> check_move(grid, 1, 0, 2, 1)  # down-right blocked, corner rule
False`

const is_none = `if x != None:`

const reversed_range = `for i in reversed(range(3)):
    print(i)`

const reversed_output = `2
1
0`

const randrange = `num = random.randrange(100)`

const randcoin = `coin = random.randrange(2)`

const sand_window_size = `python3 sand.py 100 50`

const sand_square_size = `python3 sand.py 100 50 4`