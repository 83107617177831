import { ProfileProvider } from "contexts/ProfileContext"
import { CS106ANavbar } from "cs106a/components/Navbar"
import { useWindowSize } from "react-use-size";
import { Forum } from "../../course/forum/pages/forum/Forum"


export const CS106AForumInner = () => {
    const windowSize = useWindowSize();
    return <div style={{display:'flex', flexDirection:'column', height:'100%'}}>

        <div className="container-lg">
            <div className="row">
                <div className="col">
                <Forum/>
                </div>
            </div>
        </div>
    </div>
}

export default CS106AForumInner