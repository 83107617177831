import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from "react-router-dom"
// @ts-ignore
import Pi1 from "./img/finalPrep/pi1.png"
// @ts-ignore
import Pi2 from "./img/finalPrep/pi2.png"
// @ts-ignore
import Pi3 from "./img/finalPrep/pi3.png"
// @ts-ignore
import Original from "./img/finalPrep/original.png"
// @ts-ignore
import Dark from "./img/finalPrep/dark.png"
// @ts-ignore
import Tinted from "./img/finalPrep/tinted.png"
// @ts-ignore
import Wand from "./img/finalPrep/magic_wand.mov"
// @ts-ignore
import Circles from "./img/finalPrep/circles.mov"


export const FinalPrep = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Final Prep</h1>
        <hr />
        <h5><Link to="/jasmyn"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>

        <p>We've compiled some old section problems and written some new ones for you to use as you review for the final exam.
            We'd recommend writing your answers on paper to mimic the exam environment, and attempting to solve each problem as
            best you can before turning to the solution.
        </p>

        <p>We don't have any starter code, but we've got a link to the solutions above, and you're welcome to check your answers or
            play around with the code in a <a href={`${process.env.PUBLIC_URL}/ContestProject.zip`}>blank project</a> like this one that we provided for the CS106A Contest.
        </p>

        <p>
            One other way you can study is through a completely optional teaching activity called <Link to="/cs106a/teachnowhome">TeachNow</Link>, where you can practice what you’ve learned by teaching other students.
        </p>

        <h2>Full Programs</h2>

        <h3>Rock, Paper, Scissors</h3>

        <p>In 1997, a computer named Deep Blue beat world chess champion Gary Kasparov at a game of chess. In 2023,
            IBM has finally gained the confidence to expand its repertoire of human vs. computer games and enlisted
            you to write a program that allows a human player and computer to spar over a game of Rock Paper Scissors.</p>

        <p>Each game consists of 5 rounds of Rock, Paper, Scissors. Each round consists of you - the user - choosing whether
            to play Rock, Paper or Scissors, and the computer doing the same. In this game, the user will type 1 if they
            wish to play Rock, 2 if they wish to play Paper, and 3 if they wish to play Scissors.</p>

        <p>In each round, you should do the following:</p>

        <ol>
            <li>Prompt the user to enter their move (you can assume they type '1', '2' or '3'.)</li>
            <li>Randomly choose either rock, paper, or scissors as the computer's move using the random module (remember,
                calling <code>random.randint(a, b)</code> will give you back an integer between a and b, inclusive).</li>
            <li>Determine who wins the round. As a reminder, rock beats scissors, scissors beats paper, and paper, somewhat
                inexplicably, beats rock.</li>
            <li>Print a message reporting whether the human player won or lost and which moves each player made.</li>
        </ol>

        <p>At the end of the program, you should print a message saying how many rounds the user won.</p>

        <p>Here's a sample run, where the user's input appears after "Your move (1, 2, or 3): ". Remember that this is
            a random program, so if you run your code, you might see different results in each match.
        </p>

        <PythonHighlighter code={rps_run} />

        <h3>Estimating Pi</h3>

        <p>Write a program that estimates the value of pi using Python!</p>

        <p>π is a mathematical constant that comes from geometric properties of a circle. Specifically, a circle that
            has radius r will have area (πr^2) and circumference (2πr). π's value is roughly 3.14, and in this problem,
            we'll get pretty close to that value (we're shooting for 2 decimal places of accuracy)!</p>

        <p>One nice way to estimate π is using the random module. Imagine a 2x2 square drawn on a 2D plane, centered at the
            origin. We can draw a circle inside this square, with radius 1, centered at the origin. So far, our drawing
            looks like this:</p>

        <img src={Pi1} className="img-fluid mx-auto d-block" width="50%"
            alt="2x2 square with circle with radius 1 contained within it" />

        <p>The area of the square is 2 x 2 = 4 and the area of the circle is π x 1^2 = π. The circle takes up a
            large fraction of the square's area: to be precise, the ratio of the circle's area to the square's area is π/4.</p>

        <p>One way to think about this is that if you pick a bunch of points, randomly, inside the square, the fraction of those points that will lie inside the circle is π/4.</p>

        <img src={Pi2} className="img-fluid mx-auto d-block" width="50%"
            alt="100000 points randomly plotted on graph above" />
        <img src={Pi3} className="img-fluid mx-auto d-block" width="50%"
            alt="100000 points randomly plotted on graph above, with those points landing outside the circle removed" />

        <p>Your program should randomly pick 100000 points inside the square (remember that it's good programming
            style to use constants where appropriate; consider defining a constant NUM_POINTS = 100000).</p>

        <p>You can pick a random point in the square by picking a random x-coordinate and a random y-coordinate
            (both of which should be real numbers between -1 and 1). Count the number of points that are inside
            the circle and use that number to calculate the fraction of points that landed inside the circle.
            That number is approximately π/4, so multiply your ratio by 4 and print out that value as our approximation of
            π. How close did you get? What happens when you change NUM_POINTS?</p>

        <p><i>Hint: A point (x, y) is inside the circle of radius 1, centered at the origin, if (x^2 + y^2 &lt;= 1).</i></p>

        <p><i>Note: Your function will produce a number, not the graphs that you see in this writeup. However, these
            graphs were actually generated using Python and you now have the skills to make images like these!</i></p>

        <h2>Graphics</h2>

        <h3>Expanding Circles</h3>

        <p>Starting with a black circle in the center of the canvas with radius <code>STARTING_RADIUS</code>, draw a new, larger
            black circle on top of that circle each timestamp. Each timestamp, the circle's radius should
            increase by <code>GROWTH_RATE</code>. After the circle has become as large as (or larger than) the screen, repeat
            the process for a white circle (starting with <code>STARTING_RADIUS</code> and growing to the size of the screen.
            Continue drawing these expanding circles, alternating between black and white circles every time the circle becomes
            as large as the screen (the screen is taller than it is wide, so you can just compare the circle's radius to the width).</p>

        <p>Here's a demo of this program:</p>

        <center>
            <video controls width="50%">
                <source src={Circles} className="img-fluid mx-auto d-block" type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </video>
        </center>
        <br />

        <p>Here's your starter code:</p>

        <PythonHighlighter code={circles} />


        <h3>Magic Wand</h3>

        <p>To simulate a magic wand on the cursor, we will draw a black rectangle that follows the user's cursor and emits
            yellow sparkles (circles) that float upwards. The bottom right corner of the magic wand is attached to the mouse, and
            this wand is a black rectangle with dimensions <code>WAND_WIDTH</code> by <code>WAND_HEIGHT</code>. Every timestep, a
            yellow circle with radius <code>SPARKLE_RADIUS</code> should appear at the top center of the wand, and move upwards with velocity <code>SPARKLE_Y_VAL</code>.
            Note that this velocity is just the magnitude, so to make the sparkles move upwards, you should move them in the negative
            velocity. You should write your code in an animation loop, so that the wand and sparkles continue moving around the
            screen at each timestep, with a delay of <code>DELAY</code> seconds. It's fine to have the sparkles move off of the
            screen; you don't need to do anything special
            to delete them after they move beyond the edge of the screen. You can start your wand off with its upper left at (0, 0).</p>

        <p>Here's a demo of the program:</p>

        <center>
            <video controls width="50%">
                <source src={Wand} className="img-fluid mx-auto d-block" type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </video>
        </center>
        <br />

        <p>Here's the starter code you've been provided with:</p>

        <PythonHighlighter code={magic_wand} />

        <h2>Lists</h2>

        <h3>Interesting List</h3>

        <p>Write a function <code>make_interesting(num_list)</code> that accepts a list of integers and returns
            a <i>new list</i> based on the given list.
            The new list should have each of the values in <code>num_list</code> as follows:
            if the integer in <code>num_list</code> is less than zero, the new list should not have
            this value; if the integer in num_list is greater than or equal to zero and
            odd, the new list should have this integer value with 10 added to it; if
            the integer in num_list is greater than or equal to zero and even, the new
            list should have this integer value unchanged. We'll consider the number 0 to be even.</p>

        <p>Here are some sample calls to <code>make_interesting</code> and what should be returned.</p>

        <PythonHighlighter code={interesting} />

        <h3>Collapse List</h3>

        <p>Write a function <code>collapse(lst)</code> that accepts a list of integers as a parameter and returns a new
            list containing the result of replacing each pair of integers with the sum of that pair. If the list stores
            an odd number of elements, the final element is not collapsed.</p>

        <p>Here are some sample calls to <code>collapse</code>:</p>

        <PythonHighlighter code={collapse} />

        <h3>Rotate</h3>

        <p>Write a function <code>rotate_list_right(numbers, n)</code> that returns a 'rotated' version of a list of
            integers <code>numbers</code>. You
            should rotate numbers to the right <code>n</code> times, so that each element in <code>lst</code> is shifted
            forward <code>n</code> places, and the last <code>n</code> elements are moved to
            the start of the list.

            Your function should not change the list that is passed as a
            parameter, but instead return a new list.</p>

        <PythonHighlighter code={rotate} />

        <h2>Lists of Lists</h2>

        <h3>Average Temps (Again)</h3>

        <p>This problem is like the lists problem we saw on the midterm, but made better with lists of lists! Say you're given
            a list of lists, where each inner list contains the average temperatures from all 12 months of a year as floats. We want to find
            the average temperature for each year, and form a list of these annual averages.
        </p>

        <p>Implement the function <code>annual_temps(nested)</code> that takes in a nested list of monthly temperature lists,
            and returns a (non-nested) list of the average temperatures from each year.</p>

        <p>For example, given the following list:</p>

        <PythonHighlighter code={temps_nested} />

        <p><code>annual_temps(nested)</code> should return:</p>

        <PythonHighlighter code={temps_result} />

        <h3>Fireworks</h3>

        <p>We often think of lists of lists as 2D grids, since they've got two dimensions that we can index into. We can
            refer to locations within our lists of lists as coordinates with an x and y value. For example, if we had a grid like this:
        </p>

        <PythonHighlighter code={test_grid} />

        <p>We could access the 'f' in this grid by doing <code>grid[2][1]</code>, where we think of 2 as the y-coordinate, and
            1 as the x-coordinate.</p>

        <p>In this problem we'll use a list of lists to represent a scene with certain physical properties (just like in Sand).
            Say that each location in our list of lists, or grid, can either contain a firework represented by 'f', a sparkle
            represented by 's', or None. We want to loop over every location in our grid and make any fireworks "explode", turning
            the 'f' at that location to None, and putting a sparkle 's' to the left, right, top, and bottom of that x, y location. For example,
            after exploding, the grid above should look like:
        </p>

        <PythonHighlighter code={test_explode} />

        <p>Write a function <code>explode_firewords(grid)</code> that takes in a list of lists <code>grid</code> and loops over
            the coordinates of that grid, exploding any fireworks. You can assume that the fireworks are spaced so that no sparkles
            will overlap. If a firework is placed on the edge of the grid, so that some of its sparkles would go beyond the bounds of
            the grid, don't draw any sparkles in locations that are out of bounds.</p>

        <p>Here's another example of a grid before and after calling your function <code>explode_fireworks</code>.</p>
        <PythonHighlighter code={grid} />

        <PythonHighlighter code={grid_explode} />

        <h2>Images</h2>

        <h3>Darken</h3>

        <p>Write a function <code>darken(filename)</code> that takes in the name of an image file <code>filename</code> and
            returns a darkened version of that image by halving the R, G, and B value of every pixel. Here's an example of
            what the original image might look like, and what the darkened version of this image would be.</p>

        <div className="img-fluid mx-auto d-block">
            <img src={Original} width="50%"
                alt="photo of arch in main quad, full color" />
            <img src={Dark} width="50%"
                alt="darkened photo of arch in main quad" />
        </div>

        <br />

        <h3>RGB Tint</h3>

        <p>Write a function <code>tinted(filename)</code> that takes in the name of an image file <code>filename</code> and
            returns an image with a red, green, and blue tint in each vertical third of the image. To tint a pixel a certain color,
            set the other color values to 0. For example, to tint a pixel red, we'd set that pixel's green and blue values to
            0, leaving the red alone. Pixels with x values less than a third of the image's width should be tinted red, pixels less
            than two thirds of the image's width (but not less than a third) should be tinted green, and the rest should be tinted
            blue. Here's an example of an original image next to the tinted version.</p>

        <div className="img-fluid mx-auto d-block">
            <img src={Original} width="50%"
                alt="photo of arch in main quad, full color" />
            <img src={Tinted} width="50%"
                alt="photo tinted red, green, and blue in thirds" />
        </div>
            <br />

        <h2>Strings</h2>

        <h3>Password Maker</h3>

        <p>Write the function <code>make_password(str)</code> that takes in a string <code>str</code> and makes it into a password
            by replacing common letters with similar looking special characters. Any a's should become @'s,
            i's should become !'s, and o's should become 0's. This should be case-insensitive, meaning that the uppercase versions
            of these letters should also be converted to their special characters. However, make sure that any other characters that
            were originally uppercase stay uppercase in the returned value.</p>

        <p>Here are a few sample calls of this function:</p>

        <PythonHighlighter code={password} />

        <h3>Longest Consonants</h3>

        <p>Write a function <code>longest_consonants(str)</code> that takes in a string <code>str</code> and returns the longest
            number of consonants that appear in a row. We'll consider consonants to be letters that are not A, E, I, O, or U.
            If <code>str</code> does not contain any consonants, you should return 0. Your count should be case-insensitive,
            meaning your function does not distinguish between uppercase or lowercase letters when counting consonants. There may
            also be non-alphabetic characters in <code>str</code>, which do not count as consonants.</p>

        <p><i>Hint: To check if a character is A, E,
            I, O, or U, you can check if that character is in the string 'AEIOU'.</i></p>

        <p>Here are a few sample calls of the function:</p>

        <PythonHighlighter code={cons} />

        <h2>Dictionaries</h2>

        <h3>Grade Boost</h3>

        <p>Let's say that the CS106A instructors have randomly decided to give a 5-point bonus to students whose names begin
            with a certain letter. They've stored the test scores of every student in a dictionary, where the keys are student names,
            and the values are the scores that the student earned. For example:</p>

        <PythonHighlighter code={student_dict} />

        <p>If the instructors choose to boost students whose names started with the letter 'C', this dictionary would become:</p>

        <PythonHighlighter code={student_dict_boosted} />

        <p>Write a function <code>grade_boost(students, letter)</code>, which takes in a dictionary of students like the
            one shown above and a letter indicating which students' names to boost, and updates the <code>students</code> dictionary
            to boost the corresponding grades by 5 points. You can assume the given <code>letter</code> will be uppercase, and all
            student names will start with an uppercase letter.</p>

        <p>A sample call of the function is shown below:</p>
        <PythonHighlighter code={boost} />

        <h3>Animal Counts</h3>

        <p>Let's say we had everyone in class vote on their favorite animals by entering an animal name into an online poll.
            The results were then put into a file so that each line of the file is an animal name and the number of votes
            it received. Here some some of the results:</p>

        <SyntaxHighlighter code={animal_file} />

        <p>What we want to do is to read in the data from this file into a dictionary where the key is the animal name
            (string) and the value is the number of votes this animal got (integer).</p>

        <p>Unfortunately, the polling mechanism didn't take into account that some people would capitalize the names of
            animals differently. Notice that above, “Dog” and “dog” each get their own score, but really we'd like to sum
            all the votes for each animal name in a case-insensitive way, so that we see that 39 people have “dog” as their
            favorite animal.</p>

        <p>Your task is to write a helper function <code>get_animal_counts(filename)</code> that takes in a filename and
            returns a dictionary with the duplicate counts (for keys with the same characters but different capitalization)
            combined. The new keys should be all lowercase. Calling our helper function with the file above would return:</p>

        <PythonHighlighter code={animal_dict} />

        <h2>Nested Dictionaries</h2>

        <h3>List2Dict</h3>

        <p>Say you wanted to store your friends' phone numbers using Python, but you did so before learning about dictionaries.
            Right now, you've got a big list of your friends' names (as strings), followed by their phone numbers (also as strings).
            You realize now that you'd much rather store this information as a nested dictionary, where the keys are names, and the values
            are lists of phone numbers.
        </p>

        <p>You have a list that might look something like this:</p>
        <PythonHighlighter code={friends_list} />

        <p>But you'd like to turn it into:</p>
        <PythonHighlighter code={friends_dict} />

        <p>Write a function <code>list_to_dict(friends_lst)</code> that takes in a list of friend names and numbers, and converts it to a
            nested dictionary as described above. You can assume that a phone number belongs to the name that precedes it.</p>

        <p><i>Hint: You might check whether an element in the list is a name or number using the <code>.isalpha()</code> or <code>.isnumeric()</code> function.</i></p>

        <h3>Finding Grandchildren</h3>

        <p>Implement a function <code>find_grandchildren(parents_to_children)</code>, which is given the
            dictionary <code>parents_to_children</code> whose keys are strings representing parent names and whose values are
            lists of that parent's children's names. Create and return a dictionary from people's names to
            lists of those people's grandchildren's names.</p>

        <p>For example, given this dictionary:</p>

        <PythonHighlighter code={parents} />

        <p>Daniel's grandchildren are Chibundu, Jesmyn, and Grace, since those are the children of his children.
            Additionally, Khaled is Frank's grandparent, since Frank's parent is Khaled's child. Therefore, calling <code>find_grandchildren(parents_to_children)</code> returns this dictionary:</p>

        <PythonHighlighter code={grandparents} />

        <p>Note that the people who aren't grandparents don't show up as keys in the new dictionary. Depending on the way your
            process the dictionary, the order of your results may be different, but you should have the same keys and values.
        </p>

        {/*<h2>Classes</h2>

        <h3>Library Class</h3>

        <p>Implement a class <code>Library</code> with the following methods:
            <ul>
                <li><code>init(self)</code> - initializes a new <code>Library</code> instance and any member variables</li>
                <li><code>return_book(self, title)</code> - returns a book to the library with the given title</li>
                <li><code>check_out(self, title)</code> - removes a book from the library with the given title, or
                    prints 'No book with that title' if that book isn't found</li>
                <li><code>get_catalog(self)</code> - returns a list of all books currently in the library, sorted alphabetically</li>
            </ul></p>

        <p>Here's how your class might be used from another program.</p>

        <PythonHighlighter code={library_use} />

        <p>Here's your starter code:</p>

        <PythonHighlighter code={library_starter} />


        <h3>Class Class</h3>

        <p>No, that's not a typo. We're going to implement a class that stores information about students enrolled in a class
            at Stanford. Specifically, your <code>Class</code> class will have the following methods:

            <ul>
                <li><code>init(self)</code> - initializes a new <code>Class</code> instance and any member variables</li>
                <li><code>add_student(self, name, id)</code> - adds a student by name (string) and id (int) to the class</li>
                <li><code>get_id(self, name)</code> - returns the id of the student with this name, or -1 if this student
                    isn't in the class</li>
                <li><code>get_class_list(self)</code> - returns a list of the names of all students enrolled in the class</li>
            </ul>
        </p>

        <p>Here's how your class might be used from another program.</p>

        <PythonHighlighter code={class_class} />

        <p>Here's your starter code:</p>

        <PythonHighlighter code={class_starter} />

        <h3>Pizza Class 🍕</h3>

        <p>Implement a class <code>Pizza</code> that represents a pizza pie with the following methods:
            <ul>
                <li><code>init(self)</code> - initializes a new <code>Pizza</code> instance with 8 slices and no toppings,
                    creating any member variables you'd like</li>
                <li><code>take_slice(self)</code> - removes one slice of pizza, or prints "no slices left" if there are 0 slices left</li>
                <li><code>get_topping_list(self)</code> - returns a list of all toppings currently on the pizza</li>
                <li><code>add_topping(self, topping)</code> - adds the topping (which is a string) to the pizza, so that all future calls to <code>get_topping_list</code> include this topping
                </li>
            </ul></p>

        <p>Here's how your class might be used from another program.</p>

        <PythonHighlighter code={pizza_use} />

        <p>Here's your starter code:</p>

        <PythonHighlighter code={pizza_starter} />


        <h2>Tuples and Sorting</h2>
        <p><i>Update: this topic will not appear on the final exam.</i></p>

        <h3>Sorting Houses</h3>

        <p>Given a list of tuples that represent houses for rent, the number of bedrooms, and their prices, like so:
        </p>

        <PythonHighlighter code={prices_tups} />

        <p>Sort the list in the following ways:</p>

        <ul>
            <li>In ascending order by number of rooms</li>
            <li>In descending order of price</li>
            <li>In ascending order of price-per-room</li>
        </ul>
            */}
    </>
}

const classes_dict = `INITIAL_SIZE = 10000

"""
    A simple implementation of the Python Dictionary. This version
    captures the main good idea underneath a dictionary. Keep track
    of a huge list and use a hash to go from key to index.
    """

class Dictionary:

    def __init__(self):
        self.data = []
        for i in range(INITIAL_SIZE):
            self.data.append(None)

    def set(self, key, value):
        index = self.key_to_index(key)
        self.data[index] = value

    def get(self, key):
        index = self.key_to_index(key)
        return self.data[index]

    def key_to_index(self, key):
        data_size = len(self.data)
        hash_value = hash(key)
        return hash_value % data_size`

const classes_tracing = `def main():
favorite_colors = Dictionary()

favorite_colors.set("Mehran", "Blue")
favorite_colors.set("Chris", "Green")

update_colors(favorite_colors)

print(favorite_colors.get("Mehran"))   
print(favorite_colors.get("Chris"))         
print(favorite_colors.get("Elyse"))     

def update_colors(colors):
colors.set("Mehran", "Purple")
colors = Dictionary()
colors.set("Chris", "Orange")
colors.set("Mehran", "Blue")
colors.set("Elyse", "Yellow")

if __name__ == '__main__':
main()`

const lists_1 = `# Input
scores = [80, 90, 100, 70, 85]

# Returned output
[40.0, 45.0, 50.0, 35.0, 42.5]`

const lists_2 = `def halve_scores(scores):
    # Your code here`


const dict = `import json

# Example usage:
favorites_json = '{"Chris": "papaya", "Arisa": "papaya", "Mehran": "toast", "Iddah": "banana", "Benji": "banana"}'
fruit = “papaya”
print(count_favorite_fruit(favorites_json, fruit)) #Prints 2


def count_favorite_fruit(favorites_json, fruit):
    # Your code here`

const rps_run = `Your move (1, 2, or 3): 1
It's a tie!
Your move (1, 2, or 3): 3
You win! Scissors cuts paper
Your move (1, 2, or 3): 2
You lose! Scissors cuts paper
Your move (1, 2, or 3): 2
It's a tie!
Your move (1, 2, or 3): 1
You win! Rock crushes scissors
You won 2 rounds!`

const interesting = `>>> make_interesting([-2, 33, 14, 6, -13, 9, 2])
[43, 14, 6, 19, 2]
>>> make_interesting([1, 2, 3, 4, 5])
[11, 2, 13, 4, 15]
>>> make_interesting([-10, -20, -5])
[]`

const collapse = `>>> collapse([7, 2, 8, 9, 4, 13, 7, 1, 9, 10])
[9, 17, 17, 8, 19]
>>> collapse([1, 2, 3, 4, 5])
[3, 7, 5]`

const rotate = `>>> rotate_list_right([1, 2, 3, 4, 5], 2)
[4, 5, 1, 2, 3]
>>> rotate_list_right([5, 3, 3, 1], 1)
[1, 5, 3, 3]`

const password = `>>> make_password('HI!')
'H!!'
>>> make_password('this is a good password')
'th!s !s @ g00d p@ssw0rd'`

const cons = `>>> longest_consonants('rhythm')
6
>>> longest_consonants('what is the longest consonant here')
2
>>> longest_consonants('aaaaaa')
0`

const student_dict = `students = {
    'Elyse': 5,
    'Chris': 20,
    'Mehran': 20,
    'Clinton': 40,
    'Neel': 12
}`

const student_dict_boosted = `students = {
    'Elyse': 5,
    'Chris': 25,
    'Mehran': 20,
    'Clinton': 45,
    'Neel': 12
}`

const boost = `>>> grade_boost({'Elyse': 5, 'Chris': 20, 'Mehran': 20, 'Clinton': 40, 'Neel': 12}, 'C')
{'Elyse': 5, 'Chris': 25, 'Mehran': 20, 'Clinton': 45, 'Neel': 12}
>>> grade_boost({'Student1': 10, 'Student2': 15}, 'F')
{'Student1': 10, 'Student2': 15}`

const friends_list = `['Noah', '1234567', 'Anooshree', '2347891', '9371924', 'Mel', '2398712']`

const friends_dict = `{
    'Noah': ['1234567'], 
    'Anooshree': ['2347891', '9371924'], 
    'Mel': ['2398712']
}`

const parents = `parents_to_children = {
    'Khaled': ['Chibundu', 'Jesmyn'],
    'Daniel': ['Khaled', 'Eve'],
    'Jesmyn': ['Frank'],
    'Eve': ['Grace']
}`

const grandparents = `{
    'Khaled': ['Frank'],
    'Daniel': ['Chibundu', 'Jesmyn', 'Grace']
}`

const animal_file = `Dog: 34
Bird: 20
cat: 14
Elephant: 7
dog: 5
CAT: 2`

const animal_dict = `{
    'dog': 39, 
    'bird': 20, 
    'cat': 16, 
    'elephant': 7
}`

const temps_nested = `month_values = [[1,2,3,4,5,6,7,8,9,10,11,12],
                [3,0,0,0,0,0,0,0,0,0,0,0],
                [12,0,0,0,0,0,0,0,0,0,0,0]]`

const temps_result = `[6.5,
 0.25,
 1.0]`

const test_grid = `grid = [[None, None, None],
        [None, None, None],
        [None, 'f' , None],
        [None, None, None]]`

const test_explode = `[[None, None, None],
 [None, 's' , None],
 ['s' , None, 's' ],
 [None, 's' , None]]`

const grid = `# before
[[None, 'f' , None, None, None],
 [None, None, None, None, 'f' ],
 [None, None, None, None, None],
 [None, 'f' , None, None, None]]`

const grid_explode = `# after
[['s' , None, 's' , None, 's' ],
 [None, 's' , None, 's' , None],
 [None, 's' , None, None, 's' ],
 ['s' , None, 's' , None, None]]`

const magic_wand = `from graphics import Canvas
import time

WAND_WIDTH = 5
WAND_HEIGHT = 50

SPARKLE_RADIUS = 5
SPARKLE_Y_VEL = 10

DELAY = 1/60


def main():
    canvas = Canvas()
    width = canvas.get_width()
    height = canvas.get_height()

    # YOUR CODE HERE


if __name__ == '__main__':
    main()`

const circles = `from graphics import Canvas
import time

STARTING_RADIUS = 10
GROWTH_RATE = 5
DELAY = 1/60


def main():
    canvas = Canvas()
    width = canvas.get_width()
    height = canvas.get_height()

    # YOUR CODE HERE


if __name__ == '__main__':
    main()`


const class_starter = `# File: Class.py

class Class:

    def __init__(self):
        """
        Creates a new instance of the Class class
        """
        pass

    def add_student(self, name, id):
        """
        Adds a student by name (string) and id (int) to the class
        """
        pass

    def get_id(self, name):
        """
        Returns the id of the student with this name, or -1 if this
        student isn't in the class
        """
        pass

    def get_catalog(self):
        """
        Returns a list of the names of all students enrolled in 
        the class
        """
        pass`


const class_class = `def main():
    cs106a = Class()
    cs106a.add_student('Muhammad', 12345678)
    print(cs106a.get_class_list())      # prints ['Muhammad']
    cs106a.add_student('Elyse', 56781234)
    print(cs106a.get_class_list())      # prints ['Muhammad', 'Elyse']
    print(cs106a.get_id('Maria'))       # prints -1
    print(cs106a.get_id('Muhammad'))    # prints 12345678


if __name__ == '__main__':
    main()`

const prices_tups = `houses = [('main st.', 4, 4000), ('elm st.', 1, 1200), ('pine st.', 2, 1600)]`

const library_use = `from library import Library


def main():
    green_library = Library()
    green_library.return_book('Karel the Robot Learns Python')
    green_library.return_book('The Giving Tree')
    green_library.return_book('Design as Art')
    print(green_library.get_catalog())          # prints ['Design as Art', 'Karel the Robot Learns Python', 'The Giving Tree']
    green_library.check_out('The Giving Tree')
    green_library.check_out('Annihilation')     # prints No book with that title
    print(green_library.get_catalog())          # prints ['Design as Art', 'Karel the Robot Learns Python']
    

if __name__ == '__main__':
    main()`

const library_starter = `# File: library.py

class Library:

    def __init__(self):
        """
        Creates a new instance of the Library class
        """
        pass

    def return_book(self, title):
        """
        Returns a book with the given title to the library
        """
        pass

    def check_out(self, title):
        """
        Removes a book with the given title from the library, or prints
        'No book with that title' if that book isn't found
        """
        pass

    def get_catalog(self):
        """
        Returns a list of all books currently in the library, sorted
        alphabetically
        """
        pass`

const pizza_use = `from pizza import Pizza

def main():
    veggie = Pizza()
    print(veggie.get_topping_list())  # prints []
    veggie.add_topping('green peppers')
    veggie.add_topping('mushrooms')
    for i in range(8):
        veggie.take_slice()
    print(veggie.get_topping_list())  # ['green peppers', 'mushrooms']
    veggie.take_slice()               # prints No slices left

if __name__ == '__main__':
    main()`

const pizza_starter = `# File: class.py

class Pizza:

    def __init__(self):
        """
        Creates a new instance of the Pizza class
        """
        pass

    def take_slice(self):
        """
        Takes a slice from the pizza, reducing its number of slices by 1
        """
        pass

    def get_topping_list(self):
        """
        Returns a list of all toppings currently on the pizza
        """
        pass

    def add_topping(self, topping):
        """
        Adds the topping (which is a string) to the pizza, so that all future 
        calls to get_topping_list include this topping
        """
        pass`