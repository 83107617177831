import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan4 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 4: Lists of Lists and Strings</h1>
        <hr />

        <p>This week, we're practicing the last two topics that will be on the midterm: lists of lists and
            strings. These will both be pretty new for students, so we'll focus on building a solid foundation
            in section. The midterm is also around the corner, so you can take some time to answer students'
            questions on midterm logics and how to study if they've got any.

        </p>

        <h3>Section flow</h3>
        <ol>
            <li><b>5 min: Check in, midterm reminder.</b> As usual, you may want to start with a general check-in and answer
                and questions about the class. We're having our midterm next week Tuesday 5/9 - hopefully
                students are aware and thinking about studying. We're publishing exam logistics and a practice exam
                on the course website this Tuesday, so definitely check those out and refer students to the course site. </li>
            <li><b>20 min: Enumerate.</b> This is a good nested list problem, and a nice review of list indices. Since
                lists of lists are new to students, you might start by talking about what the inner and outer lists represent and how to check things
                like grid width and height, or access the element at a specific (x, y) location.
                See <a href="https://cs106a.stanford.edu/lecture/13">Monday's lecture</a> for a list of lists overview.</li>
            <li><b>25 min: Choose a string problem or two.</b> We've got three string problems for you to choose from.
                Double Char is like a simpler version of what they'll see on the homework, so you might start with that one.</li>
        </ol>

        <h3>Section tips</h3>

        <p>Your sectionees might ask you how they should study for the midterm exam, and here's my usual answer:
            CS exams aren't really about memorization (plus we're letting them bring note sheets) and instead are about using
            the tools and patterns we've seen in class to solve new problems. So, they can definitely review lecture slides and assignments
            if there were parts they still feel conceptually iffy on, but I'd recommend spending most of the time practicing
            solving problems.
            We've got lots of problems on the online IDE, leftover section problems from each week, and a practice exam.
        </p>

        <p>When talking about either lists or strings, it's helpful to create a concrete example and label any
            relevant information (indices, length, etc.) on the board. Diagramming these problems will be just as
            important as it was for graphics.
        </p>
    </>
}