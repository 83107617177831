import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from "react-router-dom"


export const Section6 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 6: Classes</h1>
        <hr />
        <h5><Link to="/fabio"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>

        <p>This week, we'll be working more with classes in Python! We will practice writing our own classes and trace through some tricky problems dealing with classes.</p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/Section6-sum24.zip`}>Section 6 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you do with your assignment starter code.</p>
        
            <h2>Circle Class</h2>

            <p>In this problem you are going to write the circle class in the <code>circle.py</code> file for use in the
                following program. The design of the circle class is up to you, but running the program below should give
                the output below.</p>

            <PythonHighlighter code={circle} />

            <p>After running the above program, the terminal output should be:</p>

            <PythonHighlighter code={output} />

            <p>You can test your circle code with radius 5 by entering <code>python3 circle_test.py</code> (using <code>py</code> or <code>python</code> on Windows).</p>

            <p>As a reminder, a circle with radius <code>r</code> has area <code>πr^2</code> and circumference <code>2πr</code>. The
                value of <code>π</code> is stored in the constant <code>math.pi</code>, which you can access with the <code>math</code> module which
                we've imported at the top of the starter file.</p>

            <p>You are in charge of the blueprint for the circle class. As you think of how to design the circle class, consider
                what information should we store in the circle class? What methods need to exist?</p>
    
        <h2>Classes Tracing</h2>
        <p>Below is a simple class implementation of the dictionary data structure in Python:</p>
        <SyntaxHighlighter code={Dictionary} />
        <p>Trace the code below and write down the outputs to the terminal (print statements). You can play around with the code in <code>animal_sounds.py</code>.</p>
        <SyntaxHighlighter code={animal_sounds} />

        <h2>Class Class</h2>

            <p>No, that's not a typo. We're going to implement a class that stores information about students enrolled in a class
                at Stanford. Specifically, your <code>Class</code> class will have the following methods:

                <ul>
                    <li><code>init(self)</code> - initializes a new <code>Class</code> instance and any member variables</li>
                    <li><code>add_student(self, name, id)</code> - adds a student by name (string) and id (int) to the class</li>
                    <li><code>get_id(self, name)</code> - returns the id of the student with this name, or -1 if this student
                        isn't in the class</li>
                    <li><code>get_class_list(self)</code> - returns a list of the names of all students enrolled in the class</li>
                </ul>
            </p>

            <p>Here's how your class might be used from another program.</p>

            <PythonHighlighter code={class_class} />

            <p>Here's your starter code:</p>

            <PythonHighlighter code={class_starter} />
    </>
}

const Dictionary = `INITIAL_SIZE = 10000

"""
    A simple implementation of the Python Dictionary. This version
    captures the main good idea underneath a dictionary. Keep track
    of a huge list and use a hash to go from key to index.
    """

class Dictionary:

    def __init__(self):
        self.data = []
        for i in range(INITIAL_SIZE):
            self.data.append(None)

    def set(self, key, value):
        index = self.key_to_index(key)
        self.data[index] = value

    def get(self, key):
        index = self.key_to_index(key)
        return self.data[index]

    def key_to_index(self, key):
        data_size = len(self.data)
        hash_value = hash(key)
        return hash_value % data_size`
const animal_sounds = `def main():
        animal_sounds = Dictionary()
        animal_sounds.set("Dog", "Woof")
        populate(animal_sounds)
        print(animal_sounds.get("Dog"))
        print(animal_sounds.get("Cat"))
        print(animal_sounds.get("Horse"))
    
    def populate(sounds):
        # mutation
        sounds.set("Horse", "Neigh")
        print(sounds.get("Horse"))
        print(sounds.get("Dog"))
    
        # reassignment
        sounds = Dictionary()
    
        # mutation
        sounds.set("Cat", "Meow")
        print(sounds.get("Cat"))
        print(sounds.get("Horse"))
        print(sounds.get("Dog"))
    
    
    if __name__ == '__main__':
        main()`
    const class_class = `def main():
    cs106a = Class()
    cs106a.add_student('Muhammad', 12345678)
    print(cs106a.get_class_list())      # prints ['Muhammad']
    cs106a.add_student('Elyse', 56781234)
    print(cs106a.get_class_list())      # prints ['Muhammad', 'Elyse']
    print(cs106a.get_id('Maria'))       # prints -1
    print(cs106a.get_id('Muhammad'))    # prints 12345678


if __name__ == '__main__':
    main()`

    const class_starter = `# File: Class.py

class Class:

    def __init__(self):
        """
        Creates a new instance of the Class class
        """
        pass

    def add_student(self, name, id):
        """
        Adds a student by name (string) and id (int) to the class
        """
        pass

    def get_id(self, name):
        """
        Returns the id of the student with this name, or -1 if this
        student isn't in the class
        """
        pass

    def get_catalog(self):
        """
        Returns a list of the names of all students enrolled in 
        the class
        """
        pass`
        const circle = `# import the Circle class from circle.py
        from circle import Circle 
        
        def main():
            # construct circle with radius 5
            circle = Circle(5) 
            
            # print the area of the circle
            print("The area of the circle is " + str(circle.get_area()))
        
            # print the circumference of the circle
            print("The circumference of the circle is " + str(circle.get_circumference()))
          
        if __name__ == "__main__":
            main()`
        
        const output = `The area of the circle is 78.53981633974483
        The circumference of the circle is 31.41592653589793`