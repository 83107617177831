import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'
// @ts-ignore
import Canvas from "./img/graphics/canvas_coords.png"
// @ts-ignore
import Box from "./img/graphics/bounding_box.png"
// @ts-ignore
import Text from "./img/graphics/blue_text.png"
// @ts-ignore
import Line from "./img/graphics/line.png"
// @ts-ignore
import Overlap from "./img/graphics/overlap_yay.png"
// @ts-ignore
import RedOval from "./img/graphics/red_oval.png"
// @ts-ignore
import Smileys from "./img/graphics/smileys.png"
// @ts-ignore
import YellowRect from "./img/graphics/yellow_rect.png"
// @ts-ignore
import MyCanvas from "./img/graphics/my_canvas.png"

export const GraphicsReference = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Graphics Reference</h1>
    <hr />

    <p>One of the most common libraries to create graphics in Python is called Tk (short for "tkinter"). Tk is a
      powerful graphics library that should be automatically installed for Windows and Mac along with Python when
      you installed it. While Tk's great, it is missing some helpful functionality. For this reason, we provide our
      own small graphics library that is a lightweight "wrapper" around the standard Tk library. It's not a replacement for Tk -
      it just adds a few new functions to make certain things like drawing text easier. You can always explore the
      full Tk library if you're interested in seeing what else you can do!</p>

      <p>Aside: Any graphics projects we create for you will have the necessary graphics files included! You can also
      download an <a href={`${process.env.PUBLIC_URL}/Graphics.zip`}>Example Graphics</a> project, unzip it, and
      write your code there. You can also copy <code>graphics.py</code> to any project where you would like to have access to the graphics library.</p>

    

    <h3>Importing</h3>

    <p>To use our graphics library, you must first import it at the top of your program, by adding this line of
      code to the top of your file:</p>

    <PythonHighlighter code={import_graphics} />

    <h3>The Canvas</h3>

    <p>The drawing model for the graphics library is a "canvas" (much like a painting) where you are going
      to draw various shapes. The canvas is a grid of pixels that have x and y values. The coordinate (0, 0)
      is in the upper-left-hand corner of the canvas. The values of x increase as you move to the right. The values
      of y increase as you move down. In other words, you can think of the canvas as follows:</p>

    <img src={Canvas}
      className="img-fluid mx-auto d-block" width="40%"
      alt="x and y axes of canvas extending right and down, starting from the upper left" />

    <p>When we want to draw shapes on a canvas, we call functions on that canvas that “create” the shape
      we want to draw. These shapes then appear on the canvas. We will generally provide the code that
      creates the canvas for you, so all you need to worry about is adding shapes to that canvas. For the
      remainder of this handout, we will assume that a variable named <code>canvas</code> has already been created,
      and it represents the canvas that you'll be drawing on. Usually this will be done with the following
      provided code:</p>

    <PythonHighlighter code={create_canvas} />

    <p>When we create the canvas, we can optionally specify its width and height, as well as a title that will appear
      at the top of the canvas window. For example, to make a canvas that's 800 pixels wide and 200 pixels tall,
      with the title 'My Canvas', you would do the following:
    </p>

    <PythonHighlighter code={canvas_with_title} />

    <img src={MyCanvas}
      className="img-fluid mx-auto d-block" width="75%"
      alt="Canvas with size 800, 200 and title 'My Canvas'" />

    <p>Below we provide a brief tour of some of the different shapes you can draw on a canvas as well as
      highlight a few of the options you have with regard to how those shapes look.</p>

    <h3>Drawing Lines</h3>

    <p>To draw lines on the canvas, use <code>create_line</code> and specify the coordinates for the start and end points
      of the line and the line's color as a string. For example, the following command would draw a black line from
      location (10, 20) to (100, 50) on the canvas:</p>

    <PythonHighlighter code={create_line} />

    <img src={Line}
      className="img-fluid mx-auto d-block" width="40%"
      alt="line drawn on the canvas from (10, 20) to (100, 50)" />

    <p>By default, all lines are drawn in black if you omit the color parameter. </p>


    <h3>Drawing Rectangles</h3>

    <p>To draw a rectangle on the canvas, use <code>create_rectangle</code> and specify the coordinates for the top left corner and
      bottom right corner of the rectangle, as well as a color as a string. For example, the following command would draw a yellow
      rectangle with upper left-hand corner at (5, 50) and lower right-hand corner at (100, 200) on the canvas:</p>

    <PythonHighlighter code={create_rectangle} />

    <img src={YellowRect}
      className="img-fluid mx-auto d-block" width="40%"
      alt="yellow rectangle with upper left corner at (5, 50) and lower right corder at (100, 200)" />

    <p>By default, all rectangles are drawn in black if you omit the color parameter. </p>

    <h3>Drawing Ovals</h3>

    <p>To draw an oval on the canvas, use <code>create_oval</code> and specify the coordinates for the top left corner
      and bottom right corner of the bounding box containing the oval you would like to draw. In other words, the oval
      drawn will have its top, bottom, right, and left just touching the sides of the bounding box you specify. You'll also
      specify a color as in the previous functions. For example, the following command would draw a red oval that fits
      within an imaginary rectangle with upper left-hand corner at
      (5, 50) and lower right-hand corner at (100, 200) on the canvas:</p>

    <PythonHighlighter code={create_oval} />

    <p>Here's what this code would draw on the canvas - note
      that we can think of this as an imaginary bounding box from (5, 50) to (100, 200), with an oval drawn inside it:</p>

    <img src={RedOval}
      className="img-fluid mx-auto d-block" width="40%"
      alt="red oval that would fit perfectly within a bounding box with upper left corner at (5, 50) and lower right corder at (100, 200) " />

    <p>By default, all ovals are drawn in black if you omit the color parameter. </p>

    <p>To make clear the notion of a bounding box, below we draw the same red oval and yellow rectangle from before, where both the oval and
      rectangle have the same coordinates. Notice how the rectangle is like a bounding box around the oval with the same coordinates.</p>

    <PythonHighlighter code={bounding} />

    <img src={Box}
      className="img-fluid mx-auto d-block" width="40%"
      alt="red oval drawn on top of yellow rectangle, which serves at a bounding box for the oval" />


    <h3>Drawing Text</h3>

    <p>To draw text on the canvas, use <code>create_text</code> to specify an x and y coordinate, and the following parameters:</p>

    <ul>
      <li>anchor</li>
      <li>font</li>
      <li>text</li>
      <li>color (optional, defaults to black)</li>
    </ul>

    <p>The anchor is a string, one of <code>"n", "ne", "e", "se", "s", "sw", "w", "nw", or "center"</code>, that describes
      what side of your text you want to position at the (x, y) you provide. Font describes the style and size of the text,
      for example 'Helvetica 32'. The text is the text string you want to appear on the screen. Here's a sample call
      of <code>create_text</code> with all of the necessary parameters (this is all one line, we've just split it to fit
      on the page):</p>

    <PythonHighlighter code={create_text} />

    <img src={Text}
      className="img-fluid mx-auto d-block" width="40%"
      alt="blue text on the canvas" />

    <h3>Adding Images</h3>

    <p>You can add image files to your canvas as well. To do this, use <code>create_image</code> (if you want the image
      to be the same size on the canvas as the image file) or <code>create_image_with_size</code> (if you want the image
      to be another size you specify). Both require you to specify the x and y location of the upper-left corner of the
      image on the canvas, as well as the name of the image file. <code>create_image_with_size</code> also requires a
      width and a height that the image should be. Here's an example that adds a smiley-face image to the canvas with
      upper-left corner at (25, 30), assuming we have an image file named "smiley.jpg" in our PyCharm project:</p>

    <PythonHighlighter code={create_images} />

    <p>Here's what this code would draw on the canvas - note that the larger smiley is the one from
      the <code>create_image</code> call (the original image was 216 by 216 pixels), and the distorted one is the
      100x300 smiley drawn with <code>create_image_with_size</code>:</p>

    <img src={Smileys}
      className="img-fluid mx-auto d-block" width="40%"
      alt="two smiley face emojis, one is a square and one is stretched in the vertical direction" />


    <p>One note about Tk images is that they cannot be resized after you create them.</p>

    <p className="alert alert-info">Note: If images do not work in your program and you're getting an error, you may
      not have installed the library necessary for images to work. Make sure you've installed the necessary library by
      running <code>python3 -m pip install Pillow</code> in your terminal (use <code>py</code> or <code>python</code> instead
      of <code>python3</code> if you're on Windows). </p>

    <h3>Removing Objects From the Canvas</h3>

    <p>You can remove objects from the canvas using <code>delete</code>:</p>

    <PythonHighlighter code={delete_function} />

    <p>Note that deleting an object is permanent - if you want to temporarily make an object hidden,
      use <code>set_hidden</code>. You can pass in either <code>True</code> or <code>False</code> to set an object
      to be hidden or visible:</p>

    <PythonHighlighter code={set_hidden} />

    <h3>Object Ordering</h3>

    <p>Objects are drawn on the canvas in the order in which they are created by your code. So, if you draw a red
      rectangle after a yellow circle, the red rectangle can potentially cover (occlude) part of the yellow circle.
      This is sometimes referred to as the z-order of the objects.</p>

    <p>As an example, below, we first draw a yellow rectangle, then draw an orange oval (which partly overlaps the
      rectangle), and then some text (that is on top of both the rectangle and the oval).</p>

    <PythonHighlighter code={overlapping} />

    <img src={Overlap}
      className="img-fluid mx-auto d-block" width="40%"
      alt="overlapping objects, from back to front, a yellow rectangle, a red oval overlapping but offset, and black text
      on top of both rectangle and oval" />


    <h3>Changing Object Colors</h3>

    <p>Sometimes, we'll want to refer to an object after we've created it, like when we want to change an object's
      color later in the program. In that case, it's a good idea to store that object in a variable for us to use later.
      If we know we'll want to change the color of a rectangle after we've created it, we might store that object
      in a variable called <code>rect</code> and use the <code>set_color</code> function to change its color like this:</p>

    <PythonHighlighter code={set_color} />

    <p>We can also individually change the outline or fill of an object using
      the <code>set_fill_color</code> and <code>set_outline_color</code> functions as follows:</p>

    <PythonHighlighter code={set_fill_and_outline} />

    <p>Here's a <a href="https://www.tcl.tk/man/tcl8.6/TkCmd/colors.html">list of all the colors</a> you can use in Tk.</p>

    <h3>Getting Information About Objects</h3>

    <p>For all graphical objects, you can ask the canvas for information about them. For instance, you can get an object's
      dimensions via <code>get_obj_width</code> and <code>get_obj_height</code>:</p>

    <PythonHighlighter code={get_dimensions} />

    <p>You can also ask the canvas for information about an object's location. Specifically, you can get
      the left-most x coordinate of the object, or the top-most y coordinate as follows:</p>

    <PythonHighlighter code={get_location} />

    <p>Note that for something like an oval, the dimensions and left corner are from the bounding box that contains it.</p>

    <h3>Moving Graphical Objects</h3>

    <p>You can change the location of an object using <code>moveto</code>. You specify new x and y coordinates, which will
      be the upper-left corner of the bounding box for the object (e.g. bounding box for an oval, bounding box for a line, etc.).
      Here's how we would move a rect to have upper-left corner at (25, 50):</p>

    <PythonHighlighter code={move_to} />

    <p>You can also change the location of an object by specifying how much you would like to move it by, instead
      of specifying the new location. The <code>move</code> function lets you specify the change in x and the
      change in y for the object, relative to its current location. For example, here's how we would move a rectangle
      5 pixels to the right, and 10 pixels down:</p>

    <PythonHighlighter code={move} />
  </>
}

const import_graphics = `from graphics import Canvas`

const create_canvas = `# example code to create a canvas
def main():
    # create a canvas to draw on
    canvas = Canvas()

    # get the width and height of our canvas, in pixels
    canvas_width = canvas.get_width()
    canvas_height = canvas.get_height()

    # call this at the end of the program to make the window show
    canvas.mainloop()`

const canvas_with_title = `canvas = Canvas(800, 200, 'My Canvas')`

const create_line = `canvas.create_line(10, 20, 100, 50, 'black')`

const create_rectangle = `canvas.create_rectangle(5, 50, 100, 200, 'yellow')`

const create_oval = `canvas.create_oval(5, 50, 100, 200, 'red')`

const bounding = `canvas.create_rectangle(5, 50, 100, 200, 'yellow')
canvas.create_oval(5, 50, 100, 200, 'red')`

const create_text = `canvas.create_text(50, 50, anchor='w', font='Arial', font_size=24, 
    text='My first text!', color='blue')`

const create_images = `# create image with default dimensions
canvas.create_image(25, 30, 'smiley.png')

# or add with specific dimensions width = 100, height = 300
canvas.create_image_with_size(25, 30, 100, 300, 'smiley.png')`

const delete_function = `rect = canvas.create_rectangle(100, 200, 300, 400, 'yellow')
...
canvas.delete(rect)`

const set_hidden = `rect = canvas.create_rectangle(100, 200, 300, 400, 'yellow')
canvas.set_hidden(rect, True)  # no longer visible
...
canvas.set_hidden(rect, False) # visible again`

const overlapping = `canvas.create_rectangle(10, 50, 200, 300, 'yellow')
canvas.create_oval(100, 100, 300, 350, 'red')
canvas.create_text(70, 170, anchor='sw', font='Arial', font_size=30, text='yay!')`

const set_color = `rect = canvas.create_rectangle(0, 0, 50, 100, 'yellow')
...
canvas.set_color(rect, 'red')`

const set_fill_and_outline = `rect = canvas.create_rectangle(0, 0, 50, 100, 'yellow')
...
canvas.set_fill_color(rect, 'red')
canvas.set_outline_color(rect, 'blue')`

const get_dimensions = `rect = canvas.create_rectangle(0, 0, 50, 100, 'yellow')
print(canvas.get_obj_width(rect))  # prints 50
print(canvas.get_obj_height(rect)) # prints 100`

const get_location = `oval = canvas.create_oval(5, 10, 50, 100, 'red')
print(canvas.get_left_x(oval))  # prints 5
print(canvas.get_top_y(oval))   # prints 10`

const move_to = `rect = canvas.create_rectangle(100, 200, 300, 400, 'yellow')
canvas.moveto(rect, 25, 50)  # moves upper left to 25, 50`

const move = `rect = canvas.create_rectangle(100, 200, 300, 400, 'yellow')
canvas.move(rect, 5, 10)  # moves upper left to 105, 210`