import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from "react-router-dom"
import { CircleLoader } from "react-spinners";


export const Section7 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 7:  Big-O, Sorting, and Lambdas</h1>
        <hr />
        <h5><Link to="/miro"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>

        <p className="alert alert-primary">Please spend the first few minutes of section filling out the End-of-Quarter Evaluation given by your Section Leader.
            We'll collect more information about course instructors in Course Evals, so please
            use this as an opportunity to provide feedback on your section leader and other aspects of CS106A! </p>

        <p>This week in section, you are going to get some practice with a few new topics.</p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/section7-sum24.zip`}>Section 7 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you do with your assignment starter code. The sorting problems can be implemented
            in the Python interpreter.</p>

        <h2>Big-O</h2>
        <p>What is the Big O runtime of the following functions, in terms of the variable N?</p>
        <p>Code Snippet A</p>
        <PythonHighlighter code={big_o_a} />
        <p>Code Snippet B</p>
        <PythonHighlighter code={big_o_b} />
        <p>Code Snippet C</p>
        <PythonHighlighter code={big_o_c} />
        <p>Code Snippet D</p>
        <PythonHighlighter code={big_o_d} />
        <p>Code Snippet e</p>
        <PythonHighlighter code={big_o_e} />

        <h2>Sorting, Min, and Max with Lambdas</h2>
        <p>Given a list of tuples that represent houses for rent, the number of bedrooms, and their prices, like so:</p>
        <PythonHighlighter code={houses} />
        <p>Sort the list in the following ways:
            <ul>
            <li>In ascending order by number of rooms</li>
            <li>In descending order of price</li>
            <li>In ascending order of price-per-room</li>
            <li>Least expensive price-per-room (use min function)</li>
            <li>Most number of rooms (use max function)</li>
            </ul>
        </p>
        <p>A good way to quickly test your code is by running it in the terminal. Open a new terminal and type either python3 or py to start the Python interpreter. Then you can enter code line-by-line and see the results:</p>
        <PythonHighlighter code={houses_res} />

        <h2>Grocery Dictionaries, Revisited</h2>
        <p>Let's take a look at one of the inner nested dictionaries from Week 5's section, where each key is an item name and each value is how many of that item we need to buy at the store:</p>

        <h3>Reverse Keys</h3>
        <p>Recall the dictionary functions <code>keys()</code> and <code>values()</code> that return a list of keys or values respectively:</p>
        <PythonHighlighter code={rev_keys} />
        <p>Write a function <code>reverse_keys(counts)</code> that prints the keys in a <code>counts</code> dictionary in reverse alphabetical order (note how uppercase letters come after lowercase ones, this is just how alphabetical sorting works):</p>
        <PythonHighlighter code={counts} />
        <p>Test your implementation by running <code>python3 groceries_revisited.py -reverse long-list.txt</code> in the terminal.</p>
        <h3>Top 5 Items</h3>
        <p>In Python, there's a dictionary function <code>items()</code> that returns a list of key-value tuples:</p>
        <PythonHighlighter code={top_5} />
        <p>Now, with help from the <code>items()</code> function, implement a function, <code>most_used(counts)</code>, which given a counts dictionary, prints the 5 foods we need the most of with their counts, in order. For example, <code>most_used(counts)</code> for the counts dictionary shown above would print:</p>
        <PythonHighlighter code={top_5_res} />
        <p>Test your implementation by running <code>python3 groceries_revisited.py -most long-list.txt</code> in the terminal.</p>










        
    </>
}

const houses = `houses = [('main st.', 4, 4000), ('elm st.', 1, 1200), ('pine st.', 2, 1600)]`
const houses_res = `$ python3
>>> houses = [('main st.', 4, 4000), ('elm st.', 1, 1200), ('pine st.', 2, 1600)]   # define houses list
>>> list(sorted(houses, reverse=True))                                              # sort in reverse alphabetical order
[('pine st.', 2, 1600), ('main st.', 4, 4000), ('elm st.', 1, 1200)]                # see sorted result`

const dict = `counts = {'eggs': 3, 'coconut milk': 6, 'flour': 1, 'M&Ms': 5, 'croissant': 12, 'coffee': 2}`

const rev_keys = `>>> counts.keys()
dict_keys(['eggs', 'coconut milk', 'flour', 'M&Ms', 'croissant', 'coffee'])

>>> counts.values()
dict_values([3, 6, 1, 5, 12, 2])`

const counts = `flour
eggs
croissant
coffee
coconut milk
M&Ms`

const top_5 = `>>> counts.items()
dict_items([('eggs', 3), ('coconut milk', 6), ('flour', 1), ('M&Ms', 5), ('croissant', 12), ('coffee', 2)])
`
const top_5_res = `croissant 12
coconut milk 6
M&Ms 5
eggs 3
coffee 2`

const big_o_a = `sum = 0
for i in range(1, N + 3): 
    sum += 1
for j in range(1, N * 2 + 1):
    sum += 1`

const big_o_b = `sum = 0
for i in range(1, N - 4):
    for j in range(1, N - 4, 2):
        sum += 1`

const big_o_c = `sum = 0
for i in range(1, 10000001):
    sum += 1`

const big_o_d = `sum = 0
for i in range(1000000):
    for j in range(1, i + 1):
        sum += N
    for j in range(1, i + 1):
        sum += N
    for j in range(1, i + 1):
        sum += N`

const big_o_e = `sum = 0
i = 1
while i <= N:
    sum += 1
    i *= 2`