import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
import { Link } from "react-router-dom"


export const Section4 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 4: Lists of Lists and Strings</h1>
        <hr />
        <p>This week in section, we are going to focus on lists of lists and strings. </p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/Section4.zip`}>Section 4 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you do with your assignment starter code. You can test all of these functions
            by running the doctests we've provided in the starter code.</p>

        <h3>Enumerate</h3>
        <p> Write a function <code>enumerate(lst)</code> that takes in a list <code>lst</code> and returns a list
            of lists where each nested list contains the index of an element in the original list and the element itself.
            These lists should appear in increasing order of indices. Here are some sample calls of <code>enumerate</code> and
            what should be returned:</p>

        <PythonHighlighter code={enumerate_runs} />


        <h3>Double Char</h3>

        <p>Write a function <code>double_char(s)</code> that takes in a string <code>s</code> and returns a
            string where all the characters in <code>s</code> have been doubled. Here's a few sample calls
            of <code>double_char</code>:</p>

        <PythonHighlighter code={double_char_runs} />

        <h3>Find Differences</h3>

        <p>Implement the function <code>find_diffs(str1, str2)</code> that takes in two equal length strings as parameters
            and returns the number of indices where the two strings have different characters.</p>

        <PythonHighlighter code={find_diffs} />

        <h3>More String Practice</h3>
        <p>You can find more strings practice on this <a href="https://wopr-service-qbrbcbuzwa-uw.a.run.app/#string1">experimental server</a> by Nick Parlante, another CS106A lecturer. </p>

    </>
}

const enumerate_runs = `>>> enumerate(['cs106a', 'is', 'super', 'fun'])
[[0, 'cs106a'], [1, 'is'], [2, 'super'], [3, 'fun']]
>>> enumerate(['hello'])
[[0, 'hello']]
>>> enumerate([])
[]`

const double_char_runs = `>>> double_char('Hello')
'HHeelllloo'
>>> double_char('cs106a')
'ccss110066aa'`

const catty = `>>> catty('xCtxxxTacx')
'CtTac'`

const find_diffs = `>>> find_diffs("ATGCC", "ATTCA")
2
>>> find_diffs("ABC", "DEF")
3
>>> find_diffs("CAT", "CAT")
0`