import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const Handout = ({ element }) => {

  return (
    <HandoutPageOuter className="greyBackground pb-4">
      <HandoutPadding>
        <HandoutOuter>
          {element}
        </HandoutOuter>
      </HandoutPadding>
    </HandoutPageOuter>
  );
}

const HandoutPageOuter = styled.div`
  height:max-content;
  min-height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
`

const HandoutPadding = styled.div`
  padding: 20px;
  padding-top: 20px;
  display: flex;
  width:100%;
  flex-grow: 1;
  justify-content: center;

  @media (max-width: 930px) {
    padding: 0px;
  }
  `

const HandoutOuter = styled.div`
  max-width: 930px;
  background-color: white;
  border-radius: 5px;
  overflow-x: hidden;
  padding: 50px 100px 100px;
  height: 100%;
  flex-grow: 1;

  @media (max-width: 930px) {
    padding: 20px;
  }
`


