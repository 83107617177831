import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'
// @ts-ignore
import MysterySquare from "../img/section3/mystery_square.mov"
import MouseTracker from "../img/section3/Section3MouseTracker.png"



export const Section3 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
 <h1>Section 3: Functions and Strings</h1>
        <hr />
        <h5><Link to="/kaia"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>

        <p>This section, we'll practice using parameters and returns to pass information between functions. We'll also get practice with strings. We often include
            more problems on the handout than can be covered in section, so use any problems you don't get to in
            section as extra practice for Assignment 3! </p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/Section3.zip`}>Section 3 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you do with your assignment starter code.</p>

        <h3>In Range</h3>
        <p>In the file <code>in_range.py</code>, implement the function <code>in_range(num, low, high)</code>, which takes
            in 3 integers as parameters and returns whether <code>num</code> is in
            between <code>low</code> and <code>high</code>, inclusive. Here are a few
            same runs of the function, which we've included as doctests in the starter code.</p>

        <SyntaxHighlighter code={in_range_runs} />

        <p>To run the doctests, enter <code>python3 -m doctest in_range.py -v</code> in the terminal.</p>

        <p>Next, complete the program by writing a <code>main</code> function which reads in three integers from
            the user and calls <code>in_range</code> to determine whether or not the second number is in between
            the first and third. Here are three sample runs of the program (run from the terminal by
            entering <code>python3 in_range.py</code>):</p>

        <SyntaxHighlighter code={in_range_main_runs} />
         <h3>FizzBuzz</h3>

        <p>In the game Fizz Buzz, players take turns counting up from one. If a player's turn lands on a number
            that's divisible by 3, she should say “fizz” instead of the number, and if it lands on a number that's
            divisible by 5, she should say “buzz” instead of the number. If the number is both a multiple of 3
            and of 5, she should say "fizzbuzz" instead of the number.</p>

        <p>This presents an interesting problem in control flow and parameter usage. In the
            file <code>fizzbuzz.py</code>, write a function called <code>fizzbuzz(n)</code> which accepts
            as a parameter an integer called <code>n</code>. The function
            should count (printing each number) from 1 up to and including <code>n</code>, fizzing and buzzing
            along the way. Once it's done, the function should return how many numbers were fizzed or buzzed
            in total.</p>

        <p>Next, complete your program by writing a <code>main</code> function that reads in an integer
            from the user and plays fizzbuzz until it counts up to that number then prints out the count
            of the numbers that were fizzed, buzzed, or fizzbuzzed. Here's a sample run of the program:</p>

        <SyntaxHighlighter code={fizzbuzz} />

        <h3>Catty</h3>

        <p>Implement the function <code>catty(s)</code> that takes in a string <code>s</code> and returns a new
            string made of the chars from <code>s</code> that are 'c' 'a' or 't' (not case sensitive).</p>

        <PythonHighlighter code={catty} />


    </>
}

{/*
const HandoutInner = () => {
    return <>
        <h1>Section 3: Graphics & Animation</h1>
        <hr />
        <h5><Link to="/kaia"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>
        <p>This section, we'll practice using parameters and returns to pass information between functions. We'll also
        use our new animation skills to make a program that will draw a circle to follow our mouse. All the problems in this section will be super helpful for Assignment 3! </p>

        <h3>Random Circles</h3>
        <Link to="https://codeinplace.stanford.edu/cs106a/ide/a/randomcircles">Code on the online IDE!</Link><br />

        <h3>Mouse Tracker: Part 1</h3>
        <a href={`${process.env.PUBLIC_URL}/Section3.zip`}>Download the section 3 starter code here!</a>
        <p>Our goal is to draw a circle that will track the movement of our mouse. The program should wait for a user to click before the circle in the middle starts tracking the mouse. This will be super useful for Assignment 3: Breakout where we have to move the paddle with our mouse!
        The starter code draws a circle and a rectangle.
        </p>
        <div className='w-100 d-flex justify-content-center'>
            <img style={{maxWidth:400}} src={MouseTracker}/>
          </div>
        <SyntaxHighlighter code={mouse_tracker} />

        <h3>Mouse Tracker: Part 2</h3>
        <p>For the next part of the problem, have our game end once our mouse overlaps with the rectangle in the right-hand corner.
        </p>
        
        <h3>Mystery Square</h3>

        <p>In the file <code>mystery_square.py</code>, implement a program that creates a square that randomly changes
            color every second. We've provided some constants: <code>SQUARE_SIZE</code> for the dimensions of the
            square, <code>DELAY</code> for the number of seconds to pause between each color change,
            and <code>COLORS</code> which is a list of colors you should randomly select from.</p>

        <center>
            <video controls width='50%'>
                <source src={MysterySquare} className="img-fluid mx-auto d-block" type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </video>
        </center>

        <br />

        <p>Here's a good outline for approaching this problem:</p>

        <ol>
            <li>Create a square that's <code>SQUARE_SIZE</code> pixels in width and height
                in the center of the screen. You can choose any initial color.</li>
            <li>Create an animation loop; within a loop, sleep for <code>DELAY</code> seconds and
                then call <code>canvas.update()</code>.</li>
            <li>Select a new color from the list <code>COLORS</code> each time you loop. Perhaps this is a good
                place to employ a helper function, perhaps <code>get_random_color()</code> which returns a
                random color from your list.</li>
        </ol>

        <p>To run this program, enter <code>python3 mystery_square.py</code> in your terminal.</p>

   

    </>
}
*/}


const catty = `>>> catty('xCtxxxTacx')
'CtTac'`
const mouse_tracker = `import sys
import random
from graphics import Canvas

CANVAS_WIDTH = 800
CANVAS_HEIGHT = 600
CIRCLE_SIZE = 70  # Setting the diameter of the circle
RECT_SIZE = 100

def create_rectangle(canvas):
    """
    Creates and returns rectangle object in the upper right corner of the canvas.
    """
    # Calculate the coordinates for the rectangle
    rect_x1 = CANVAS_WIDTH - RECT_SIZE
    rect_y1 = 0
    rect_x2 = CANVAS_WIDTH
    rect_y2 = RECT_SIZE
    
    rectangle = canvas.create_rectangle(rect_x1, rect_y1, rect_x2, rect_y2, fill="blue")
    return rectangle


def create_circle(canvas):
    """
    Creates and returns circle object, which starts
    at the center of the canvas.
    """
    # Calculate the coordinates to center the oval
    x1 = (CANVAS_WIDTH - CIRCLE_SIZE) / 2
    y1 = (CANVAS_HEIGHT - CIRCLE_SIZE) / 2
    x2 = x1 + CIRCLE_SIZE
    y2 = y1 + CIRCLE_SIZE
    
    circle = canvas.create_oval(x1, y1, x2, y2)
    return circle


def main():
    """
    mouse.py
    Write a program that creates a circle to track a person's mouse
    """
    canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT, "Follow Mouse Circle")
    circle = create_circle(canvas)
    rectangle = create_rectangle(canvas)

    canvas.mainloop()


if __name__ == "__main__":
    main()
`

const in_range_runs = `>>> in_range(5, 1, 10)
True
>>> in_range(5, 1, 3)
False
>>> in_range(5, 5, 10)
True`


const in_range_main_runs = `Enter first number: 1
Enter second number: 10
Enter third number: 20
10 is in between 1 and 20

Enter first number: -4
Enter second number: 1
Enter third number: 7
1 is in between -4 and 7

Enter first number: 5
Enter second number: 1
Enter third number: 10
1 is not in between 5 and 10`

const fizzbuzz = `Number to count to: 17
1
2
Fizz
4
Buzz
Fizz
7
8
Fizz
Buzz
11
Fizz
13
14
Fizzbuzz
16
17
7 numbers were fizzed, buzzed, or fizzbuzzed`