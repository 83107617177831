import { Handout } from "cs106a/components/Handout"
import styled from "styled-components"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import Example1 from './example1.png';
import Example2 from './example2.png';
import Infinite from "./exampleInf.png"
import Start from "./start.png"
import Files from "./files.png"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter";
import { FaFileZipper } from "react-icons/fa6";
import { FaComment, FaCompass, FaDownload, FaKey } from "react-icons/fa";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

export const InfiniteStory = () => {
  return <Handout element={<HandoutInner />} />
}

// Anjali, Yasmine, Katie

const HandoutInner = () => {
  return <>
    <h1>Assignment 4: Infinite Story</h1>
    <hr />
    <i>Assignment designed by Chris Piech, inspired by Eric Roberts. Handout written with Anjali Sreenivas, Yasmine Alonso, Katie Liu. Ethics by Javokhir Arifov and Dan Webber. Test scripts by Iddah Mlauzi and Tina Zheng. Advised by Mehran Sahami and Ngoc Nguyen, and more!</i>
    <br />
    <br />

    <p><b>Due: 11:59pm (Pacific Time) on Friday, August 2nd</b></p>

    <div className="alert alert-primary"><b>Wahoo!</b> This assignment is new! How exciting. In fact, to the best of our knowledge it is one of the first assignments in intro CS that uses ChatGPT, even beyond Stanford. If you notice anything that you think could be a mistake, let us know! Also if you are having fun let us know too. The best way to contact us is via a private post on the &nbsp; 
      <a target="_blank" className="alert-link" href="https://edstem.org/us/courses/57479/discussion/"><FaComment/> Course Discussion Forum</a>. Errata:
    </div>



    <p>Helpful links:<br /><a href="https://edstem.org/us/courses/60442/discussion/5126072">Infinite Story starter code</a><br />
      <Link className="btn btn-secondary mt-1" to="/notopenai"><FaKey /> Get your API Key</Link></p>

    <Milestone>Overview</Milestone>

    {/* <i>Created by Chris .</i> */}

    <Quote>You are exploring a world. Almost every scene is normal, but if the hero explores enough, they will slowly start to uncover the mysterious parts…</Quote>

    <p>In this assignment, you will write a choose-your-own-adventure adventure game that harnesses the power of generative AI. You will get to apply the knowledge you have learned about dictionaries and combine that with making requests to ChatGPT to ultimately help guide your user through a mystical adventure. In the end, you will think about the ethical implications underlying the use of generative AI in storytelling applications.
    </p>

    <p>
      When the user starts the program it will look like this:
      <DemoImg src={Example1} />
    </p>

    <p>
      The user can then choose what action to take next. Here they chose 1, to take the road up the hill:
      <DemoImg src={Example2} />
    </p>

    <p>
      Up to this point, it seems like a standard storytelling app. The magic happens when the user gets to a scene that hasn't been written yet. Instead of crashing, or preventing the user from continuing, the app will make a request to ChatGPT to generate the next scene. The story continues!
      <DemoImg src={Infinite} />
    </p>

    <p>One of the goals of this assignment is that you get practice decomposing your program. In the milestones we will give you suggestions of helpful functions. However the control flow decisions are ultimately in your good hands.</p>


    <Milestone>Milestone 1: Loading the Story</Milestone>

    <p>A key limitation for any story of this nature is that you will eventually hit a dead end. Program <code>warmup.py</code> to find the "dead ends" in a story. Dead ends are scene keys that are referenced in the story, but are not defined. </p>

    <p>In order to complete this warmup we need to first understand how story data is stored. The data for a story is saved as a nested dictionary. We've created two story dictionaries for you, <code>original_big.json</code> and <code>original_small.json</code>.  These files are stored in a folder called <code>data</code>:<br />
      <img src={Files} style={{ maxWidth: 300 }} />
    </p>

    <p>First, load in a story dictionary. Since each story is in json format you can load it with the following line of code which should be at the start of your <code>main</code> function:

      <PythonHighlighter code={"story_data = json.load(open('data/original_small.json'))"} /> You will routinely access this dictionary throughout your program. You should only load the file once.</p>

    <p>Before proceeding, take some time to understand how the data in story_data is organized. Here is an excerpt of the story_data dictionary:

      <SyntaxHighlighter code={originalSmallExtract} customStyle={{ fontSize: '12px' }} />
    </p>

    <p>Wow! A truly nested dictionary. Don't be intimidated by it. Instead, understand it step by step.</p>

    <p><b>Story:</b> Every story is a dictionary with two keys: a key "plot" that is associated with a string description of the overall story line, and a key "scenes" that is associated with a dictionary containing all the data of the scenes in the story (see Scenes below).</p>
    <p><b>Scenes:</b> A dictionary with all the scene data. In the scenes dictionary <i>scene keys</i> are associated with all the data for that given scene. Each scene has a "text" description, a "scene_summary" and a list of choices that the user can take from the scene (see Choices below). Here is scene_data associated with the scene key "knocking_on_small_brick_building". It only has one choice: <SyntaxHighlighter code={exampleScene} customStyle={{ fontSize: '12px' }} /></p>



    <p><b>Choice:</b> A choice has both the "text" of the choice and the "scene_key" that the hero will go to if they take this choice.</p>

    <p>Now that you have the story_data loaded, it is time to program <code>warmup.py</code> to find "dead ends":<br/>
    <ul>
      <li>Loop over all the scenes in the story. </li>
    <li>For each scene, loop over all the choices for that scene.</li> 
    <li>Each choice has a value associated with the key "scene_key".</li>
    <li>If the scene_key is not a key in story_data["scenes"], then it is a dead end. Print out scene_key.</li>
    </ul>
   For example if you ran <code>warmup.py</code> with the story constant set to "original_small.json" file it should print out the following keys:

      <TerminalOut>next_to_gully<br />
        descend_into_valley<br />
        watching_sunset<br />
        continue_exploring_hilltop<br />
        return_to_small_brick_building</TerminalOut></p>

    <p>We print out <code>descent_into_valley</code> because it is referenced by a choice, but it is not in the scenes dictionary (it is a dead end). We do not print out <code>knocking_on_small_brick_building</code> as it is a key in the scenes dictionary. Recall that for this milestone you should write your code in <code>warmup.py</code>. Test your code on both original_small and original_big. The order of dead ends does not matter. </p>

    {/*  */}

    <p><b>Extra understanding:</b> Are you still trying to wrap your head around <code>story_data</code>? Check out this beautiful visualization by Anjali Sreenivas: <br/><a className="btn btn-secondary" href="https://prezi.com/p/embed/IlHuwC9B5SuMr3iSmtOr/" target="_blank"><FaCompass/> story_data prezi</a><br/>
    You can also try these <b>optional</b> challenges. Can you print out these values using story_data:
      <ul>
        <li>The "plot" of the story?</li>
        <li>The "text" description of the scene with key "start"?</li>
        <li>The "scene_key" you would go to if you took the first choice from the "start" scene (the choice that has text "Take the road up the hill")?</li>
      </ul>
    </p>


    <Milestone>Milestone 2: Printing a Scene to the Console</Milestone>

    <p><div className="alert alert-primary"><b>Installing packages:</b> <br/>
    For the rest of the assignment you will be programming in <b>infinitestory.py</b>. Before you can run your code, you will need to install the <code>requests</code> package. You can do this by running <TerminalOut>python3 -m pip install requests</TerminalOut> in your terminal. You may need to replace <code>python3</code> with <code>python</code> or <code>py</code> depending on your system.
    </div></p>

    <p>Write a function to print a single scene to the console. It should only take one parameter, the dictionary associated with that scene. Write your code in <code>infinite_story.py</code>. In this new program you should load the story_data json in the same way as in Milestone 1. You can test your code on either the big or small story data. We recommend that at this point you start working with <code>original_big</code>.</p>

    <p>Your function should print out the "text" for the scene as well as the "text" for each choice associated with the "choices" key in the scene data. Choices should be numbered for the user, starting at 1. For example, if you called the function with the data for the "start" scene, it should print out the following:
      <TerminalOut>{sceneStartConsole}</TerminalOut></p>
    
    <p><i>Aside: We are eventually going to render a scene by both printing out a description of the scene to the console <b>and</b> render an image associated with the scene. Rendering the image is in a future Milestone.</i></p>

    <p>Recall that a scene dictionary looks like this (this one is for the start scene):

      <SyntaxHighlighter code={`{
  "text": "You are standing at the end of a road before a small brick building. A small stream flows out of the building and down a gully to the south. A road runs up a small hill to the west.",
  "scene_summary": "Start of the story. Standing in front of a brick building.",
  "choices": [
    {
      "text": "Take the road up the hill",
      "scene_key": "overlooking_valley"
    },
    {
      "text": "Walk up to the stream",
      "scene_key": "next_to_gully"
    },
    {
      "text": "Knock on the door",
      "scene_key": "knocking_on_small_brick_building"
    }
  ]
}`} customStyle={{ fontSize: '12px' }} />
    </p>

    <p>Observe that every scene has a description ("text") as well as a list of next scene options that the user can choose from ("choices").
      Notice that choices is a list and that each element in the list is a dictionary of its own. The "text" key in each inner dictionary is the text description for the choice.
    </p>

    



    <p>Your function should work for any scene, not just the start. Note, that you do not need to use the "scene_summary" key, that will come in handy later.</p>

    <Milestone>Milestone 3: Get a Valid Choice</Milestone>

    <p>Write a function to get a valid choice from the user. This function should also only take in one parameter, the data dictionary of a scene. It should return the choice the user made.</p>

    <p>After you print a scene to the console, including the choices, you will need to get a valid choice from the user. In this milestone you will write the function that gets that information from the user.</p>

    <p>Ask the user what they choose, with input prompt <code>"What do you choose? "</code>. Their response needs to be one of the integers that corresponds to a printed choice for the scene. Until the choice is valid, print out <code>"Please enter a valid choice: "</code> and then re-prompt the user to enter a valid choice.
      When you get a valid choice, return the choice made.</p>

    <p>Here is an example, where we call the get valid choice function for the "start" scene. Note that the scene has already been printed using the function from the previous milestone. User input is in blue italics. The user enters two invalid choices, before they eventually enter a valid choice, <code>1</code>, which corresponds to the text "Take the road up the hill"<TerminalOut>You are standing at the end of a road before a small brick building. A small stream flows out of the building and down a gully to the south. A road runs up a small hill to the west.<br />
      1. Take the road up the hill<br />
      2. Walk up to the stream<br />
      3. Knock on the door<br />
      What do you choose? <UserInput>cat</UserInput><br />
      Please enter a valid choice: <UserInput>5</UserInput><br />
      Please enter a valid choice: <UserInput>1</UserInput></TerminalOut></p>
    <p>Pro Tip: Python lists are 0-indexed, but our choices are indexed starting at 1! How might you take care of this?</p>

    <Milestone>Milestone 4: Scene Flow</Milestone>



    <p>Now that you have functionality to print out a scene, and get a valid choice for a scene, it is time to stitch our story app together! In main(), your job is to infinitely run scenes by transitioning from one scene to the next based on what the user inputs.</p>

    <p>Pro Tip: You should have a variable which keeps track of the current scene key. The first scene always has the scene key "start".</p>

    <p> In an infinite loop you should:
      <ol>
        <li>get the scene data for the current scene</li>
        <li>print the current scene</li>
        <li>get a valid choice from the user</li>
        <li>update the current scene key</li>
      </ol>  </p>

    <p>Suggested: To make it easier for the user to read, print out an extra (blank) line to the console before you print out a scene.</p>


    <p>Woohoo! Look at your infinite story coming together! If you play for long enough, you will eventually reach a scene_key which is not in your story. Your program will likely crash with an error that says <code>KeyError</code> and complains that a scene key is not in your scenes dictionary. So what do we do if we don't have a scene description?! We will resolve this issue in the next milestone! 🙂</p>


    <Milestone>Milestone 5: Handling Infinity</Milestone>

    <p>When we get to a scene key where we don't have scene data (i.e. the key does not exist in our story_data dictionary, a dead end), we are not going to crash. Instead, we are going to call upon ChatGPT for help in generating a new scene, including appropriate choices! Then, we will continue our program with the newly created scene. For this milestone, define a function that creates a new scene when the scene key doesn't exist in your story_data dictionary. Specifically you should:
      <ol>
        <li>Print "[Suspenseful music plays as the story continues...]"</li>
        <li>Construct a prompt to ask Chat GPT to generate the next scene</li>
        <li>Add the scene into your story_data, so that if the story brings the hero back to the same scene key, you display the same information.</li>
      </ol></p>

    <p>The prompt should be formatted like this:<br />
      <code>"Return the next scene of a story for key [scene key]. An example scene should be formatted in json like this: [example scene]. The main plot line of the story is [plot]."</code><br />
      scene_key is the key of the scene to be generated. The plot should be the value associated with "plot" in story_data. The example scene should be the start scene's data turned into a string. You can cast a dictionary to a string, just like you can cast an integer to a string, using a command like <code>str(start_scene_data)</code>. It is important to give ChatGPT an example json so that it knows what format you are expecting scenes to be in.</p>

    <p>So how do we communicate with ChatGPT to generate our next scene when we do not already have the data in our dictionary? To do this, we will first need to understand a new key concept: APIs.
    </p>

    <p>APIs (Application Programming Interfaces) allow software applications to communicate and interact with each other. They define the methods and data formats that applications can use to request and exchange information, allowing computer scientists to integrate various services, such as ChatGPT, seamlessly into their own projects without a lot of added complexity. Think of them as functions on a remote computer that you can call over the internet. They are a super powerful tool!</p>

    <p>See <code>example_request.py</code> for a full example of making an API "call" to OpenAI.
      <PythonHighlighter code={gptExample} />
    </p>

    <p>This is an example of an API "call", where we are sending a prompt to OpenAI's chat API (client) to generate a response based on the prompt "What is the capital of all countries in east africa? Reply in json where keys are countries". It also specifies that the input message is from the user ("role": "user"), indicates the GPT model to be used ("gpt-3.5-turbo"), and specifies that the response should be formatted as json (response_format={'{'}"type": "json_object"{'}'}).</p>

    <p>In order for the call to work we need to first initialise the API client. Notice the line in your program:
      <pre style={{margin:0}}>CLIENT = NotOpenAI(api_key="yourapikey")</pre>
      You are going to need to replace "yourapikey" with your own API Key. To get your API key go to <a href="https://cs106a.stanford.edu/notopenai" target="_blank">https://cs106a.stanford.edu/notopenai</a><br /></p>

    <p>The <code>chat_completion</code> that you get back from the API call is a rather complicated object. To get just the json response you can use the following two lines:
      <PythonHighlighter code={parseCompletion} />
      If ChatGPT has done its job, <code>new_scene_data</code> will now be a new dictionary for a scene, complete with text, summary and choices. You can now add this new scene to your story_data dictionary.</p>


    <p><i>Aside: Why is the API called NotOpenAI? Its just a joke. OpenAI is the company that created GPT-3.5. To keep things free for you we are routing all of your requests through our CS106A paid account. We call it NotOpenAI because we are not OpenAI 🙂. The API is identical to the OpenAI API, and if you want to switch over to your own paid OpenAI key, you can! See <a href="https://cs106a.stanford.edu/notopenai" target="_blank">API page</a> for details.</i>
    </p>


    <Milestone>Milestone 6: Visualize Scene Images</Milestone>

    <p>Perhaps you arere travelling in a lush valley along a winding river, passing by quaint and charming brick cottages. Or maybe, once dusk has come upon us, you continue following this glowing, luminous stream to encounter a mysterious building with light pouring out of it. These visuals bring magic to the entire assignment – it is up to you to use the given <code>show_illustration</code> function now to enhance the user's experience! We have written the function for you, but you should call it in your code to diplay the visualizations. For all of the scenes in original_big and original_small, we have asked <a href="https://openai.com/index/dall-e-3/" target="_blank">DallE</a> to generate a corresponding image. They are all saved in the img folder.</p>

    <p>Note: you might need to run <code>py3 infinite_story.py</code> (Windows) or <code>python3 infinite_story.py</code> (Mac) in your PyCharm terminal in order for the images to appear.</p>
    <p>Below is a brief explanation of the code!</p>

    <p>It is important to understand where these images reside, and how we can access them. Notice that in your infinite story assignment folder, there is a sub-folder called img. Within this img folder, we will have all of the images (stored as .jpg images) that can be used to craft our story! Each image is named "[scene_key].jpg" where scene_key is the key in the "scenes" of the original_big story_data dictionary. For example "img/start.jpg" has this image:
      <img src={Start} className="w-100" /><br />
      Which corresponds with the start scene in the original_big story_data dictionary.</p>

    <p>A path to an image is a string that tells you how you can get from a starting directory (in this case, the current folder for your infinite story assignment) to your end destination (the desired image). We separate folders (directories) with slashes (<code>'/'</code>) to indicate what directories must be “clicked through” to get to our destination. That is why <code>"img/start.jpg"</code> is the path to this image above.
    </p>

    <p>As mentioned before: we have two potential cases, either we have an image for our scene (in which case we will display it) or we don't have an image for the scene (in which case we will display a dark screen). It is up to you to do this! We used our handy dandy canvas library (similar to images library) functions to display your images. 🙂 </p>

    <p>The code:
      <ul>
        <li>Creates a canvas in your main function using this line:<br />
          <code>canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT, "Infinite Story")</code></li>
        <li>We construct a path to your desired image! It looks like this, where [your scene key] is replaced by the key of the current scene: <br />
          <code>path = "img/[your scene key].jpg"</code></li>
        <li>To check if we are able to find an image, we used<br />
          <code>os.path.exists(path)</code><br />
          This will evaluate to <code>True</code> if the inputted path is a valid one (meaning that the image exists and we can use it), or to <code>False</code> if the inputted path is an invalid one (meaning the image doesn't exist, and we should display our black screen).</li>
        <li>To display an image using canvas, we can use<br /><code>canvas.create_image_with_size(x, y, img_width, img_height, path)</code> where x and y are the coordinates of the upper left corner of your image.</li>
      </ul>
    </p>

    <p>CONGRATULATIONS!! Look at your program, what magic! You just implemented your first nested dictionaries, and your first generative AI project! We are so proud of you. ♥️ </p>


    <Milestone>Milestone 7: Reflection</Milestone>

    <p>Awesome! Now, we have given creative control over storytelling to our code and the AI model. However, is it really as simple as that? Generative AI is an exciting new tool, but it comes with a lot of complicated questions. Is the AI doing a "good" job? Is the AI copying stories from human content creators? What does it mean for our society if we can't tell the difference between AI content and human content. Finally: what values and biases does this generative AI use? In this last milestone we are going to think about that last question.</p>

    <p>Consider all of the choices you make when telling a story. Narratives, settings, and even names were all things that you, as a storyteller, would have decided. When we prompt our AI model, what choices does it make for us? Whose stories is it really telling?</p>

    <p>Change the story to <code>engineer_story.json</code>. This story only has a single scenes, so it should start generating as soon as the user makes a choice.  </p>

    <p>Run the story a few times and take note of the names that it generates for your co-workers. You can explore statistics behind any names generated using this website: <a href="https://forebears.io/forenames" target="_blank">https://forebears.io/forenames</a>. Feel free to create your own stories or to change the prompt. After, answer these questions in the <code>infinite_ethics.txt</code> file:</p>

    <p><Quote>Q1: You should notice that these names generated by OpenAI for this story are quite popular in a lot of countries. What type of countries/regions keep showing up for these names? Do you feel like they are distributed evenly across the world population? As a hint, compare the names generated in your story to the list of authors of this handout! Justify your answer. Write at least two sentences</Quote></p>

    <p><Quote>Q2: How comfortable do you feel letting the AI model decide the names used in the story? Are you willing to trust the model with other storytelling decisions? There is no wrong position, but please justify your answer. Write at least two sentences.</Quote></p>

    <p><Quote>Q3: Given how ChatGPT handled names in this context, what sorts of issues could you imagine coming up if instead of asking ChatGPT to generate a story, you were using ChatGPT to evaluate candidates for a job? </Quote></p>

    <Milestone>Submission</Milestone>
    <p>Submit through the <a href="https://cs198.stanford.edu/paperless/" target="_blank">Paperless website</a>. 
 Here are the files to submit for this assignment:

<ul>
  <li>warmup.py</li>
  <li>infinite_story.py</li>
  <li>infinite_ethics.txt</li>
  </ul>
  As well as any files you need for your extensions.
  </p>

    <Extensions />
  </>

}

const Extensions = () => {
  return <>
    <Milestone>Extensions</Milestone>
    <Accordion defaultActiveKey="-1" alwaysOpen={false}>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ marginTop: 0 }}>Better scenes with&nbsp;<code>history</code></Accordion.Header>
        <Accordion.Body>
          <HistoryExtension />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header style={{ marginTop: 0 }}>Make your own creative story</Accordion.Header>
        <Accordion.Body>
          <CreativeStory />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ marginTop: 0 }}>Open-ended actions</Accordion.Header>
        <Accordion.Body>
          <OpenEndedAction />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header style={{ marginTop: 0 }}>Deeper ethics analysis</Accordion.Header>
        <Accordion.Body>
          <DeeperEthics />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header style={{ marginTop: 0 }}>Tracking objects</Accordion.Header>
        <Accordion.Body>
          <TrackingObjects />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header style={{ marginTop: 0 }}>Displaying All Scenes</Accordion.Header>
        <Accordion.Body>
          <DisplayInfinite />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header style={{ marginTop: 0 }}>Saving Extension</Accordion.Header>
        <Accordion.Body>
          <SavingExtension />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header style={{ marginTop: 0 }}>Use Your Imagination 🌱</Accordion.Header>
        <Accordion.Body>
          <p>The joy of an extension is sometimes to think of something that nobody else is doing. We say go for it!</p>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  </>
}

const SavingExtension = () => {
  return <>
    <p>For this extension, you will need to think about how to save the state of your story. You can save either just the new scenes created by open ai, or you could also save the story progress! You should use json.dump to save variables to file. Here is an example:
      <PythonHighlighter code={`json.dump(my_variable, open('saved_data.json', 'w'))`} fontSize={12} />
      </p>
      </>
}

const CreativeStory = () => {
  return <>
    <p>
      One way to go above and beyond is to create your own story. You can write your own plot, your own scenes and even create your own images. Interested in an alternative history where Stanford is run by the Olhone? Make it! Want to write a story about a detective in the 1920s? Go for it! This is a great way to show off your creativity and storytelling skills, while practicing authoring json.
    </p>
  </>
}

const TrackingObjects = () => {
  return <>
    <p>For this extension, you will need to think about how to keep track of objects in your story. For example, if the user picks up a key in one scene, you will need to remember that they have the key in the next scene. Could that key then be necessary to take a particular action? This is a challenging extension, but it is a great way to make your story more interactive.
    </p>
  </>
}

const DisplayInfinite = () => {
  return <>
    <p>
      In the assignment specification, when you encounter a scene with no image, you simply draw a black square. What if you instead rendered the text of the scene description. You might find it helpful to use <code>create_text_area</code>, a canvas function we haven't used yet. This function takes in a string, which could be multiline and renders it to the canvas, wrapping the text. 

      <PythonHighlighter code={exampleTextArea} fontSize={12} />
    </p>
  </>
}

const DeeperEthics = () => {
  return <>
    <p>For this extension, you will need to think about the ethical implications of using generative AI in storytelling applications. In the last milestone, you explored the names generated by OpenAI for the story. The ethical issues with large language models and generative AI are deep, and a lot of them are currently being debated! Can you use your ability to call OpenAI from python in order to uncover some interesting ethical insight?</p>
  </>
}

const OpenEndedAction = () => {
  return <>
    <p>
      What if the user wants to do something that is not in the list of choices? For example, what if the user wants to "jump in the stream" when the choices are "Take the road up the hill", "Walk up to the stream", and "Knock on the door"? How would you handle this situation? One option is to always give the user the option for an open ended action, then use ChatGPT to generate a new scene based on the user's input. This is a challenging extension, but it is a great way to make your story more interactive.
    </p>

    <p>Important: to make the story still feel believable, you should also introduce a new dynamic where ChatGPT can decide if you action actually happens. For example if the user enteres "fly to the moon" ChatGPT could decide that this is impossible. You could either make that have no impact on the scene the user is in, the action just fails. Alternatively ChatGPT could generate a scene for the failed action state! Get creative.</p>
  </>
}

const HistoryExtension = () => {
  return <>
    <p>Sometimes the scenes generated by OpenAPI can be non-sensical, because the prompt doesn't give the language model access to the <i>history</i> of things that have happened. In this extension we are going to append to the prompt a line like "Here is what has happened so far: [history]" where [history] is a list of the scenes the hero has visited and the choices they have made. Develop a way to keep track of the history.</p>

    <p>Important: use the "scene_summary" part of each scene when adding to your history of visited scenes. This will keep history from getting too large.</p>
  </>
}


const TerminalOut = styled.div`
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-family: monospace;
    font-size: 14px;
    `

const UserInput = styled.i`
    color: #66bfff;
    font-weight: bold;
    `

const Milestone = styled.h2`
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 1.8em;
    `

const Quote = styled.div`
    font-style: italic;
    padding: 10px;
    border-left: 5px solid #ccc;
    margin: 10px;
    `

const DemoImg = styled.img`
    width: 100%;
    margin: 0px 0;
    `

const sceneStartConsole = <>You are standing at the end of a road before a small brick building. A small stream flows out of the building and down a gully to the south. A road runs up a small hill to the west.<br />
  1. Take the road up the hill<br />
  2. Walk up to the stream<br />
  3. Knock on the door</>

const parseCompletion = `# get the content of the response
response_str = chat_completion.choices[0].message.content
# turn the string into a dictionary using loads
new_scene_data = json.loads(response_str)`

const gptExample = `chat_completion = CLIENT.chat.completions.create(
  messages=[{
    "role": "user",
    "content": """What is the capital of all countries in east africa?
    Reply in json where keys are countries""",
  }],
  model="gpt-3.5-turbo", # the GPT model to use
  response_format={"type": "json_object"} # we want our response in json format,
)`

const exampleScene = `{
  "text": "You knock on the door, but no one answers. You hear a faint sound of a dog barking from inside.",
  "scene_summary": "Nobody answers.",
  "choices": [
    {
      "text": "Go back to the start",
      "scene_key": "start"
    }
  ]
}`

const originalSmallExtract = `{
  "plot": "You are exploring a world. Almost every scene is normal, but if the hero explores enough of the normal parts (eg more than 10 scenes), they will slowly start to uncover the mysterious parts. Most of the tone is simply setting a beautiful and uplifting landscape filled with wonder.",
  "scenes": {
    "start": {
      "text": "You are standing at the end of a road before a small brick building. A small stream flows out of the building and down a gully to the south. A road runs up a small hill to the west.",
      "scene_summary": "Start of the story. Standing in front of a brick building.",
      "choices": [
        {
          "text": "Take the road up the hill",
          "scene_key": "overlooking_valley"
        },
        {
          "text": "Walk up to the stream",
          "scene_key": "next_to_gully"
        },
        {
          "text": "Knock on the door",
          "scene_key": "knocking_on_small_brick_building"
        }
      ]
    },
    "knocking_on_small_brick_building": {
      "text": "You knock on the door, but no one answers. You hear a faint sound of a dog barking from inside.",
      "scene_summary": "Nobody answers.",
      "choices": [
        {
          "text": "Go back to the start",
          "scene_key": "start"
        }
      ]
    },
    ... # more scenes, hidden for space
}`

const exampleTextArea = `# create_text_area function
canvas.create_text_area(x, y, end_x, end_y, text, font_size, font)

# example call
canvas.create_text_area(0, 0,
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  text="this is a test",
  font_size=24,
  font="Times")`