import { Loading } from "components/loading/Loading";
import { CompletionContext } from "contexts/CompletionContext";
import { ProfileContext } from "contexts/ProfileContext";
import { TeachNowButtonWrapper } from "course/carehours/CareHoursPage";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { Handout } from "cs106a/components/Handout"
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaHandshake } from "react-icons/fa";
import { useNavigate } from "react-router-dom"



export const TeachNowSplash = () => {
    return <Handout element={<TeachNowSplashInner />} />
}

const TeachNowSplashInner = () => {
    const navigate = useNavigate()
    const courseId = useCourseId()
    const firebaseDocPath = `course/${courseId}/handouts/teachnowsplash`
    const firebaseDocPathLower = `course/${courseId}/handouts/teachnowsplashbottom`

    const { userData, loading } = useContext(ProfileContext)

    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Welcome to TeachNow</h1>
            <h4>An <i>Optional</i> Teaching Opportunity for CS106A Students</h4>
            <AdminRichTextEditor firebaseDocPath={firebaseDocPath}/>
            <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '50px', gap: '10px'}}>
            <Button variant='warning' onClick={() => navigate(`/${courseId}/teachnowtraining`)}>Start Training</Button>
            <CS106AStudentTeachNowButtonWrapper buttonOnly={true}/>
            
            </div>
            <AdminRichTextEditor firebaseDocPath={firebaseDocPathLower}/>
            
        </div>
    );
}

const CS106AStudentTeachNowButtonWrapper = ({buttonOnly}) => {
    const { isTeachNowTrainingCompleted } = useContext(CompletionContext)

    const trainingCompleted = isTeachNowTrainingCompleted()
  
    if (trainingCompleted) {
      return <TeachNowButtonWrapper buttonOnly={buttonOnly}/>
    } else {
      return <OverlayTrigger
          placement="top"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              You must complete the training before you can use TeachNow.
            </Tooltip>
          )}
        >
          <div>
          <button
              className="btn btn-success mt-1"
              style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}
              disabled={true}
            >
              <div>
                <FaHandshake size={50} />
              </div>
              Teach Now
            </button>
          </div>
        </OverlayTrigger>
    }
  }