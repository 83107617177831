import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { loginWithRedirect } from "cs106a/authentication/Login"
import { Handout } from "cs106a/components/Handout"
import { getAuth } from "firebase/auth"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useUserId } from "hooks/user/useUserId"
import { useEffect } from "react"
import { ProgressBar } from "react-bootstrap"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { FaCopy } from "react-icons/fa"
import styled from "styled-components"
import Swal from "sweetalert2"
import {v4 as uuidv4} from 'uuid';

export const NotOpenAI = () => {

  return <Handout element={<KeyFetcher />} />
}

const KeyFetcher = () => {
  const userId = useUserId()
  const keyPath = `notopenai/spr24/apiKeyMap/${userId}`
  const db = getFirestore()
  const [serverData, serverIsLoading, serverError] = useDocumentData(doc(db, keyPath))



  useEffect(() => {
    if(userId && !serverIsLoading && !serverData){
      console.log('Generating a new key for user', userId)
      const randomKey = uuidv4()
      const newKeyPath = `notopenai/spr24/apiKeys/${randomKey}`
      console.log(newKeyPath)
      setDoc(doc(db, newKeyPath), {owner: userId})
      setDoc(doc(db, keyPath), {apiKey: randomKey})
    }
  }, [serverIsLoading, userId])

  if(serverIsLoading){
    return <>Loading...</>
  }

  if(serverData?.apiKey) {
    const apiKey = serverData.apiKey
    return <KeyReader apiKey={apiKey}/>
    // return <>{serverData?.apiKey}</>
  }

  if(userId) {
    return <>Generating an API key...</>
  }

  return <>You need to sign in<br/> <button onClick={()=>loginWithRedirect()}className="btn btn-primary">Sign In</button></>
}

const KeyReader = ({apiKey}) => {

  const keyPath = `notopenai/spr24/apiKeys/${apiKey}`
  const db = getFirestore()
  const [serverData, serverIsLoading, serverError] = useDocumentData(doc(db, keyPath))

  console.log('serverData', serverData)
  console.log('serverIsLoading', serverIsLoading)
  console.log('serverError', serverError)
  if(serverError){
    return <>Error: {serverError}</>
  }
  if(!serverData){
    return <></>
  }

  function onCopyKey() {
    navigator.clipboard.writeText(apiKey)
    Swal.fire({
      title: "API Key copied to clipboard",
      icon: "success",
      toast: true,
      position: 'top-end',
      timer: 2000,
      showConfirmButton: false,
      timerProgressBar: true
    })
  }

  function onCopyCode(code) {
    navigator.clipboard.writeText(code)
    Swal.fire({
      title: "Code copied to clipboard",
      icon: "success",
      toast: true,
      position: 'top-end',
      timer: 2000,
      showConfirmButton: false,
      timerProgressBar: true
    })
  }

  const tokenLimit = 1000000
  const totalTokens = serverData?.totalTokens || 0
  const pctUsage = 100 * totalTokens/tokenLimit
  // format to one decimal place
  const pctUsageStr = pctUsage.toFixed(0)

  const exampleCode = getExampleCode(apiKey)
  const openAiCode = getOpenAICode()
  return <>
  <h1>NotOpenAI</h1>
  <hr/>
  <h3>API Key</h3>
    <b>Your API Key:</b> <ApiKeySpan>{apiKey}</ApiKeySpan> <button 
      className="btn btn-primary btn-sm"
      onClick={onCopyKey}
    ><FaCopy/> Copy Key</button><br/>
    <span>Do not share this with other people, including other students. It is your personal API key!</span><br/><br/>
    <b>Your usage:</b> <span>{totalTokens} tokens</span> <br/>
    <b>Your token limit:</b> <span>{tokenLimit} tokens</span> <br/>
    <ProgressBar now={pctUsage} label={`${pctUsageStr}%`} />
   
    <h3>Example API Call</h3>
    <button className="btn btn-light btn-sm"
      onClick={() => onCopyCode(exampleCode)}
    ><FaCopy/> Copy Example Code</button>
    <PythonHighlighter code={exampleCode} fontSize={12} />
    

    <h3>Switching to OpenAI API</h3>
    <button className="btn btn-light btn-sm"
      onClick={() => onCopyCode(openAiCode)}
    ><FaCopy/> Copy OpenAI Code</button>
    <PythonHighlighter code={openAiCode} fontSize={12} />
    <p>To get your OpenAI API Key head over to <a target="_blank" href="https://platform.openai.com/api-keys">https://platform.openai.com/api-keys</a></p>
  </>
}

function getOpenAICode() {
  return `# See what we did there?
from openai import OpenAI
  
def main():
    # initialize the OpenAI client
    client = OpenAI(api_key = "your open ai key")

    # the rest is the same!..
    `}  


function getExampleCode(apiKey) {
  return `from notopenai import NotOpenAI

"""
NotOpenAI API Example
---------------------
Example of a program which makes a request to the NotOpenAI API
which is exactly the same as the OpenAI API -- except that it is
free for CS106A students to use.
"""

def main():
    # initialize the client, which can send request
    client = NotOpenAI(api_key = "${apiKey}")

    print("Sending a chat request to the API. This could take a moment...")

    # Construct a prompt as a string
    prompt = "What is the capital of countries in east africa? Reply in json"

    # Make a request to ChatGPT via NotOpenAI. 
    # See https://github.com/openai/openai-python for more details on roles etc
    chat_completion = client.chat.completions.create(
        messages=[
            {
                "role": "user",
                "content": prompt,
            }
        ],
        model="gpt-3.5-turbo", # no choice here if you are using our free API
        response_format={"type": "json_object"},
    )

    # you have to extract the content from the API response
    print(chat_completion.choices[0].message.content)
    """
    Result:
    {
      "Kenya": "Nairobi",
      "Tanzania": "Dodoma",
      "Uganda": "Kampala",
      "Rwanda": "Kigali",
      "Burundi": "Bujumbura",
      "South Sudan": "Juba",
      "Sudan": "Khartoum"
    }
    """
    `}


const ApiKeySpan = styled.span`
  font-family: monospace;
  background-color: #f8f9fa;
  padding: 0.5em;
  border-radius: 0.5em;
`