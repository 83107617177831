import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln6 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 6: Classes (Solution)</h1>
        <hr />
        <h2>Circle Class</h2>
        <PythonHighlighter code={circle} />
        <h2>Tracing</h2>
        <PythonHighlighter code={trace} />
        <h2>Class Class</h2>
        <PythonHighlighter code={class_class} />

    </>
}

const class_class = `# File: Class.py

class Class:

    def __init__(self):
        """
        Creates a new instance of the Class class
        """
        self.students = {}   # instance variable to keep track of the student/id pairs

    def add_student(self, name, id):
        """
        Adds a student by name (string) and id (int) to the class
        """
        self.students[name] = id

    def get_id(self, name):
        """
        Returns the id of the student with this name, or -1 if this
        student isn't in the class
        """
        if name in self.students:
            return self.students[name]
        return -1

    def get_class_list(self):
        """
        Returns a list of the names of all students enrolled in the class
        """
        student_lst = []
        for student in self.students:
            student_lst.append(student)
        return student_lst`

const trace = `Neigh
Woof
Meow
None
None
Woof
None
Neigh`

const circle = `"""
File: circle.py
----------------
This file defines a Class for a Circle with some radius, with
methods for getting the area and circumference of the circle.
"""

import math


class Circle:

    def __init__(self, radius):
        """
        Creates a Circle object and initializes the radius
        """
        self.radius = radius  # we'll want to keep track of radius

    def get_area(self):
        """
        Returns the area of this circle
        """
        area = math.pi * (self.radius ** 2)
        return area

    def get_circumference(self):
        """
        Returns the circumference of this circle
        """
        circumference = math.pi * self.radius * 2
        return circumference`