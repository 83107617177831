import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher"
import { DraftPost, Post } from "course/forum/firebase/ForumDataModels";
import { useState, useContext, useEffect } from "react";
import { query, where, orderBy, and, limit, or, startAfter } from "firebase/firestore";
import { QueryFilterConstraint } from "firebase/firestore";
import { FiltersContext } from "course/forum/FiltersContext";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { ForumContext } from "course/forum/ForumContext";
import { useFirestoreCollectionOnce } from "../../../../hooks/database/useFirebaseDocOnce";
import { useFirestoreInfiniteQuery } from "@react-query-firebase/firestore";

const PAGE_SIZE = 80;



export const usePostLists = () => {
    const dataFetcher = useForumDataFetcher()
    const [drafts, setDrafts] = useState<DraftPost[]>([]);
    const [posts, setPosts] = useState<Post[]>([]);
    const { tagFilters, hideResolved, flaggedOnly } = useContext(FiltersContext);
    const { privatePostThreshold } = useContext(ForumContext)
    const { userData } = useContext(ProfileContext);
    const canViewPrivatePosts = Gate.hasRole(privatePostThreshold)(userData);
    const [announcements, setAnnouncements] = useState<Post[]>([])

    const draftsQuery = query(
      dataFetcher.get_draft_collection_ref(),
      where("authorUid", "==", userData.id),
      where("isPosted", "==", false),
      orderBy('time', 'desc')
  );
    
    let postsQuery;
    let constraints: QueryFilterConstraint[] = [where("isDraft", "==", false)];
    if (hideResolved) constraints.push(where("isResolved", "==", false));
    if (flaggedOnly) constraints.push(where("isFlagged", "==", true));
    if (!canViewPrivatePosts) { constraints.push(or(where("isPrivate", "==", false), where("authorUid", "==", userData.id))); }

    postsQuery = query(
      dataFetcher.get_post_collection_ref(),
      and(...constraints),
      orderBy("isPinned", "desc"),
      orderBy("time", "desc"),
      limit(PAGE_SIZE)
    );
  


    const [draftsData, draftsIsLoading, draftsError, refreshDrafts] = useFirestoreCollectionOnce(draftsQuery);

    const postsData = useFirestoreInfiniteQuery(
        ["posts", { canViewPrivatePosts, hideResolved }],
        postsQuery,
        (snapshot) => {
          const lastDocument = snapshot.docs[snapshot.docs.length - 1];
          if (!lastDocument) {
            return;
          }
          return query(postsQuery, startAfter(lastDocument));
        }
      );

    useEffect(() => {
        if (!draftsIsLoading && draftsData) {
          setDrafts(draftsData)
        }
      }, [draftsIsLoading, draftsData]);


    useEffect(() => {
        if (postsData.data?.pages) {
        const allPosts = postsData.data.pages
            .flatMap((page) => page.docs.map((doc) => {
            return doc.data() as Post
            }))
            .filter((post) => {
            if (tagFilters.filter((tag) => tag !== "resolved").length === 0) {
                return true;
            }
            return post.tags.some((tag) => tagFilters.includes(tag));
            });

        const allAnnouncements = allPosts.filter(post => post.isPinned);
        const nonAnnouncements = allPosts.filter(post => !post.isPinned);
        // If we have a full page of announcements and no non-announcements, fetch the next page
        if(allAnnouncements.length === PAGE_SIZE && nonAnnouncements.length === 0 && postsData.hasNextPage) {
            postsData.fetchNextPage();
        }
        setAnnouncements((_) => allAnnouncements);
        setPosts((_) => nonAnnouncements);

        }
    }, [postsData.data?.pages, tagFilters ]);



    const refreshPosts = () => {
      postsData.refetch();
    }



    return {drafts, announcements, posts, postsData, refreshDrafts, refreshPosts }



}