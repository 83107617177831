import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
// @ts-ignore
import SandStart from "./img/assn4/sand1.png"
// @ts-ignore
import SandDown from "./img/assn4/sand2.png"
// @ts-ignore
import CornerRule from "./img/assn4/corner.png"
// @ts-ignore
import Campfire from "./img/assn4/campfire.jpeg"
// @ts-ignore
import CampfireGray from "./img/assn4/campfire_gray.png"
// @ts-ignore
import SandDemo from "./img/assn4/sand_demo.mov"

import { Link } from "react-router-dom";


export const Assn3 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Assignment 3: Lists of Lists, Strings, Images, and Sand</h1>
        <hr />

        <p><b>Due: 11:59pm (Pacific Time) on Monday, July 22nd</b></p>

        <i>Based on problems by Nick Parlante and Eric Roberts.</i>

        <br />
        <br />


        <p>This assignment consists of a few different programs to give you practice with an assortment of topics
            we've seen recently in lecture: list of lists
            (2-dimensional lists), strings, and images in Python. We'll finish with a longer program called
            Sand, which uses lists of lists to form a grid through which grains of sand can fall.  Here's
            the <a href={`${process.env.PUBLIC_URL}/Assignment3-summer24.zip`}>Assignment 3 starter code</a>. The
            starter code provides Python files for you to write your programs in.</p>

        <h2>Part 1: Sandcastles</h2>

        <h3>1. Zip Lists</h3>

        <p>In the file <code>ziplists.py</code>, implement the function <code>zip2lists(list1, list2)</code>. This
            function is passed two lists of strings (<code>list1</code> and <code>list2</code>), and you can
            assume that both lists have the same length (number of elements). The function should return a
            new list that "zips" together the two lists passed in. That is, the result should be a list that
            contains lists that are pairs of elements, one from each of the original lists, in order.
            For example:</p>

        <PythonHighlighter code={zip_lists_run} />

        <p>The original lists passed in should <b>not</b> be changed. If this function is passed two empty
            lists, it should just return an empty list, since there would be no lists (of pairs) in the result.
            In other words:</p>

        <PythonHighlighter code={zip_lists_empty} />

        <p>You can think of that as a list that contains no lists as elements.</p>

        <p>Doctests are provided in the starter code for you to test your <code>zip2lists</code> function.
            You can run them by right-clicking on the doctests and then clicking "Run Doctest …", or by
            running <code>python3 -m doctest ziplists.py -v</code> in
            the terminal (replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows
            computers). Feel free to write additional doctests if you would like to test your code more thoroughly.</p>

        <h3>2. Expand Encoded Strings</h3>

        <p>One way of compressing text with many repeated characters is to use something known as <i>run-length encoding</i>.
            The idea is that rather than representing every character in a string explicitly, we instead have each character
            immediately followed by a digit which denotes how many times that character should be repeated.</p>

        <p>For example, the string <code>'B4'</code> would be the encoding for the string <code>'BBBB'</code> as the
            character B is to be repeated 4 times. Similarly, the string <code>'m1e2t1'</code> would
            represent <code>'meet'</code>. Thus, the general
            format for run-length encoded strings is a series of pairs, where each pair contains a single character
            followed immediately by a single digit (1 through 9 only, and the digit will never be 0). The digit denotes
            the number of consecutive occurrences of the character immediately before it in the encoded string.</p>

        <p>In the file <code>encoded_string.py</code>, implement the function <code>expand_encoded_string(encoded)</code> that
            takes as a parameter <code>encoded</code>, which is a string representing the run-length encoded text, and returns a
            string which is the expanded version of the text.</p>

        <PythonHighlighter code={encoded_string_run} />

        <p>We've included doctests in the starter code for you to test your <code>expand_encoded_string</code> function.
            You can run them by right-clicking on the doctests and then clicking "Run Doctest …", or by
            running <code>python3 -m doctest encoded_string.py -v</code> in
            the terminal (replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows
            computers). Feel free to write additional doctests if you would like to test your code more thoroughly.</p>

        <h3>3. Finding Forest Fires</h3>

        <p><i>Important setup note:</i> Before you get started on this part of the assignment, make sure that you have
            followed the Pillow installation instructions, which can be found on
            the <Link to="/images"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Image Reference</Link> handout.
            If you cannot get Pillow installed successfully, please come to LaIR or post on the forum for help.</p>

        <p>We're going to write a function called <code>highlight_fires</code> in the
            file <code>forestfire.py</code> that highlights the areas where a forest fire is active. You're given a
            satellite image of the 2018 "Camp Fire" wildfire in California (credit: NASA), and your job is to detect
            all of the "sufficiently red" pixels
            in the image, which are indicative of where fires are burning.</p>

        <p>As we did in class with the "redscreening" example (see lecture 12 code), we consider a pixel "sufficiently red"
            if its red value is greater than or equal to the average
            of the pixel's three RGB values times some intensity threshold. In this case, we have provided you with
            an appropriate intensity threshold of 1.35 via a constant named <code>INTENSITY_THRESHOLD</code> in the
            file <code>forestfire.py</code>. Note that this is a different intensity threshold value than we used
            in class for the "redscreening" example, as different applications often require different intensity
            thresholds.</p>

        <p>When you detect a "sufficiently red" pixel in the original image, set its red value to 255 and its green
            and blue values to 0. This will highlight the pixel by making it entirely red. For all other pixels
            (i.e., those that are not "sufficiently red"), you should convert them to their grayscale equivalent,
            so that we can more easily see where the fire is burning. You can grayscale a pixel by
            summing together its red, green, and blue values and dividing by three (finding the average), and then
            setting the pixel's red, green, and blue values to all have this same "average" value. Once you
            highlight the areas that are on fire in the image (and grayscale all the remaining pixels), you
            should see an image like the one shown below on the right.</p>

        <div className="img-fluid mx-auto d-block">
            <img src={Campfire} width="50%"
                alt="aerial view of forest fire and smoke" />
            <img src={CampfireGray} width="50%"
                alt="aerial view of forest fire, which has been grayscaled except the fire which is now colored red" />
        </div>

        <br />

        <p>You can run your code by entering <code>python3 forestfire.py</code> in the terminal
            (replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows computers).</p>

        <p>For a helpful reminder about the <code>SimpleImage</code> library and tips on working with images, you
            can use the <Link to="/images"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Image Reference</Link> handout.</p>

        <h2>Part 2: Sand</h2>
        <p>For this part of the assignment, you will follow the directions in this <a href="https://docs.google.com/document/d/1E920rsQlZf2sOvtdeCcYYNjS0XuSFc_337zsh6WbNKQ/edit?usp=sharing">Sand Handout</a>.</p>

        <h2>Submission</h2>
        <p>Please submit <code>ziplists.py</code>, <code>encoded_string.py</code>, <code>forestfire.py</code>, <code>waterfall.py</code>, and <code>sand.py</code> in Paperless as usual!</p>

    </>

}

const zip_lists_run = `# calling this function:
zip2lists(['a', 'b', 'c'], ['d', 'e', 'f'])
# should return the following new list (of lists):
[['a', 'd'], ['b', 'e'], ['c', 'f']]`

const zip_lists_empty = `# calling this function:
zip2lists([],[])
# should return the following empty list:
[]`

const encoded_string_run = `# calling this function:
expand_encoded_string('B1o2k2e2p1e1r1!3')
# should return the following string:
'Bookkeeper!!!'`

const sand_start = `[['r', 's', 'r'], ['r', None, 'r']]`

const sand_end = `[['r', None, 'r'], ['r', 's', 'r']]`

const do_move_doctest = `>>> grid = [['r', 's', 'r'], ['r', None, 'r']]
>>> do_move(grid, 1, 0, 1, 1) 
[['r', None, 'r'], ['r', 's', 'r']]`

const bounds_doctest = `>>> grid = [[None, 's', 'r'], [None, None, None]]
>>> check_move(grid, 0, 0, -1, 0) # x2 = -1 is out of bounds
False
>>> check_move(grid, 0, 0, 0, 4)  # y2 = 4 is out of bounds
False
>>> check_move(grid, 0, 0, 3, 1)  # x2 = 3 is out of bounds
False`

const empty_doctest = `>>> grid = [[None, 's', 'r'], [None, None, None]]
>>> # check of left move from (1,0)
>>> check_move(grid, 1, 0, 0, 0)  # left ok
True
>>> # check of right move from (1,0)
>>> check_move(grid, 1, 0, 2, 0)  # right blocked
False
>>> # check of down move from (1,0)
>>> check_move(grid, 1, 0, 1, 1)  # down, ok
True`

const corner_doctest = `>>> grid = [[None, 's', 'r'], [None, None, None]]
>>> # check of down-left move from (1,0)
>>> check_move(grid, 1, 0, 0, 1)  # down-left ok, corner rule
True
>>> # check of down-right move from (1,0)
>>> check_move(grid, 1, 0, 2, 1)  # down-right blocked, corner rule
False`

const is_none = `if x != None:`

const reversed_range = `for i in reversed(range(3)):
    print(i)`

const reversed_output = `2
1
0`

const randrange = `num = random.randrange(100)`

const randcoin = `coin = random.randrange(2)`

const sand_window_size = `python3 sand.py 100 50`

const sand_square_size = `python3 sand.py 100 50 4`