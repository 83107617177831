import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const Contest = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>CS106A Contest</h1>
    <hr />

    <p><b>Submission deadline: 11:59pm (Pacific Time) on Friday, August 9th.</b></p>

    <i>Based on a handout by Eric Roberts</i>

    <br />

    <p>The programs you've written in this class have helped you build a
      foundation of computing skills. Still, those assignments have mostly
      asked you to implement programs that someone else defined. We want to
      give you a chance to use your programming abilities to develop something
      that you think is interesting. So, we are pleased to announce the
      CS 106A Contest. Note that this is a purely <b>optional</b> contest, so
      you should not feel obligated to
      enter if you don't have the time. It's really a chance for you to show
      some more creativity in working with Python and showcase some of your
      programming skills if you so choose.</p>

    <p>Each of you is eligible to submit <b>one</b> entry for the
      contest, where an entry consists of an original program written in
      Python using the facilities you've learned about in CS106A. The output
      of the program might showcase graphics, images, or a console program.
      If you like, your
      program can produce an animation or be interactive - it doesn't have to
      just produce a static  output, though that's certainly fine, too.
      You are free to use any of the capabilities you've learned about in
      this class and are free to lookup additional features of Python if you
      think they might be useful in helping you develop your program. Of
      course, your contest entry must be entirely your own work.</p>

    <h2>Selection criteria</h2>

    <p>The entries will be judged by the CS106A staff (see official rules
      below), and prizes will be awarded based on two categories:</p>

    <ul>
      <li>
        <p><em>Aesthetic merit.</em> This criteria is based on the aesthetic value
          of the graphics/images/output produced by your program.</p>
      </li>
      <li>
        <p><em>Algorithmic sophistication.</em> This criteria is based on the difficulty of
          the underlying programming task and the sophistication of what the
          program does.</p>
      </li>
    </ul>

    <p>Programming style will be part of the evaluation (so
      don't submit horrendous code), but the functionality (and output) of
      your program will be the most important aspect for judging.</p>

    <h2>Prizes</h2>

    <p>The winner(s) of the <b>grand prize</b> will be able to change their lowest score in the course and replace it with a 100%. This could mean that if you win the grand prize, you will not have to take the Final, and you will still receive a 100% on it. We reserve the right to give multiple grand prizes.</p>

    <p>In addition, we will award a number of <b>runner-up prizes</b>. These winners will receive a
      grade boost similar to getting a + on one of their assignments. Note that this extra credit is not
      worth more than an assignment or your exam grade. If you're looking for an extra credit opportunity,
      this is a good chance to show what you know, but please don't prioritize the contest over your other
      required CS106A coursework.
    </p>

    <h2>Official rules</h2>

    <ol>
      <li>
        <p>Only students registered in CS106A are eligible to submit entries
          in the contest.</p>
      </li>
      <li>
        <p>Only <b>one</b> entry per person will be accepted.</p>
      </li>
      <li>
        <p>All entries must be submitted electronically using the Paperless
          submission system by the submission deadline. Late entries will not
          be accepted, and you <b>cannot use late days</b> for the
          contest.</p>
      </li>
      <li><p>You may not resubmit prior extensions for the CS106A contest - you have
        already received any extra credit for that work.</p>
      </li>
      <li>
        <p>Contest entries should be sensitive to Stanford's individual and
          cultural diversity. Programs or narratives that have the effect of
          perpetuating harm or negativity will not be considered for prizes.</p>
      </li>
      <li>
        <p>Contest entries will be evaluated initially by Clinton and Ngoc.
          The best entries will then be judged collectively by the section
          leaders, who will choose the ultimate winners in each category.
          Winners will be annouced in class on Tuesday, August 13th.</p>
      </li>
    </ol>

    <h2>Starter Project</h2>

    <p>To get started with your contest entry, you should download
      the <a href={`${process.env.PUBLIC_URL}/ContestProject.zip`}>Contest Starter Project</a>. You are
      free to modify this starter project however you
      wish, as long as you remain within the rules of the contest.</p>
  </>
}
