import { useWindowSize } from "react-use-size";
import { CS106ANavbar } from "../components/Navbar";
import { DesktopLanding } from "./DesktopLanding";
import { MobileLanding } from "./MobileLanding";

export const Landing = () => {
    const windowSize = useWindowSize();

    // set the tab title
    document.title = "CS106A | Summer 24";

    const mobileView = windowSize.width < 768;
    if(mobileView){
        return <MobileLanding/>
    }
    return <DesktopLanding/>
}
export default Landing