import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'
// @ts-ignore
import Karel from "./img/practiceMidterm/midterm_karel.png"


export const PracticeMidterm = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Practice Midterm Diagnostic</h1>
    <hr />

    <p>This handout is intended to give you practice solving problems that are comparable in difficulty
      to those which will appear on the midterm diagnostic.</p>

    <h3>Resources</h3>

    <p>You may bring in 5 pages of notes, double sided, to reference during the exam. You are welcome to print
      or handwrite these notes, and you may include lecture slides, handouts, or whatever content you'd like in
      these notes (within the honor code). </p>

    <li> <a href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">Karel reference</a></li>
    <li> <a href="https://cs.stanford.edu/people/nick/py/">Nick Parlante's Python guide</a></li>
    <li> <Link to="/graphics"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Graphics reference</Link></li>
    <li> <Link to="/interactive-programs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Interactive programs reference</Link></li>
    <li> <a href="https://web.stanford.edu/class/archive/cs/cs106a/cs106a.1234/handouts_w2021/midterm-21-2-solution.html#:~:text=red%27)%20%5Bbit.erase()%5D-,General%20functions%3A,-len()%20int()%20str">“General functions” section</a> from last quarter's CS106A midterm reference (some functions may
      be different this quarter)</li>
    <br />

    <p>You may not use a laptop or any other devices during the exam, unless
      you have academic accommodations to use such devices.
    </p>

    <h3>Format</h3>

    <p>The exam is on paper, and you will write your answers on the exam using a pencil or pen. Please
      bring a writing implement to use during the exam. You will have 2 hours to complete the exam.</p>

    <h3>Coverage</h3>
    <p>The midterm exam covers the material presented in class through this Wednesday (May 1st), which means that
      you are responsible for the Karel material plus the Python material covered up through and including strings.
      Doctests and images will not be on the exam.</p>

    <h3>General Instructions</h3>

    <p>Make your best attempt to answer each of the questions included in the exam. Make sure to include any
      work that you wish to be considered for partial credit (even if it is not compilable code). Each question is
      marked with the number of points assigned to that problem. The total number of points is 120. We intend
      for the number of points to be roughly comparable to the number of minutes you should spend on that problem.
      You can also write helper functions, if that helps you solve the problem.</p>

    <p>Unless otherwise indicated as part of the instructions for a specific problem, comments will not be required
      on the exam. Uncommented code that gets the job done will be sufficient for full credit on the problem.</p>

    <h2>Practice Exam</h2>
    <h4><Link to="/practice-midterm-solution"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h4>

    <h3>Problem 1: Karel the Robot (20 points)</h3>

    <p>We want to write a Karel program which will create an inside border around the world. Each location
      that is part of the border should have one (and only one) beeper on it and the border should be inset
      by one square from the outer walls of the world like this:</p>

    <img src={Karel} className="img-fluid mx-auto d-block" width="70%"
      alt="initial state: karel is in an empty world, end state: karel has created a border of beepers one corner in from the edge of the world" />

    <p>In solving this problem, you can count on the following facts about the world:</p>

    <ul>
      <li>You may assume that the world is at least 3x3 squares. The correct solution for a 3x3 square world is to
        place a single beeper in the center square.</li>
      <li>Karel starts off facing East at the corner of 1st Street and 1st Avenue with an infinite number beepers
        in its beeper bag.</li>
      <li>We do not care about Karel's final location or orientation.</li>
      <li>You do not need to worry about efficiency.</li>
      <li>You are limited to the instructions in the Karel course reader. Notably, <b>you cannot use variables</b> except
        as the index variable in for loops (such as the variable <code>i</code>) and you cannot refer to that variable otherwise.</li>
    </ul>

    <p>The starter code is below.</p>

    <PythonHighlighter code={karel_starter} />

    <h3>Problem 2: Full Program (25 points)</h3>

    <p>For this problem, write a program that asks the user to enter integers until they enter a 0 as a
      sentinel to indicate the end of the input list. You program should then print out the largest and
      second-largest values from values the user entered. A sample run of the program might look like this
      (user input is the numbers after "Enter value:"):</p>

    <SyntaxHighlighter code={full_program_run} />

    <p>To reduce the number of special cases, you may make the following assumptions:</p>

    <ul>
      <li>The user must enter at least two values before the sentinel.</li>
      <li>All values inputed by the user are positive integers.</li>
      <li>If the largest value appears more than once, that value should be listed as both the largest
        and second-largest value, as shown in the following sample run:</li>
    </ul>
    <SyntaxHighlighter code={full_program_run2} />

    <p>The starter code is provided below.</p>

    <SyntaxHighlighter code={full_program_start} />

    <h3>Problem 3: 2-Dimensional Lists (25 points)</h3>

    <p>When working with 2-dimensional lists in Python, it can often be easier to work with a "true"
      grid where every row in the 2-dimensional list has the same number of elements, as opposed to a
      "jagged" grid where each row may have a different number of elements.</p>

    <p>Your job is to write a function called <code>make_true_grid(grid, row_size, filler)</code> that is passed
      a 2-dimensional list of integers (<code>grid</code>), the minimum row size for each row of the grid after the grid is
      made "true" (<code>row_size</code>), and an integer "filler" value (<code>filler</code>) that will be used
      to pad rows in the original grid to make them all the same size.</p>

    <p>Your function should modify the grid passed in so that every row has the <b>same number</b> of elements.
      If the original grid passed in has any rows larger than <code>row_size</code>, then each row in the grid
      after your function is done should be the size of the longest row (row with the most elements) in the
      original grid passed in (i.e., you should guarantee that all the elements in the original grid show up in
      the final grid). If the length of all the rows in the original grid are less than or equal to <code>row_size</code>,
      then each row in the grid after your function is done should have the length <code>row_size</code>.</p>

    <p>In order to make the length of each row in the grid the same size, you should add the <code>filler</code> value
      to the ends of those rows, as needed, to make them all the same size. There should be no extra rows added to the grid.
    </p>

    <p>Here are some examples of calls to <code>make_true_grid</code>, and what the initial grid and resulting grid
      (after the function is called), should look like:</p>

    <PythonHighlighter code={grid} />

    <p>As a side note (although it will not explicitly impact your code), if an already true grid (all the
      rows are the same length and the the length is greater than or equal to <code>row_size</code>) is passed to your
      function, that grid should not be changed by your function (as shown in the last example above).</p>

    <p>The starter code is below.</p>

    <PythonHighlighter code={grid_starter} />


    <h3>Problem 4: Graphics (25 points)</h3>

    <p>Write a graphics program that animates a filled red "seeker" square on the
      screen.</p>

    <p>The "seeker" continually moves to try
      to reach the mouse position (so that the seeker center x and y coordinates match the x and y of the mouse).
      The program should start with the seeker at 0, 0. The height and width of the seeker are
      both given by the constant <code>SEEKER_SIZE</code>. The height and width of the canvas are both
      given by the constant <code>CANVAS_SIZE</code>.</p>

    <p>In each time step of the animation, the seeker
      should try to move to the same (x, y) position as the mouse by at most 1 pixel in the x
      direction and 1 pixel in the y direction. For example, if the seeker is at the same (center)
      x coordinate as the target, then the seeker would not update its x coordinate in each step
      of the animation.</p>

    <p>Your program should pause <code>PAUSE_TIME</code> seconds between each
      step in the animation of the seeker.</p>

    <p>Note that the program never actually ends. If the seeker's center reaches the same
      location as the mouse, it just remains there until the user moves the mouse, at which
      point the seeker would then again start moving toward
      the mouse.</p>

    <p>The starter code is below.</p>

    <PythonHighlighter code={graphics_starter} />


    <h3>Problem 5: Strings (20 points)</h3>

    <p>In the early part of the 20th century, there was considerable interest in both England and the United States
      in simplifying the rules used for spelling English words, which has always been a difficult proposition.
      One suggestion advanced as part of this movement was the removal of all doubled letters from words.
      If this were done, no one would have to remember that the name of the Stanford student union is spelled
      "Tresidder," even though the incorrect spelling "Tressider" occurs at least as often. If double letters
      were banned, everyone could agree on "Tresider."</p>

    <p>Write a function <code>remove_doubled_letters(str)</code> that takes a string <code>str</code> as its argument and returns a
      new string with all doubled letters in the string replaced by a single letter. Here are a few samples
      calls of the function and what it should return:</p>

    <PythonHighlighter code={string_run} />

    <p>In writing your solution, you should keep in mind the following:</p>

    <ul>
      <li>You do not need to write a complete program. All you need is the implementation of the
        function <code>remove_doubled_letters</code> that returns the desired result.</li>
      <li>You may assume that all letters in the string are lower case so that you don't have to
        worry about changes in capitalization.</li>
      <li>You may assume that no letter appears more than twice in a row. (It is likely that your
        program will work even if this restriction were not included; we've included it explicitly
        only so that you don't even have to think about this case.)</li>
    </ul>

    <p>The starter code is provided below.</p>

    <PythonHighlighter code={string_starter} />
  </>
}

const karel_starter = `from karel.stanfordkarel import *
















if __name__ == "__main__":
  run_karel_program()`

const full_program_run = `This program finds the two largest integers entered by the user. 
Enter values, one per line, using a 0 to signal the end of the input.

Enter value: 7
Enter value: 42
Enter value: 18
Enter value: 9
Enter value: 35
Enter value: 0
The largest value is 42
The second largest is 35`

const full_program_run2 = `This program finds the two largest integers entered by the user. 
Enter values, one per line, using a 0 to signal the end of the input.

Enter value: 1
Enter value: 8
Enter value: 6
Enter value: 8
Enter value: 0
The largest value is 8
The second largest is 8`

const full_program_start = `SENTINEL = 0    # the sentinel used to signal the end of the input
















if __name__ == '__main__':
    main()`

const grid = `grid = [[2, 3], [5], [1, 2, 3, 4, 5]]
# After calling make_true_grid(grid, 3, 0), the grid should be: 
[[2, 3, 0, 0, 0], [5, 0, 0, 0, 0], [1, 2, 3, 4, 5]]

grid = [[10, 20], [], [1]]
# After calling make_true_grid(grid, 4, 9), the grid should be: 
[[10, 20, 9, 9], [9, 9, 9, 9], [1, 9, 9, 9]]

grid = [[1, 2], [3, 4], [5, 6]]
# After calling make_true_grid(grid, 1, 0), the grid should be: 
[[1, 2], [3, 4], [5, 6]]`

const grid_starter = `def make_true_grid(grid, row_size, filler):














`

const graphics_starter = `from graphics import Canvas
import random
import time

SEEKER_SIZE = 10
CANVAS_SIZE = 400
PAUSE_TIME = 1/60



















if __name__ == '__main__':
  main()`

const images_starter = `def create_blurred_image(img):












    `

const string_run = `>>> remove_doubled_letters('tresidder')
'tresider'
>>> remove_doubled_letters('bookkeeper')
'bokeper'`

const string_starter = `def remove_doubled_letters(str):










    `