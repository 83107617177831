import { TextbookButtonBar } from "components/richTextEditor/TipTap/buttonbars/TextbookButtonBar"
import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { ProfileContext, Role } from "contexts/ProfileContext"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useState } from "react"
import { FaBars, FaEdit } from "react-icons/fa"
import { useParams } from "react-router-dom"
import { getChapterLocation } from "./context/TextbookUtil"
import { useProSidebar } from "react-pro-sidebar"
import { isMinimumRole } from "contexts/ProfileUtil"

export const TextbookSplash = ({textbookId, chapterData, textbookPath}) => {
    const { userData } = useContext(ProfileContext)
    const [editing, setEditing] = useState(false)
    const courseId = useCourseId()
  
    const tipTapPath = `${textbookPath}/chapters/splash`
  
    const courseRole = userData?.courseRole

    const title = chapterData?.title || 'Python Reader'

    const editable = isMinimumRole(courseRole, Role.TA)
    return <div style={{ flexGrow:2, marginLeft: 20, marginRight: 20, marginBottom: 30 }} className="mt-3">
      <div className="d-flex justify-content-between">
        <div style={{ fontSize: '1.875rem' }}>{title}</div>
        <div className="d-flex">
          {editable && <button
            style={{ marginTop: '-5px' }}
            className="ml-2 btn btn-lg btn-light"
            onClick={() => setEditing(!editing)}
            aria-label="edit"
          ><FaEdit /></button>}
          <ToggleAside />
        </div>
      </div>
      <hr />
      <div className="textbook" style={tiptapWrapperStyle}><TipTap
        editable={editing}
        firebaseDocPath={tipTapPath}
        collaborative={editable}
        buttonBar={TextbookButtonBar}
        showLoadingSkeleton={true}
        height="100%"
      />
      </div>
    </div>
  }
  
  const ToggleAside = (props) => {
    const { broken, toggleSidebar } = useProSidebar();
    return (
      <div>
        {broken && (
          <button
            className="btn btn-lg btn-light"
            onClick={() => toggleSidebar()}
            aria-label="menu"
          >
            <FaBars />
          </button>
        )}
      </div>
    );
  };
  
  const tiptapWrapperStyle = {
    height: 'calc(100vh - 120px)',
    maxWidth: '780px'
  }