import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const PracticeMidtermSoln = () => {
    // The navbar already exists, this is just the component bellow the nav :-)
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Practice Midterm Solutions</h1>
        <hr />
        <h3>Problem 1: Karel the Robot</h3>
        <PythonHighlighter code={karel} />

        <h3>Problem 2: Full Program</h3>
        <PythonHighlighter code={program} />

        <h3>Problem 3: 2-Dimensional Lists</h3>
        <PythonHighlighter code={lists} />

        <h3>Problem 4: Graphics</h3>
        <PythonHighlighter code={graphics} />

        <h3>Problem 5: Strings</h3>
        <PythonHighlighter code={strings} />
    </>
}

const karel = `from karel.stanfordkarel import *

def next_position():
    # Assumes Karel is facing a wall at the end of line of placed
    # beepers and repositions Karel to be facing in direction of next
    # line in the border of beepers that needs to be placed
    turn_right()
    move()
    turn_right()
    move()
    turn_right()

def handle_border():
    # Assumes Karel starts one avenue before the first beeper to
    # be placed in this line of the border.  Places beepers until
    # Karel reaches a wall, but does not place a beeper on the last
    # corner (where Karel is facing the wall).
    move()
    while front_is_clear():
        # We check for any existing beepers, so we don't put
        # two beepers on any of the "corners" of the border
        if no_beepers_present():
            put_beeper()
        move()

def move_up_row():
    # Moves Karel up one row while keeping the same orientation
    turn_left()
    move()
    turn_right()

def turn_right():
    # Karel turns to the right of the direction it is originally facing
    for i in range(3):
        turn_left()

def main():
    move_up_row()
    for i in range(4):
        handle_border()
        next_position()

if __name__ == "__main__":
    run_karel_program()`


const lists = `def max_cols(grid):
    """
    This function returns maximum number of columns across 
    all rows of grid.
    """
    max_elems = 0  # Keep track of maximum row size 
    for i in range(len(grid)):
        num_elems = len(grid[i])
        if num_elems > max_elems:  # If this row has more elements, 
            max_elems = num_elems  # update the maximum row size found.
    return max_elems

def make_true_grid(grid, row_size, filler):
    """
    This function makes the grid passed in a true grid by padding rows 
    with the filler element to make sure they are all the same size.
    """
    # If the grid has a row with more elements than row_size, update 
    # row_size to be that number of elements, making all rows as large
    # as largest row.  Otherwise, row_size will be unchanged.    
    cols = max_cols(grid)    
    if cols > row_size:        
        row_size = cols    
    # Pad rows with extra filler elements, making all rows same length
    for i in range(len(grid)):        
        cols = len(grid[i])        
        for j in range(row_size - cols):            
            grid[i].append(filler)`

const program = `SENTINEL = 0    # the sentinel used to signal the end of the input

def main():
    print('This program finds the two largest integers entered '
    'by the user.')
    print('Enter values, one per line, using a ' + str(SENTINEL) 
    + 'to signal the end of the input.')

    largest = -1
    second_largest = -1
    while True:
        value = int(input("Enter value: "))
        if value == SENTINEL:
            break
        if value > largest:
            second_largest = largest
            largest = value
        elif value > second_largest:
            second_largest = value

    print('The largest value is ' + str(largest))
    print('The second largest is ' + str(second_largest))


# This provided line is required at the end of a Python file
# to call the main() function.
if __name__ == '__main__':
    main()`


const graphics = `from graphics import Canvas
import random
import time

SEEKER_SIZE = 10
CANVAS_SIZE = 400
PAUSE_TIME = 1/60


def main():
    canvas = Canvas(CANVAS_SIZE, CANVAS_SIZE, 'Seeker')
    seeker = canvas.create_rectangle(0,0,SEEKER_SIZE,SEEKER_SIZE)

    while True:
        # collect all the data we need
        mouse_x = canvas.get_mouse_x()
        mouse_y = canvas.get_mouse_y()
        seeker_x = canvas.get_left_x(seeker) + SEEKER_SIZE/2
        seeker_y = canvas.get_top_y(seeker) + SEEKER_SIZE/2

        # calculate the dx, dy
        dx = 0
        dy = 0
        if seeker_x < mouse_x:
            dx = 1
        elif seeker_x > mouse_x:
            dx = -1

        if seeker_y < mouse_y:
            dy = 1
        elif seeker_y > mouse_y:
            dy = -1

        # update and animate
        canvas.move(seeker, dx, dy)
        canvas.update()
        time.sleep(PAUSE_TIME)


if __name__ == '__main__':
    main()`

const strings = `def remove_doubled_letters(str):
    result = ""
    for i in range(len(str)):
        ch = str[i]
        if (i == 0) or (ch != str[i - 1]):
            result += ch
    return result`
