import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from "react-router-dom"


export const Section5 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 5: File Reading and Dictionaries</h1>
        <h5><Link to="/febie"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>

        <hr />
        <p>This week, we'll be practicing two powerful skills in data processing: reading data in from text files, and
            storing that data in dictionaries. This will be great practice for your next homework ☺. We'll start with a
            review of dictionaries and then use nested dictionaries to represent complex information.</p>
        <p><i>Please refrain from discussing the midterm in section; some students are still taking it. We'll be grading
            over the weekend, so there won't be any news on that until next week.</i></p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/Section5.zip`}>Section 5 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you do with your assignment starter code.</p>

        <h2>Dictionary Review</h2>
        <p>Here's a problem you can work through in the Python interpreter to review some common dictionary operations.
            Let's say we have a dictionary <code>classes</code> where the keys are class names (strings) and the values are
            the unit count for that class (ints).
        </p>

        <PythonHighlighter code={dict_review} />

        <p>Write a single line of code to:
            <ul>
                <li>Access the value for the key <code>'PSYCH1'</code>.</li>
                <li>Update the value for the key <code>'CS106A'</code>, changing it from 5 to 3.</li>
                <li>Add a new key/value pair to the dictionary, <code>'AMSTUD107'</code> with value 4.</li>
            </ul>
        </p>
        <h2>Grocery <s>Lists</s> Dictionaries</h2>
        <p>
            In this problem, you'll create a helpful grocery dictionary from your disorganized grocery list.
        </p>
        <h4>Your Data</h4>
        <p>
            Throughout the week, you make notes of ingredients, where to buy them from, and how many to buy. Unfortunately,
            you lazily jot down each item at the bottom of a long list, rather than checking if it was already in the
            list and keeping a tally. When it's time to go shopping, you've got a grocery list that looks like this:
        </p>

        <SyntaxHighlighter code={list} />

        <p>How do you know how many eggs you need to buy at Safeway? It's pretty tedious to scan through the entire list
            and tally each item in case of any duplicates. We're going to write a program that can help.</p>
        <h4>The <code>groceries</code> Dictionary</h4>
        <p>
            We want to build a dictionary, which we'll call <code>groceries</code> that stores your items so that it's easy
            to see what you need from each store. Here's what the <code>groceries</code> dict would look like for the
            example list above:
        </p>

        <PythonHighlighter code={dict_structure} />

        <p>Now that our data's organized in a new way, it's easier to see that we need 3 cartons of eggs from Safeway.
            Our <code>groceries</code> dictionary has store names as the keys and then inner dictionaries as values. Within each
            store's inner dictionary, the keys are item names, and the value is the number of that item we need to buy this
            week.
        </p>

        <h3>Warmup: Navigating Dictionaries</h3 >
        <p>Let's consider a smaller <code>groceries</code> dictionary, shown below, that we got after reading the first
            three lines of our grocery list and putting them into our dictionary. How would this dictionary look after
            reading in the following lines from our file? <i>Hint: It's a good idea to write this out!</i>

            <PythonHighlighter code={dict_exercise} />
            <ol>
                <li><code>target:sugar cookies,1</code></li>
                <li><code>safeway:flour,1</code></li>
                <li><code>safeway:eggs,2</code></li>
            </ol>
        </p>
        <h3>Adding Items</h3>
        <p>Now that you've got a feel for how to add a single item to <code>groceries</code>, let's implement this process
            in Python. We want to write the function <code>add_item(groceries, store, item, num)</code> which takes
            a <code>groceries</code> dict like we've seen above, and adds a new item to our dict as specified by the
            parameters <code>store, item, num</code>. If you check out the starter project, we've provided some code to
            help you get started.</p>

        <p><i>Hint: Let the different cases from the warmup to guide your code. What do we do if we haven't seen a store
            name before? What if we've seen both the store and item before?</i></p>

        <h3>Making the Grocery Dict</h3>
        <p>
            Now, you'll read your entire grocery list in from a text file and construct a <code>groceries</code> dictionary.
            To implement the function <code>make_groceries(filename)</code>, call your <code>add_item()</code> helper
            function in a file reading loop to build up a <code>groceries</code> dictionary, then
            return <code>groceries</code> at the end of the function after reading all the lines of the file.
        </p>
        <p>For a refresher on what these grocery files look like, check out <code>short_list.txt</code> or <code>long_list.txt</code> in
            the starter project. You can use <code>.find()</code> or <code>.split()</code> to
            separate out the important parts of each line.
        </p>

        <h4>Running your program</h4>
        <p>
            You did it - you've got a program that can turn a messy grocery list into a helpful dictionary! To run your
            program from the terminal, run <code>groceries.py</code> with one argument (a text filename) to read in all the
            data from that file and print out a summary of your groceries. Remember to replace <code>python3</code> with <code>py</code> or <code>python</code> on
            Windows computers.
        </p>

        <SyntaxHighlighter code={list_output} />


        <h2>Smallest Unique Positive Integer</h2>
        <p> In the file <code>smallest_int.py</code>, implement the function <code>find_smallest_int(filename)</code> that takes as a parameter a filename (which is just a string)
            that refers to a file containing a single integer on each line, and returns the smallest unique positive integer in the file.
            An integer is positive if is greater than 0, and unique if it occurs exactly once in the file. For example,
            suppose <code>numbers1.txt</code> looks like this:</p>

        <SyntaxHighlighter code={file_contents} />

        <p>Calling <code>find_smallest_int('numbers1.txt')</code> would return 12. You may assume that each line of the
            file contains exactly one integer (although it may not be positive) and that there is at least one positive
            integer in the file. To test your code, you can run <code>python3 smallest_int.py numbers1.txt</code>,
            replacing <code>python3</code> with <code>py</code> or <code>python</code> on Windows computers. You can switch
            the filename to <code>numbers2.txt</code> to try out another text file, for which your function should return 7.</p>


    </>
}

const file_contents = `42
1
13
12
1
-8
20`

const dict_review = `>>> classes = {'CS106A': 5, 'PSYCH1': 5, 'PWR1': 4}`

const list = `safeway:eggs,1
costco:croissant,12
safeway:coconut milk,3
target:sugar cookies,1
safeway:flour,1
safeway:eggs,2
trader joes:pineapple,4
trader joes:grapes,1
costco:coffee,2
trader joes:kale,1`

const dict_structure = `groceries = {
    'safeway': {'eggs': 3, 'coconut milk': 3, 'flour': 1},
    'costco': {'croissant': 12, 'coffee': 2},
    'target': {'sugar cookies': 1},
    'trader joes': {'pineapple': 4, 'grapes': 1, 'kale': 1}
}`

const dict_exercise = `groceries = {
  'safeway': {'eggs': 1, 'coconut milk': 3}, 
  'costco': {'croissant': 12}
}`

const list_output = `$ python3 groceries.py long_list.txt
You need 3 eggs(s) from safeway
You need 3 coconut milk(s) from safeway
You need 1 flour(s) from safeway
You need 12 croissant(s) from costco
You need 2 coffee(s) from costco
You need 1 sugar cookies(s) from target
You need 4 pineapple(s) from trader joes
You need 1 grapes(s) from trader joes
You need 1 kale(s) from trader joes`