import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
// @ts-ignore
import Pythag1 from "./img/assn2/pythag1.png"
// @ts-ignore
import Pythag2 from "./img/assn2/pythag2.png"
import { Accordion } from "react-bootstrap";



export const Assn2 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Assn 2: Khansole Academy & Computing Interest</h1>
        <hr />

        <p><b>Due: 11:59pm (Pacific Time) on Friday, July 12th</b></p>

        <i>Based on problems by Jerry Cain, Eric Roberts, Nick Bowman, Sonja Johnson-Yu, Kylie Jue, Chris Piech and current CS106A staff.</i>

        <br />
        <br />




        <p>This assignment consists of eight problems in total: six short problems to give you practice with several different concepts
            in Python (e.g., variables, control structures, etc.) as well as two longer programs. Here's
            the <a href={`${process.env.PUBLIC_URL}/Assignment2.zip`}>Assignment 2 starter code</a>. The
            starter code provides Python files for you to write your programs in.</p>

        <p><a href="#trace">Problem 1</a> | <a href="#subtract">Problem 2</a> | <a href="#liftoff">Problem 3</a> | <a href="#random">Problem 4</a> | <a href="#moon">Problem 5</a> | <a href="#pythag">Problem 6</a> | <a href="#khansole">Problem 7</a> | <a href="#compute-interest">Problem 8</a> </p>

        <p>One of our longer problems is called Khansole (like the Python console in PyCharm) Academy, and we'll
            use our coding skills to make a program that helps users review math problems.
            In the other longer problem, we'll help users compute the interest in a bank account..</p>



        <p>"Sandcastle" problems are shorter problems that help solidify important
            concepts (like control flow, variables, and functions) before moving on to writing larger programs.
            They're kind of like building sandcastles in a sandbox - they're meant to be fun to do and no one
            gets hurt. Please make sure you do these six problems before moving on to other parts of the assignment.</p>

        <h3 id="trace">1. Trace</h3>
        <p>For this problem, you will be tracing through some code. <i>Please try doing this by hand</i>. What would get printed
            to the console when the following function call is made? Put your answer in the file <code>trace.txt</code> provided
            in the <code>Assignment2</code> folder.
            <PythonHighlighter code={trace} />
        </p>


        <h3 id="subtract">2. Subtract Numbers</h3>
        <p>Write a program in the file <code>subtract_numbers.py</code> that reads two real numbers from the user and prints
            the first number minus the second number. You can assume the user will always enter valid real numbers
            as input (negative values are fine). Yes, we know this problem is really similar to a problem we did
            in class - that's why this problem is a sandcastle! A sample run of the program is shown below
            (user input is 5.5 and 2.1):
            <SyntaxHighlighter code={subtract} />
        </p>

        <h3 id="liftoff">3. Liftoff!</h3>
        <p>Write a program in the file <code>liftoff.py</code> that prints out the calls for a spaceship
            that is about to launch. Countdown the numbers from 10 to 1 and then write "Liftoff!"
            Your program should include a loop. You may use any loop. A sample run of the program is below.
            <SyntaxHighlighter code={liftoff} />
        </p>

        <h3 id="random">4. Random Numbers</h3>
        <p>Write a program in the file <code>random_numbers.py</code> that prints 10 random integers (each random
            integer should have a value between 0 and 100, inclusive). Your program should use a
            constant named <code>NUM_RANDOM</code> (with a value of 10), which determines the number of random
            numbers to print. Your program should also use constants named <code>MIN_RANDOM</code> and <code>MAX_RANDOM</code> to
            determine the minimal and maximal values of the random numbers generated (with respective
            values 0 and 100). To generate random numbers, you should use the function <code>random.randint()</code> from
            Python's <code>random</code> library, which we discussed in class . A sample run of the program is
            shown below.
            <SyntaxHighlighter code={random} />
        </p>


        <h3 id="moon">5. Moon Weight</h3>
        <p>Humans first landed on the moon on July 20, 1969. (No, the moon landing was not faked.)
            People have often wondered how much they would weigh if they were on the moon. It turns out
            that on the moon, you would weigh 16.5% of your weight on Earth. Write a program <code>moon_weight.py</code> that
            asks the user for their weight (you can assume a real-valued input is given by the user) and prints out
            their weight on the moon. If the user enters a negative value (e.g. they're being mean and
            trying to break your program), you should just print out that weights can't be negative.
            Remember when writing your solution, that it's good programming style to use constants where
            appropriate. Two sample runs of the program are shown below.</p>

        <p>Sample run 1 (user's input is 165.3, and note the imprecision of float values in the answer):</p>
        <SyntaxHighlighter code={moon1} />

        <p>Sample run 2 (user's input is -13):</p>
        <SyntaxHighlighter code={moon2} />


        <h3 id="pythag">6. Pythagorean</h3>
        <p>In geometry, you learned the Pythagorean theorem for the relationship among the lengths of
            the three sides of a right triangle:</p>

        <img src={Pythag1} className="img-fluid mx-auto d-block" width="30%"
            alt="Pythagorean theorem: a^2 + b^2 = c^2" />

        <p>which can alternatively be written as:</p>
        <img src={Pythag2} className="img-fluid mx-auto d-block" width="30%"
            alt="Pythagorean theorem: c = sqrt(a^2 + b^2)" />

        <p>Write a program in the file <code>pythagorean.py</code> that asks the user for two values
            for <code>a</code> and <code>b</code> as floats
            (you can assume that <code>a</code> and <code>b</code> will be positive real values) and then
            calculates the solution of <code>c</code> and prints it. Recall that to compute square roots, you
            can use the function <code>math.sqrt()</code> from Python's <code>math</code> library, which we discussed in class.
            A sample run of the program is shown below (user inputs 9.7 and 3.2):</p>
        <SyntaxHighlighter code={pythag} />


        <h3 id="khansole">7. Khansole Academy</h3>

        <p>Now that you've seen how programming can help us in a number of different areas, it's
            time for you to implement Khansole Academy—a program that helps other people learn!
            In this problem, you'll write a program in the file <code>khansole_academy.py</code> that
            randomly generates addition problems for the user, reads in the answer from the user,
            and then checks to see if they got it right or wrong, until the user appears to have
            mastered the material.</p>

        <p>More specifically, your program should be able to generate addition problems
            that involve adding two 2-digit integers (10 through 99, inclusive). The
            user should be asked for an answer to each problem. Your program should determine if the
            answer was correct or not, and give the user an appropriate message to let them know.
            Your program should keep giving the user problems until the user has gotten 3 problems
            correct <i>in a row</i>. (Note: the number of problems the user needs to get correctly in a
            row to complete the program is just one example of a good place to specify a constant
            in your program).</p>

        <p>A sample run of the program is shown below (user input is after <code>Your answer:</code>).</p>

        <SyntaxHighlighter code={khansole} />

        <p>As a side note, one of the earliest programs Mehran wrote (with his friend Matthew) when he
            was first learning how to program was very similar to Khansole Academy. It was called
            "M&M's Math Puzzles." It was written in a language named BASIC on a computer that had 4K
            of memory (that's 4 Kilobytes) and used cassette tapes (the same kind used for music in
            the 1970's) to store information. Yeah, Mehran is old.</p>

        <h3 id="compute-interest">8. Computing Interest</h3>
        <p>Write a program <code>compute_interest.py</code> that helps a user compute how much interest
                        their bank account will accrue over time. The program starts by asking the user for an initial
                        account balance, which is entered as a float (you can assume a positive real-value is entered).
                        The program then asks the user for a starting year and month as well as an ending year and
                        month, all entered as integers. The program then asks the user for a monthly interest rate.
                        For example, a 2% interest rate would be entered by the user as the value 0.02.</p>

                    <p>The program should print out the monthly balance in the account from the starting year/month
                        up to and including the ending year/month with interest accruing <i>monthly</i>. The amount of
                        interest earned each month is simply the amount in the account in that month multiplied
                        by the interest rate entered by the user. So (using a 2% interest rate) an account with
                        $1000.00 at the start of the month would earn $20.00 that month and have a total of
                        $1020.00 at the start of the next month.</p>

                    <p>After printing the monthly balance projection, your program should then repeat the process
                        of asking the user for a new interest rate and printing the monthly balance in the account <i>using
                            the same starting account balance, starting year/month, and ending year/month as the
                            user initially entered in the program</i>. In other words, don't re-prompt the user for anything but a
                        new interest rate. The program should end if the user specifies a monthly
                        interest rate of 0%.</p>

                    <p>You can assume that the user will always enter a valid (i.e., positive) value for the
                        start/end year and an integer from 1 to 12 for the start/end month. If the starting
                        year/month is the same as or after the ending year/month, your program should not
                        ask for an interest rate, but rather simply print "Starting date needs to be before
                        the ending date." and then end without an error.</p>

                    <p>The values for the initial balance, the starting year/month, and the ending year/month
                        are entered by the user. The monthly balance is simply printed as a float, so you don't
                        have to worry about rounding to the nearest cent.</p>

                    <p>Remember that <i>it's a good idea to decompose your program</i> and use parameters with functions
                        as needed to pass information around your program. That's really part of good programming style.</p>

                    <p>Two sample runs of the program are shown below:</p>

                    <p>Sample run 1 (showing what happens if the user enters a starts year/month that is after the
                        ending year/month. Note the program simply ends without asking for an interest rate.):</p>
                    <SyntaxHighlighter code={interest1} />

                    <p>Sample run 2:</p>
                    <SyntaxHighlighter code={interest2} />

        



        <h3>Submitting your code</h3>
        <p>All assignments are submitted electronically through the <a href="https://cs198.stanford.edu/paperless/">Paperless
            website</a>. Since this timestamp is based on the time that the server that receives the assignment, we recommend
            submitting with at least a few minutes to spare, particularly if you discover that your computer's clock is
            running slow.</p>

        <p>Click on the link to Paperless above. Select the assignment you wish to submit (in this case, Assignment 2:
            Khansole Academy & Computing Interest).
            You should upload all of the appropriate files for the chosen assignment. The end of each assignment handout
            you will find a list of all of the files that we expect you to submit - please do not rename the files
            when you download them. Here are the files to submit for this assignment:</p>

        <ul>
            <li><code>trace.txt</code></li>
            <li><code>subtract_numbers.py</code></li>
            <li><code>liftoff.py</code></li>
            <li><code>random_numbers.spy</code></li>
            <li><code>moon_weight.py</code></li>
            <li><code>pythagorean.py</code></li>
            <li><code>khansole_academy.py</code></li>
            <li><code>compute_interest.py</code></li>
        </ul>

        <p>If you did an optional extension on the assignment, you should submit either or both of:</p>

        <ul>
            <li><code>extension.py</code></li>
            <li><code>hailstones.py</code></li>
        </ul>

        <p><i>Remember, if you are adding functionality to a problem like Khansole Academy for extra credit,
            please do so in a file other than the regular file for that problem.</i> This ensures that you get full
            credit for the main parts
            of the assignment in the case that your extended program introduces any bugs. If you have image files,
            audio clips, or extra Python files that are part of your extra credit submission, please also make sure
            to submit those.</p>

        <p>Once they have been uploaded and you have verified that you've included all of the files
            outlined in the assignment handout, hit the "Submit Assignment" button. You should always check the contents
            of your submission to make sure the code files are up-to-date and correct.
            To inspect the submission, click on the specific assignment and use the dropdown to view each file's contents.</p>

        <p>Hooray, you've finished assignment 2! You can submit each assignment as many times
            as you would like by following the instructions outlined in this document. However, your section leader will
            grade only the most recent submission.</p>

        <Extensions />
    </>

}

const Extensions = () => {
    return <>
        <h3>Extensions</h3>
        <Accordion defaultActiveKey="-1" alwaysOpen={false}>
            <Accordion.Item eventKey="0">
                <Accordion.Header style={{ marginTop: 0 }}>Hailstone</Accordion.Header>
                <Accordion.Body>
                    <p>Implement this in <code>hailstones.py</code>. A separate (optional) problem you could consider writing is based on a
                        problem in Douglas Hofstadter's Pulitzer-prize-winning book Gödel, Escher, Bach. That book
                        contains many interesting mathematical puzzles, many of which can be expressed in the form of
                        computer programs. In Chapter XII, Hofstadter mentions a wonderful problem that is well within
                        the scope of what you know. The problem can be expressed as follows:</p>

                    <ul>
                        <li>Pick some positive integer and call it n.</li>
                        <li>If n is even, divide it by two.</li>
                        <li>If n is odd, multiply it by three and add one.</li>
                        <li>Continue this process until n is equal to one.</li>

                    </ul>

                    <p>On page 401 of the Vintage edition of his book, Hofstadter illustrates this
                        process with the following example, starting with the number 15:</p>

                    <SyntaxHighlighter code={hailstone1} />

                    <p>As you can see from this example, the numbers go up and down, but eventually—at least
                        for all numbers that have ever been tried—comes down to 1. In some respects,
                        this process is reminiscent of the formation of hailstones, which get carried upward
                        by the winds over and over again before they finally descend to the ground. Because of this
                        analogy, this sequence of numbers is usually called the Hailstone sequence,
                        although it goes by many other names as well.</p>

                    <p>You might want to write a Python program that reads in a number from the user and then
                        displays the Hailstone sequence for that number, just as in Hofstadter's book,
                        followed by a line showing the number of steps taken to reach 1. For example, here's a
                        sample run of what such a program might look like (user input is 17):</p>

                    <SyntaxHighlighter code={hailstone2} />
                </Accordion.Body>
            </Accordion.Item>
        
            <Accordion.Item eventKey="1">
                <Accordion.Header style={{ marginTop: 0 }}>Extend Khansole Academy</Accordion.Header>
                <Accordion.Body>
                    <p>You could consider extending your Khansole Academy program to,
                        for example, add more problem types (subtraction, multiplication, division, and more).
                        You could also consider problems beyond arithmetic. If you could build your own version
                        of Khansole Academy, what would you use it to help people learn? Be creative and enjoy.</p>

                        <p>Make sure to save your solution in <code>khansole_extension.py</code> so that we can grade your base khansole academy solution separately.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header style={{ marginTop: 0 }}>Extension of your dreams</Accordion.Header>
                <Accordion.Body>
                    <p>Create something wonderful of your own imagination! Put it in <code>extension.py</code></p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>


    //     <p>We want to write a program <code>compute_interest.py</code> that helps a user compute how much interest
    //     their bank account will accrue over time. The program starts by asking the user for an initial
    //     account balance, which is entered as a float (you can assume a positive real-value is entered).
    //     The program then asks the user for a starting year and month as well as an ending year and
    //     month, all entered as integers. The program then asks the user for a monthly interest rate.
    //     For example, a 2% interest rate would be entered by the user as the value 0.02.</p>

    // <p>The program should print out the monthly balance in the account from the starting year/month
    //     up to and including the ending year/month with interest accruing <i>monthly</i>. The amount of
    //     interest earned each month is simply the amount in the account in that month multiplied
    //     by the interest rate entered by the user. So (using a 2% interest rate) an account with
    //     $1000.00 at the start of the month would earn $20.00 that month and have a total of
    //     $1020.00 at the start of the next month.</p>

    // <p>After printing the monthly balance projection, your program should then repeat the process
    //     of asking the user for a new interest rate and printing the monthly balance in the account <i>using
    //         the same starting account balance, starting year/month, and ending year/month as the
    //         user initially entered in the program</i>. In other words, don't re-prompt the user for anything but a
    //     new interest rate. The program should end if the user specifies a monthly
    //     interest rate of 0%.</p>

    // <p>You can assume that the user will always enter a valid (i.e., positive) value for the
    //     start/end year and an integer from 1 to 12 for the start/end month. If the starting
    //     year/month is the same as or after the ending year/month, your program should not
    //     ask for an interest rate, but rather simply print "Starting date needs to be before
    //     the ending date." and then end without an error.</p>

    // <p>The values for the initial balance, the starting year/month, and the ending year/month
    //     are entered by the user. The monthly balance is simply printed as a float, so you don't
    //     have to worry about rounding to the nearest cent.</p>

    // <p>Remember that <i>it's a good idea to decompose your program</i> and use parameters with functions
    //     as needed to pass information around your program. That's really part of good programming style.</p>

    // <p>Two sample runs of the program are shown below:</p>

    // <p>Sample run 1 (showing what happens if the user enters a starts year/month that is after the
    //     ending year/month. Note the program simply ends without asking for an interest rate.):</p>
    // <SyntaxHighlighter code={interest1} />

    // <p>Sample run 2:</p>
    // <SyntaxHighlighter code={interest2} />

    // <h3>Possible Extensions</h3>

    // <p>Once you've completed all the required parts of the assignment, you might want to
    //     consider adding some extensions.</p>

    // <h5>Extend Khansole Academy</h5>

    // <p>Implement this in <code>extension.py</code>. You could consider extending your Khansole Academy program to,
    //     for example, add more problem types (subtraction, multiplication, division, and more).
    //     You could also consider problems beyond arithmetic. If you could build your own version
    //     of Khansole Academy, what would you use it to help people learn? Be creative and enjoy.</p>

    // <h5>Hailstones</h5> <p>Implement this in <code>hailstones.py</code>. A separate (optional) problem you could consider writing is based on a
    //     problem in Douglas Hofstadter's Pulitzer-prize-winning book Gödel, Escher, Bach. That book
    //     contains many interesting mathematical puzzles, many of which can be expressed in the form of
    //     computer programs. In Chapter XII, Hofstadter mentions a wonderful problem that is well within
    //     the scope of what you know. The problem can be expressed as follows:</p>

    // <ul>
    //     <li>Pick some positive integer and call it n.</li>
    //     <li>If n is even, divide it by two.</li>
    //     <li>If n is odd, multiply it by three and add one.</li>
    //     <li>Continue this process until n is equal to one.</li>

    // </ul>

    // <p>On page 401 of the Vintage edition of his book, Hofstadter illustrates this
    //     process with the following example, starting with the number 15:</p>

    // <SyntaxHighlighter code={hailstone1} />

    // <p>As you can see from this example, the numbers go up and down, but eventually—at least
    //     for all numbers that have ever been tried—comes down to 1. In some respects,
    //     this process is reminiscent of the formation of hailstones, which get carried upward
    //     by the winds over and over again before they finally descend to the ground. Because of this
    //     analogy, this sequence of numbers is usually called the Hailstone sequence,
    //     although it goes by many other names as well.</p>

    // <p>You might want to write a Python program that reads in a number from the user and then
    //     displays the Hailstone sequence for that number, just as in Hofstadter's book,
    //     followed by a line showing the number of steps taken to reach 1. For example, here's a
    //     sample run of what such a program might look like (user input is 17):</p>

    // <SyntaxHighlighter code={hailstone2} />
}

const trace = `"""
This program is just meant to test your understanding
of variables, control flow, and functions.
"""

def mystery():
    x = 3
    x = 5 - x / 2
    print(x)


def main():
    x = 1
    while x < 20:
        print("x = " + str(x))
        mystery()
        x *= 2
    print("x = " + str(x))


if __name__ == "__main__":
    main()`

const subtract = `This program subtracts one number from another.
Enter first number: 5.5
Enter second number: 2.1
The result is 3.4.`

const liftoff = `10
9
8
7
6
5
4
3
2
1
Liftoff!`

const random = `35
10
45
59
45
100
8
31
48
6`

const moon1 = `Enter your weight: 165.3
Your weight on the moon is 27.274500000000003`

const moon2 = `Enter your weight: -13
Sorry, you can't have a negative weight.`

const pythag = `a: 9.7
b: 3.2
c = 10.21420579389313`

const khansole = `What is 51 + 79?
Your answer: 120
Incorrect. The expected answer is 130
What is 33 + 19?
Your answer: 42
Incorrect. The expected answer is 52
What is 55 + 11?
Your answer: 66
Correct! You've gotten 1 correct in a row.
What is 84 + 25?
Your answer: 109
Correct! You've gotten 2 correct in a row.
What is 26 + 58?
Your answer: 74
Incorrect. The expected answer is 84
What is 98 + 85?
Your answer: 183
Correct! You've gotten 1 correct in a row.
What is 79 + 66?
Your answer: 145
Correct! You've gotten 2 correct in a row.
What is 97 + 20?
Your answer: 117
Correct! You've gotten 3 correct in a row.
Congratulations! You mastered addition.`

const interest1 = `Initial balance: 500.00
Start year: 2020
Start month: 9
End year: 2019
End month: 10
Starting date needs to be before the ending date.`

const interest2 = `Initial balance: 1000.00
Start year: 2022
Start month: 5
End year: 2024
End month: 3
Interest rate (0 to quit): 0.02
Year 2022, month 5 balance: 1000.0
Year 2022, month 6 balance: 1020.0
Year 2022, month 7 balance: 1040.4
Year 2022, month 8 balance: 1061.208
Year 2022, month 9 balance: 1082.43216
Year 2022, month 10 balance: 1104.0808032
Year 2022, month 11 balance: 1126.162419264
Year 2022, month 12 balance: 1148.6856676492798
Year 2023, month 1 balance: 1171.6593810022655
Year 2023, month 2 balance: 1195.0925686223109
Year 2023, month 3 balance: 1218.994419994757
Year 2023, month 4 balance: 1243.3743083946522
Year 2023, month 5 balance: 1268.2417945625452
Year 2023, month 6 balance: 1293.6066304537962
Year 2023, month 7 balance: 1319.478763062872
Year 2023, month 8 balance: 1345.8683383241296
Year 2023, month 9 balance: 1372.7857050906123
Year 2023, month 10 balance: 1400.2414191924245
Year 2023, month 11 balance: 1428.246247576273
Year 2023, month 12 balance: 1456.8111725277984
Year 2024, month 1 balance: 1485.9473959783543
Year 2024, month 2 balance: 1515.6663438979213
Year 2024, month 3 balance: 1545.9796707758796
Interest rate (0 to quit): 0.05
Year 2022, month 5 balance: 1000.0
Year 2022, month 6 balance: 1050.0
Year 2022, month 7 balance: 1102.5
Year 2022, month 8 balance: 1157.625
Year 2022, month 9 balance: 1215.50625
Year 2022, month 10 balance: 1276.2815624999998
Year 2022, month 11 balance: 1340.0956406249998
Year 2022, month 12 balance: 1407.1004226562497
Year 2023, month 1 balance: 1477.4554437890622
Year 2023, month 2 balance: 1551.3282159785153
Year 2023, month 3 balance: 1628.894626777441
Year 2023, month 4 balance: 1710.3393581163132
Year 2023, month 5 balance: 1795.8563260221288
Year 2023, month 6 balance: 1885.6491423232353
Year 2023, month 7 balance: 1979.9315994393971
Year 2023, month 8 balance: 2078.928179411367
Year 2023, month 9 balance: 2182.8745883819356
Year 2023, month 10 balance: 2292.0183178010325
Year 2023, month 11 balance: 2406.619233691084
Year 2023, month 12 balance: 2526.950195375638
Year 2024, month 1 balance: 2653.29770514442
Year 2024, month 2 balance: 2785.962590401641
Year 2024, month 3 balance: 2925.260719921723
Interest rate (0 to quit): 0`

const hailstone1 = `15 is odd, so I make 3n+1: 46
46 is even, so I take half: 23
23 is odd, so I make 3n+1: 70
70 is even, so I take half: 35
35 is odd, so I make 3n+1: 106
106 is even, so I take half: 53
53 is odd, so I make 3n+1: 160
160 is even, so I take half: 80
80 is even, so I take half: 40
40 is even, so I take half: 20
20 is even, so I take half: 10
10 is even, so I take half: 5
5 is odd, so I make 3n+1: 16
16 is even, so I take half: 8
8 is even, so I take half: 4
4 is even, so I take half: 2
2 is even, so I take half: 1`

const hailstone2 = `Enter a number: 17
17 is odd, so I make 3n + 1: 52
52 is even, so I take half: 26
26 is even, so I take half: 13
13 is odd, so I make 3n + 1: 40
40 is even, so I take half: 20
20 is even, so I take half: 10
10 is even, so I take half: 5
5 is odd, so I make 3n + 1: 16
16 is even, so I take half: 8
8 is even, so I take half: 4
4 is even, so I take half: 2
2 is even, so I take half: 1
The process took 12 steps to reach 1`