import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan8 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 8</h1>
        <hr />

        <p>This week in section, we're doing final review. There's no section handout; instead, we'll be drawing from
            the <a href="https://cs106a.stanford.edu/final-prep">final prep handout</a>. You could more or less follow this
            plan, or let students' questions drive section. I didn't provide any timing for the problems because it's more
            of an open-ended review rather than a typical section agenda. Feel free to spend the last few minutes saying
            goodbye... it's been an awesome quarter!
        </p>

        <h3>Section Problem Flow</h3>

        <p>These are just some of my favorite problems on the handout.</p>

        <ol>
            <li><b>Magic Wand.</b> A fun and tricky graphics problem involving tracking a list of objects.</li>
            <li><b>Fireworks.</b> List of lists problem that involves exploding fireworks 'f' into sparkles 's'.</li>
            <li><b>Longest Consonants.</b> Find the longest consecutive string of consonants within a given string.</li>
            <li><b>Finding Grandchildren.</b> Use a nested dictionary of parent-child relationships to build a dictionary of
                grandparent-grandchild relationships.</li>
        </ol >

        <h3>Section tips</h3>

        <h4>Final Logistics</h4>
        <p> Mehran and Chris will make a more detailed announcement in class this week about what content will be covered
            on the final exam. For now, we know the exam is 3 hours, in a similar format to the midterm, and will be
            cumulative. Check the course page on Wednesday for an announcement with additional logistics.
        </p>

        <h4>Study Materials</h4>
        <p>Students have the following resources available to them as they study (details on all of these can be found
            on the course website by Wednesday):
        </p>
        <ul>
            <li><a href="https://cs106a.stanford.edu/final-prep">Final prep handout</a> - a respository
                of old section problems and some new ones I've written. DM me if you have a
                problem you'd want to add!
            </li>
            <li><b>Practice final</b> - published on the course page by the end of this week.
            </li>
            <li><a href="https://web.stanford.edu/class/archive/cs/cs106a/cs106a.1226/handouts/final-solutions.html">Fall 2022 final exam</a>.</li>
            <li><b>Final exam cheat sheet</b> - coming soon, thanks Kieran!</li>
            <li><b>In-class final review</b> - happening during the last class, June 7th.</li>
        </ul>

        <h4>Saying Goodbye</h4>

        <p>From your end of quarter feedback and the anecdotes I've heard, I can tell
            you've had a big impact on your sectionees this quarter. Thank you all for your amazing work,
            I appreciate you a lot!
        </p>

        <p>Before saying goodbye to your sectionees, you might offer any contact info, advice, or stories you're
            comfortable with sharing. You're an awesome resource for your sectionees should they be interested in pursuing
            CS further - let them know if and how you're available to connect in the future. This could also be shared
            in an email after section.</p>
    </>
}
