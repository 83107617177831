import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'
import Karel from "./img/practiceMidterm/midterm_karel.png"
import Karel1 from "./img/practiceMidterm/karel1.jpg"
import Karel2 from "./img/practiceMidterm/karel2.jpg"


export const MidtermPrep= () => {
  // The navbar already exists, this is just the component below the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Midterm Prep Handout</h1>
    <hr />

    <p>This handout is intended to give you practice solving problems that are comparable in difficulty
      to those which will appear on the midterm diagnostic. It is <b>not</b> a real practice exam</p>

    <h3>Resources</h3>

    <p>You may bring in 1 page of notes, double sided, to reference during the exam. You are welcome to print
      or handwrite these notes, and you may include lecture slides, handouts, or whatever content you'd like in
      these notes (within the honor code). </p>

    <li> <a href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">Karel reference</a></li>
    <li> <a href="https://cs.stanford.edu/people/nick/py/">Nick Parlante's Python guide</a></li>
    <li> <Link to="/images"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Images reference</Link></li>
    <li> <Link to="/interactive-programs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Interactive programs reference</Link></li>
    <li> <a href="https://web.stanford.edu/class/archive/cs/cs106a/cs106a.1234/handouts_w2021/midterm-21-2-solution.html#:~:text=red%27)%20%5Bbit.erase()%5D-,General%20functions%3A,-len()%20int()%20str">“General functions” section</a> from last quarter's CS106A midterm reference (some functions may
      be different this quarter)</li>
    <br />

    <p>You may not use a laptop or any other devices during the exam, unless
      you have academic accommodations to use such devices.
    </p>

    <h3>Format</h3>

    <p>The exam is on paper, and you will write your answers on the exam using a pencil or pen. Please
      bring a writing implement to use during the exam. You will have 2 hours to complete the exam.</p>

    <h3>Coverage</h3>
    <p>The midterm exam covers the material presented in class through this Friday (July 19th), but not nested dictionaries, which means that
      you are responsible for the Karel material plus the Python material covered up through and including strings, lists, grids, simple dictionaries, files, and images.
      Doctests and images will not be on the exam.</p>

    <h3>General Instructions</h3>

    <p>Make your best attempt to answer each of the questions included in the exam. Make sure to include any
      work that you wish to be considered for partial credit (even if it is not compilable code). Each question is
      marked with the number of points assigned to that problem. You can also write helper functions, if that helps you solve the problem.</p>

    <p>Unless otherwise indicated as part of the instructions for a specific problem, comments will not be required
      on the exam. Uncommented code that gets the job done will be sufficient for full credit on the problem.</p>

    <h2>Problems</h2>
    <h4><Link to="/practice-midterm-solution"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h4>

    <h3>Karel: 1</h3>

    <p>We want to write a Karel program which will create an inside border around the world. Each location
      that is part of the border should have one (and only one) beeper on it and the border should be inset
      by one square from the outer walls of the world like this:</p>

    <img src={Karel} className="img-fluid mx-auto d-block" width="70%"
      alt="initial state: karel is in an empty world, end state: karel has created a border of beepers one corner in from the edge of the world" />

    <p>In solving this problem, you can count on the following facts about the world:</p>

    <ul>
      <li>You may assume that the world is at least 3x3 squares. The correct solution for a 3x3 square world is to
        place a single beeper in the center square.</li>
      <li>Karel starts off facing East at the corner of 1st Street and 1st Avenue with an infinite number beepers
        in its beeper bag.</li>
      <li>We do not care about Karel's final location or orientation.</li>
      <li>You do not need to worry about efficiency.</li>
      <li>You are limited to the instructions in the Karel course reader. Notably, <b>you cannot use variables</b> except
        as the index variable in for loops (such as the variable <code>i</code>) and you cannot refer to that variable otherwise.</li>
    </ul>

    <p>The starter code is below.</p>

    <PythonHighlighter code={karel_starter} />

    <h3>Karel: 2</h3>
    <p>Write a program that directs Karel to construct a fence. Here is an example fence for a world with 11 columns:</p>
    <img src={Karel1} className="img-fluid mx-auto d-block" width="70%" />
    
    <p>Here is an example fence for a world with 7 columns:</p>
    <img src={Karel2} className="img-fluid mx-auto d-block" width="70%" />

    <p>The structure of fences is as follows:</p>
    <ul>
      <li>Fences are made out of fence-posts that are connected with a cross-beeper</li>
      <li>The fence-posts are always four beepers placed in a vertical line</li>
      <li>Fence-posts are placed on every other column</li>
      <li>In between each fence-post is a single cross-beeper that is three corners from the bottom</li>
    </ul>

    <p>You may assume that:</p>
    <ul>
      <li>Karel starts facing East at corner (1, 1) in a world that is initially empty of beepers and walls</li>
      <li>Karel can end in any location</li>
      <li>The world is always 6 rows high</li>
      <li>The world is always an odd number of columns wide</li>
    </ul>

    <p>Note that the functions turn_right(),turn_around(), and move_to_wall() and are already defined for you, if you want to use them.</p>
    <SyntaxHighlighter code={karel_helper} />
    <p>The starter code is below.</p>

    <SyntaxHighlighter code={karel_starter} />

    <h3>Full Program (25 points)</h3>

    <p>For this problem, write a program that asks the user to enter integers until they enter a 0 as a
      sentinel to indicate the end of the input list. You program should then print out the largest and
      second-largest values from values the user entered. A sample run of the program might look like this
      (user input is the numbers after "Enter value:"):</p>

    <SyntaxHighlighter code={full_program_run} />

    <p>To reduce the number of special cases, you may make the following assumptions:</p>

    <ul>
      <li>The user must enter at least two values before the sentinel.</li>
      <li>All values inputed by the user are positive integers.</li>
      <li>If the largest value appears more than once, that value should be listed as both the largest
        and second-largest value, as shown in the following sample run:</li>
    </ul>
    <SyntaxHighlighter code={full_program_run2} />

    <p>The starter code is provided below.</p>

    <SyntaxHighlighter code={full_program_start} />

    <h3>Full Program: 2</h3>
    <p>Write a console program that simulates a training session between a player and Mew, a Pokémon character. Both the user and Mew will have a value for stamina. Set the initial stamina values for the user and Mew using the constants YOUR_START_STAMINA and MEW_START_STAMINA, respectively.</p>
    <p> In each iteration of the game loop, first print out the remaining stamina. Then the user will go first and then Mew will take a turn.   </p>
    <ul>
      <li>User's Turn: Prompt the user "Do you want to (S)pell or (H)eal?" </li>
      <li>If the user types “S”, they cast a spell. Choose a random value (integer) for the spell power within the range given by the constants YOUR_MIN and YOUR_MAX, and subtract this from Mew's stamina. Your program should print: You cast a spell with power [power value].</li>
      <li>If the user types “H”, add the constant HEAL_AMOUNT to the user's stamina. Your program should print: You healed yourself for [HEAL_AMOUNT] health. </li>
      <li>You can assume the user always enters valid input (“S” or “H”).</li>
      <li>You can assume the user always enters valid input (“S” or “H”).</li>
      <li>Mew’s Turn: If Mew still has stamina more than 0 after the user's turn, it should cast a spell with power value (integer) randomly selected between MEW_MIN and MEW_MAX, and subtract this from the user's stamina. Your program should print: Mew casts a spell with power [ower value]. </li>
    </ul>
  
 <p>The loop ends when either the user or Mew’s stamina drops to zero or below. Your program should print "Victory!" if Mew’s stamina reaches zero first (even if the user’s stamina has also reached zero in the same round).  Otherwise, your program should print "Defeat!" if the user's stamina reaches zero first. Write your solution on the next page. </p>
  
 Example Run (user input is in italics): 
 Your stamina: 70 
 Mew's stamina: 50 
 Do you want to (S)pell or (H)eal? S 
 You cast a spell with power 15. 
 Mew casts a spell with power 25. 
  
 Your stamina: 45 
 Mew's stamina: 35 
 Do you want to (S)pell or (H)eal? S 
 You cast a spell with power 21. 
 Mew casts a spell with power 25. 
  
 Your stamina: 19 
 Mew's stamina: 14 
 Do you want to (S)pell or (H)eal? H 
 You healed yourself for 30 health. 
 Mew casts a spell with power 22. 
  
 Your stamina: 27 
 Mew's stamina: 14 
 Do you want to (S)pell or (H)eal? S You cast a spell with power 30. 
 Victory! 
 

    <h3>Grids</h3>


    <h3>Problem 5: Strings (20 points)</h3>

    <p>In the early part of the 20th century, there was considerable interest in both England and the United States
      in simplifying the rules used for spelling English words, which has always been a difficult proposition.
      One suggestion advanced as part of this movement was the removal of all doubled letters from words.
      If this were done, no one would have to remember that the name of the Stanford student union is spelled
      "Tresidder," even though the incorrect spelling "Tressider" occurs at least as often. If double letters
      were banned, everyone could agree on "Tresider."</p>

    <p>Write a function <code>remove_doubled_letters(str)</code> that takes a string <code>str</code> as its argument and returns a
      new string with all doubled letters in the string replaced by a single letter. Here are a few samples
      calls of the function and what it should return:</p>

    <PythonHighlighter code={string_run} />

    <p>In writing your solution, you should keep in mind the following:</p>

    <ul>
      <li>You do not need to write a complete program. All you need is the implementation of the
        function <code>remove_doubled_letters</code> that returns the desired result.</li>
      <li>You may assume that all letters in the string are lower case so that you don't have to
        worry about changes in capitalization.</li>
      <li>You may assume that no letter appears more than twice in a row. (It is likely that your
        program will work even if this restriction were not included; we've included it explicitly
        only so that you don't even have to think about this case.)</li>
    </ul>

    <p>The starter code is provided below.</p>

    <PythonHighlighter code={string_starter} />
  </>
}

const karel_starter = `from karel.stanfordkarel import *

def main():
















if __name__ == "__main__":
  run_karel_program()`

const full_program_run = `This program finds the two largest integers entered by the user. 
Enter values, one per line, using a 0 to signal the end of the input.

Enter value: 7
Enter value: 42
Enter value: 18
Enter value: 9
Enter value: 35
Enter value: 0
The largest value is 42
The second largest is 35`

const full_program_run2 = `This program finds the two largest integers entered by the user. 
Enter values, one per line, using a 0 to signal the end of the input.

Enter value: 1
Enter value: 8
Enter value: 6
Enter value: 8
Enter value: 0
The largest value is 8
The second largest is 8`

const full_program_start = `SENTINEL = 0    # the sentinel used to signal the end of the input
















if __name__ == '__main__':
    main()`

const grid = `grid = [[2, 3], [5], [1, 2, 3, 4, 5]]
# After calling make_true_grid(grid, 3, 0), the grid should be: 
[[2, 3, 0, 0, 0], [5, 0, 0, 0, 0], [1, 2, 3, 4, 5]]

grid = [[10, 20], [], [1]]
# After calling make_true_grid(grid, 4, 9), the grid should be: 
[[10, 20, 9, 9], [9, 9, 9, 9], [1, 9, 9, 9]]

grid = [[1, 2], [3, 4], [5, 6]]
# After calling make_true_grid(grid, 1, 0), the grid should be: 
[[1, 2], [3, 4], [5, 6]]`

const grid_starter = `def make_true_grid(grid, row_size, filler):














`

const graphics_starter = `from graphics import Canvas
import random
import time

SEEKER_SIZE = 10
CANVAS_SIZE = 400
PAUSE_TIME = 1/60



















if __name__ == '__main__':
  main()`

// @ts-ignore
const images_starter = `def create_blurred_image(img):












    `

const string_run = `>>> remove_doubled_letters('tresidder')
'tresider'
>>> remove_doubled_letters('bookkeeper')
'bokeper'`

const string_starter = `def remove_doubled_letters(str):










    `
  
const karel_helper = `def turn_right():
  for i in range(3):
  turn_left()
def turn_around():
  turn_left()
  turn_left()
def move_to_wall():
  while front_is_clear():
  move()`

