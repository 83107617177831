import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln5 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 5: File Reading and Dictionaries (Solution)</h1>
        <hr />
        <h2>Dictionary Review</h2>
        <PythonHighlighter code={dict_warmup} />

        <h2>Grocery <s>Lists</s> Dictionaries</h2>
        <PythonHighlighter code={grocery_lists} />

        <h2>Smallest Unique Positive Integer</h2>
        <PythonHighlighter code={file_reading} />
    </>
}

const file_reading = `def find_smallest_int(filename):
    nums_so_far = []
    duplicates = []
    with open(filename) as f:
        for line in f:
            num = int(line)
            if num > 0:
                # if we've seen this number already
                if num in nums_so_far:
                    # record that it's a duplicate
                    duplicates.append(num)
                # note that we've seen this number
                nums_so_far.append(num)

    uniques = []
    for elem in nums_so_far:
        if elem not in duplicates:
            uniques.append(elem)
    return min(uniques)`

const dict_warmup = `>>> # Initialize dictionary
>>> classes = {'CS106A': 5, 'PSYCH1': 5, 'PWR1': 4}
>>>
>>> # Access the value for the key 'PSYCH1'
>>> classes['PSYCH1']           
5
>>>
>>> # Update the value for the key 'CS106A', changing it from 5 to 3
>>> classes['CS106A'] = 3      
>>> classes
{'CS106A': 3, 'PSYCH1': 5, 'PWR1': 4}
>>>
>>> # Add a new key/value pair to classses, 'AMSTUD107' with value 4
>>> classes['AMSTUD107'] = 4    
>>> classes
{'CS106A': 3, 'PSYCH1': 5, 'PWR1': 4, 'AMSTUD107': 4}`

const grocery_lists = `def add_item(groceries, store, item, num):
    """
    Given a groceries dict which may
    already contain some data, update the
    dict to add new data for the given
    store, item, and num of that item.
    >>> add_item({}, 'safeway', 'eggs', 1) # new store, new item
    {'safeway': {'eggs': 1}}
    >>> add_item({'safeway': {'eggs': 1}}, 'costco', 'croissant', 12) # new store, new item
    {'safeway': {'eggs': 1}, 'costco': {'croissant': 12}}
    >>> add_item({'safeway': {'eggs': 1}, 'costco': {'croissant': 12}}, 'safeway', 'eggs', 2) # seen store, seen item
    {'safeway': {'eggs': 3}, 'costco': {'croissant': 12}}
    >>> add_item({'safeway': {'eggs': 3}, 'costco': {'croissant': 12}}, 'safeway', 'coconut milk', 3) # seen store, new item
    {'safeway': {'eggs': 3, 'coconut milk': 3}, 'costco': {'croissant': 12}}
    """
    if store not in groceries:
        groceries[store] = {}

    inner = groceries[store]
    if item not in inner:
        inner[item] = 0
    inner[item] += num
    '''
    can also do:
    if item not in inner:
        inner[item] = num
    else:
        inner[item] += num
    '''

    return groceries


def make_groceries(filename):
    """
    Given a grocery list file, where each
    line is in the format 'store:item,num'
    create and return the groceries dict
    made from this list.
    Hint: Use your helper function!
    >>> make_groceries('short_list.txt')
    {'safeway': {'eggs': 3, 'coconut milk': 3}, 'costco': {'croissant': 12}}
    """
    groceries = {}
    with open(filename) as f:
        for line in f:
            line = line.strip()
            colon = line.find(':')
            comma = line.find(',')
            store = line[:colon]
            item = line[colon + 1:comma]
            num = int(line[comma + 1:])
            add_item(groceries, store, item, num)
    return groceries


def print_groceries(groceries):
    """
    (provided)
    Prints contents of groceries dict.
    """
    for store in groceries:
        items = groceries[store]
        for item in items:
            count = items[item]
            print('You need ' + str(count) + ' ' + item + '(s) from ' + store)


def main():
    args = sys.argv[1:]
    # to run from terminal:
    # python3 groceries.py filename      # prints out all groceries
    if len(args) == 1:
        groceries = make_groceries(args[0])
        print_groceries(groceries)


if __name__ == '__main__':
    main()`