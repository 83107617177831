export const helpTimeline = [
  {
    title: "Welcome to the Code in Place IDE!",
    intro: "Let me take you around!",
  },
  {
    title: "Editor",
    element: "#code-editor",
    intro: "This is where you write your code!",
  },
  {
    title: "Run Button",
    element: "#start-stop-button",
    intro: "Click this to run your code!",
  },
  {
    title: "Replay Mode",
    element: "#replay-mode-stepper",
    intro:
      "After running your code, you can step back in time to see how each line of code affects the position and behavior of Karel.",
  },
  {
    title: "Karel World",
    element: "#canvas-pane",
    intro:
      "Here is Karel and her world. When you hit run, this Karel will perform the instructions in your code.",
  },
  {
    title: "Terminal",
    element: "#termshell",
    intro: "This is the terminal! This is where you can see your errors.",
  },
  {
    title: "Docs",
    element: "#Docs_sidebar_icon",
    intro:
      "Click this to view the documentation. It's a good reference if you forget any important commands for Karel!",
  },
];
