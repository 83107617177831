import { Handout } from "cs106a/components/Handout"
// @ts-ignore
import NewspaperWorld from "./img/karelPycharm/newspaper_karel_world.png"
// @ts-ignore
import StoneMasonWorld from "./img/assn1/stone_mason.png"
// @ts-ignore
import StoneMasonComplete from "./img/assn1/stone_mason_complete.png"
// @ts-ignore
import CheckerboardEmpty from "./img/assn1/checkerboard_empty.png"
// @ts-ignore
import CheckerboardComplete from "./img/assn1/checkerboard_complete.png"
// @ts-ignore
import CheckerboardOdd from "./img/assn1/5x3_checkerboard.png"
// @ts-ignore
import MidpointEmpty from "./img/assn1/midpoint_empty.png"
// @ts-ignore
import MidpointComplete from "./img/assn1/midpoint_complete.png"
import { Link } from "react-router-dom"

export const Assn1 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Assignment 1: Karel the Robot</h1>
        <hr />

        <p><b>Due: 11:59pm (Pacific Time) on Friday, July 5th</b></p>

        <p><i>Based on problems by Nick Parlante and Eric Roberts, lovingly modified by your current CS106A staff.</i></p >

        <p>This assignment consists of four Karel programs. There is a starter project linked below that contains
            these problems on the CS106A website under the "Assignments" tab. <b>Before you start on this assignment,
                make sure to read through the handout <Link to="/karel-pycharm">Using Karel with PyCharm</Link> in its entirety</b>.
            Here's an overview of what you'll need to do for this assignment:</p>
        <ol>
            <li>Download the <a href={`${process.env.PUBLIC_URL}/Assignment1.zip`}>Karel the Robot starter code</a>.</li>
            <li>Edit the Karel program files so that the assignment code does what it's supposed to do. This will involve a cycle of coding, testing, and debugging until everything works.</li>
            <li>Once you have gotten each part of the program to run correctly in the default world associated with the problem, you should make sure that your code runs properly in all of the worlds that we have provided for a given problem.</li>
            <li>Submit your assignment on Paperless as described at the bottom of this handout. Remember that you can re-submit your assignment as much as you want, but only the most recent submission will be graded. If you discover an error after you've made a submission, just fix your program and submit a new copy of all files (not just the one you fixed).</li>
        </ol>
        <p>The four Karel problems to solve are described later in this handout.</p>

        <p><b>Please remember that your Karel programs must limit themselves to the language features
            described in <a href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">Karel the Robot Learns Python</a>.</b> You
            may not use other features of Python (including variables, parameters, <code>break</code>, and <code>return</code>),
            even though the PyCharm-based version of Karel accepts them.</p>

        <h3>Problem 1 (CollectNewspaperKarel.py)</h3>

        <p>Your first task is to solve a simple story-problem in Karel's world. Suppose that Karel
            has settled into its house, which is the square area in the center of the image below.</p>

        <img src={NewspaperWorld} className="img-fluid mx-auto d-block" width="50%"
            alt="Karel's starting state for CollectNewspaperKarel" />
        <br />

        <p>Karel starts off in the northwest corner of its house as shown in the diagram.
            The problem you need to solve is to get Karel to collect the newspaper. The newspaper,
            like all objects in Karel's world, is represented by a beeper. You must get Karel to pick
            up the newspaper located outside the doorway and then to return to its initial position.</p>

        <p>This exercise is simple and is meant to help you get you started programming with Karel.
            You can assume that every part of the world looks just as it does in the diagram: the
            house is exactly this size, the door is always in the position shown, and the beeper is
            just outside the door. Thus, all you have to do is write the sequence of commands necessary to have Karel:</p>

        <ol>
            <li>Move to the newspaper,</li>
            <li>Pick it up, and</li>
            <li>Return to its starting point.</li>
        </ol>

        <p>Although the program does not have many lines of code, it is still worth getting some practice with
            decomposition. In your solution, <b>write at least two helper functions: one for step 1 and another for step
                3 in the outline above.</b> You're welcome to write a helper function for step 2 as well, but since picking up a beeper
            is only one line of code, it's okay to do this step without a helper function.</p>

        <p>Your program should run successfully in the following world: <code>CollectNewspaperKarel.w</code> (default world).
            Note that all Karel worlds are located in the worlds folder in the <code>Assignment1</code> project folder.</p>

        <h3>Problem 2 (StoneMasonKarel.py)</h3>

        <p>Your next task is to repair the damage done to the Main Quad in the <a href="https://en.wikipedia.org/wiki/1989_Loma_Prieta_earthquake">1989 Loma Prieta earthquake</a>.
            In particular, Karel should repair a set of arches where some of the stones (represented by beepers,
            of course) are missing from the columns supporting the arches, as illustrated below.</p>

        <img src={StoneMasonWorld} className="img-fluid mx-auto d-block" width="50%"
            alt="An initial example world with broken arches that StoneMasonKarel must repair" />
        <br />

        <p>Your program should work on the world shown above, but <b>it should be general enough to handle any
            world that meets the basic conditions outlined at the end of this problem</b>. There are several
            example worlds in the starter folder, and your program should work correctly in all of them.</p>

        <p>When Karel is done, the missing stones in the columns should be replaced by beepers, so that
            the final picture resulting from the initial world shown above would look like the
            illustration below.</p>

        <img src={StoneMasonComplete} className="img-fluid mx-auto d-block" width="50%"
            alt="Karel should repair the Main Quad to a structurally sound state after completion." />
        <br />

        <p>Karel's final location and the final direction Karel is facing at the end of the run do not matter.</p>

        <p>Karel may count on the following facts about the world:</p>

        <ul>
            <li>Karel starts at the corner where 1st Avenue and 1st Street meet, facing east,
                with an infinite number of beepers in Karel's beeper bag. The first column should
                be built on 1st Avenue.</li>
            <li>The columns are always exactly four Avenues apart, so they would be built on 1st
                Avenue, 5th Avenue, 9th Avenue, and so on.</li>
            <li>The final column will always have a wall immediately after it. Although this wall
                appears after 13th Avenue in the example figure, your program should work for any
                number of beeper columns.</li>
            <li>The top of a beeper column will always be marked by a wall. However, Karel cannot
                assume that columns are always five units high, or even that all columns within
                a given world are the same height.</li>
            <li>In an initial world, some columns may already contain beepers representing stones
                that are still in place. Your program should not put a second beeper on corners
                that already have beepers. Avenues that will not have columns will never contain
                existing beepers.</li>
        </ul>
        <p>You should make sure your program runs successfully in all of the following worlds
            (which are just a few different examples to test out the generality of your solution): <code>StoneMasonKarel.w</code> (default world), <code>SampleQuad1.w</code>, <code>SampleQuad2.w</code>.
            Note that all Karel worlds are located in the <code>worlds</code> folder in the <code>Assignment1</code> project folder.</p>

        <h3>Problem 3 (CheckerboardKarel.py)</h3>

        <p>Your third task is to get Karel to create a checkerboard pattern of beepers inside an
            empty rectangular world, as illustrated below. (Karel's final location and the final
            direction it is facing at the end of the run do not matter.)</p>
        <div className="img-fluid mx-auto d-block">
            <img src={CheckerboardEmpty} width="50%"
                alt="The beginning state for CheckerboardKarel.." />
            <img src={CheckerboardComplete} width="50%"
                alt="The end state for CheckerboardKarel." />
        </div>
        <br />

        <p>This problem has a nice decomposition structure along with some interesting algorithmic
            issues. As you think about how you will solve the problem, you should make sure that your
            solution works with checkerboards that are different in size from the standard 8x8 checkerboard
            shown in the example above. Some examples of such cases are discussed below.

            Odd-sized checkerboards are tricky, and you should make sure that your program generates
            the following pattern in a 5x3 world:</p>
        <img src={CheckerboardOdd} className="img-fluid mx-auto d-block" width="50%"
            alt="In a 5x3 world, the bottom and top row start with a beeper on the left, and the middle row doesn't." />
        <br />

        <p>Other special cases you should consider are worlds with only a single column or a single row.
            The starter code folder contains several sample worlds with these special cases, and you should
            make sure that your program works for each of them.
        </p>

        <p>This problem is hard: Try simplifying your solution with decomposition. Can you checker
            a single row/column? Make the row/column work for different widths/heights? Once you've
            finished a single row/column, can you make Karel fill two? Three? All of them? Incrementally
            developing your program in stages helps break it down into simpler parts and is a wise strategy
            for attacking hard programming problems.
        </p>
        <p>You should make sure your program runs successfully in all of the following worlds (which are
            just a few different examples to test out the generality of your solution): <code>CheckerboardKarel.w</code> (default world),
            <code>8x1.w, 1x8.w, 7x7.w, 6x5.w, 3x5.w, 40x40.w, 1x1.w</code>. Note that all Karel worlds are located
            in the <code>worlds</code> folder in the <code>Assignment1</code> project folder.
        </p>

        <h3>Problem 4 (MidpointKarel.py)</h3>

        <p>As an exercise in solving algorithmic problems, you will program to find the midpoint of 1st Street.
            Say Karel starts in the 5x5 world at 1st Street, 1st Avenue. Karel should end in the
            center of 1st Street.</p>

        <div className="img-fluid mx-auto d-block">
            <img src={MidpointEmpty} width="50%"
                alt="The beginning state for MidpointKarel in a 5x5 world, bottom left, facing east, not on a beeper" />
            <img src={MidpointComplete} width="50%"
                alt="The end state for MidpointKarel" />
        </div>

        <p>Note that the final configuration of the world should have only a single beeper at the midpoint of
            1st Street. Along the way, Karel is allowed to place additional beepers wherever it wants to, but
            must pick them all up again before it finishes. Similarly, if Karel paints/colors any of the corners
            in the world, they must all be uncolored before Karel finishes.</p>

        <p>In solving this problem, you may count on the following facts about the world:</p>

        <ul>
            <li>Karel starts at 1st Avenue and 1st Street, facing east, with an infinite number of beepers in its bag.</li>
            <li>The initial state of the world includes no interior walls or beepers.</li>
            <li>The world need not be square, but you may assume that it is at least as tall as it is wide.</li>
        </ul>

        <p> Your program, moreover, can assume the following simplifications:</p>
        <ul>
            <li>If the width of the world is odd, Karel must put the beeper in the center square. If the width is
                even, Karel may drop the beeper on either of the two center squares.</li>
            <li>It does not matter which direction Karel is facing at the end of the program.</li>
        </ul>

        <p>There are many different algorithms you can use to solve this problem so feel free to be creative!</p>

        <p>You should make sure your program runs successfully in all of the following worlds (which are
            just a few different examples to test out the generality of your solution): <code>MidpointKarel.w</code> (default world),
            <code>Midpoint1.w, Midpoint2.w, Midpoint8.w</code>. Note that all Karel worlds are located
            in the <code>worlds</code> folder in the <code>Assignment1</code> project folder.</p>

        <h3>Possible extension: Create your own Karel project</h3>
        <p>If you finish early, you may <i>optionally</i> write a Karel project of your own choice.
            Modify <code>ExtensionKarel.py</code> to use Karel to complete any task of your choosing.
            Extensions are a great chance for practice and, if your extension is substantial enough,
            it might help you earn a + score. Make sure to write comments to explain what your program
            is doing and update <code>ExtensionKarel.w</code> to be an appropriate world for your program.</p>

        <h3>Submitting your code</h3>
        <p>All assignments are submitted electronically through the <a href="https://cs198.stanford.edu/paperless/">Paperless
            website</a>. Since this timestamp is based on the time that the server that receives the assignment, we recommend
            submitting with at least a few minutes to spare, particularly if you discover that your computer's clock is
            running slow.</p>

        <p>Click on the link to Paperless above. Select the assignment you wish to submit (in this case, Assignment 1: Karel the Robot).
            You should upload all of the appropriate files for the chosen assignment. The end of each assignment handout
            you will find a list of all of the files that we expect you to submit - please do not rename <code>.py</code> the files
            when you download them. Here are the files to submit for this assignment:</p>

        <ul>
            <li><code>CollectNewspaperKarel.py</code></li>
            <li><code>StoneMasonKarel.py</code></li>
            <li><code>CheckerboardKarel.py</code></li>
            <li><code>MidpointKarel.py</code></li>
        </ul>

        <p>If you did an optional extension on the assignment, you should also submit along with these 4 files:</p>

        <ul>
            <li><code>ExtensionKarel.py</code></li>

        </ul>

        <p><b>If you are adding functionality to your project for extra credit in a file other than a
            designated <code>ExtensionKarel.py</code> file, please submit a regular version of your
            code along with the extended version in a separate file</b>. This ensures that you get full credit for the main parts
            of the assignment in the case that your extended program introduces any bugs. If you have image files,
            audio clips, or extra Python files that are part of your extra credit submission, please also make sure
            to submit those.</p>

        <p>Once they have been uploaded and you have verified that you've included all of the files
            outlined in the assignment handout, hit the "Submit Assignment" button.</p>

        <p><b>You should always check the contents of your submission to make sure the code files are up-to-date and correct</b>.
            To inspect the submission, click on the specific assignment and use the dropdown to view each file's contents.</p>

        <p>Congratulations, you've now successfully submitted your assignment! You can submit each assignment as many times
            as you would like by following the instructions outlined in this document. However, your section leader will
            grade only the most recent submission.</p >
    </>

}