import { CS106ANavbar } from "cs106a/components/Navbar"
import { Footer } from "../components/Footer"
import { courseInfo } from "../courseInfo"
import { Announcements } from "./components/Announcements"
import { KeyDates } from "./components/KeyDates"
import { ResourcesMobile } from "./components/Resources"
import { TeachingTeamMobile } from "./components/TeachingTeam"
import { TodoList } from "./components/TodoList"
import { Values } from "./components/Values"

export const MobileLanding = () => {
    return <><CS106ANavbar/><div className="container-lg">



        {/* <!-- Home page header --> */}
        <div className="row" id="indexHead">
            <div className="col-12 mt-4 title-section">
                <img style={{width:110}} className="float-left mr-3 mb-3" src={`${process.env.PUBLIC_URL}/stanford2.gif`}></img>
                <div id="headerText">
                    <div className="title">CS106A: Programming Methodologies</div>
                </div>
            </div>
            <div className="col-12">
                <div>Summer 2024, Tuesday, Thursday, Friday { courseInfo.lectureTime },&nbsp;
                    <a target="_blank" href={courseInfo.locationMap}>{courseInfo.location}</a>
                </div>



                <hr />


            </div>
        </div>
        <div className="row">
            <div className="col">
                <TodoList/>
                <hr/>

                <TeachingTeamMobile/>

                <hr />
                <ResourcesMobile/>
                <hr />



                <KeyDates/>
                <hr />
                <p className="subtleHeading">Announcements</p>

                <Announcements/>

                <hr />
                <Values/>


                <Footer/>
            </div>
        </div>
    </div>
    </>
}

const slightlySmallStyle = {
    fontSize: '.9em'
  }