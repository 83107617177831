import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'
// @ts-ignore
import HospitalStart from "../img/section1/hospital_start.png"
// @ts-ignore
import HospitalEnd from "../img/section1/hospital_end.png"
// @ts-ignore
import DemocracyKarel from "../img/section1/democracy_karel.png"


export const Section1 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 1: Karel the Robot</h1>
        <hr />
        <h5><Link to="/isais"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>

        <p>This week in section, your first priority is to meet your section leader and
            discover what sections in CS106A are all about. Your section leader will therefore
            spend the first part of section on introductions and course logistics. Afterwards, you'll
            solve some Karel problems using decomposition and stepwise refinement.</p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/Section1.zip`}>Section 1 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you did with your Assignment 1 starter code.</p>

        <h3>1. Hospital Karel</h3>

        <p>Countries around the world are dispatching hospital-building robots to make sure anyone who gets sick
            can be treated. They have decided to enlist Karel robots, and your job is to program those robots.</p>

        <p>Karel begins at the left (west) end of a row that might look like this:</p>
        <img src={HospitalStart} className="img-fluid mx-auto d-block" width="70%"
            alt="Karel's starting state for hospital karel, on the bottom left of the
            world facing east, with beepers placed along the path." />
        <br />
        <p>Each beeper in the figure represents a pile of supplies. Karel's job is to walk along 1st Street and
            build a new hospital in the places marked by each beeper. The new hospital should be centered at the
            point at which the bit of debris was left, which means that
            the first hospital in the diagram above will be constructed with its left edge along 2nd Avenue, since
            the beeper was originally at 3rd Avenue.</p>
        <p>At the end of the run, Karel should be at the east end of 1st street having created a set of hospitals
            that look like this for the initial conditions shown above: </p>
        <p />

        <img src={HospitalEnd} className="img-fluid mx-auto d-block" width="70%"
            alt="Karel's end state, in place of each beeper is a 'hospital' which is three sets
            of three columns of stacked beepers, each 3 beepers tall,
             with the middle column of beepers shifted up
            one place from the left and right columns" />
        <br />

        <p>Keep in mind the following information about the world:</p>

        <ol>
            <li>Karel starts facing east at (1, 1) with an infinite number of beepers in its beeper bag.</li>
            <li>The beepers indicating the positions at which hospitals should be built will be spaced
                so that there is room to build the hospitals without overlapping or hitting walls.</li>
            <li>You will not have to build a hospital that starts in either of the last two columns.</li>
            <li>Karel should not crash into a wall if it builds a hospital that ends in the final corner.</li>
        </ol>

        <p>Write the code to implement Hospital Karel. <b>Use helper functions</b>. Think, "what are the high-level
            steps Karel needs to take?" and make these steps into helper functions. Remember that your program should
            work for any world that meets the above conditions.</p>

        <p>If you're working in the starter project, write your code in the <code>main()</code> function
            of <code>HospitalKarel.py</code>. To test your code, open the terminal and enter:</p>

        <code>python3 HospitalKarel.py</code> (replacing python3 with py or python if you're using Windows).

        <h3>2. Karel Defends Democracy</h3>

        <p>The <a href="https://en.wikipedia.org/wiki/2000_United_States_presidential_election_recount_in_Florida">2000
            U.S. Presidential Election</a> was plagued by the hanging-chad problem. To vote,
            voters punched columns out of a paper ballot, but if they only punched partially,
            the column was left hanging. This can lead to incorrect counts and recounts!</p>

        <p>In Karel's world, a ballot consists of a series of columns that a voter can “punch out”.
            Karel starts on the left of a ballot and should progress through each column. If a column
            contains a beeper in the center row, the voter did not intend to vote on that column,
            and Karel should move to the next column. However, if a column contains no beeper in
            the center row, Karel must make sure that there is no hanging chad. In other words,
            Karel should check the corners above and below and remove any beepers. A corner may
            contain any number of beepers. Karel must finish facing east at the rightmost edge
            of the ballot.</p>

        <p>An example initial world is shown on the left below. The world on the right below shows
            what Karel's final world should look like (when given the initial world on the left).</p>

        <img src={DemocracyKarel} className="img-fluid mx-auto d-block" width="80%"
            alt="Democracy karel start and end states. Karel fills in each column by fixing the
            hanging chad." />
        <br />

        <p> Keep in mind the following information about the world:</p>

        <ol>
            <li>Karel starts facing east at (1, 2) with an infinite number of beepers in its beeper bag.</li>
            <li>Karel must end up facing east at the end of 2nd street.</li>
            <li>The world consists of an arbitrary number of 3-height columns, and Karel
                can travel along the middle row without hitting a wall.
            </li>
        </ol>

        <p>Write the code to implement Democracy Karel, as described above. <b>Use helper functions</b>. Think,
            "what are the high-level steps Karel needs to take?" and make these steps into helper functions.
            Remember that your program should work for any world that meets the above conditions.</p>

        <p>If you're working in the starter project, write your code in the <code>main()</code> function
            of <code>DemocracyKarel.py</code>. To test your code, open the terminal and enter:</p>

        <code>python3 DemocracyKarel.py</code> (replacing python3 with py or python if you're using Windows).
    </>
}