import Swal from "sweetalert2";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { uuidv4 } from "lib0/random";

export const getDemoVideoStoragePath = (userId) => {
    return `slDemoVideos/${userId}`;
}

export const getTeachNowTrainingVideoStoragePath = (userId) => {
    return `teachNowTrainingVideos/${userId}`;
}

export const uploadVideo = (setVideoUrl, setUploadProgress, setErrorState, demoVideoStoragePath) => {
    Swal.fire({
        title: "Select video",
        input: "file",
        inputAttributes: {
        accept: "video/*",
        "aria-label": "Upload your demo video",
        },
    }).then((e) => {
        const file = e.value;
        if (file) {
            uploadVideoFile(file, setVideoUrl, setUploadProgress, setErrorState, demoVideoStoragePath);
        }
    });
}

const uploadVideoFile = (file, setVideoUrl, setUploadProgress, setErrorState, demoVideoStoragePath) => {

    // lets get this started
    setUploadProgress(1)
    
    const storage = firebase.storage();
    const storageRef = storage.ref();
    
    const uploadTask = storageRef.child(demoVideoStoragePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      },
      (error) => {
        setErrorState({
            code: error.code,
            message: error.message
        })
      },
      () => {
        // get a playable url from the snapshop ref
        const ref = uploadTask.snapshot.ref
        ref.getDownloadURL().then((url) => {
            setVideoUrl(url);
        })
      }
    );
  };


