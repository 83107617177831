// Implements a chat component that can be used to send and receive messages.
// The component uses firebase realtime database and the hooks defined in
// src/firebase/Chat.ts to send and receive messages.

import React, { useState, useEffect, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { useMessages, addMessage } from "../../firebase/realtime/Chat";
import { auth } from "firebaseApp";

import { ChatRoom } from "./ChatRoom";
import { PeerSessionContext } from "ide/contexts/PeerSessionContext";
import { useRoomMembers } from "../../firebase/realtime/Rooms";

export const TeachNowChat = () => {
  // @ts-expect-error
  const [user] = useAuthState(auth());
  const [message, setMessage] = useState("");

  const { courseId, roomId } = useParams();
  const messages = useMessages(roomId, courseId);
  const { meetingRole, roomInfo } = useContext(PeerSessionContext)

  const roomMemberInfo = useRoomMembers(courseId, roomId)

  if (messages === undefined || roomMemberInfo === undefined || roomInfo === undefined) {
    return <div>Loading...</div>;
  }

  const sendMessage = async (msg) => {
    if (msg) {
      const result = await addMessage(
        {
          text: msg,
          senderId: user.uid,
          senderName: user?.displayName,
          timestamp: null, // gets replaced by firebase
        },
        roomId,
        courseId
      );
    }
    // if(result.success) {
    // setMessage("");
    // }
  };

  const zoomInfo = roomInfo['zoomInfo']
  // insert element to the start of the messages list
  const displayNames = roomMemberInfo['displayNames']
  // Join the display names with commas and "and" before the last name
  let names = displayNames.slice(0, -1).join(', ') + ' and ' + displayNames.slice(-1);

  // Create the welcome message
  let welcomeMessage = `Welcome ${names} to this collaborative learning session!`;

  // Custom messages
  const coPioltMessageText = "You are the co-pilot! You are in charge of leading the conversation. Note that everyone can see the same code, but you will each have to click run on your own IDEs to see the output."
  const pilotMessageText = "You are the pilot! You are in charge of editing and running the code. Note that everyone can see the same code, but you will each have to click run on your own IDEs to see the output."

  const individualMessageText = meetingRole === "pilot" ? pilotMessageText : coPioltMessageText

  if (messages.length === 0 || messages[0].senderId !== "simba") {
    // messages.unshift({
    //   text: individualMessageText,
    //   senderId: "simba",
    //   senderName: "Ali and Juliette",
    //   timestamp: 3,
    // });
    // messages.unshift({
    //   text: "In this session, you should work together to solve the problem. Only the pilot can edit the code.",
    //   senderId: "simba",
    //   senderName: "Ali and Juliette",
    //   timestamp: 2,
    // });
    messages.unshift({
      text: "Happy Coding! 🚀",
      senderId: "simba",
      senderName: "Ali and Juliette",
      timestamp: 1,
    });
    messages.unshift({
      text: "Please do not hit \"End Session\" until you are ready to leave as this will end the session and Zoom meeting for everyone.",
      senderId: "simba",
      senderName: "Ali and Juliette",
      timestamp: 1,
    });
    messages.unshift({
          text: ``,
          senderId: 'simba',
          senderName: "Ali and Juliette",
          timestamp: 1
    })
    messages.unshift({
      text: `You can also use the chat to communicate with each other. If you have any questions, feel free to ask!`,
      senderId: "simba",
      senderName: "Ali and Juliette",
      timestamp: 1,
    });
    messages.unshift({
      text: `Click the \"Launch Zoom\" button on the top-right of the window to start a Zoom meeting. Or you can directly join using the following link: [${zoomInfo.joinUrl}](${zoomInfo.joinUrl}).`,
      senderId: "simba",
      senderName: "Ali and Juliette",
      timestamp: 1,
    });
    messages.unshift({
      text: "During this time, you can talk to each other and discuss any questions about the current assignment. The session should last no more than 30 minutes.",
      senderId: "simba",
      senderName: "Ali and Juliette",
      timestamp: 1,
    });
    messages.unshift({
      text: welcomeMessage, //"Welcome to collaborative learning! 🚀",
      senderId: "simba",
      senderName: "Ali and Juliette",
      timestamp: 1,
    });
  }

  return (
    // <div style={{width: '30%', height: '500px', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto',}}>
    <div
      style={{
        width: "100%",
        height: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <div> */}
      <ChatRoom
        userId={user.uid}
        messages={messages}
        onSendMessage={sendMessage}
      />
    </div>
  );
};
