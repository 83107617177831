import React, { useEffect, useRef } from "react";
import useState from 'react-usestateref';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDebounce } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import * as awarenessProtocol from "y-protocols/awareness.js";
import PropTypes from 'prop-types';
import {
  yDocToProsemirrorJSON,
  yDocToProsemirror,
  prosemirrorJSONToYDoc,
} from "y-prosemirror";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import {
  FaBold,
  FaCode,
  FaHeading,
  FaICursor,
  FaImage,
  FaPaintBrush,
  FaPlay,
  FaPython,
  FaSquareRootAlt,
  FaStop,
  FaTerminal,
} from "react-icons/fa";
import Swal from "sweetalert2";

// tiptap
import { getSchema } from "@tiptap/core";
import { useEditor, EditorContent } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";

import { lowlight } from "lowlight";
import python from "highlight.js/lib/languages/python";


// yjs
import * as Y from "yjs";
import { WebrtcProvider } from "y-webrtc";
import { Dropdown } from "react-bootstrap";
import { doc, getFirestore } from "firebase/firestore";
import { KarelWorldTipTap } from "./extensions/KarelWorldTipTap";
import suggestion from "./components/suggestion";
import { Skeleton } from "antd";
import { SK } from "country-flag-icons/react/3x2";
import { randomUserColor } from "./TipTapUtil";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { editorExtensions } from "./EditorExtensions";
import { uploadImg } from "./extensions/Image";

import 'react-resizable/css/styles.css';
import { useUserId } from "hooks/user/useUserId";

lowlight.registerLanguage("python", python);

export const WEBRTC_PROVIDERS = [
  "wss://y-webrtc.codeinplace.org",
  'wss://y-webrtc-eu.fly.dev'
  // 'wss://y-webrtc-signaling-eu.herokuapp.com',
];

/**
 * Props:
 * editable: boolean. Can you edit the doc?
 * firebaseDocPath: string. Where in the firebase should we write the doc? We use our own
 *    format for this doc path, so I would recommend a doc that isn't used for anything else
 * onServerWrite: function with one parameter. An optional function which is called each time
 *    we write to the server. This can be used to do something like update a search index. It
 *    will be passed a JSON containing the content of the editor. Why use the JSON? If you
 *    want to highlight a found string in the editor, you will need the JSON format. Note, if
 *    you have multiple clients connected to the same document, only one of them will be in
 *    charge up updating the database.
 *
 *
 * TipTap is the *best* editor I have found. It is built off of ProseMirror, is
 * actively supported and just works so well. It also has principled support
 * for collaborative editing and for creating custom components.
 *
 * Collaboration:
 * Collaboration is built off the amazing yjs library. yjs can be backed by either
 * webrtc either serverlessly, or with a server. The server is the recommended option
 * however it doesn't work well with firebase. For the time being I am having the clients
 * take turns being the centralized server.
 * The "truth" is the webrtc yjs object. However this truth needs to be saved to the db
 * and loaded when a new editing room is created. Who should save? who should load?
 * The great idea is to use the following protocol:
 * whomever has the lowest sessionId is in charge of backing up to the firebase.
 * whomever is first to open the document is in charge of loading from the firebase.
 *
 * Loading / saving to the database:
 * Loading: set the editor content. This will propogate to future yjs connections
 * Saving: save the json of the editor content
 *
 * Notes on YJS and firebase:
 * https://github.com/yjs/yjs/issues/189
 *
 * I thought this conversation was very helpful:
 * https://discuss.yjs.dev/t/persisting-to-db-could-it-be-this-easy/358/3
 *
 * "awareness" is an important concept! Its used for things like...
 * knowing who else is online
 * keeping track of their cursor position etc
 *
 * Known issues:
 * - Cant drag/drop code
 */

export const TipTap = (props) => {

  const {
    editable,
    firebaseDocPath,
    onServerWrite,
    collaborative,
    handleUpdate,
    buttonBar,
    showLoadingSkeleton,
    height
  } = props

  // this component is not reactive to
  // firebaseDocPath changing, or collaborative changing
  // if either of those change, we need to force react
  // to re-create this component. We do that by giving
  // the component a unique key based on the two components

  if(!firebaseDocPath) {
    return <LoadingSkeleton showLoadingSkeleton={showLoadingSkeleton}/>
  }

  if (editable && !collaborative) {
    return <>Editable and not collaborative are not supported</>;
  }

  const innerCollaborative = collaborative && editable

  // force the component to un-mount if any of these change
  const uniqueKey = `${firebaseDocPath}-${collaborative}-${editable}`
  return <TipTapSafe {...props} collaborative={innerCollaborative} key={uniqueKey}/>
}

const TipTapSafe = ({
  editable,
  firebaseDocPath,
  onServerWrite,
  collaborative,
  handleUpdate,
  buttonBar,
  showLoadingSkeleton,
  height
}) => {

  // STABILITY CHECK:
  // I want to make sure that the firebaseDocPath never changes in this doc
  // and same with the collaborative state. TipTap should have managed this
  const docPathRef = useRef(firebaseDocPath)
  const collaborativeRef = useRef(collaborative)
  useEffect(() => {
    if(!firebaseDocPath) {
      throw new Error("firebaseDocPath is null in TipTapSafe")
    }
    if(docPathRef.current !== firebaseDocPath) {
      throw new Error("firebaseDocPath changed in TipTapSafe")
    }
    if(collaborativeRef.current !== collaborative) {
      throw new Error("collaborative changed in TipTapSafe")
    }
    if(!collaborative && editable) {
      throw new Error("editable and not collaborative are not supported")
    }
  }, [firebaseDocPath, collaborative])


  const db = getFirestore();
  const docRef = doc(db, firebaseDocPath);

  // how convinced are we that this switches first when firebaseDocPath changes?
  const [serverData, serverDataLoading, serverDataError] =
    useDocumentDataOnce(docRef);
  if (serverDataLoading) return <LoadingSkeleton showLoadingSkeleton={showLoadingSkeleton}/>
  if (serverDataError) return <p>Error loading data</p>;
  
  return (
    <TipTapWithData
      serverData={serverData}
      firebaseDocPath={firebaseDocPath}
      editable={editable}
      onServerWrite={onServerWrite}
      handleUpdate={handleUpdate}
      collaborative={collaborative}
      buttonBar={buttonBar}
      key={firebaseDocPath}
      height={height}
    />
  );
};

TipTap.defaultProps = {
  showLoadingSkeleton:true,
  onServerWrite: () => {},
  handleUpdate: () => {},
  collaborative: true,
  editable: true,
  height:null
};

// Warning: there have been many garuntees made to get to this point.
// do not export this component, only TipTapSafe may use it.
const TipTapWithData = ({
  editable,
  firebaseDocPath,
  serverData,
  onServerWrite,
  handleUpdate,
  collaborative,
  buttonBar,
  height
}) => {
  let [webProvider, setWebProvider] = useState(null);
  const userId = useUserId()

  // for writing to the firebase. ready changes are the json to save
  // which gets debounced to prevent too many writes.
  const [readyChanges, setReadyChanges] = useState(null);
  const [debouncedChanges] = useDebounce(readyChanges, 250);

  // Constructor
  useEffect(() => {
    if(webProvider) {
      console.error('We already have a webprovider')
    }
    let roomName = getUniqueProviderRoom(firebaseDocPath);
    let ydoc = new Y.Doc();


    if (collaborative && serverData && serverData.ydoc) {
      // load a ydoc from the data if it has one
      // be careful: dont try and inject from the json
      // that can lead to strange sync issues with yjs
      let docBlob = serverData.ydoc;
      let docArray = docBlob.toUint8Array();
      if (serverData.version == "V2") {
        Y.applyUpdateV2(ydoc, docArray);
      } else {
        Y.applyUpdate(ydoc, docArray);
      }
    }

    if (collaborative) {
      let awareness = new awarenessProtocol.Awareness(ydoc);
      const provider = new WebrtcProvider(roomName, ydoc, {
        signaling: WEBRTC_PROVIDERS,
        awareness,
      });
      setWebProvider(provider);
    }

    return () => {
      ydoc.destroy();
      webProvider?.disconnect();
    };
  }, []);

  // onUpdate
  const onUpdate = (json, html, text) => {
    // the underlying json has changed
    if (webProvider && isLoggingUser(webProvider.awareness)) {
      setReadyChanges(json);
    }
    if (handleUpdate) {
      handleUpdate(json, html, text);
    }
  };

  // writing to the server
  useEffect(() => {
    // this is where we write to the database
    // before doing so, it would be a good practice to make sure that everyone
    // has proper permissions!
    if (debouncedChanges) {
      let docArray = Y.encodeStateAsUpdateV2(webProvider.doc);
      let docBlob = firebase.firestore.Blob.fromUint8Array(docArray);
      firebase
        .firestore()
        .doc(firebaseDocPath)
        .set(
          {
            content: debouncedChanges,
            ydoc: docBlob,
            lastEdit: new Date(),
            version: "V2",
            editors: firebase.firestore.FieldValue.arrayUnion(userId)
          },
          { merge: true }
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Failed to save to server",
            position: "top-end",
            text: error.message,
          });
        });
      if (onServerWrite) {
        onServerWrite(debouncedChanges);
      }
    }
  }, [debouncedChanges]);



  // wait until you are ready...
  if (collaborative && !webProvider) {
    return <>loading...</>;
  }

  return (
    <TipTapWithDoc
      onUpdate={onUpdate}
      collaborative={collaborative}
      provider={webProvider}
      editable={editable}
      serverData={serverData}
      buttonBar={buttonBar}
      height={height}
      firebaseDocPath={firebaseDocPath}
    />
  );
};

/**
 * This is the actual editor
 */
const TipTapWithDoc = ({
  provider,
  onUpdate,
  editable,
  collaborative,
  serverData,
  buttonBar,
  height,
  firebaseDocPath
}) => {
  const [user] = useAuthState(getAuth(getApp()));

  const editorRef = useRef(null);


  let extensions = editorExtensions;
  if (collaborative) {
    const colabExtensions = [
      Collaboration.configure({
        document: provider ? provider.doc : null,
      }),

      CollaborationCursor.configure({
        provider: provider,
        user: {
          name: user?.displayName ?? "Anonymous",
          color: randomUserColor(),
          // this does not update, but it is a promising idea...
          editable: editable,
        },
      }),
    ];
    // @ts-ignore
    // there is a type script error about extension type and colaboration type
    extensions = extensions.concat(colabExtensions);
  }

  const handleDrop = (view, event, slice, moved)=> {
    if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0]; // the dropped file
      const filesize = ((file.size/1024)/1024) // the file size in MB
      const isImage = file.type.startsWith('image/')
      if (isImage && filesize < 10) {
        const userId = user.uid
        uploadImg(editorRef.current,userId,file)
        return true
      }
    }
    // handled
    return false
  }

  const editerParams = {
    extensions: extensions,
    editorProps: {
      handleDrop
    },
    editable: editable,
  };

  if (!collaborative) {
    // there are two ways to injest data, via a YJS provider
    // or via setting the content field. We use the later
    // if and only if we are not using YJS
    if (!serverData || !serverData.content) {
      editerParams.content = "";
    } else {
      editerParams.content = serverData.content;
    }
  }
  const editor = useEditor(editerParams);

  useEffect(() => {

    const assignChildrenIds = (parent, prefix) => {
      if(!parent) return;
      const children = parent.children;
      if (!children || children.length === 0) {
        return;
      }
      for (let i = 0; i < children.length; i++) {
        if(!children[i].id) {
          children[i].id = prefix + "-" + i;

        }
        if(children[i].children.length > 0){
          assignChildrenIds(children[i], prefix + "-" + i);
        }
      }
    }

    const parentElement = document.getElementById("tiptap-editor-container"); // Select the parent element
    assignChildrenIds(parentElement, `tiptap-${firebaseDocPath}`); // Assign the ids
    }, [editor])


  useEffect(() => {
    // this is a hack, but it is one I ran through the discussion
    // forum of tiptap. I believe since I brought it up they
    // may have fixed it so that the editable field of the editor
    // is reactive (children can listed to it)
    if (editor) {
      editor.setEditable(editable);
      editor.chain().focus().insertContent("").run();
    }
    if(collaborative){
      // update the user's editable status

    }
  }, [editable]);

  useEffect(() => {
    if (!editor) return;
    editorRef.current = editor;
    editor.on("update", ({ editor }) => {
      let json = editor.getJSON();
      let html = editor.getHTML();
      let text = editor.getText();
      onUpdate(json, html, text);
    });
  }, [editor]);

  let tiptapStyle = outerStyle
  if(height){
    tiptapStyle = {
      ...outerStyle,
      height: height
    }
  }

  return (
    <div style={tiptapStyle}>
      {buttonBar({ editor, editable })}
      {/* classname editor is the parent to prosemirror styles */}
      <div
        className={editable ? "editor" : ""}
        style={editable ? innerStyle : {}}
        onClick={() => {
          if (editable) {
            // Bug: this didn't work (it kept stealing focus from ace editor)
            // if (!editor.view.hasFocus()) {
            //   editor.chain().focus().run();
            // }
          }
        }}
        id="tiptap-editor-container"
      >
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

const getUniqueProviderRoom = (firebaseUrl) => {
  const SALT = "codeinplace25041989";
  return SALT + firebaseUrl.replaceAll("/", "-");
};

const isLoggingUser = (awareness) => {
  /**
   * GOAL: I would like this to alternate answers among users to add some
   * robustness
   * I would also like to make sure that the user who is writing to the
   * database has an editable document
   * Ways that I could possibly do this include:
   *  - take the time and mod it by half a second, and use this
   *    to chose which person should write (edit: wont work)
   *  - in the awareness dicitonary, we could record the last time
   *    that the person wrote to the database. Person who is supposed
   *    to write is the one who has written the least recently (with
   *    some code to manage ties and to manage users who never write)
   *  - everyone debounces every 250 * N_USERS ms and we somehow make
   *    sure that they are well stratified (edit: wont work)
   */

  // are you in charge of saving to the database?
  let states = awareness.getStates();

  let userArray = Array.from(states).map((state) => state);

  let minUserId = awareness.clientID;
  for (let user of userArray) {
    let userId = user[0];
    if (userId < minUserId) {
      minUserId = userId;
    }
  }
  return minUserId == awareness.clientID;
};

export const LoadingSkeleton = ({showLoadingSkeleton}) => {
  if(!showLoadingSkeleton) return <></>;
  return <Skeleton  paragraph={{ rows: 2 }} title={false} />;
};

const outerStyle = {
  display:'flex',
  flexDirection:'column',
}

const innerStyle = {
  padding: "10px",
  flex: 1,
  overflow:'auto'
}
