import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const PracticeFinalSoln = () => {
    // The navbar already exists, this is just the component bellow the nav :-)
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Practice Final Solutions</h1>
        <hr />

        <h3>Problem 1: Sandcastles</h3>
        <h4>A</h4>
        <PythonHighlighter code={sand_a} />
        <h4>B</h4>
        <PythonHighlighter code={sand_b} />
        <h4>C</h4>
        <PythonHighlighter code={sand_c} />

        <h3>Problem 2: List</h3>
        <PythonHighlighter code={list} />

        <h3>Problem 3: Strings</h3>
        <PythonHighlighter code={strings} />

        <h3>Problem 4: Graphics</h3>
        <PythonHighlighter code={graphics} />

        <h3>Problem 5: File Processing</h3>
        <PythonHighlighter code={files} />

        <h3>Problem 6: Using Data Structures</h3>
        <PythonHighlighter code={data} />

        <h3>Problem 7: Full Program</h3>
        <PythonHighlighter code={full} />
    </>
}

const sand_a = `Purple
Green
None`

const sand_b = `def halve_scores(scores):
    result = []
    for score in scores:
        result.append(score/2)
    return result`

const sand_c = `def count_favorite_fruit(favorites_json, fruit):
    favorites_dict = json.loads(favorites_json)
    count = 0
    for person in favorites_dict:
        if favorites_dict[person] == fruit:
            count += 1
    return count`

const list = `def interleave_lists(list1, list2):
    result = []

    # Determine which list is shorter
    min_length = min(len(list1), len(list2))

    # Loop over the length of shorter list, appending elements from list1 and list2
    # on to the result list in an interleaved pattern.
    for i in range(min_length):
        result.append(list1[i])
        result.append(list2[i])

    # Create the remainder by slicing the elements from the longer list that were
    # not added to the result
    if len(list1) > len(list2):
        remainder = list1[min_length:]
    else:
        remainder = list2[min_length:]

    # Add the remaining elements from the longer list to the result
    result.extend(remainder)

    return result`

const strings = `def fix_capitalization(str):
    # Start with an empty result string that we will build up.
    result = ''

    # next_cap keeps track of whether next letter in input string should
    # be capitalized.  Note that the first letter must be capitalized.
    next_cap = True

    for i in range(len(str)):
        ch = str[i]
        # If we have a letter, check to see if it should be capitalized or not
        if ch.isalpha():
            if next_cap:
                ch = ch.upper()
                next_cap = False
            else:
                ch = ch.lower()
        # If we didn't have letter, see if it's end-of-sentence punctuation
        # mark (in which case we should capitalize the next letter).
        elif ch == '.' or ch == '!' or ch == '?':
            next_cap = True

        # Add the character to the result we are building up
        result += ch

    return result`

const graphics = `from graphics import Canvas
import random

CANVAS_WIDTH = 500      # Width of drawing canvas in pixels
CANVAS_HEIGHT = 300     # Height of drawing canvas in pixels
COLORS = ['red', 'orange', 'yellow', 'green', 'blue', 'violet']
RADIUS = 5

def draw_sketch(canvas):
    num_colors = len(COLORS)
    num_lines = int(input('Number of lines in drawing: '))

    # Determine starting point for first line
    start_x = random.randint(0, CANVAS_WIDTH)
    start_y = random.randint(0, CANVAS_HEIGHT)
    centered_circle(canvas, start_x, start_y, COLORS[0])

    # Draw the lines
    for i in range(num_lines):
        end_x = random.randint(0, CANVAS_WIDTH)
        end_y = random.randint(0, CANVAS_HEIGHT)

        # Use i + 1 to determine color for circle since starting point
        # of first line already used the first color
        centered_circle(canvas, end_x, end_y, COLORS[(i + 1) % num_colors])
        canvas.create_line(start_x, start_y, end_x, end_y)

        # The ending point of this line becomes starting point of next line
        start_x = end_x
        start_y = end_y

# Draws a circle centered at point (x, y) with the given color
def centered_circle(canvas, x, y, color):
    canvas.create_oval(x - RADIUS, y - RADIUS, x + RADIUS, y + RADIUS,
                       color)

def main():
    canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT)   # Assume make_canvas exists
    draw_sketch(canvas)
    canvas.mainloop()

if __name__ == '__main__':
    main()`

const files = `def contains_loop(filename):
    # Use a dictionary to keep track of each person's best friend
    bf_dict = {}

    # Read the file into a dictionary where names are keys, best friends are values
    with open(filename, 'r') as file:
        for line in file:
            line = line.strip()
            names = line.split()
            bf_dict[names[0]] = names[1]


    # For each name in the bf_dict, see if we can find a loop starting at that name
    for name in bf_dict.keys():
        # Store path from a given person to determine if we loop back into it
        path = []

        # Loop until we get to a dead-end (or find loop)
        while name in bf_dict:
            path.append(name)     # Add the current name to path of names
            name = bf_dict[name]  # Look up best friend as the next person in path

            # If we reach a name we've seen before in this path, we found a loop
            if name in path:
                return True

    # Will only return false if we've found no loops after trying to
    # find one starting from every name in the dictionary.
    return False`

const data = `def add_bignums(bignum1, bignum2):
    """
    Add two "bignumbers" together, and returns a new "bignumber" (list)
    representing the sum.
    """
    result = []
    # Keep track of "carry" when two digits added
    carry = 0
    # Determine which bignumber has more digits
    max_size = max(len(bignum1), len(bignum2))

    # Loop through digits/columns of the bignumbers
    for i in range(max_size):
        # Add digits in current column of bignumbers (including carry)
        sum = safe_get_digit(bignum1, i) + safe_get_digit(bignum2, i) + carry
        # Determine if there is a carry to the next column
        if sum >= 10:
            carry = 1
            sum = sum - 10
        else:
            carry = 0
        # Add new digit to end of list, since bignum is stored in reverse order
        result.append(sum)

    # Check for a carry on the last digit/column we added
    if carry == 1:
        result.append(carry)
    return result


    # Returns digit at given index in bignum if it exists, returns 0 otherwise
def safe_get_digit(bignum, index):
    if index >= len(bignum):
        return 0
    else:
        return bignum[index]`

const full = `import random

def main():
    num_wins = 0
    last_winner = 1
    while num_wins < 2:
        player1 = random.randint(1, 6)
        player2 = random.randint(1, 6)

        print('Player 1 rolled ' + str(player1))
        print('Player 2 rolled ' + str(player2))

        if player1 >= player2 + 2:
            print('Player 1 wins round')
            if last_winner == 1:
                num_wins += 1
            else:
                num_wins = 1
                last_winner = 1
        elif player2 >= player1 + 2:
            print('Player 2 wins round')
            if last_winner == 2:
                num_wins += 1
            else:
                num_wins = 1
                last_winner = 2
        else:
            num_wins = 0
            print('No winner')
    print('Game over, Winner is Player ' + str(last_winner))

if __name__ == '__main__':
    main()`

const classes = `# Number of seats available in ticketing system
NUM_SEATS = 500

class TicketSystem:

    # Constructor
    def __init__(self):
        # Keep track of next ticket (seat number)
        self.next_seat = 1

        # Keep track of returned tickets in a list
        self.returned_tickets = []


    def has_ticket(self):
        # We have tickets to sell if either there are returned tickets
        # or we have not yet sold all the seats (tickets)
        return self.returned_tickets or self.next_seat <= NUM_SEATS


    def next_ticket(self):
        # First check for returned tickets to sell
        if self.returned_tickets:
            # Sell the first returned ticket in the list
            return self.returned_tickets.pop(0)
        elif self.next_seat <= NUM_SEATS:
            # If we still have seats to sell, get the seat number for next ticket
            ticket = self.next_seat
            # Update the next seat to be sold
            self.next_seat += 1
            return ticket
        else:
            # We should only get to this case if there are no returned tickets
            # and no seats left to sell, in which case there are no tickets.
            return -1


    def return_ticket(self, ticket):
        # Add the returned ticket to the list of returned tickets
        self.returned_tickets.append(ticket)`