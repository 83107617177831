import { useContext, useEffect, useRef, useState } from "react"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"


import { FaEdit, FaInfoCircle, FaTerminal, FaPaintBrush, FaTrash, FaBars } from "react-icons/fa"
import { useCourseId } from "../../hooks/router/useUrlParams"
import { AssnContext } from "course/contexts/AssnContext"
import { getAuth } from "firebase/auth"
import { getApp } from "firebase/app"
import { codeCopy } from "./CodeCopy"
import { PracticeProblemsTable } from "./PracticeProblemsTable"
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate"
import styled from "styled-components"
import { Button } from "react-bootstrap"
import { useProSidebar } from "react-pro-sidebar"

const rowStyle ={
  // Set a minimum height for all cells in this column
  // minHeight: '60px', // Adjust this value based on your needs
  // Vertical alignment for the content
  verticalAlign: 'middle',
  alignItems: 'center', // This centers the content vertically
  justifyContent: 'center' // This centers the content horizontally, if desired
}

const creativeColumns = [
  {
    dataField: 'projectLink',
    text: 'Project',
    style: rowStyle,
  }, {
    dataField: 'displayType',
    text: 'Type',
    sort: true,
    style: rowStyle,
  },
  {
    dataField: "actions",
    text: "Actions",
    style: rowStyle,
  }
]

export const CustomProjectsTable = (props) => {
  return (
    <>
      <CodeCreateButtons />
      <Projects />
    </>
  )
}

const collectProjects = () => {
  // TODO: sort by last modified date!
  // TODO: @Ali: i think we need a createdOn date.
  // Note: we have a createdOn date now, but I think its fine without sorting
  const { courseId } = useParams()
  const [projectsList, setProjects] = useState([])
  const [metaData, setMetaData] = useState({})

  const url = new URL(window.location.href)
  const subpath = "/" + url.pathname.slice(1) // removes the leading slash


  const {
    creativeProjects,
    creativeMetaData,
    deleteCreativeProject
  } = useContext(AssnContext)


  useEffect(() => {
    const newMetaData = {}
    const newList = []
    collectProjects(newList, newMetaData)
    setProjects(newList)
    setMetaData(newMetaData)
  }, [creativeProjects])


  const collectProjects = (newList, newMetaData) => {
    for (const creativeId of creativeProjects) {
      const data = creativeMetaData[creativeId]
      if (!data) continue
      const projectKey = `projects/${creativeId}`
      newList.push(projectKey)
      newMetaData[projectKey] = {
        ...data,
        displayType: titleCase(data.type),
        isAssn: false,
        isGroup: false,
        projectId: creativeId,
        projectLink: <Link
          to={`/${courseId}/ide/p/${creativeId}`}
          state={{ from: subpath }}
        >{data.title}</Link>,
        actions: (
          <>
            <Link
              to={`/${courseId}/ide/p/${creativeId}`}
              state={{ from: subpath }}
              className="btn btn-light btn-sm"
              aria-label="Edit"
            >
              <FaEdit /> Edit
            </Link>
            <button className="btn" onClick={() =>
              Swal.fire({
                  title: 'Are you sure?',
                  text: `You will not be able to recover this project!`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, keep it'
                }).then(async(result) => {
                  if (result.isConfirmed) {
                    await deleteCreativeProject(creativeId)
                  }
                })
              }
            >
              <FaTrash />
            </button>
          </>
        )
      }
    }
  }


  return [
    projectsList,
    metaData
  ]
}

const Projects = ({ }) => {
  const [projectsList, metaData] = collectProjects()
  return <>

    <PracticeProblemsTable
      practiceProblemData={Object.entries(metaData).map(([key, value]) => value)}
      columns={creativeColumns}
    />
  </>
}


const CodeCreateButtons = () => {
  return <>
    <div className="d-flex justify-content-between">
      <h3 className="mt-4">Make Your Own <PersonalProjectsInfo /></h3>
      <ToggleAside/>
      </div>
    <CreateContainer>
      <CreateProjectButton projectType="karel" />
      <CreateProjectButton projectType="console" />
      <CreateProjectButton projectType="graphics" />
    </CreateContainer>
  </>
}
const ToggleAside = (props) => {
  const { broken, toggleSidebar } = useProSidebar();
  return (
      <>{broken && (
        <button
          className="btn btn-sm btn-light"
          onClick={() => toggleSidebar()}
        >
          <FaBars />
        </button>
      )}
      </>
  );
};


const CreateProjectButton = ({ projectType }) => {
  const navigate = useHistoryNavigate()
  const courseId = useCourseId()
  const [user] = useAuthState(getAuth(getApp()))
  const creationCopy = codeCopy[projectType]
  const isKarel = projectType === "karel"
  const isConsole = projectType === "console"
  const isGraphics = projectType === "graphics"
  const faIcon = isConsole ? (<FaTerminal style={{ marginTop: 5, marginBottom: 5 }} size={30} className="text-black" />) : (<FaPaintBrush style={{ marginTop: 5, marginBottom: 5 }} size={30} className="text-black" />)
  const projectIcon = isKarel ? (
    <img src={process.env.PUBLIC_URL + "/karel192.png"} style={{ width: '40px'}} />

  ) : faIcon

  const createNewProject = async () => {
    const { value: projectName } = await Swal.fire({
      title: `New ${creationCopy.title} Project!`,
      input: "text",
      inputLabel: "What is the name of your project?",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You can't have an empty project name!"
        }
      },
    })

    if (projectName) {
      creationCopy.createNewProject(projectName, courseId, user.uid, (projectId) => {
        const projectUrl = `/${courseId}/ide/p/${projectId}`
        navigate(projectUrl)
      })
    }

  }

  return (
    <StyledCreateButton className="btn h-100 w-100 btn-outline-primary me-2"
      onClick={createNewProject}
    >
      {projectIcon}
      <span className="ml-2">New {creationCopy.title} Project</span>
    </StyledCreateButton>
  )
}


export const PersonalProjectsInfo = (props) => {
  const onClick = () => {
    Swal.fire({
      title: 'Personal Projects?',
      text: `Make something beautiful! Practice in a blank project. Have fun. Show us what you can do!`,
      icon: 'info'
    })
  }

  const infoStyle = {
    marginTop: "-2px"
  }

  return <button
    onClick={onClick}
    className="btn btn-link"
    style={infoStyle} >
    <FaInfoCircle aria-label="Personal projects information" />
  </button>
}

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

const StyledCreateButton = styled.button`

  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  padding:10px;
  padding-top:5px;
  font-weight: 600;
  color: black;
  border-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4); // Slight shadow

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const CreateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
