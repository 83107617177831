import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln7 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 7:  Big-O, Sorting, and Lambdas (Solution)</h1>
        <hr />
        <h2>Big-O</h2>

        <p>Code Snippet A has a runtime complexity of O(N): The first for loop runs in O(N),
        the second also runs in O(N). This makes O(N) + O(N) = O(2N) = O(N), because we 
        throw away constants</p>

        <p>Code Snippet B has a runtime complexity of O(N^2): The code in the innermost for
        loop is just a sum so that runs in constant time, O(1). The j for loop iterates 
        a total of (N - 5)/2 times which is equal to O(N) since we ignore constants and 
        scalars. The outermost for loop also runs in O(N). In total we get 
        O(1) * O(N) * O(N) = O(N^2)</p>

        <p>Code Snippet C has a runtime complexity of O(1): This loop doesn't depend on N 
        at all, and loops up to a constant 10000000. Therefore runtime is O(1)</p>

        <p>Code Snippet D has a runtime complexity of O(1): This is also constant time. The 
        outermost for loop loops up until 1000000, which is constant. The 3 inner loops 
        all loop until i, which will be always less than 1000000. Therefore total 
        runtime is O(1)</p>

        <p>Code Snippet E has a runtime complexity of O(logN): This runs in logarithmic 
        time, O(logN). You can see this by counting the number of times it takes i to get to
        n. i starts at 1, then jumps to 2, then 4, ..., n. It takes logN steps to get
        to N. This is similar to logarithmic runtime analysis in class that starts from 
        n and drops down to 1.</p>

        <h2>Sorting, Min, and Max with Lambdas</h2>


        <PythonHighlighter code={house} />

        <h2>Grocery Dictionaries, Revisited</h2>
        <PythonHighlighter code={reverse_keys} />
        <PythonHighlighter code={most_used} />
    </>

}

const house = `# A.
>>> sorted(houses, key=lambda tup:tup[1])
[('elm st.', 1, 1200), ('pine st.', 2, 1600), ('main st.', 4, 4000)]
# B.
>>> sorted(houses, key=lambda tup:tup[2], reverse=True)
[('main st.', 4, 4000), ('pine st.', 2, 1600), ('elm st.', 1, 1200)]
# C.
>>> sorted(houses, key=lambda tup:tup[2]/tup[1])
[('pine st.', 2, 1600), ('main st.', 4, 4000), ('elm st.', 1, 1200)]
# D. 
>>> min(houses, key=lambda tup:tup[2]/tup[1])
('pine st.', 2, 1600)
# E.
>>> max(houses, key=lambda tup: tup[1])
('main st.', 4, 4000)`

const reverse_keys = `def reverse_keys(counts):
"""
Takes in a counts dictionary and prints the
keys of the dictionary in reverse alphabetical order.
"""
foods = counts.keys()
foods_reversed = sorted(foods, reverse=True)
for food in foods_reversed:
    print(food)`

const most_used = `
def most_used(counts):
    """
    Takes in a counts dictionary and prints the 5
    highest count foods in the dictionary.
    """
    count_tuples = counts.items()  # [('eggs', 3), ('coconut milk', 6) ...
    tuples_sorted = sorted(count_tuples, key=lambda t: t[1], reverse=True)  # sort tuples by count descending
    top_5 = tuples_sorted[:5]  # slice first 5 tuples
    
    for food, count in top_5:  # unpack tuple with loop
        print(food, count)
    """
    can also do:
    for tup in top_5:
        print(tup[0], tup[1])
    """`

