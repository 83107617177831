import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan5 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 5: File Reading and Dictionaries</h1>
        <hr />

        {/*<p>In the last week, we've introduced dictionaries and file reading. On Wednesday, we're showing
            students how they can nest other structures, like lists and dictionaries, within dictionaries
            to store more complex forms of data. All of this will be pretty new, so we'll spend the majority of section on
            one problem that builds up a nested dictionary using data read in from a file - this looks a lot like HW5!
        </p>
*/}

        <p>We've introduced dictionaries and file reading. We've showed
            students how they can nest other structures, like lists and dictionaries, within dictionaries
            to store more complex forms of data. All of this will be pretty new, so we'll spend the majority of section on
            one problem that builds up a nested dictionary using data read in from a file.
        </p>


        <h3>Section Problem Flow</h3>
        <p>If you're sensing that students are feeling
            confused about dictionaries, you might start with a brief review and do the coding portions as a big
            group. Note that the starter code provides a good amount of scaffolding, so definitely check this out
            even if you're not having students code on their computers in section. We've also provided a problem at the
            end of the handout that focuses on file reading, but this is intended for students to review in their own
            time if they'd like extra practice.
        </p>

        <ol>
            <li><b>5 min: Check in, midterm reminder.</b> Most students will have just taken the midterm, but we
                do have a few students still taking the exam later in the week. Let your section know that you won't be
                answering any questions related to the midterm in section this week, and the exam won't be graded until
                the weekend (we'll probably release grades early next week).</li>
            <li>
                <b>5 min: Dictionary review.</b> Past feedback showed that some students were a bit confused
                jumping right into nested dictionaries, so we've added a warmup that you can do in the Python
                interpreter in your terminal. This is a regular, non-nested dictionary, but it can help students
                see how to access, update, and add key/value pairs in a dictionary.
            </li>
            <li>
                <p><b>40 min: Grocery <s>lists</s> dictionaries.</b> This is the focus of section. Start by walking students
                    through
                    the warmup, either on the board or in the terminal, to get a feel for how the dictionary
                    should
                    be updated with each new tweet we read in. Getting a good intuition for <i>what</i> we're
                    trying
                    to do will be helpful when we get to <i>how</i> to do it. The provided code
                    for <code>add_item()</code> stores the inner nested dictionary in a variable and then
                    updates this dictionary just like a regular non-nested dictionary. It works because the
                    decomposed variable is a reference to the actual inner dictionary, so we can just
                    modify it and the changes persist:
                </p>
                <PythonHighlighter code={decomp_var} />


            </li>
        </ol>

        <h3>Section tips</h3>

        <h4>Shorter Code</h4>
        <p>Students are also welcome to handle nested structures with some slightly denser code. We don't
            suggest
            using this in your section code, but be aware that this is also a valid solution:</p>
        <PythonHighlighter code={shorter_code} />


        <h4>Initialize/Update Paradigm</h4>
        <p>When working with Python dictionaries, we'll need to intialize values for keys that don't yet exist
            in our dictionary. Here are two example approaches to initialize/update for a dictionary:</p>
        <PythonHighlighter code={initialize_update} />

    </>
}

const decomp_var = `# outer dictionary                                    
groceries = {
    'safeway': {'eggs': 1, 'coconut milk': 3}, 
    'costco': {'croissant': 12}
}

# access inner dictionary for specific store
inner = groceries['safeway']

# update inner dictionary
inner['eggs'] += 2

# outer dictionary is now
groceries = {
    'safeway': {'eggs': 3, 'coconut milk': 3}, 
    'costco': {'croissant': 12}
}`

const shorter_code = `# outer dictionary
groceries = {
    'safeway': {'eggs': 1, 'coconut milk': 3},
    'costco': {'croissant': 12}
}
# access and update inner dictionary for specific item
groceries['safeway']['eggs'] += 2`

const initialize_update = `# counts dictionary
inner = {'eggs': 1, 'coconut milk': 3}
key = 'eggs'

# approach 1
if key not in inner:
    inner[key] = 0
inner[key] += 1

# approach 2
if key not in inner:
    inner[key] = 1
else:
    inner[key] += 1`