export const Footer = () => {
    return <div className="row">
		<div className="col-md-12">
			<hr/>

			<div className="footer">
				<p className="pull-left">
					© Stanford 2023 | CS106A has been developed over decades by many talented teachers, including Nick Parlante, Eric Roberts and many more.
				</p>
			</div>
		</div>
	</div>
}