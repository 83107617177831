import { PostReplies } from "./PostReplies"
import { InfiniteScrollReplies } from "./InfiniteScrollReplies" // Import the missing component

export const PostRepliesRoot = ({ postId }) => {
  return <InfiniteScrollReplies
    parent={postId}
    level={1}
    condensed={false}
    postId={postId}
  />
}