import { SpeechBubbleLesson } from "components/duolessons/lessontypes/SpeechBubbles";
import { BlankSlideWithText, CS106AEndOfTraining, DualPhotoSpeechBubble, EndOfTraining, TNSplashCoverpage, TeachNowTrainingOverview } from "./TrainingSlides";
import { TeacherPuzzles } from "./TeacherPuzzles";
import { TeachNowTrainingUploadVideoDmeo } from "./UploadVideoTP";

export const MentorTrainingArm = {
    id: 'teachingTeamTraining',
    startSlide: 'slide14',
    shortTitle: 'Teacher Puzzles',
    title: 'Teach Now Training',
    authors: 'Juliette Woodrow, Ali Malik, and Chris Piech',
    course: 'Code in Place 2024',
    lectureName: 'Teach Now Training',
    lengthMins: 30,
    slides: [
        {
            id: 'slide1',
            title: 'Welcome',
            shortTitle: 'Welcome',
            component: TNSplashCoverpage,
            type: 'splashcoverpage',
            data: {
                text: 'Welcome to the teacher puzzles lesson. This lesson will teach you how to use the teacher puzzles feature to create puzzles for your students.',
            }
        },
        {
          id: 'slide2',
          title: 'Training Learning Goals',
          shortTitle: 'Training Learning Goals',
          component: TeachNowTrainingOverview,
          type: 'TNLearningGoals',
          data: {
              learningGoals: [
                {"key": 0, "value": "Practice identifying common bugs in student code"},
                {"key": 1, "value": "Practice responding to students who need help by providing hints and debugging strategies"},
                {"key": 2, "value": "Get feedback on your teaching moves"},
                {"key": 3, "value": "Pass the evaluation and start hosting 1:1 help sessions!"},
            ],
            imgUrl: '/people/juliette.jpeg'

          }
      },
      {
        id: 'slide3',
        title: 'Training Explanation 1',
        shortTitle: 'Explanation 1',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: 'In this training you are going to work through a few activities. In them, you will practice finding bugs and explore teaching strategies. Think of it as improving your teaching skills without waiting for real student interactions.'

        }
      },
      {
        id: 'slide4',
        title: 'Training Explanation 2',
        shortTitle: 'Explanation 2',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: 'In the first activity, you will be shown buggy code similar to what a student might write. Your task will be to identify all the bugs.'

        }
      },
      {
        id: 'slide5',
        title: 'Prepare by Solving the Challenge',
        shortTitle: 'Prepare',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "To prepare, first solve the same programming challenge to grasp the problem and solution. Click the link below to open the IDE. After solving it, you'll return here to continue.",
            ideLink: '/cip4/ide/a/tpnondecreasing',
            assnId: 'tpnondecreasing',
            afterCompletionText: "You have already solved this problem yourself! You are all set to move onto the next step. If you want to view the problem again, click the link, otherwise click 'Continue' to move on."

        }
      },
        {
          id: 'slide6',
          title: 'Find Some Bugs',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "Let's go find some bugs!",
              imgUrl: '/people/juliette.jpeg'
          }
      },
      {
        id: 'slide7',
        title: 'Bug Identification Puzzle',
        type: 'teacherPuzzle',
        puzzleType: 'bugIdentification',
        component: TeacherPuzzles,
        data: {
            lengthMins: 10,

        }
      },
      {
        id: 'slide8',
        title: 'Overview of Moves Puzzle',
        shortTitle: 'Overview MP',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "In the next activity, you will be shown buggy code similar to what a student might write. Then, you will craft a response to help the student debug their code.",

        }
      },
      {
        id: 'slide9',
        title: 'Prepare for Moves Puzzle',
        shortTitle: 'Moves Puzzle Prep',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "To prepare, first solve the programming challenge to get a sense of this problem and its solution. Click the link below to open the IDE. After solving it, you'll return here to continue.",
            ideLink: '/cip4/ide/a/tpastorheight',
            assnId: 'tpastorheight',
            afterCompletionText: "You have already solved this problem yourself! You are all set to move onto the next step. If you want to view the problem again, click the link, otherwise click 'Continue' to move on."

        }
      },
      {
        id: 'slide10',
        title: 'Overview of Moves Puzzle 2',
        shortTitle: 'Overview MP 2',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "When working with a student you want to ensure that you are welcoming and respectful. You also want to guide them to the answer without directly giving it. You can checkout the below handout for different strategies you might use to help students.",
            handoutLink: 'https://docs.google.com/document/d/1NuHjCgUv3BrnapfqTkR40nPASKbJ2PppYj3xab7Xs44/edit'

        }
      },
      {
        id: 'slide11',
        title: 'Overview of Moves Puzzle 2',
        shortTitle: 'Overview MP 2',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "You will be evaluated on the welcoming tone of your response and your ability to guide without directly giving the answer. We will provide feedback on your response and you will can try many times. Once you pass this set of evaluations, you can start hosting 1:1 help sessions!",

        }
      },
      {
        id: 'slide12',
        title: 'Find Some Bugs',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Let's go practice teaching!",
            imgUrl: '/people/juliette.jpeg'
        }
      },
      {
        id: 'slide13',
        title: 'Moves Puzzle',
        type: 'teacherPuzzle',
        puzzleType: 'moves',
        component: TeacherPuzzles,
        data: {
            lengthMins: 10,

        }
      },
      {
        id: 'slide16',
        title: 'End',
        shortTitle: 'End',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "Congratulations! You have completed this part of the training. You can now start hosting 1:1 help sessions! Click the button below to try one now.",
            teachNowButton: true

        }
      },

    ]
}

const ALI = '/people/ali.jpg'
const JULIETTE = '/people/juliette.jpeg'

export const StudentTrainingArm = {
    id: 'studentTraining',
    startSlide: 'slide14',
    shortTitle: 'Teacher Puzzles',
    title: 'Introduction to One-on-One Teaching',
    authors: 'Juliette Woodrow, Ali Malik, and Chris Piech',
    course: 'Code in Place 2024',
    lectureName: 'Teach Now Training',
    lengthMins: 30,
    slides: [
        {
            id: 'slide1',
            title: 'Welcome',
            shortTitle: 'Welcome',
            component: TNSplashCoverpage,
            type: 'splashcoverpage',
            data: {
                text: 'Welcome to the teacher puzzles lesson. This lesson will teach you how to use the teacher puzzles feature to create puzzles for your students.',
            }
        },
        {
          id: 'slide2',
          title: 'Teaching Helps Others',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "Did you know? Research shows that students can be great teachers to their fellow peers!",
              imgUrl: JULIETTE,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide3',
          title: 'Teaching as Learning',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "Teaching isn't just about sharing what you know—it's also a powerful way to deepen your own understanding.",
              imgUrl: ALI,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide4',
          title: 'Step into the World of One-on-One Guidance',
          component: DualPhotoSpeechBubble,
          type: 'speechbubble',
          data: {
              text: "Hi! We are Juliette and Ali and we are two of the creators of Code in Place. We are so excited that you are interested in one-on-one teaching!",
              leftImg: JULIETTE,
              rightImg: ALI,
              textSide: 'left',
              textAlign: 'left',
          }
        },
        {
          id: 'slide5',
          title: 'Step into the World of One-on-One Guidance',
          component: DualPhotoSpeechBubble,
          type: 'speechbubble',
          data: {
              text: "In this module, you will practice some important skills needed for leading an effective one-on-one help session.",
              leftImg: JULIETTE,
              rightImg: ALI,
              textSide: 'right',
              textAlign: 'right',
          }
        },
        {
          id: 'slide6',
          title: 'But What is One-on-One Teaching?',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "What do we mean when we say 'one-on-one teaching'?",
              imgUrl: JULIETTE,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide7',
          title: 'What is One-on-One Teaching?',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "In a one-on-one session, you guide a peer through an assignment they are working on that you have already completed yourself. You help them understand concepts, debug any issues, and empower them to solve the problem.",
              imgUrl: ALI,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide8',
          title: 'Do not give away the answer',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "When helping your peer, remember not to give away the answer. Instead, guide them to the solution by asking questions and providing hints.",
              imgUrl: ALI,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide9',
          title: 'We will practice',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "In this lesson, we will practice three key aspects of effective one-on-one sessions: (1) identifying bugs in student code, (2) guiding peers towards solutions without giving away the answers, and (3) setting a positive and welcoming tone. ",
              imgUrl: JULIETTE,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide10',
          title: 'Get Started!',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "Let's get started!",
              imgUrl: JULIETTE,
              width: '400px',
              fontSize: '20px'
          }
        },
        {
          id: 'slide11',
          title: 'Training Explanation 2',
          shortTitle: 'Explanation 2',
          component: BlankSlideWithText,
          type: 'blankslidewithtext',
          data: {
              text: "A key part of teaching is looking at a student's work and guiding them on what to do next."

          }
        },
        {
          id: 'slide12',
          title: 'Training Explanation 2',
          shortTitle: 'Explanation 2',
          component: BlankSlideWithText,
          type: 'blankslidewithtext',
          data: {
              text: "In the first activity, you will see the code from a student working on their Khansole Academy assignment. Your task is to understand their code and give encouraging advice on what they should do next."
          }
        },
      {
        id: 'slide13',
        title: 'Prepare by Solving the Challenge',
        shortTitle: 'Prepare',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "As a first step, solve the problem yourself if you haven't already! Click the link below to open the IDE. After solving it, you'll return here to continue.",
            ideLink: '/cip4/ide/a/khansole',
            assnId: 'khansole',
            afterCompletionText: "You have already solved this problem yourself! You are all set to move onto the next step. If you want to view the problem again, click the link, otherwise click 'Continue' to move on."
        }
      },
      {
          id: 'slide14',
          title: 'Find Some Bugs',
          component: SpeechBubbleLesson,
          type: 'speechbubble',
          data: {
              text: "Before you are ready to practice teaching, we have a few tips to share!",
              imgUrl: ALI,
              textAlign: 'left',
              width: '400px',
              fontSize: '20px'
          }
      },
      {
        id: 'slide15',
        title: 'Find Some Bugs',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "An important part of teaching is guiding the student to find the answer on their own. Don't give away the answer. Let them do the thinking.",
            imgUrl: JULIETTE,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide16',
        title: 'Find Some Bugs',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "A friendly reminder to always be encouraging and respectful. Start with some positive feedback or mention something they did well. Then, provide advice on what to work on next.",
            imgUrl: ALI,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide17',
        title: 'Find Some Bugs',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Now that you have some tips, let's go practice teaching!",
            imgUrl: JULIETTE,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide18',
        title: 'Bug Identification Puzzle',
        type: 'teacherPuzzle',
        puzzleType: 'student',
        component: TeacherPuzzles,
        data: {}
      },
      {
        id: 'slide19',
        title: 'Next avtivity',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Great work! Now let's move on to the next activity.",
            imgUrl: ALI,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
    },
      {
        id: 'slide20',
        title: 'Upload a Video',
        shortTitle: 'Upload a Video',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "In the next section, you will upload a video where you practice introducing yourself and setting the tone for a one-on-one help session.",
        },
      },
      {
        id: 'slide21',
        title: 'Upload a Video',
        shortTitle: 'Upload a Video',
        component: TeachNowTrainingUploadVideoDmeo,
        type: 'blankslidewithtext',
        data: {
            text: ""
        },
      },
      {
        id: 'slide22',
        title: 'End of Lesson',
        shortTitle: 'End of Lesson',
        component: EndOfTraining,
        type: 'speechbubble',
        removeContinueButton: true,
        continueButtonText: 'Mark Lesson Complete',
        isLastSlide: true,
        data: {
          text: "",
        }
      },
    ]
}


export const CS106ATrainingArm = {
  id: 'cs106aStudentTraining',
  startSlide: 'slide14',
  shortTitle: 'Teacher Puzzles',
  title: 'Introduction to One-on-One Teaching',
  authors: 'Juliette Woodrow, Ali Malik, and Chris Piech',
  course: 'CS106A',
  lectureName: 'TeachNow Training',
  lengthMins: 30,
  slides: [
      {
          id: 'slide1',
          title: 'Welcome',
          shortTitle: 'Welcome',
          component: TNSplashCoverpage,
          type: 'splashcoverpage',
          data: {
              text: ''
          }
      },
      {
        id: 'slide2',
        title: 'Teaching Helps Others',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Did you know? Research shows that students can be great teachers to their fellow peers!",
            imgUrl: JULIETTE,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide3',
        title: 'Teaching as Learning',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Teaching isn't just about sharing what you know—it's also a powerful way to deepen your own understanding.",
            imgUrl: ALI,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide4',
        title: 'Step into the World of One-on-One Guidance',
        component: DualPhotoSpeechBubble,
        type: 'speechbubble',
        data: {
            text: "Hi! We are Juliette and Ali, two former CS106 instructors, and we built this training and tool for you to teach others! We are so excited that you are interested in one-on-one teaching!",
            leftImg: JULIETTE,
            rightImg: ALI,
            textSide: 'left',
            textAlign: 'left',
        }
      },
      {
        id: 'slide5',
        title: 'Step into the World of One-on-One Guidance',
        component: DualPhotoSpeechBubble,
        type: 'speechbubble',
        data: {
            text: "In this module, you will practice some important skills needed for leading an effective one-on-one help session.",
            leftImg: JULIETTE,
            rightImg: ALI,
            textSide: 'right',
            textAlign: 'right',
        }
      },
      {
        id: 'slide6',
        title: 'But What is One-on-One Teaching?',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "What do we mean when we say 'one-on-one teaching'?",
            imgUrl: JULIETTE,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide7',
        title: 'What is One-on-One Teaching?',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "In a one-on-one session, you guide a peer through an assignment they are working on that you have already completed yourself. You help them understand concepts, debug any issues, and empower them to solve the problem.",
            imgUrl: ALI,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide8',
        title: 'Do not give away the answer',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "When helping your peer, remember not to give away the answer. Instead, guide them to the solution by asking questions and providing hints.",
            imgUrl: ALI,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide9',
        title: 'We will practice',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "In this lesson, we will practice three key aspects of effective one-on-one sessions: (1) identifying bugs in student code, (2) guiding peers towards solutions without giving away the answers, and (3) setting a positive and welcoming tone. ",
            imgUrl: JULIETTE,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide10',
        title: 'Get Started!',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Let's get started!",
            imgUrl: JULIETTE,
            width: '400px',
            fontSize: '20px'
        }
      },
      {
        id: 'slide11',
        title: 'Training Explanation 2',
        shortTitle: 'Explanation 2',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "A key part of teaching is looking at a student's work and guiding them on what to do next."

        }
      },
      {
        id: 'slide12',
        title: 'Training Explanation 2',
        shortTitle: 'Explanation 2',
        component: BlankSlideWithText,
        type: 'blankslidewithtext',
        data: {
            text: "In the first activity, you will see the code from a student working on their Khansole Academy assignment. In this version, they were only supposed to ask the user to solve one addition problem. Your task is to understand their code and give encouraging advice on what they should do next."
        }
      },
    {
      id: 'slide13',
      title: 'Prepare by Solving the Challenge',
      shortTitle: 'Prepare',
      component: BlankSlideWithText,
      type: 'blankslidewithtext',
      data: {
          text: "As a first step, solve the problem yourself if you haven't already! Click the link below to open the IDE. After solving it, you'll return here to continue.",
          ideLink: 'https://ide.stanford.edu/cs106a/a/khansole',
          assnId: 'khansole',
          afterCompletionText: "You have already solved this problem yourself! You are all set to move onto the next step. If you want to view the problem again, click the link, otherwise click 'Continue' to move on."
      }
    },
    {
        id: 'slide14',
        title: 'Find Some Bugs',
        component: SpeechBubbleLesson,
        type: 'speechbubble',
        data: {
            text: "Before you are ready to practice teaching, we have a few tips to share!",
            imgUrl: ALI,
            textAlign: 'left',
            width: '400px',
            fontSize: '20px'
        }
    },
    {
      id: 'slide15',
      title: 'Find Some Bugs',
      component: SpeechBubbleLesson,
      type: 'speechbubble',
      data: {
          text: "An important part of teaching is guiding the student to find the answer on their own. Don't give away the answer. Let them do the thinking.",
          imgUrl: JULIETTE,
          textAlign: 'left',
          width: '400px',
          fontSize: '20px'
      }
    },
    {
      id: 'slide16',
      title: 'Find Some Bugs',
      component: SpeechBubbleLesson,
      type: 'speechbubble',
      data: {
          text: "A friendly reminder to always be encouraging and respectful. Start with some positive feedback or mention something they did well. Then, provide advice on what to work on next.",
          imgUrl: ALI,
          textAlign: 'left',
          width: '400px',
          fontSize: '20px'
      }
    },
    {
      id: 'slide17',
      title: 'Find Some Bugs',
      component: SpeechBubbleLesson,
      type: 'speechbubble',
      data: {
          text: "Now that you have some tips, let's go practice teaching!",
          imgUrl: JULIETTE,
          textAlign: 'left',
          width: '400px',
          fontSize: '20px'
      }
    },
    {
      id: 'slide18',
      title: 'Bug Identification Puzzle',
      type: 'teacherPuzzle',
      puzzleType: 'student',
      component: TeacherPuzzles,
      data: {}
    },
    {
      id: 'slide19',
      title: 'Next avtivity',
      component: SpeechBubbleLesson,
      type: 'speechbubble',
      data: {
          text: "Great work! Now let's move on to the next activity.",
          imgUrl: ALI,
          textAlign: 'left',
          width: '400px',
          fontSize: '20px'
      }
  },
    {
      id: 'slide20',
      title: 'Upload a Video',
      shortTitle: 'Upload a Video',
      component: BlankSlideWithText,
      type: 'blankslidewithtext',
      data: {
          text: "In the next section, you will upload a video where you practice introducing yourself and setting the tone for a one-on-one help session.",
      },
    },
    {
      id: 'slide21',
      title: 'Upload a Video',
      shortTitle: 'Upload a Video',
      component: TeachNowTrainingUploadVideoDmeo,
      type: 'blankslidewithtext',
      data: {
          text: ""
      },
    },
    {
      id: 'slide22',
      title: 'End of Lesson',
      shortTitle: 'End of Lesson',
      component: CS106AEndOfTraining,
      type: 'speechbubble',
      removeContinueButton: true,
      // continueButtonText: 'Mark Lesson Complete',
      // isLastSlide: true,
      data: {
        text: "",
      }
    },
  ]
}




// {
//   id: 'slide17',
//   title: 'Overview of Moves Puzzle 2',
//   shortTitle: 'Overview MP 2',
//   component: BlankSlideWithText,
//   type: 'blankslidewithtext',
//   data: {
//       text: "When working with a student you want to ensure that you are welcoming and respectful. You also want to guide them to the answer without directly giving it. The next few slides have some examples for things you might want to say to a student."

//   }
// },
// {
//   id: 'slide18',
//   title: 'Overview of Moves Puzzle 2',
//   shortTitle: 'Overview MP 2',
//   component: BlankSlideWithText,
//   type: 'blankslidewithtext',
//   data: {
//       text: "You might want to start by asking the student to walk you through their thought process. This helps them articulate their approach and can reveal misunderstandings."

//   }
// },
// {
//   id: 'slide19',
//   title: 'Overview of Moves Puzzle 2',
//   shortTitle: 'Overview MP 2',
//   component: BlankSlideWithText,
//   type: 'blankslidewithtext',
//   data: {
//       text: "You can encourage deeper insight by asking questions like, 'What alternative solutions could we consider?' or 'Can you explain why this part of your code is necessary?' This prompts them to think critically about their choices."

//   }
// },
// {
//   id: 'slide20',
//   title: 'Overview of Moves Puzzle 2',
//   shortTitle: 'Overview MP 2',
//   component: BlankSlideWithText,
//   type: 'blankslidewithtext',
//   data: {
//       text: "Or you might help students make connections by comparing their current problem to similar examples they've seen in the course. Ask, 'How is this problem similar to or different from that example?' This can help them apply learned concepts to new situations.",

//   }
// },