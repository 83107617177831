import React, { useState, useEffect, useContext, useRef } from "react"; // every page needs to import react

// if you want to interact with the database
import "firebase/compat/firestore";
import "react-tabs/style/react-tabs.css";

import { GraphicsCanvas } from "./RightPane/GraphicsCanvas";
import { XTermTerminal } from "./TerminalPane/Terminal/XTermTerminal";
import AssnPrompt from "./AssnPrompt/AssnPrompt";
import {
  FaFlag,
  FaLaptopCode,
  FaPlay,
  FaFolder,
  FaPlus,
  FaRunning,
  FaWalking,
} from "react-icons/fa";

import { FileEditor } from "./Editor/Editor";
import useMediaQuery, { Breakpoints } from "../utils/useMediaQuery";
import { getPromptPath, getSolnPath } from "../utils/general";
import FileTree from "./Files/FileTree";
import { IDEContext } from "./contexts/IDEContext";
import { checkIsProjectKarel } from "./utils/general";
import { Canvas } from "./RightPane/Canvas";
import { LeftColTitle } from "./LeftCol";
import { DocsSplash } from "./LeftCols/DocsSplash";
import styled from "styled-components";
import { SettingsView } from "./LeftCols/Settings";
import { TestList } from "./RightPane/TestView";
import { isIDE } from "components/duolessons/lessonchats/ChatConstants";
import { IDEChat } from "components/duolessons/lessonchats/IDEChat";

const MobileOutput = () => {

  const {
    terminalRef,
    projectData,
    assnData,
    runErrorOccurred,
    setRunErrorOccurred,
    runSuccessOccurred,
    setRunSuccessOccurred,
    karelSleepTime,
    setKarelSleepTime,
  } = useContext(IDEContext);
  const isKarel = checkIsProjectKarel(projectData,assnData);
  const isConsole = projectData?.type === "console" || assnData?.metaData?.type === "console" 
  const [selectedTab, setSelectedTab] = useState(isConsole ? "Console": "Visual");


  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.refreshTerm();
    }
  }, []);

  useEffect(() => {
    if (runErrorOccurred) {
      setSelectedTab("Console");
      setRunErrorOccurred(false);
    }
  }, [runErrorOccurred]);

  useEffect(() => {
    if (runSuccessOccurred) {
      if(isConsole) {
        setSelectedTab("Console");
      } else {
        setSelectedTab("Visual");
      }
      setRunSuccessOccurred(false);
    }
  }, [runSuccessOccurred]);

  return  <>
      <div className="d-flex flex-row">
        <span
          style={{
            background: selectedTab == "Visual" ? "" : "#eeeeee",
          }}
          className={`flex-grow-1 h-1 p-3 btn`}
          onClick={() => {
            setSelectedTab("Visual");
          }}
        >
          {isConsole ? "Unit Tests" : isKarel ? "Karel World" : "Canvas"}
        </span>
        <span
          style={{
            background: selectedTab == "Console" ? "" : "#eeeeee",
          }}
          className={`flex-grow-1 h-1 p-3 btn`}
          onClick={() => {
            setSelectedTab("Console");
          }}
        >
          Console
        </span>
      </div>
      <div
        className={`w-ful ${selectedTab == "Visual" && !isKarel
          ? "d-block"
          : selectedTab == "Visual" && isKarel
            ? "d-flex flex-column gap-4 justify-content-center align-items-center pt-4"
            : "d-none"
          }`}
      >
        {isConsole ? (
          <>
            <TestList />
          </>
        ) : isKarel ? (
          <>
            <Canvas />
            {isKarel ? (
              <div className="d-flex align-items-center content-center gap-2 ml-2 mt-3 w-full">
                <FaWalking />
                <input
                  id="stepslider"
                  min={0}
                  max={1}
                  step={0.1}
                  type="range"
                  onChange={(e) => {
                    setKarelSleepTime(1 - e.target.value);
                  }}
                  value={1 - karelSleepTime}
                ></input>

                <FaRunning />
              </div>
            ) : null}
          </>
        ) : (
          <GraphicsCanvas />
        )}
      </div>

      <div className={`${selectedTab == "Console" ? "d-block" : "d-none"}`}>
      {!isKarel ? 
      <div style={{
        padding: '7px 0',
        background: '#1b1c1a',
      }}>
      </div>
      : null}
        <XTermTerminal />
      </div>
    </>

};

const MoreItem = ({ title, tab, onClick }) => {
  return (
    <div
      style={{
        padding: "16px",
        background: "#eeeeee",
        borderRadius: "12px",
        width: "47%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        onClick(tab);
      }}
    >
      {title}
    </div>
  );
};
const MoreScreen = ({ onClick }) => {

  const { chatType } = useContext(IDEContext);

  return (
    <>
      <LeftColTitle title="More Actions" />
      <div className="p-3 d-flex flex-wrap flex-row gap-3">
        <MoreItem tab="Files" title="Files" onClick={onClick} />
        <MoreItem tab="FAQ" title="FAQs" onClick={onClick} />
        <MoreItem tab="Docs" title="Docs" onClick={onClick} />
        <MoreItem tab="Settings" title="Settings" onClick={onClick} />
        { isIDE(chatType) && <MoreItem tab="Chat" title="Chat" onClick={onClick} />}
      </div>
    </>
  );
};
export const MobileIDEView = ({
  onFileCodeUpdate,
  currFile,
  selectedMobileTab,
  setSelectedMobileTab,
  revertToStarterFn,
  projectData,
  assnData,
  onChangeFile,
  fileStructure,
  setFileStructure,
}) => {
  const workedExample =  assnData?.metaData?.workedExample
  const promptPath = getPromptPath(projectData);
  const solnPath = getSolnPath(projectData)
  const hasAssn = promptPath != undefined;
  const bottomBarRef = useRef(null);
  return (
    <ContentOuter>
      {selectedMobileTab == "Problem" ? (
        <AssnPrompt
          revertToStarterFn={revertToStarterFn}
          promptPath={promptPath}
          solnPath={solnPath}
          assnTitle={projectData.title}
          workedExample={workedExample}
          bottomBarRef={bottomBarRef}
        />
      ) : selectedMobileTab === "Code" ? (
        <FileEditor
          currFile={currFile}
          onFileCodeUpdate={onFileCodeUpdate}
          projectId={projectData.uid}
        />
      ) : selectedMobileTab === "More" ? (
        <MoreScreen
          onClick={(tab) => {
            setSelectedMobileTab(tab);
          }}
        />
      ) : selectedMobileTab === "Files" ? (
        <FileTree
          projectId={projectData.uid}
          fileStructure={fileStructure}
          setFileStructure={setFileStructure}
          onChangeFile={onChangeFile}
          currFile={currFile}
          isMobile={true}
          switchToCodeTab={() => {
            setSelectedMobileTab("Code");
          }}
          isCreative={undefined}
        />
      ) : selectedMobileTab === "FAQ" ? (
        <LeftColTitle title="Coming Soon!" />
      ) : selectedMobileTab === "Docs" ? (
        <DocsSplash bottomBarRef={bottomBarRef}/>
      ) : selectedMobileTab === "Settings" ? (
        <SettingsView/>

      ) : selectedMobileTab === "Chat" ? (
        <IDEChat bottomBarRef={bottomBarRef} />
      ) : null}
      <div
        className={`${selectedMobileTab == "Output" ? "d-block" : "d-none"}`}
      >
        <MobileOutput />
      </div>
      <BottomNavBar
        hasAssn={hasAssn}
        selectedMobileTab={selectedMobileTab}
        setSelectedMobileTab={setSelectedMobileTab}
        bottomBarRef={bottomBarRef}
      />
    </ContentOuter>
  );
};

const BottomNavBarItem = ({ selected, text, icon, onClick }) => {
  const isMobile = useMediaQuery(Breakpoints.XS);

  return (
    <div
      onClick={onClick}
      className={`d-flex flex-column justify-content-center align-items-center my-2 btn`}
      style={{
        opacity: selected ? 1 : 0.4,
        background: selected ? "#1b2126" : "",
        borderRadius: "20px",
      }}
    >
      {icon}
      <span
        className={`${selected ? "fw-bold" : ""} text-white mt-1`}
        style={{
          fontSize: isMobile ? "14px" : "10px",
        }}
      >
        {text}
      </span>
    </div>
  );
};
const BottomNavBar = ({ selectedMobileTab, setSelectedMobileTab, hasAssn, bottomBarRef }) => {
  return (
    <nav
      className="navbar fixed-bottom navbar-light d-flex bg-dark flex-row align-items-center flex-nowrap"
      style={{
        height: "auto",
      }}
      ref={bottomBarRef}

    >
      {hasAssn ? (
        <BottomNavBarItem
          selected={selectedMobileTab === "Problem"}
          text="Problem"
          icon={<FaFlag color="white" size={20} />}
          onClick={() => {
            setSelectedMobileTab("Problem");
          }}
        />
      ) : null}
      <BottomNavBarItem
        text="Code"
        icon={<FaLaptopCode color="white" size={20} />}
        selected={selectedMobileTab === "Code"}
        onClick={() => {
          setSelectedMobileTab("Code");
        }}
      />
      <BottomNavBarItem
        text="Output"
        icon={<FaPlay color="white" size={20} />}
        selected={selectedMobileTab === "Output"}
        onClick={() => {
          setSelectedMobileTab("Output");
        }}
      />
      <BottomNavBarItem
        text="More"
        icon={<FaPlus color="white" size={20} />}
        selected={selectedMobileTab === "More"}
        onClick={() => {
          setSelectedMobileTab("More");
        }}
      />
    </nav>
  );
};


const ContentOuter = styled.div`
  padding-top: 55px;
  flex-grow: 1;
`