import { Avatar, Button, Popconfirm, Skeleton, Spin, Tooltip } from "antd";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { ForumContext } from "course/forum/ForumContext";
import { getDocFromServer } from "firebase/firestore";
import { UserRole } from "firebase/models";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { firebaseStringToRole, roleToForumString } from "contexts/ProfileUtil";
import styled from "styled-components";

export function UserChip(props: {
  showAvatar: boolean;
  uid: string;
  name: string;
}) {
  const { uid } = props;
  const { userData, loading: userIsLoading } = useContext(ProfileContext);

  if (Gate.hasStaffRole(userData)) {
    return (
      
        <UserChipButton>
          <UserChipContents {...props} />
        </UserChipButton>
    );
  }
  return <UserChipContents {...props} />;
}

export function UserChipContents(props: {
  showAvatar: boolean;
  uid: string;
  name: string;
}) {
  const { uid, name, showAvatar } = props;
  const dataFetcher = useForumDataFetcher();

  const courseId = useCourseId();

  const userQuery = useQuery(["userData", uid], () =>
    getDocFromServer(dataFetcher.get_user_data_ref(uid))
  );

  const userRoleQuery = useQuery(["userRole", uid], () =>
    getDocFromServer(dataFetcher.get_user_role_ref(uid, courseId))
  );

  if (userQuery.isLoading || userRoleQuery.isLoading) {
    return (
      <UserDetails>
        <Skeleton paragraph={false} />
      </UserDetails>
    );
  }

  const userData = userQuery?.data?.data() ?? {
    displayName: "A",
  };

  let courseRole = null;
  if(userRoleQuery.data) {
    const roleData = userRoleQuery.data.data();
    courseRole = roleData?.role;
    
  }

  const displayName = userData?.displayName ?? "Anonymous";

  const role = getRole(courseRole) as UserRole;

  const safePhotoURL = userData["photoURL"] ?? "";
  return (
    <UserDetails>
      {showAvatar && <UserIcon photoURL={safePhotoURL} name={displayName} />}
      <DisplayName to={`/${courseId}/user/${uid}`} style={{fontWeight:'normal'}}>{displayName}</DisplayName>
      <RoleBadge role={role} />
    </UserDetails>
  );
}

export const RoleBadge = (props: { role: UserRole }) => {
  const { role } = props;

  if (role === "student" || role === "experiencedstudent") {
    return <></>;
  }


  const colorRoleMap = {
    instructor: "primary",
    admin: "primary",
    ta: "secondary",
    sl: "secondary",
  };

  const roleLabel = roleToForumString(firebaseStringToRole(role));
  const color = colorRoleMap[role];

  return <span className="badge" style={{
    color: "white",
    backgroundColor:"#5541cd"
  }}>{roleLabel}</span>;
};

export const UserIcon = (props: { photoURL: string; name: string }) => {
  // draw a salmon circle with the letter C in the middle
  let initial = "";
  if (props.name) {
    if (props.name.length > 0) {
      initial = props.name[0];
    }
  }

  return (
    <div style={userIconStyle}>
      {props?.photoURL ? <Avatar src={props.photoURL} /> : initial}
    </div>
  );
};

const getRole = (courseRole: string) => {
  if (!courseRole) {
    return "student";
  }

  return courseRole;
};

const userIconStyle = {
  width: "40px",
  height: "40px",
  color: "white",
  borderRadius: "50%",
  backgroundColor: "salmon",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "24px",
};

const UserChipButton = styled.div`
  padding:0px;
`

const UserDetails = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

`

const DisplayName = styled(Link)`
  color: black;
`