import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan3 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 3: Parameters, Returns, and Graphics</h1>
        <hr />

        <p>Students have learned a lot of new things since you've last seen them - we've covered
            lists, graphics, and animation! Even though parameters and returns aren't so new anymore,
            we're hoping to use section as a chance to solidify this concept as students work through Assignment 3.
        </p>

        <h3>Section flow</h3>
        <ol>
            <li><b>5 min: Check in.</b> You might spend a few minutes at the begining of every section
                checking in with your sectionees about how the course and life in general is going. You could do a
                short icebreaker, ask if students have any questions... we just want everyone to get comfortable and engaged
                before diving into the section content.</li>
            <li><b>25 min: In Range or FizzBuzz.</b> Both of these problems give students practice writing helper functions
                that take in parameters and return something. This will be extremely useful on Breakout, so take your time here
                and use diagrams to show students how data is being passed between functions. FizzBuzz is a personal favorite -
                if you choose this one, maybe play a round of this game with your section before trying to solve the problem.</li>
            <li><b>20 min: Mystery Square.</b> Students saw graphics in Monday's lecture (Lecture 10) and animation in
                Wednesday's lecture (Lecture 11), so this may be their first time actually using some of these functions.
                While Breakout will be more involved, this problem is excellent practice for Assignment 3, as students will
                be working with constants, making their own decomposition decisions, and writing an animation loop! We've got
                a <a href="https://cs106a.stanford.edu/graphics">Graphics Reference</a> and Animation Reference (coming soon)
                in the 'Course' dropdown above that you should read through to familiarize yourself with some of these functions.</li>
        </ol>

        <h3>Section tips</h3>

        <p>If you've SLed for CS106A in previous quarters, you might have seen us pay attention to off-by-one errors
            and adding -1 when drawing. We're not doing that here - students are just using the constants we provide and
            doing calculations without worrying much about the fact that pixels on the canvas are 0-indexed.</p>

        <p>The In Range problem has doctests provided in the starter code, and if you choose this problem,
            it's worth talking a bit about how these work and why it's a good practice to test helper functions as you go
            (rather than running <code>main</code>). I'd also briefly cover doctest syntax to show students how they can
            write their own.</p>

        <p>As you know, we don't plan on getting through every section problem on the handout in 50 minutes. You might
            remind your section of this, and let them know that we release section solutions over the weekend so they can
            work through the remaining problems in their own time.
        </p>
    </>
}