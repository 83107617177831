import { CodeBlock } from "components/richTextEditor/DraftEditor/CodeBlock/CodeBlockUnthrow"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln1 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 1: Karel the Robot (Solution)</h1>
        <hr />

        <h3>1. Hospital Karel</h3>
        <PythonHighlighter code={hospital} />

        <h3>2. Obstacles</h3>
        <PythonHighlighter code={democracy} />

    </>
}

const hospital = `"""
Program: Hospital Karel
Karel traverses 1st street from west to east, building hospitals
wherever it encounters a beeper.
"""


def main():
    while front_is_clear():
        if beepers_present():
            build_hospital()
        if front_is_clear():
            move()


def build_hospital():
    """
    Karel picks up supplies and builds a hospital.
    Pre-condition: Karel is on a beeper, representing a
        pile of supplies. Karel is facing east.
    Post-condition: Karel is standing at the bottom
        of the last column of the hospital, facing east.
    """
    # pick up supplies
    pick_beeper()
    back_up()
    # make hospital
    do_one_column()
    move()
    turn_left()
    move()
    turn_right()
    do_one_column()
    move()
    do_one_column()


def do_one_column():
    """
    Karel builds a single column of a hospital.
    Pre-condition: Karel is facing east at the bottom
        of where we want to build a column.
    Post-condition: Karel is facing east at the bottom
        of the column it just built.
    """
    turn_left()
    put_three_beepers()
    return_to_base()
    turn_left()


def put_three_beepers():
    """
    Karel places three beepers in a row.
    Pre-condition: Karel is on the corner where we want
        to place the first beeper.
    Post-condition: Karel is on the corner where it
        placed the third beeper in a row.
    """
    put_beeper()
    move()
    put_beeper()
    move()
    put_beeper()


def return_to_base():
    """
    Karel turns around and goes to the wall.
    Pre-condition: Karel is at the end of the column
        it just built, facing north.
    Post-condition: Karel has returned to 1st Street,
        below the column is just built, facing south.
    """
    turn_around()
    move_to_wall()


def move_to_wall():
    while front_is_clear():
        move()


def back_up():
    turn_around()
    move()
    turn_around()


def turn_right():
    turn_left()
    turn_left()
    turn_left()


def turn_around():
    turn_left()
    turn_left()`

const democracy = `"""
Program: Democrary Karel
Karel traverses a ballot from left to right, removing the 
"hanging chads".
"""


def main():
    while front_is_clear():
        process_column()
        move()
    # handles fencepost problem for last column
    process_column()


def process_column():
    """
    Clears chad from the current column, if any.
    Pre-condition: Karel is standing in the center of a column, 
        facing east. 
    Post-condition: Karel is back in the same place/orientation
        and chad (if any) has been cleared.
    """
    if no_beepers_present():
        remove_chad()


def remove_chad():
    """
    Clears chad from the current column.
    Pre-condition: Karel is standing in the center of a 
        column to be cleared, facing east.
    Postcondition: Karel is standing in the same place/
        orientation and the column has been cleared.
    """
    # clean upper corner
    turn_left()
    remove_beepers_front()
    turn_around()
    # clean lower corner
    remove_beepers_front()
    turn_left()


def remove_beepers_front():
    """
    Clears chad from whichever corner Karel is facing.
    Pre-condition: Karel is facing a corner to be cleared of 
        the chad (beepers).
    Post-condition: Karel is in the same location/orientation,
        but the chad has been cleared from the corner Karel 
        is facing.
    """
    move()
    while beepers_present():
        pick_beeper()
    back_up()


def back_up():
    turn_around()
    move()
    turn_around()


def turn_around():
    turn_left()
    turn_left()`