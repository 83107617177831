import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const Citations = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Retractions and Retroactive Citations</h1>
    <hr />

    <p>At the end of the quarter, CS106A staff runs analytic software over all the homework submissions to find
      sections of code that are copied from somewhere. We then look at the code more carefully to figure out what
      happened and who is involved. We have not done this yet for this quarter, so there's a chance for anyone who
      made a mistake earlier in the quarter to set the record straight before we find the honor code violations ourselves.</p>

    <p>Essentially, our honor code policy says that you can exchange ideas with other students and the staff, but
      then you should take those insights and use them to write your own code. If you discuss the homework with
      other students or brainstorm in a big group during office hours, then incorporate those ideas into your code,
      that's completely fine. That is within the spirit of exchanging ideas and writing code.</p>

    <p>In contrast, we'll say a type-1 honor code violation looks like someone had access to someone else's solution
      or a solution generated by AI tools like ChatGPT and used it as the basis of their solution. A type-2 honor code
      violation looks like two students collaborating
      on one copy of the code and then both submitting a version of that solution.</p>

    <p><b>If you are concerned that you have a type-1 or type-2 case</b>: fill out
      the <a href="https://docs.google.com/forms/d/e/1FAIpQLSeqJVCNtInWluHs5FM4jTAaghEgkqNQoezai96FoT13Y5b32w/viewform?usp=sf_link">retroactive citation form</a> and explain
      what happened and where this code came from. You do not need to name names. The contents of this form will only be
      seen by Mehran, Chris, and Elyse. In order for this to protect you from an honor code case, you need to be <b>truthful
        and specific</b> about what happened. Mehran, Chris, and Elyse will look at these responses and work out a fair assignment
      score, potentially retracting the assignment entirely.</p>
  </>
}
