import { Modal } from "react-bootstrap";
import { PostTags } from "../PostTags/PostTags";
import Gate from "../../../../contexts/Gate";
import { Switch } from "antd";
import { FiltersContext } from "course/forum/FiltersContext";
import { useContext } from "react";


export const FilterModal = ({  isSlOrAbove }) => {
  const { setShowFilters, showFilters, tagFilters, setTagFilters, hideResolved, toggleHideResolved, flaggedOnly, toggleFlaggedOnly } = useContext(FiltersContext);


    return (
        <Modal
        show={showFilters}
        onHide={() => setShowFilters(false)}
        contentClassName="searchContent"
      >
        <h1>Forum Filters</h1>
        { isSlOrAbove && (
          <div className="filterResolved">
            <strong>Hide Resolved Posts</strong>
            <Switch onClick={toggleHideResolved} checked={hideResolved} />
            <strong>See Only Flagged Posts</strong>
            <Switch onClick={toggleFlaggedOnly} checked={flaggedOnly} />
          </div>
        )}
        <PostTags 
          currentTags={tagFilters}
          editable={true}
          changeHandler={setTagFilters}
          placeholder="Filter by tags"
        />
      </Modal>
    )


}