import { courseInfo } from "cs106a/courseInfo"
import { FaBook, FaCheck, FaComment, FaHandsHelping, FaPause, FaPython, FaTerminal, FaThLarge, FaVideo } from "react-icons/fa"
import { Link } from "react-router-dom"

export const Resources = () => {
  return <>
    <p className="subtleHeading">Resources</p>

    <FaBook className="sidebaricon" />
    <a target="_blank" href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">Karel Reader</a>
    <br />
    <FaBook className="sidebaricon" />
        <a target="_blank" href="https://docs.google.com/spreadsheets/d/1tucnpkyZgEmHxOJOLsQCUcbvmM1tbN_H/edit#gid=1760974437">Schedule</a>
        <br />


    {/* <FaBook className="sidebaricon" />
    <a target="_blank" href="https://cs.stanford.edu/people/nick/py/">Python Guide</a>
    <br />

    <FaThLarge className="sidebaricon" />
    <Link to="/schedule">Schedule</Link>
    <br />
  */}
    

    <ForumLink />

    <CodeLink />
    <ReplLink/>

    <LairLink />
    <PaperlessLink/>

    <VideoLink />


  </>
}

export const ResourcesMobile = () => {
  return <>
    <p className="subtleHeading">Resources</p>
    <div className="row">
      <div className="col-6">

        <FaBook className="sidebaricon" />
        <a target="_blank" href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">Karel Reader</a>
        <br />
        <FaBook className="sidebaricon" />
        <a target="_blank" href="https://docs.google.com/spreadsheets/d/1tucnpkyZgEmHxOJOLsQCUcbvmM1tbN_H/edit#gid=1760974437">Karel Reader</a>
        <br />

        {/* <FaBook className="sidebaricon" />
        <a target="_blank" href="https://cs.stanford.edu/people/nick/py/">Python Guide</a>
        <br /> 

        <FaThLarge className="sidebaricon" />
        <Link to="/schedule">Schedule</Link>
        <br />*/}

        <VideoLink />
      </div>
      <div className="col-6">

        <ForumLink />

        <CodeLink />
        <PaperlessLink />

         <LairLink />


        <ReplLink/>

      </div>


    </div>
  </>
}

const ForumLink = () => {
  return <>
    <FaComment className="sidebaricon" />
    <Link target="_blank" to="https://edstem.org/us/courses/60442/discussion">Forum</Link>
    <br />
  </>
}

const CodeLink = () => {
  return <>
    <FaPython className="sidebaricon" />
    <Link target="_blank" to="https://ide.stanford.edu/cs106a">Online IDE</Link>
    <br />
  </>
}

const LairLink = () => {
  return <>
    <FaHandsHelping className="sidebaricon" />
    <a target="_blank" href="https://cs198.stanford.edu/lair">LaIR Signup</a>
    <br />
  </>
}

const PaperlessLink = () => {
  return <>
    <FaCheck className="sidebaricon" />
    <a target="_blank" href="https://cs198.stanford.edu/paperless/CS/106A">Paperless</a>
    <br />
  </>
}

const VideoLink = () => {
  const link = courseInfo.canvasLink
  return <>
    <FaVideo className="sidebaricon" />
    <a href={link} target="_blank">Lecture Videos</a>
    <br />
  </>
}

const ReplLink = () => {
  return <>
    <FaTerminal className="sidebaricon" />
    <a target="_blank" href="https://pyodide.org/en/stable/console.html">REPL</a>
    <br />
  </>
}