import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln3 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 3: Functions and Strings (Solution)</h1>
        <hr />

        <h3>In Range</h3>

        <PythonHighlighter code={in_range} />

        <h3>FizzBuzz</h3>
        <PythonHighlighter code={fizzbuzz} />

        <h3>Catty</h3>
        <PythonHighlighter code={catty} />

    </>
}

/*const HandoutInner = () => {
    return <>
        <h1>Section 3: Parameters, Returns, and Graphics (Solution)</h1>
        <hr />

        <h3>In Range</h3>

        <PythonHighlighter code={in_range} />

        <h3>FizzBuzz</h3>
        <PythonHighlighter code={fizzbuzz} />

        <h3>Mystery Square</h3>
        <PythonHighlighter code={mystery_square} />
    </>
}*/

const catty = `def catty(s):
result = ''
for i in range(len(s)):
    # Decomp var: save lower form in var
    low = s[i].lower()
    if low == 'c' or low == 'a' or low == 't':
        result += s[i]  # Use original, not low
return result`

const in_range = `def in_range(n, low, high):
"""
Returns True if n is between low and high, inclusive.
>>> in_range(5, 1, 10)
True
>>> in_range(5, 1, 3)
False
>>> in_range(5, 5, 10)
True
"""
if n >= low and n <= high:
    return True
# don't need else; if n was in range, we would have returned already
return False


def main():
num_1 = int(input("Enter first number: "))
num_2 = int(input("Enter second number: "))
num_3 = int(input("Enter third number: "))

if in_range(num_2, num_1, num_3):
    print(str(num_2) + " is in between " +
          str(num_1) + " and " + str(num_3))
else:
    print(str(num_2) + " is not in between " +
          str(num_1) + " and " + str(num_3))


if __name__ == "__main__":
main()`
const random_circles = `from graphics import Canvas
import random

CANVAS_WIDTH = 300
CANVAS_HEIGHT = 300
CIRCLE_SIZE = 20
N_CIRCLES = 20

def draw_random_circles(canvas):
    for i in range(N_CIRCLES):
        # Generate random coordinates for the circle's bounding box
        x = random.randint(0, CANVAS_WIDTH - CIRCLE_SIZE)
        y = random.randint(0, CANVAS_HEIGHT - CIRCLE_SIZE)
        
        # Generate a random color
        color = random_color()
        
        # Draw the circle on the canvas
        canvas.create_oval(x, y, x + CIRCLE_SIZE, y + CIRCLE_SIZE, color)

def random_color():
    colors = ['blue', 'purple', 'salmon', 'lightblue', 'cyan', 'forestgreen']
    return random.choice(colors)

def main():
    print('Random Circles')
    canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT)
    draw_random_circles(canvas)
    canvas.mainloop()

if __name__ == '__main__':
    main()
`
const mouse_tracker = `import sys
import random
from graphics import Canvas

CANVAS_WIDTH = 800
CANVAS_HEIGHT = 600
CIRCLE_SIZE = 70  # Setting the diameter of the circle
RECT_SIZE = 100

def create_rectangle(canvas):
    """
    Creates and returns rectangle object in the upper right corner of the canvas.
    """
    # Calculate the coordinates for the rectangle
    rect_x1 = CANVAS_WIDTH - RECT_SIZE
    rect_y1 = 0
    rect_x2 = CANVAS_WIDTH
    rect_y2 = RECT_SIZE
    
    rectangle = canvas.create_rectangle(rect_x1, rect_y1, rect_x2, rect_y2, fill="blue")
    return rectangle

def create_circle(canvas):
    """
    Creates and returns circle object, which starts
    at the center of the canvas.
    """
    # Calculate the coordinates to center the oval
    x1 = (CANVAS_WIDTH - CIRCLE_SIZE) / 2
    y1 = (CANVAS_HEIGHT - CIRCLE_SIZE) / 2
    x2 = x1 + CIRCLE_SIZE
    y2 = y1 + CIRCLE_SIZE
    
    circle = canvas.create_oval(x1, y1, x2, y2)
    return circle

def move_circle(canvas, circle):
    """
    Moves circle to mouse location, preventing
    circle from going off-screen.
    """

    mouse_x = canvas.get_mouse_x()
    mouse_y = canvas.get_mouse_y()
    
    # Adjust the x-coordinate to keep the circle within the canvas
    if mouse_x < CIRCLE_SIZE / 2:
        mouse_x = CIRCLE_SIZE / 2
    elif mouse_x >= (CANVAS_WIDTH - CIRCLE_SIZE / 2):
        mouse_x = (CANVAS_WIDTH - CIRCLE_SIZE / 2)
    
    # Adjust the y-coordinate to keep the circle within the canvas
    if mouse_y < CIRCLE_SIZE / 2:
        mouse_y = CIRCLE_SIZE / 2
    elif mouse_y >= (CANVAS_HEIGHT - CIRCLE_SIZE / 2):
        mouse_y = (CANVAS_HEIGHT - CIRCLE_SIZE / 2)
    
    canvas.moveto(circle, mouse_x - CIRCLE_SIZE / 2,
                  mouse_y - CIRCLE_SIZE / 2)
    
def is_overlapping(canvas, rectangle, circle):
    circle_coords = canvas.coords(circle)  # bounding box around ball
    x_1 = circle_coords[0]
    y_1 = circle_coords[1]
    x_2 = circle_coords[2]
    y_2 = circle_coords[3]

    overlapping_list = canvas.find_overlapping(x_1, y_1, x_2, y_2)
    for elem in overlapping_list:
            if elem == rectangle:
                return True
    return False

def main():
    """
    mouse.py
    Write a program that creates a circle to track a person's mouse
    """
    canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT, "Follow Mouse Circle")
    circle = create_circle(canvas)
    rectangle = create_rectangle(canvas)
    canvas.wait_for_click()
    
    while True:
        move_circle(canvas, circle)
        if is_overlapping(canvas, rectangle, circle):
            print("game over!")
            break
        canvas.update()
    canvas.mainloop()

if __name__ == "__main__":
    main()
`

const fizzbuzz = `def fizzbuzz(n):
    count = 0
    for i in range(1, n + 1):
        if i % 15 == 0:
            count += 1
            print("Fizzbuzz")
        elif i % 3 == 0:
            count += 1
            print("Fizz")
        elif i % 5 == 0:
            count += 1
            print("Buzz")
        else:
            print(i)
    return count


def main():
    num = int(input("Number to count to: "))
    count = fizzbuzz(num)
    print(str(count) + " numbers were fizzed or buzzed")


if __name__ == "__main__":
    main()`


const mystery_square = `import random
import time
from graphics import Canvas

CANVAS_WIDTH = 600
CANVAS_HEIGHT = 400
SQUARE_SIZE = 200
DELAY = 1
COLORS = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet',
          'pink', 'purple', 'white', 'grey', 'brown', 'tan', 'black']


def get_random_color():
    """
    Returns random color from COLORS list.
    """
    num_colors = len(COLORS)
    # need -1; lists are 0 indexed
    index = random.randint(0, num_colors - 1)
    return COLORS[index]


def main():
    canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT, "Mystery Square")

    gap_x = (CANVAS_WIDTH - SQUARE_SIZE) / 2
    gap_y = (CANVAS_HEIGHT - SQUARE_SIZE) / 2

    square = canvas.create_rectangle(gap_x, gap_y,
        CANVAS_WIDTH - gap_x, CANVAS_HEIGHT - gap_y, 'black')

    while True:
        color = get_random_color()
        canvas.set_color(square, color)

        time.sleep(DELAY)
        canvas.update()


if __name__ == "__main__":
    main()`   