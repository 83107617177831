import { useContext } from "react"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer"
import { AssnContext } from "course/contexts/AssnContext"
import { CustomProjectsTable } from "./CustomProjects"
import { RoadmapAssignments } from "./RoadmapAssignments"


export const CodeSplash = (props) => {
  const creative = props.creative || false
  return (
    <CoursePageBodyContainer
      title={<span>Coding Center</span>}
      mainColumn={<CodeMainColumn creative={creative} narrow={false} />}
      rightColumn={<></>}
      singleColumn={<CodeMainColumn creative={creative} narrow={true} />}
    />
  )
}

const CodeMainColumn = (props) => {
  const { assnLoaded, creativeLoaded } = useContext(AssnContext)

  if (!assnLoaded || !creativeLoaded) {
    return <></>
  }

  if (props.creative) {
    return (
      <CustomProjectsTable />
    )

  }

  return (
    <RoadmapAssignments narrow={props.narrow} />
  )
}