import { collection, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";

export async function deleteProject(
    targetProjectId,
    courseId,
    userId,
    onDelete,
    onError
  ) {

    // never delete an actual project, instead we are going to try and remove it
    // from the users' creative projects list
    const db = getFirestore();

    // this is where projects are saved
    const path = `users/${userId}/${courseId}/creativeProjects`;
    const userRef = doc(db, path);

    // first get the current projects lists.
    // even though this might be saved somewhere else
    // we repeat the query to get the up to date data
    // (could have been changed in another tab)
    const userData = (await getDoc(userRef)).data();
    const currentProjects = userData?.creativeProjects;
    if(!currentProjects) {
      onError("No projects found for user")
      return
    }

    // construct a project list that doesn't have the
    // targetProjectId
    const newProjectList = []
    let foundProject = false;
    for(let currProjectId of currentProjects) {
      if(currProjectId !== targetProjectId) {
        newProjectList.push(currProjectId)
      } else {
        foundProject = true
      }
    }
    if(!foundProject) {
      onError("Project not found for user")
      return
    }

    // write the new project list to the user's creative projects
    await updateDoc(userRef, {
      creativeProjects: newProjectList,
    });


    // you are done
    onDelete();
  }