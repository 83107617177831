import { ProfileContext, ProfileProvider } from "contexts/ProfileContext"
import { CodeSingleColumn, CodeSplash } from "course/code/CodeSplash"
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { AssnContext, AssnProvider } from "course/contexts/AssnContext"
import { CS106ANavbar } from "cs106a/components/Navbar"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useEffect, useState } from "react"
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import { createNewKarelProject, createNewConsoleProject } from "ide/utils/createNewProject";
import Swal from "sweetalert2";
import { Handout } from "cs106a/components/Handout";
import Loading from "react-loading";
import { useUserId } from "hooks/user/useUserId";
import { deleteProject } from "ide/utils/deleteProject";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate";

export const CodeCenter = () => {
  
  return <>
    <ProfileProvider>
      <CodeCenterInner />
    </ProfileProvider>
  </>
}

const CodeCenterInner = () => {
  const {loading} = useContext(ProfileContext)
  if(loading) return <Loading/>
  const courseId = useCourseId()
  return <AssnProvider courseId={courseId}>
        
        <Handout element={<CodeMainColumn/>}/>
      
    </AssnProvider>
}



const CodeMainColumn = (props) => {
  
    const {assnLoaded, creativeLoaded} = useContext(AssnContext);
    if(!assnLoaded || ! creativeLoaded) {
      console.log('loading...')
      return <Loading/>
    }
    return <>
    <h1>Practice IDE</h1>
    <p className="mb-4">The online ide is a place where you can practice with python. You will do your assignments in Pycharm! Enjoy these warmup challenges, and of course feel free to make a blank project.</p>
    
    <hr/>
      <AllProjects />
    </>
    
    
  };
  
  export const collectProjects = () => {
    // TODO: sort by last modified date!
    // TODO: @Ali: i think we need a createdOn date.
  
    const [projectsList, setProjects] = useState([])
    const [metaData, setMetaData] = useState({})
  
    const {
      assignments,
      assnMetaData,
      creativeProjects,
      creativeMetaData,
    } = useContext(AssnContext);
  
    useEffect(() => {
      const newMetaData ={}
      const newList = []
      collectAssignments(newList, newMetaData)
      collectProjects(newList, newMetaData)
      setProjects(newList)
      setMetaData(newMetaData)
    }, [])
  
    const collectAssignments = (newList, newMetaData) => {
      for(const assnGroup of assignments) {
        for(const assnId of assnGroup.assnList) {
          const data = assnMetaData[assnId]
          if(!data) continue
          const assnKey = `assns/${assnId}`
          newList.push(assnKey)
          newMetaData[assnKey] = {
            ...data,
            isAssn : true,
            assnId,
            assnGroup
          }
        }
      }
    }
  
    const collectProjects = (newList, newMetaData) => {
      for(const creativeId of creativeProjects) {
          const data = creativeMetaData[creativeId]
          if(!data) continue
          const projectKey = `projects/${creativeId}`
          newList.push(projectKey)
          newMetaData[projectKey] = {
            ...data,
            isAssn : false,
            projectId: creativeId
          }
      }
    }
  
    return [
      projectsList,
      metaData
    ]
  }
  
  const AllProjects = ({}) => {
    
    return <>
      <Assignments/>
      <CreativeProjects/>
    </>
  };

  const CreativeProjects  = ({}) => {
    const {
      assignments,
      assnMetaData,
      creativeProjects,
      creativeMetaData,
    } = useContext(AssnContext);

    return <><div className="mb-3">
      <h3>Personal Projects</h3>
      {creativeProjects.map((projectId) => {
        const data = creativeMetaData[projectId]
        if(!data) return <></>
        return <CodeProject 
          key={projectId}
          metaData = {{
            ...data,
            isAssn : false,
            projectId
          }}/>
      })}
      
    </div>
    <div>
    <div><CreateKarelProjectButton/></div>
    <div><CreateConsoleProjectButton/></div>
    </div>
    </>
  }

  const Assignments = ({}) => {
    const {
      assignments,
      assnMetaData,
      creativeProjects,
      creativeMetaData,
    } = useContext(AssnContext);

    return <>
    <h3>Practice</h3>
    {assignments.map((assnGroup) => {
      const title = assnGroup.title
      return <div className="mb-3" key={title}>
        {/* <span style={{fontSize:24}}>{assnGroup.title}</span> */}
        {assnGroup.assnList.map((assnId) => {
          const data = assnMetaData[assnId]
          if(!data) return <></>
          return <CodeProject 
            key={assnId}
            metaData = {{
              ...data,
              isAssn : true,
              assnId,
              assnGroup
            }}/>
        })}
      </div>
    })}
    </>
  }

  const CodeProject = ({metaData}) => {
    const {courseId} = useParams()
    const userId = useUserId()
    const isAssn = metaData.isAssn
    const ideUrl = isAssn ?
      `/${courseId}/ide/a/${metaData.assnId}` :
      `/${courseId}/ide/p/${metaData.projectId}`

    const onDeleteComplete = () => {
      Swal.fire('Deleted!')
    }

    const onDeleteError = (errMsg) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errMsg,
      })
    }

    const onDelete = () => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteProject(metaData.projectId, courseId, userId, onDeleteComplete,onDeleteError)
        }
      })
    }

    return <div className="code-center-row d-flex flex-row align-items-center justify-content-between">
    
      <h5 style={{fontSize:18}}><Link to = {ideUrl}>{metaData.title}</Link></h5>

      {!isAssn && <button onClick = {()=>onDelete()}className="btn btn-light btn-sm"><FaTrash/></button>}
      </div>
  }
  
  const CodeProjectTr = ({metaData }) => {
    const {courseId} = useParams()
    // urls are different from projects and assignments
    const ideUrl = metaData.isAssn ?
      `/${courseId}/ide/a/${metaData.assnId}` :
      `/${courseId}/ide/p/${metaData.projectId}`
    const assnId = metaData.isAssn ? metaData.assnGroup.title : 'Personal Projects'
    let lastEdited = metaData.projectId
    console.log(metaData)
  
  
    return <tr><td>
        <Link to = {ideUrl}>{metaData.title}</Link>
        {/* {metaData.isAssn && <span className="ml-2 badge rounded-pill bg-secondary">Assn</span>} */}
      </td>
      <td>{assnId}</td>
      {/* <td>{lastEdited}</td> */}
      <td className="text-end">
  
          <Link to={ideUrl} className="btn btn-light btn-sm">
          <FaEdit/> Edit
          </Link>
      </td>
      </tr>
  };
  
  
  
  
  
  const CreateKarelProjectButton = (props) => {
    const navigate = useHistoryNavigate();
    const courseId = useCourseId()
    const [user] = useAuthState(getAuth(getApp()));
  
    return (
      <>
        <button
          className="btn btn-primary btn-sm mt-1"
          // style={{backgroundColor:'#b757f6', border:'none'}}
          onClick={async () => {
            const { value: projectName } = await Swal.fire({
              title: "New Karel Project!",
              input: "text",
              inputLabel: "What is the name of your project?",
              showCancelButton: true,
              inputValidator: (value) => {
                if (!value) {
                  return "You can't have an empty project name!";
                }
              },
            });
  
            createNewKarelProject(projectName, courseId, user.uid, (projectId) => {
              const projectUrl = `/${courseId}/ide/p/${projectId}`;
              navigate(projectUrl)
            });
          }}
        >
          <FaPlus /> New Karel Project
        </button>
  
      </>
    );
  };

  const CreateConsoleProjectButton = (props) => {
    const navigate = useHistoryNavigate();
    const courseId = useCourseId()
    const [user] = useAuthState(getAuth(getApp()));
  
    return (
      <>
        <button
          className="btn btn-primary btn-sm mt-1"
          // style={{backgroundColor:'#b757f6', border:'none'}}
          onClick={async () => {
            const { value: projectName } = await Swal.fire({
              title: "New Console Project!",
              input: "text",
              inputLabel: "What is the name of your project?",
              showCancelButton: true,
              inputValidator: (value) => {
                if (!value) {
                  return "You can't have an empty project name!";
                }
              },
            });
  
            createNewConsoleProject(projectName, courseId, user.uid, (projectId) => {
              const projectUrl = `/${courseId}/ide/p/${projectId}`;
              navigate(projectUrl)
            });
          }}
        >
          <FaPlus /> New Console Project
        </button>
  
      </>
    );
  };
  