import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

// @ts-ignore
import LicenseError from "./img/pycharmBugs/license_error.png"
// @ts-ignore
import MacDownloadDropdown from "./img/pycharmBugs/mac_download_dropdown.png"
// @ts-ignore
import DarkMode from "./img/pycharmBugs/dark_mode.png"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"


export const StyleGuide = () => {
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>
      CS106A Style Guide
    </h1>
    <i>Created by Juliette Woodrow</i>
    <hr />

    <p>As you start to write your first programs, it&#39;s important to understand the concept of
      programming style and why it is essential in the development process. In this handout, we
      will discuss the importance of good programming style and provide guidelines to help you write
      clean and maintainable code.</p>

    <h3>Why is programming style important?</h3>
    <p>Good programming style is essential for several reasons:</p>
    <ol>
      <li>It makes your code easier to read and understand.</li>
      <li>It helps you and others identify and fix bugs more quickly.</li>
      <li>It enables better collaboration with other programmers.</li>
      <li>It leads to more maintainable and reusable code.</li>
    </ol>
    <p>&quot;Readability&quot; is the key factor to consider when it comes to programming style. Code readability is crucial for understanding, maintaining, and collaborating on a coding project. Writing readable code makes it easier for you and others to identify and fix bugs, build upon existing code, and work together on projects. In this handout we will discuss many strategies for writing code that is easy to read and understand. </p>
    <p>Now, let&#39;s dive into some specific programming style guidelines for Python:</p>

    <h3>Variable and Function Names</h3>
    <p>Choosing descriptive variable and function names is an essential aspect of writing clean and maintainable code. Meaningful names make it easier for you and others to understand the purpose of each component in your code, reducing the need for extensive comments and making your code more self-explanatory. In Python, we follow the snake_case naming convention for variables and functions. Here are some guidelines to help you choose the right names:</p>
    <h4>Descriptive Names</h4>

    <p>Select clear and descriptive names for your variables and functions. This makes it easier for you and others to quickly grasp the functionality of your program.</p>
    <p>Example:</p>
    <PythonHighlighter code={names} />

    <p>Karel can move in a lot of ways and so we need to be more specific and descriptive when choosing the name of the function so that it is easier to understand exactly what the function does. </p>
    <h4>Snake Case</h4>
    <p>In Python, use snake_case for variable and function names. This means using lowercase letters and separating words with underscores. This naming convention is easy to read and is the standard practice in Python.</p>
    <p>Example:</p>
    <PythonHighlighter code={snake} />

    <p>In the second example, it is harder to understand what the function does. Though the person writing the function likely knows that p means price and tr means tax rate, these names are not clear to other people reading the code (and the author if they look back at this code later). Though the function name is clear, it is not written in snake case. In python programs, it is standard to use snake case for all function names. </p>
    <h4>Avoid Python Keywords as Names</h4>
    <p>In addition to picking descriptive names, there are certain python keywords that we do not want to use when naming our own variables and functions as that can be confusing. Python keywords that should not be used as function or variable names: <code>False</code>, <code>True</code>, <code>None</code>, <code>and</code>, <code>as</code>, <code>assert</code>, <code>async</code>, <code>await</code>, <code>break</code>, <code>className</code>, <code>continue</code>, <code>def</code>, <code>del</code>, <code>elif</code>, <code>else</code>, <code>except</code>, <code>finally</code>, <code>for</code>, <code>from</code>, <code>global</code>, <code>if</code>, <code>import</code>, <code>in</code>, <code>is</code>, <code>lambda</code>, <code>nonlocal</code>, <code>not</code>, <code>or</code>, <code>pass</code>, <code>raise</code>, <code>return</code>, <code>try</code>, <code>while</code>, <code>with</code>, <code>yield</code></p>
    <p>By following these guidelines and using descriptive variable and function names written in snake_case, you can greatly improve the readability and maintainability of your Python code.</p>
    <h3>Using Whitespace to Improve Code Readability</h3>
    <p>Whitespace helps visually separate different sections of your code and makes it easier to understand the structure and flow of your program. To effectively use whitespace for better code readability, follow these guidelines:</p>
    <ol>
      <li>Use consistent indentation to show the hierarchy of your code blocks, typically 4 spaces per indentation level in Python. Luckily, a tab is typically 4 spaces. So you can just hit the tab button instead of typing out 4 spaces each time. </li>
      <li>Separate function definitions with two blank lines to clearly distinguish between them.</li>
      <li>Add a single blank line between logical sections within a function to group related lines of code together.</li>
      <li>Surround operators with spaces to make expressions easier to read. Instead of <code>x=3+4</code> you should write <code>x = 3 + 4</code>. </li>
    </ol>
    <p>By following these whitespace guidelines, you can make your code more visually appealing and easier to navigate. This may seem time consuming, but it will save you lots of time in your editing and debugging process.</p>
    <h3>Comments</h3>
    <p>Comments help you and others understand the purpose and functionality of your code, making it easier to debug, maintain, and collaborate on projects. However, it&#39;s also essential to strike the right balance between commenting and not over-commenting. There are two standard types of comments in python. In-line comments are comments that start with a # and go only to the end of the line. Docstring comments are comments inside a set of three triple quotes and can be more than one line. We discuss below when to use each type of comment. Here are some guidelines for using comments effectively in your code:</p>
    <h4>Comment for each function</h4>
    <p>Write a short comment for each function in your program to explain its purpose and how it works. This helps others quickly understand the function&#39;s role and functionality within the code. Comments should go below the def and before the code body to make it clear which function this comment belongs to. It is standard in python for comments at the top of each function to be inside of a doc string as shown below.</p>
    <p>Example:</p>
    <PythonHighlighter code={function_comments} />

    <h4>Comment at the top of each file</h4>
    <p>Include a comment at the beginning of each file in your program, describing its overall purpose and any important information about its contents. This helps provide context for the code in the file and makes it easier for others to understand how it fits into the larger project. It is standard in Python to place file header comments inside a doc string</p>
    <p>Example:</p>
    <PythonHighlighter code={header_comments} />
    <h4>Inline comments for complex code</h4>
    <p>Use inline comments to provide explanations for any code that might be difficult to understand or requires additional
      clarification. Avoid writing comments for straightforward code, as this can clutter your code and make it harder to read.
      Inline comments can go above or next to the line they describe, just be consistent!</p>
    <p>Example:</p>
    <PythonHighlighter code={inline_comments} />

    <p>In the &quot;Good&quot; example, the in-line comments are used to provide clear explanations for any non-obvious or potentially confusing parts of the code, such as the additional 2% discount applied for rates between 10% and 20%, and the maximum discount of 25%.</p>
    <p>In the &quot;redundant / unnecessary comments&quot; example, the in-line comments are overly wordy and explain parts of the code that are already clear enough. This may distract the reader and make the code harder to understand. </p>
    <h3>Decomposition</h3>
    <p>Decomposition is the process of breaking down a complex problem into smaller, more manageable parts. In programming, this often means dividing your code into smaller, reusable functions. This helps make your code easier to understand, debug, and maintain. Let&#39;s discuss some guidelines to follow when decomposing your code:</p>
    <ol>
      <li><p><strong>Do one thing</strong>: Each function should have a single responsibility, meaning it should only do one conceptual thing, like building a hospital, not building a hospital and then moving to the next hospital. This makes your code easier to reuse and modify in the future. It will also make the debugging process easier because you will be able to narrow the issue down to a single function. </p>
      </li>
      <li><p><strong>Know what it does by looking at its name</strong>: Choose function names that clearly describe their purpose, so you can understand what they do just by looking at their names. This improves code readability and reduces the need for extensive comments.</p>
      </li>
      <li><p><strong>Less than 10 lines, 3 levels of indentation</strong>: Keep your functions short (preferably less than 10 lines) and limit the levels of indentation to 3 or fewer. Note that 10 lines / 3 levels of indentation is not a strict rule. Rather these are guidelines to keep in mind as you are starting out in your programming journey. This helps keep your code readable and maintainable, as it&#39;s easier to understand the logic and flow of shorter functions.</p>
      </li>
      <li><p><strong>Reusable and easy to modify</strong>: Write code that is easy to reuse and modify by using functions. This allows you to save time and effort when making changes or building upon your existing codebase.</p>
      </li>
    </ol>
    <h3>Constants and Magic Numbers</h3>
    <p>In programming, it&#39;s important to understand the concept of constants and magic numbers, as well as how to use them properly. This section will explain what constants and magic numbers are and provide guidelines for using them effectively in your code.</p>
    <h3>Constants</h3>
    <p>Constants are values that do not change during the execution of a program. They are used to give meaningful names to certain values, making the code easier to understand. In Python, constants are typically defined in upper snake case, which uses uppercase letters with words separated by underscores.</p>
    <p>Example:</p>
    <PythonHighlighter code={constants} />

    <p>By using constants, you can easily update the value in one place if it needs to change, without having to search for and modify multiple occurrences of the same value throughout your code.</p>
    <h4>Magic Numbers</h4>
    <p>Magic numbers are hardcoded values that appear directly in your code without any explanation of their meaning or purpose. These values can make your code difficult to understand and maintain, as it&#39;s not clear where they come from or why they are being used.</p>
    <p>Example:</p>
    <PythonHighlighter code={magic} />

    <p>To avoid magic numbers in your code, replace magic numbers with named constants, as shown in the example above. </p>
    <h3>Parameters</h3 >
    <p>Parameters are pieces of information that functions need to use when we call them. You can think of them as inputs to the function. By using parameters, functions can work with different inputs without changing the code inside the function. This makes the function more adaptable and easy to use in various situations. Parameters help functions focus on the information they receive as input and not depend on information from other parts of the program. Using parameters is another example of good programming style. </p>
    <p>I think it is helpful to understand how parameters can improve programming style through an example. Let&#39;s consider an example where we want to create a function that adds two numbers together. First, let&#39;s look at a version without using parameters:</p>
    <PythonHighlighter code={no_parameters} />

    <p>In the above example, we have to change the values of <code>a</code> and <code>b</code> inside the function every time we want to add different numbers. Now, let&#39;s rewrite the function using parameters:</p>

    <PythonHighlighter code={parameters} />
    <p>By using parameters, we can now call the <code>add_numbers</code> function with different inputs without changing the code inside the function. This makes the function more flexible and reusable, allowing us to easily add different pairs of numbers without modifying the function itself.</p>
    <p>Advantages of using parameters:</p>
    <ol>
      <li><strong>Modularity</strong>: Functions with parameters work independently and can be used in different parts of your program or even in other projects without any problems.</li>
      <li><strong>Ease of maintenance</strong>: When you need to change a function&#39;s behavior, you&#39;ll only have to change the code inside the function. This won&#39;t affect the rest of your program.</li>
      <li><strong>Simpler testing</strong>: It&#39;s easier to test functions that use parameters because you can provide different inputs to check how the function behaves in various scenarios without changing the function itself.</li>
    </ol>
    <h3> Conclusion </h3>
    <p>Remember that your code is not only meant for computers to execute but also for humans to read and understand. By consistently practicing good programming style, you will develop strong coding habits that will benefit you and your collaborators in the long run, making you a more effective and efficient programmer.</p>
    <p>This guide is a rundown of some of the most important, official Python rules that we want to emphasize while you are first learning how to program. As mentioned above, not all of these are strict rules so this should be used as guidance and inspiration. Some style tactics are up to programmer preference, and in that case, readability should be what you&#39;re going for. Feel free to ask your section leader or post on the forum if you&#39;re unsure about style rules. </p>
    <p>If you want even more style advice, you can check out the official python style guide. This. has more advanced topics that are outside the scope of this course and not necessarily things we emphasize to people who are just learning how to program, but feel free to peruse at your leisure. </p>
    <p>Happy coding!</p>

  </>
}

const names = `# Good
def move_to_wall():
  ...

# Bad
def karel_moving():
  ...`

const snake = `# Good
def calculate_total_price(price, tax_rate):
  total_price = price * (1 + tax_rate)
  return total_price

# Bad
def CalculateTotalPrice(p,tr):
  x = p * (1 + tr)
  return x`

const function_comments = `def calculate_area(radius):
  """
  Calculate the area of a circle given its radius.
  """
  area = 3.14 * radius ** 2
  return area`

const header_comments = `"""
Filename: geometry.py
Author: Juliette
Description: This module contains functions for calculating the
area, perimeter, and volume of various geometric shapes.
"""
...
# Your code here
...`

const inline_comments = `# Good
def calculate_discount(price, discount_rate):
    if discount_rate <= 0.1:
        discount = price * discount_rate
    elif discount_rate <= 0.2:
        # Apply an additional 2% discount for rates between 10% and 20%
        discount = price * (discount_rate + 0.02)  
    else:
        # Maximum discount of 25%
        discount = price * 0.25  

    return discount

# Some redundant / unnecessary comments
def calculate_discount(price, discount_rate):
    # Check if discount rate is less than or equal to 10%
    if discount_rate <= 0.1:  
      # Calculate discount  
      discount = price * discount_rate  
    # Check if discount rate is less than or equal to 20%
    elif discount_rate <= 0.2: 
      # Calculate discount with additional 2%     
      discount = price * (discount_rate + 0.02)  
      # Discount rate is greater than 20%
      else:  
        # Calculate discount with maximum 25% rate
        discount = price * 0.25  

    return discount`

const constants = `PI = 3.14159
GRAVITY = 9.81
MAX_CONNECTIONS = 100`

const magic = `# Good
PI = 3.14159
area = PI * radius ** 2

# Bad
area = 3.14159 * radius ** 2`

const no_parameters = `# Without parameters
def add_numbers():
    a = 5
    b = 3
    result = a + b
    print(result)

add_numbers()  # Prints out: 8

# To use different numbers, must change 'a' and 'b' inside the function
def add_numbers():
    a = 7
    b = 4
    result = a + b
    print(result)

add_numbers()  # Prints out: 11`

const parameters = `# With parameters
def add_numbers(a, b):
    result = a + b
    print(result)

add_numbers(5, 3)  # Prints out: 8
add_numbers(7, 4)  # Prints out: 11`
