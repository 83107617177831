import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const Soln4 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 4: Lists of Lists and Strings (Solution)</h1>
        <hr />

        <h3>Enumerate</h3>
        <PythonHighlighter code={enumerate} />

        <h3>Double Char</h3>
        <PythonHighlighter code={double} />

        <h3>Find Differences</h3>
        <PythonHighlighter code={diffs} />
    </>
}

const enumerate = `def enumerate(lst):
    enum_lsts = []
    for i in range(len(lst)):
        val = lst[i]
        enum_lsts.append([i, val])
    return enum_lsts`

const double = `def double_char(s):
    result = ''
    for i in range(len(s)):
        result = result + s[i] + s[i]
    return result`

const catty = `def catty(s):
    result = ''
    for i in range(len(s)):
        # Decomp var: save lower form in var
        low = s[i].lower()
        if low == 'c' or low == 'a' or low == 't':
            result += s[i]  # Use original, not low
    return result`

const diffs = `def find_diffs(str1, str2):
    count = 0
    for i in range(len(str1)):
        ch1 = str1[i]
        ch2 = str2[i]
        if ch1 != ch2:
            count += 1
    return count`