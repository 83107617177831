import { Role } from "./ProfileContext"

export const isStaffRole = (courseRole) => {
    if(courseRole === Role.INSTRUCTOR){
        return true
    }
    if(courseRole === Role.ADMIN){
        return true
    }
    if(courseRole === Role.TA){
        return true
    }
    return false
}

export const isInCourse = (courseRole) => {
    return courseRole !== Role.UNREGISTERED
}

export const isATypeOfStudent = (courseRole) => {
    return courseRole === Role.STUDENT || courseRole === Role.EXPERIENCED_STUDENT

}

export const isSectionLeader = (courseRole) => {
    return courseRole === Role.SECTION_LEADER
}

export const isMentor = (courseRole) => {
    return courseRole === Role.MENTOR
}

export const isExternalStudent = (courseRole) => {
    return courseRole === Role.EXTERNAL_STUDENT
}

export const isExperiencedStudent = (courseRole) => {
    return courseRole === Role.EXPERIENCED_STUDENT
}

export const isStudent = (courseRole) => {
    return courseRole === Role.STUDENT
}

export const isInstructor = (courseRole) => {
    return courseRole === Role.INSTRUCTOR
}

export const isAdmin = (courseRole) => {
    return courseRole === Role.ADMIN
}

export const isMinimumRole = (courseRole, requiredRole) => {
    if(!courseRole){
        return false
    }
    // Create a priority list of roles
    const priority= [
        Role.UNREGISTERED,
        Role.STUDENT,
        Role.EXPERIENCED_STUDENT,
        Role.MENTOR,
        Role.SECTION_LEADER,
        Role.TA,
        Role.ADMIN,
        Role.INSTRUCTOR
    ]
    // determine if the roles are in the priority list, and if so compare their indexes
    const courseRoleIndex = priority.indexOf(courseRole)
    const requiredRoleIndex = priority.indexOf(requiredRole)

    if (courseRoleIndex === -1 || requiredRoleIndex === -1){
        return false
    }

    return courseRoleIndex >= requiredRoleIndex
}

export const roleToString = (courseRole) => {
    if(courseRole === Role.UNREGISTERED){
        return "Unregistered"
    }
    if(courseRole === Role.STUDENT){
        return "Student"
    }
    if(courseRole === Role.SECTION_LEADER){
        return "Section Leader"
    }
    if(courseRole === Role.TA){
        return "TA"
    }
    if(courseRole === Role.INSTRUCTOR){
        return "Instructor"
    }
    if(courseRole === Role.ADMIN){
        return "Admin"
    }
    if(courseRole === Role.MENTOR){
        return "Section Leader (Mentor)"
    }
    if(courseRole === Role.EXPERIENCED_STUDENT){
        return "Student"
    }
    return "Unknown"
}



export const roleToForumString = (courseRole) => {
    if(courseRole === Role.SECTION_LEADER){
        return "SL"
    }
    if(courseRole === Role.TA){
        return "TA"
    }
    if(courseRole === Role.INSTRUCTOR){
        return "Instructor"
    }
    if(courseRole === Role.ADMIN){
        return "Admin"
    }
    if(courseRole === Role.MENTOR){
        return "SL"
    }
    return ""
}

export const roleToAdminString = (courseRole) => {
    if(courseRole === Role.UNREGISTERED){
        return "Unregistered"
    }
    if(courseRole === Role.STUDENT){
        return "Student"
    }
    if(courseRole === Role.SECTION_LEADER){
        return "Section Leader"
    }
    if(courseRole === Role.TA){
        return "TA"
    }
    if(courseRole === Role.INSTRUCTOR){
        return "Instructor"
    }
    if(courseRole === Role.ADMIN){
        return "Admin"
    }
    if(courseRole === Role.MENTOR){
        return "Section Leader (Mentor)"
    }
    if(courseRole === Role.EXPERIENCED_STUDENT){
        return "Experienced Student"
    }
    return "Unknown"
}

export const roleToFirebaseString = (courseRole) => {
    if(courseRole === Role.UNREGISTERED){
        return "unregistered"
    }
    if(courseRole === Role.STUDENT){
        return "student"
    }
    if(courseRole === Role.SECTION_LEADER){
        return "sl"
    }
    if(courseRole === Role.TA){
        return "ta"
    }
    if(courseRole === Role.INSTRUCTOR){
        return "instructor"
    }
    if(courseRole === Role.ADMIN){
        return "admin"
    }
    if(courseRole === Role.MENTOR){
        return "mentor"
    }
    if(courseRole === Role.EXPERIENCED_STUDENT){
        return "experiencedstudent"
    }
    if (courseRole === Role.EXTERNAL_STUDENT) {
        return "externalstudent"
    }
    return "Unknown"
}

export const firebaseStringToRole = (firebaseStr) => {
  switch (firebaseStr) {
    case "student": return Role.STUDENT;
    case "sl": return Role.SECTION_LEADER;
    case "admin": return Role.ADMIN;
    case "ta": return Role.TA;
    case "instructor": return Role.INSTRUCTOR;
    case "mentor": return Role.MENTOR;
    case "experiencedstudent": return Role.EXPERIENCED_STUDENT;
    case "externalstudent": return Role.EXTERNAL_STUDENT;
  }
  return Role.UNREGISTERED;
}

export const isProfileComplete = (profileContext) => {
    const userData = profileContext.userData
    const userProtectedData = profileContext.userProtectedData

    

    const hasDisplayName = userData.displayName && userData.displayName.length > 0 && userData.displayName !== "Anon"
    const hasCountry = userData.country && userData.country.length > 0
    const hasDob = userProtectedData?.dateOfBirth !== undefined
    // const hasGender = userProtectedData?.gender !== undefined

    
    const hasFields = hasDisplayName && hasCountry && hasDob
    if(!hasFields){
        return false
    }

    

    const requiredDobFields = ["day", "month", "year"]
    const dobFields = userProtectedData.dateOfBirth
    const hasDobFields = requiredDobFields.every((field) => dobFields[field] !== undefined)

    return hasDobFields
}