/** Important: we are going to change this whole file to use lazy load! */

// import firebase from "firebase";
import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// import the styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.scss";
import "./styles/index.scss";

// import authentication first

import { FirebaseAuthProvider } from "../components/auth/FirebaseAuthProvider";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseConfig } from "../config";
import { database } from "firebaseApp.js";

import ErrorBoundary from "components/errors/ErrorBoundary";
// import { Loading } from "components/loading/Loading";
import { Schedule } from "./handouts/Schedule";
import CoursePage from "./components/CoursePage";
import Landing from "./landing/Landing";
import PyodideProvider from "../components/pyodide/PyodideProvider";
import { IDEProvider } from "ide/contexts/IDEContext";
import { IDE } from "ide/IDE";
import { LoggedIn, LoginWrapper } from "./authentication/Login";
import { ForumContext } from "course/forum/ForumContext";
import { ProfileProvider, Role } from "contexts/ProfileContext";
import { Forum } from "course/forum/pages/forum/Forum";
import { CS106AForumInner } from "./forum/CS106AForum";
import { CodeCenter } from "./code/CodeCenter";
import { Syllabus } from "./handouts/Syllabus";
import { Assn0 } from "./handouts/Assn0";
import { Assn1 } from "./handouts/Assn1";
import { Assn2 } from "./handouts/Assn2";
import { Assn3 } from "./handouts/Assn3-summer24";
import { Assn4 } from "./handouts/Assn4";
import { Assn5 } from "./handouts/Assn5";
import { Assn6 } from "./handouts/Assn6";
import {InfiniteStory} from "./assn/infiniteStory/InfiniteStory";
import { Section1 } from "./handouts/section/Section1";
import { Section2 } from "./handouts/section/Section2";
import { Section3 } from "./handouts/section/Section3";
import { Section4 } from "./handouts/section/Section4-summer24";
import { Section5 } from "./handouts/section/Section5";
import { Section6 } from "./handouts/section/Section6-summer24";
import { Section7 } from "./handouts/section/Section7-sum24";
import { LessonPlan1 } from "./handouts/section/LessonPlan1";
import { LessonPlan2 } from "./handouts/section/LessonPlan2";
import { LessonPlan3 } from "./handouts/section/LessonPlan3";
import { LessonPlan4 } from "./handouts/section/LessonPlan4";
import { LessonPlan5 } from "./handouts/section/LessonPlan5";
import { LessonPlan6 } from "./handouts/section/LessonPlan6";
import { LessonPlan7 } from "./handouts/section/LessonPlan7";
import { LessonPlan8 } from "./handouts/section/LessonPlan8";
import { Soln1 } from "./handouts/section/Soln1";
import { Soln2 } from "./handouts/section/Soln2";
import { Soln3 } from "./handouts/section/Soln3";
import { Soln4 } from "./handouts/section/Soln4";
import { Soln5 } from "./handouts/section/Soln5";
import { Soln6 } from "./handouts/section/Soln6-sum24";
import { Soln7 } from "./handouts/section/Soln7-sum24";
import { Published } from "publishedCode/Published";
import { HonorCode } from "./handouts/HonorCode";
import { InstallingPycharm } from "./handouts/InstallingPycharm";
import { PycharmBugs } from "./handouts/PycharmBugs";
import { KarelPycharm } from "./handouts/KarelPycharm";
import { GraphicsReference } from "./handouts/GraphicsReference";
import { InteractivePrograms } from "./handouts/InteractivePrograms";
import { ImageReference } from "./handouts/ImageReference";
import { StyleGuide } from "./handouts/StyleGuide";
import { Lecture } from "./lecture/Lecture";
import { TextbookPage } from "textbook/TextbookPage";
import { TextbookChapter } from "textbook/TextbookChapter";
import { TextbookSplash } from "textbook/TextbookSplash";
import { PracticeMidterm } from "./handouts/PracticeMidterm";
import {  MidtermPrep } from "./handouts/MidtermHandout-summer24";
import { PracticeMidtermSoln } from "./handouts/PracticeMidtermSoln";
import { MidtermSoln } from "./handouts/MidtermSoln";
import { Contest } from "./handouts/Contest";
import { FinalPrep } from "./handouts/FinalPrep";
import { FinalPrepSoln } from "./handouts/FinalPrepSoln";
import { PracticeFinal } from "./handouts/PracticeFinal";
import { PracticeFinalSoln } from "./handouts/PracticeFinalSoln";
import { Citations } from "./handouts/Citations";
import { FinalSoln } from "./handouts/FinalSoln";
import { NotOpenAI } from "./handouts/NotOpenAI";
import { SectionLeaders } from "./handouts/SectionLeaders";
import { TeachNowCS106A } from "./tools/TeachNowCS106A";
import { CareHoursHandout } from "course/carehours/WhatIsCareHours";
import { CheckJoinPage } from "ide/PairProgram/ForceJoin";
import { useStandardIdeProjectLoader } from "ide/hooks/loadIdeData";
import { CollabProvider } from "ide/sync/CollabContext";
import { AssnProvider } from "course/contexts/AssnContext";
import { CompletionProvider } from "contexts/CompletionContext";
import { EventsProvider } from "course/contexts/EventsContext";
import { ToastLayer } from "components/toasts/ToastLayer";
import { useActiveRoom } from "../firebase/realtime/Rooms";
import { Loading } from "components/loading/Loading";
import { TeachNowSplash } from "./tools/TeachNowSplash";

const queryClient = new QueryClient();

const Authenticated = ({ component: Component, ...props }) => {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <LoginWrapper component={Component} />
    </FirebaseAuthProvider>
  );
};

const IDEWithProvider = () => {
  return (
    <CompletionProvider courseId="cip4">
      <AssnProvider courseId="cip4">
        <PyodideProvider>
          <IDEProvider useProject={useStandardIdeProjectLoader}>
            <CollabProvider>
              <IDE />
            </CollabProvider>
          </IDEProvider>
        </PyodideProvider>
      </AssnProvider>
    </CompletionProvider>
  );
};

const TeachNowTrainingWithContexts = () => {
  const [user, loading] = useAuthState(firebase.auth());
  const courseId = 'cip4'
  const activeRoom = useActiveRoom(user?.uid, courseId);

  if (loading) {
    return <Loading />;
  }
  return (
    <ProfileProvider>
      <EventsProvider courseId="cip4">
        <ToastLayer user={user} activeRoom={activeRoom}>  
          <CompletionProvider courseId="cs106a">
            <AssnProvider courseId="cip4">
              <PyodideProvider>
                <TeachNowCS106A />
              </PyodideProvider>
            </AssnProvider>
          </CompletionProvider>
        </ToastLayer>
      </EventsProvider>
    </ProfileProvider>
  );
}

const TeachNowSplashWithContexts = () => {
  const [user, loading] = useAuthState(firebase.auth());
  const courseId = 'cip4'
  const activeRoom = useActiveRoom(user?.uid, courseId);

  if (loading) {
    return <Loading />;
  }
  return (
    <ProfileProvider>
      <EventsProvider courseId="cip4">
        <ToastLayer user={user} activeRoom={activeRoom}>  
          <CompletionProvider courseId="cs106a">
            <AssnProvider courseId="cip4">
              <PyodideProvider>
                <TeachNowSplash />
              </PyodideProvider>
            </AssnProvider>
          </CompletionProvider>
        </ToastLayer>
      </EventsProvider>
    </ProfileProvider>
  );
}

const CS106AForum = () => {
  return (
    <ProfileProvider>
      <ForumContext.Provider
        value={{
          forumId: "cs106a_spr23_main",
          forumType: "Course",
          privatePostThreshold: Role.SECTION_LEADER,
          setPinnedThreshold: Role.ADMIN,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <CS106AForumInner />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ForumContext.Provider>
    </ProfileProvider>
  );
};

const PeerWithContexts = () => {
  return (
    <ProfileProvider>
      <PyodideProvider>
        <CheckJoinPage />
      </PyodideProvider>
    </ProfileProvider>
  );
};
        

const ToHome = () => {
  return <Navigate to="/" />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>
      

     

      <Route path="/" element={<Landing />} />

      <Route path="textbook" element={<TextbookPage />}>
          <Route index element={<TextbookSplash />} />
          <Route path=":chapterId" element={<TextbookChapter chapterData={{}}/>} />
        </Route>
      
      <Route
        path=":courseId/teachnowtraining/*"
        element={<Authenticated component={TeachNowTrainingWithContexts} />}
      />

      <Route 
        path=":courseId/teachnow" 
        element={<Authenticated component={CareHoursHandout} />}
      />
      <Route 
        path=":courseId/peer/:roomId" 
        element={<Authenticated component={PeerWithContexts} />}
      />

      {/* IDE Routes */}
      <Route>
        <Route
          path=":courseId/ide/:urlFormat/:urlKey"
          element={<Authenticated component={IDEWithProvider} />}
        />
      </Route>

      <Route path="*" element={<CoursePage />} errorElement={<ErrorBoundary />}>

        <Route
          path=":courseId/teachnowhome"
          element={<Authenticated component={TeachNowSplashWithContexts} />}
        />
        
        <Route path="notopenai" element={<NotOpenAI/>}/>
        <Route path="SectionLeaders" element={<SectionLeaders/>}/>

        <Route path="syllabus" element={<Syllabus />} />
        <Route path="schedule" element={<Schedule />} />
        <Route path="installing-pycharm" element={<InstallingPycharm />} />
        <Route path="pycharm-bugs" element={<PycharmBugs />} />
        <Route path="karel-pycharm" element={<KarelPycharm />} />
        <Route path="honor-code" element={<HonorCode />} />
        <Route path="graphics" element={<GraphicsReference />} />
        <Route path="interactive-programs" element={<InteractivePrograms />} />
        <Route path="images" element={<ImageReference />} />
        <Route path="style" element={<StyleGuide />} />
        <Route path="practice-midterm" element={<PracticeMidterm />} />
        <Route path="practice-midterm-solution" element={<PracticeMidtermSoln />} />
        <Route path="midterm-soln" element={<MidtermSoln />} />
        <Route path="contest" element={<Contest />} />
        <Route path="final-prep" element={<FinalPrep />} />
        <Route path="jasmyn" element={<FinalPrepSoln />} />
        <Route path="practice-final" element={<PracticeFinal />} />
        <Route path="practice-final-soln" element={<PracticeFinalSoln />} />
        <Route path="citations" element={<Citations />} />
        <Route path="rao" element={<FinalSoln />} />

        <Route path="lecture/:lectureId" element={<Lecture />} />
        <Route path="assn0" element={<Authenticated component={Assn0} />} />
        <Route path="assn1" element={<Assn1 />} />
        <Route path="assn2" element={<Assn2 />} />
        <Route path="assn3" element={<Assn3 />} />
        <Route path="assn4" element={<Assn4 />} />
        <Route path="assn5" element={<InfiniteStory />} />
        <Route path="assn6" element={<Assn6 />} />
        <Route path="section1" element={<Section1 />} />
        <Route path="section2" element={<Section2 />} />
        <Route path="section3" element={<Section3 />} />
        <Route path="section4" element={<Section4 />} />
        <Route path="section5" element={<Section5 />} />
        <Route path="section6" element={<Section6 />} />
        <Route path="section7" element={<Section7 />} />
        <Route path="isais" element={<Soln1 />} />
        <Route path="elijah" element={<Soln2 />} />
        <Route path="kaia" element={<Soln3 />} />
        <Route path="matthew" element={<Soln4 />} />
        <Route path="febie" element={<Soln5 />} />
        <Route path="fabio" element={<Soln6 />} />
        <Route path="miro" element={<Soln7 />} />
        <Route path="lesson1" element={<LessonPlan1 />} />
        <Route path="lesson2" element={<LessonPlan2 />} />
        <Route path="lesson3" element={<LessonPlan3 />} />
        <Route path="lesson4" element={<LessonPlan4 />} />
        <Route path="lesson5" element={<LessonPlan5 />} />
        <Route path="lesson6" element={<LessonPlan6 />} />
        <Route path="lesson7" element={<LessonPlan7 />} />
        <Route path="lesson8" element={<LessonPlan8 />} />
        <Route path="midtermprep" element={<MidtermPrep/>} />


        <Route
          path=":courseId/code"
          element={<Authenticated component={CodeCenter} />}
        />
        <Route path="signin" element={<Authenticated component={ToHome} />} />
        <Route
          path=":courseId/forum"
          element={<Authenticated component={CS106AForum} />}
        />
      </Route>
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};


/*
<Route
        path=":courseId/ide/:urlFormat/:urlKey"
        element={
          <PyodideProvider>
            <Authenticated component={IDEWithProvider} />
          </PyodideProvider>
        }
      />
      */