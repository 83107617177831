import { courseInfo } from "cs106a/courseInfo"
import { useState } from "react"
import { FaClock, FaEnvelope, FaHome, FaUser, FaUserFriends } from "react-icons/fa"

const sectionLeaderInfo = {
    name: "Section Leaders",
    img:`${process.env.PUBLIC_URL}/people/mainquad.jpeg`,
    website:'https://cs198.stanford.edu/cs198/'
}

export const TeachingTeam = () => {
    /*
    const [instructorComponent, _] = useState([
        <ProfilePic {...courseInfo.instructors[0]} role="Co-Instructor"/>,
        <ProfilePic {...courseInfo.instructors[1]} role="Co-Instructor"/>,
    ].sort(() => Math.random() - 0.5))*/
   
    return <>
        <p className="subtleHeading">Teaching Team</p>
        <ProfilePic {...courseInfo.instructors} role="Instructor"/>
        <ProfilePic {...courseInfo.headTA} role="Head TA"/>
    
    </>
}

export const TeachingTeamMobile= ()=>{
    const [instructorComponent, _] = useState([
        <MobileProfilePic {...courseInfo.instructors[0]} role="Co-Instructor"/>,
        <MobileProfilePic {...courseInfo.instructors[1]} role="Co-Instructor"/>,
    ].sort(() => Math.random() - 0.5))
    return <>
     <p className="subtleHeading">Teaching Team</p>
     <div className="row g-0">
        {/* {instructorComponent} */}
        <MobileProfilePic {...courseInfo.instructors} role="Instructor"/>
        <MobileProfilePic {...courseInfo.headTA} role="Head TA"/>
        <MobileProfilePic {...sectionLeaderInfo} role="SL"/>
        </div>
    </>
}

const MobileProfilePic = ({name, img, website, role}) => {
    return <div className="col-6">
        <a target="_blank" href={website}>{name}</a>
		<br/>
        <img className="round-img" src={img} alt={name}/><br/>
    </div>
}


const ProfilePic = ({name, img, email, officeHours, office, website, role}) => {
    return <div className="mb-2">
		<a target="_blank" href={website}>{name}</a>
		<br/>
		<img className="round-img" src={img} alt={name}/><br/>
        <FaUserFriends className="sidebaricon"/> {role}<br/>
        <FaEnvelope className="sidebaricon"/> {email}<br/>
        <FaClock className="sidebaricon"/> {officeHours}<br/>
        <FaHome className="sidebaricon"/> {office}
	</div>
}