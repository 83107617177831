import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useCourseId } from "hooks/router/useUrlParams";
import { NavigationHistoryContext } from "contexts/NavigationContext";
import { CompletionContext } from "contexts/CompletionContext";
import { useContentHelpers } from "components/duolessons/lessonchats/ContentHelpers";
import { useUserId } from "hooks/user/useUserId";
import { MentorTrainingArm, StudentTrainingArm, CS106ATrainingArm } from './ContentArms'

const defaultData = {
  lessonId: undefined,
  lessonData: undefined,
  slides: undefined,
  currSlideData: undefined,
  currSlideIndex: 0,
  currSlideId: undefined,
  nextSlide: () => { },
  prevSlide: () => { },
  gotoSlide: (id) => { },
  closeLesson: () => { },
  getOutlineSlideId: (e) => { },
  player: null,
  setPlayer: (e) => { },
  getCurrentContent: async () => { return "" },
};

export const TeachNowTrainingContext = React.createContext(defaultData);


const teachNowTrainingLessons = {
    teachingTeamTraining: MentorTrainingArm, 
    studentTraining: StudentTrainingArm,
    cs106aStudentTraining: CS106ATrainingArm,
}

export const TeachNowTrainingProvider = ({ children, startSlideId, userArm }) => {
  const navigate = useNavigate();

  /**
   * slideIndex vs slideId
   * Note to the user: there are two ways of knowing where you are:
   * slideIndex and slideId. If the author of the lesson choses to change
   * the order of things (eg by inserting a new slide early on), slideId 
   * will be preserved, but slideIndex will not. As such, only use slideIndex
   * for local session state.
   * 
   * page or slide?
   * In different parts of the code, we refer to the lesson slides as pages.
   * We are trying to migrate everything to slides...
   * 
   * slideId in the outline?
   * Not all slides will show up in the outline. If you are in a slide that 
   * does not show up in the outline, it can be helpful to know the last slide
   * which does. For that you can use getOutlineSlideId(index)
   */

  // figure out which arm you are in and then pull that lesson 
  const lessonData = teachNowTrainingLessons[userArm]

  const {getPreviousLocation} = useContext(NavigationHistoryContext)

  const slides = lessonData?.slides;
  const armId = lessonData?.id;
  const startSlideIndex = getStartSlideIndex(slides, startSlideId)

  const [currSlideIndex, setCurrSlideIndex] = useState(startSlideIndex);
  const previousLocation = getPreviousLocation()
  const courseId = useCourseId();

  // The video player for the lesson
  const [player, setPlayer] = useState(null);

  const currSlideData = slides[currSlideIndex];
  const currSlideId = currSlideData?.id;


  // TODO: Add a teachNowTraining completion to the Completion Context 
  const { setTeachNowTrainingCompleted, setTeachNowTrainingSlideCompleted } = useContext(CompletionContext);

  useEffect(() => {
    // add the slideId to the URL
    if (armId && currSlideId) {
      const path = `/${courseId}/teachnowtraining/${armId}/${currSlideId}`;
      if (window.location.pathname !== path) {
        navigate(path, { replace: true });
      }
    }
  }, [currSlideId])

  useEffect(() => {
    // if the startSlideId changes (eg a new URL) we should react
    // by changing the current slide index
    const startSlideIndex = getStartSlideIndex(slides, startSlideId)
    setCurrSlideIndex(startSlideIndex);
  }, [startSlideId])
  

  const { getCurrentContent } = useContentHelpers({ player, lessonData, currSlideId, currSlideData });

  const closeLesson = () => {
    // log to a different location if you are in the cs106a training
    if (courseId === 'cs106a') {
      navigate('/cs106a/teachnowhome', { replace: true });
      return;
    }
    const onwardsUrl = previousLocation || `/${courseId}/home`
    navigate(onwardsUrl, { replace: true });
  };

  const nextSlide = () => {
    const nextIndex = currSlideIndex + 1;
    if (nextIndex >= slides.length) {
      Swal.fire({
        title: 'Congratulations!',
        text: 'You have completed this part of the training!',
        icon: 'success',
        confirmButtonText: 'Wahoo!'
      }).then(() => {
        setTeachNowTrainingCompleted()
        closeLesson();
      })
    } else {
      setTeachNowTrainingSlideCompleted(currSlideId)
      setCurrSlideIndex(currSlideIndex + 1);
    }
  }

  const gotoSlide = (targetId) => {
    // loop over all the pages
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].id === targetId) {
        setCurrSlideIndex(i);
        return;
      }
    }
  }

  const prevSlide = () => {
    setCurrSlideIndex(currSlideIndex - 1);
  }

  const getOutlineSlideId = (startIndex) => {
    /** 
     * Not all slides will show up in the outline. If you are in a slide that 
     * does not show up in the outline, it can be helpful to know the last slide
     * which does have an Id (for example when highlighting the current slide 
     * in the LessonDrawer). 
     */
    let i = startIndex;
    // some error cases
    if (!slides || i < 0 || i >= slides.length) {
      return undefined
    }

    // find the first slide that is not hidden from the outline
    while(i >= 0) {
      const slideData = slides[i]?.data
      const hideFromOutline = slideData?.hideFromOutline
      if (!hideFromOutline) {
        return slides[i]?.id;
      }
      i--;
    }
    
    // if there does not exist an answer
    return undefined;
  }

  return (
    <TeachNowTrainingContext.Provider
      value={{
        armId,
        lessonData,
        slides,
        currSlideData,
        currSlideId,
        currSlideIndex,
        getOutlineSlideId,
        nextSlide,
        prevSlide,
        gotoSlide,
        closeLesson,
        player,
        setPlayer,
        getCurrentContent
      }}
    >
      {children}
    </TeachNowTrainingContext.Provider>
  );
};

function getStartSlideIndex(slides, startSlideId) {
  if (!slides || !startSlideId) {
    return 0;
  }
  for (let i = 0; i < slides.length; i++) {
    if (slides[i].id === startSlideId) {
      return i;
    }
  }
  return 0;
}