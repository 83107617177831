import React, { useState, useEffect, useCallback, useRef } from "react";
import "firebase/compat/firestore";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useDocumentData } from "react-firebase-hooks/firestore";

/**
 * This function loads a project data, when you do not need the
 * assignment data (for example when rendering published code)
 * @param {string} projectId
 * @returns
 */
export function loadPublishedProject(projectId, courseId) {
  // this function runs faster by making two parallel requests.
  // projectRef uses document data (it updates when the project does)
  const db = getFirestore();
  const projectRef = doc(db, `published/${courseId}/studentPublished/`, projectId);

  const [projectData, projectLoading, projectError] = useDocumentData(projectRef)
  const [projectCode, setProjectCode] = useState(null);
  const [error, setError] = useState(null);

  // load the project code
  useEffect(() => {
    const fetchData = async () => {
      
      await getProjectCode(
        projectId,
        courseId,
        (r) => setProjectCode(r),
        (e) => setError(e)
      );
    };
    fetchData().catch(console.error);
  }, []);

  // listen for errors
  useEffect(() => {
    if (projectError) {
      setError(projectError);
    }
  }, [projectError]);

  return [projectData, projectCode, error, projectLoading];
}

// This is just a synchronous function wrapper
export function loadDisplayName(uid) {
  const [displayName, setDisplayName] = useState(null);
  const [error, setError] = useState(null);
  // load the project metaData
  useEffect(() => {
    const fetchData = async () => {
      await getDisplayName(
        uid,
        (r) => setDisplayName(r),
        (e) => setError(e)
      );
    };
    fetchData().catch(console.error);
  }, []);

  return [displayName, error];
}

const functions = getFunctions();
const getUserNames = httpsCallable(functions, "getUserNames");

// Look up the uid in the database
async function getDisplayName(uid, onResponse, onError) {
  const lookupUsersResult = await getUserNames({
    uids: [uid],
  });
  try {
    // TO DO - add better error checking around this
    onResponse(lookupUsersResult.data[0]);
  } catch (e) {
    onError("getDisplayName did not work");
    console.log("getDisplayName did not work");
  }
}

async function getProjectData(projectId, courseId, onResponse, onError) {
  const db = getFirestore();
  const docRef = doc(db, `published/${courseId}/studentPublished/`, projectId);
  const response = await getDoc(docRef);

  if (response.exists()) {
    const data = response.data();
    onResponse(data);
  } else {
    onError("Project ID is invalid.");
    console.error("Failed to load project data");
  }
}

async function getProjectCode(projectId, courseId, onResponse, onError) {
  const db = getFirestore();
  const collectionRef = collection(
    db,
    `published/${courseId}/studentPublished/${projectId}/code`
  );
  const response = await getDocs(collectionRef);

  const responseData = {};
  response.forEach((doc) => {
    responseData[doc.id] = {
      content: doc.data().content,
    };
  });

  console.log("PROJECT CODE: ", responseData);

  onResponse(responseData);
}
