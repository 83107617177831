import { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import { TeachNowTrainingContext } from "./TeachNowTrainingContext";
import { HorizontalSpeechBubble } from "components/duolessons/lessontypes/SpeechBubbles";
import { ReactComponent as MissionIcon } from "icons/mission.svg";
import { Button } from "react-bootstrap";
import { TeachNowButtonWrapper } from "course/carehours/CareHoursPage";
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate";
import { useUserId } from "hooks/user/useUserId";
import { useUploadedVideo, useStudentPuzzleDataDoc } from "./TNFirebase"
import { getTeachNowTrainingVideoStoragePath } from "course/application/sectionLeaderApplication/teachingDemo/uploadVideo";
import { Loading } from "components/loading/Loading";
import { CompletionContext } from "contexts/CompletionContext";
import { Link } from "react-router-dom";

export const TNSplashCoverpage = () => {
  const { currSlideId, lessonData } = useContext(TeachNowTrainingContext);
  const title = lessonData.title
  const authors = lessonData.authors
  const course = lessonData.course

  
  // const lessonData = lessonsMap[currSlideId]
  const lessonLength = computeLength(lessonData)

  return <SplashOuter>
    <ImageOuter>
      <BannerImg src={'/stanford.png'} alt="Stanford Logo"/>
    </ImageOuter>
    <BannerText>
      <h1>{title}</h1>
      <h2>{authors}</h2>
      <h2>{course}</h2>
    </BannerText>
    {/* <p>Estimated Time: {lessonLength} minutes</p> */}
  </SplashOuter>
}

const ImageOuter = styled.div`
  width: 100%;
  height: auto; /* Adjust this to ensure the container fits content but does not exceed its max height */
  flex-grow: 1;
  flex-basis: 0; /* new */
  max-height: 350px;
  min-height: 50px; /* Ensure there's a minimum height */
  display: flex;
  align-items: center;
  justify-content: center;

`


const BannerImg = styled.img`
  max-width: 100%;
  max-height: 100%; /* This respects the container's max-height */
  object-fit: contain; /* Keeps aspect ratio without dictating container size */
`


const BannerText = styled.div`
  margin-top: 20px;
  border: 3px solid black;
  padding: 40px;
  min-width: 600px;
  margin-bottom: 40px;
  border-radius: 10px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100px;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-height: 800px) {
    padding: 20px;
  }
`


const SplashOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


function computeLength(data) {
  // for slide in data.slides
  let totalMins = 0
  for (var i = 0; i < data.slides.length; i++) {
    const slide = data.slides[i]
    const slideData = slide?.data
    const slideLength = slideData?.lengthMins
    if (slideLength) {
      // parse string
      totalMins += parseInt(slideLength)
    }
  }

  return totalMins
}




const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width:100%;
`;

const ButtonCard = ({ text, icon, to }) => {

  const state = {
    from: '/welcome/choice'
  }
  return <ButtonCardOuter className="card mb-3">


    <ButtonCardInner>
      
      <div  style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
        {icon}
      </div>
      <CardText>
        <h5 className="card-text">{text}</h5>
      </CardText>
    </ButtonCardInner>


  </ButtonCardOuter>
}

const CardText = styled.div`
  flex: 1;
  padding: 10px;
  `


const ButtonCardOuter = styled.div`
  max-width: 500px;
  text-align:left;
  width: 100%;
  `;

const ButtonCardInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

export const TeachNowTrainingOverview = ({ data }) => {
  const goals = data?.learningGoals
  const iconPath = data?.imgUrl || '/karel192.png'
  return <FullContent>
    <CenteredContent>
      <div className="mb-3">
        <HorizontalSpeechBubble text="In this training you will:" iconPath={iconPath} />
      </div>
      {goals.map((goal, i) => {
        return <ButtonCard
          key={i}
          to={goal.value}
          text={goal.value}
          icon={<MissionIcon style={{ width: '50px', height: '50px' }} aria-hidden="true"/>}
        />
      })}
    </CenteredContent>
  </FullContent>
}

export const BlankSlideWithText = ({ data }) => {
  const { currSlideData } = useContext(TeachNowTrainingContext);
  const { isAssnCompleted } = useContext(CompletionContext);
  const assnId = currSlideData?.data?.assnId;
  const text = data?.text || ''
  const ideLink = data?.ideLink || ''
  const afterCompletionText = data?.afterCompletionText || ''
  const handoutLink = data?.handoutLink || ''
  const navigate = useHistoryNavigate()
  const teachNowButton = data?.teachNowButton || false

  // Logic to render a different text if the user has already completed the assignment. 
  if (ideLink && afterCompletionText && assnId && isAssnCompleted(assnId)) {
    return <FullContent>
      <CenteredContent>
        <p style={{maxWidth: "800px", fontSize: "1.8em"}}>
          {afterCompletionText} <a href={ideLink} target="_blank">View problem in IDE.</a>
        </p>
        </CenteredContent>
    </FullContent>
  }

  return <FullContent>
    <CenteredContent>
      <p style={{maxWidth: "800px", fontSize: "1.8em"}}>
        {text}
      </p>
      {
        teachNowButton ? 
        <div className="d-flex flex-column" style={{width: "200px"}}>
          <TeachNowButtonWrapper
            buttonOnly={true}
          />
        </div>
        : null
      }
      {
        data?.handoutLink ? 
        <a href={handoutLink} target="_blank" rel="noreferrer">
          <Button style={{ backgroundColor: 'mediumseagreen', borderColor: 'mediumseagreen'}}>
            View Handout 
          </Button>
        </a>
        : null
      }
      {
        data?.ideLink ? 
        <a href={ideLink} target="_blank" rel="noreferrer">
        <Button>
          Open IDE
        </Button>
        </a>
        : null
      }
    </CenteredContent>
  </FullContent>
}


// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Card = styled.div`
  width: 500px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  text-align: center;
`;

const Title = styled.h1`
  margin: 0 0 20px 0;
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const List = styled.ul`
  text-align: left;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  font-size: 16px;
  color: ${props => props.completed ? 'green' : 'red'};
  margin-bottom: 10px;
`;


export const DualPhotoSpeechBubble = ({ data }) => {
  const { leftImg, rightImg, text, textSide, textAlign, fontSize='20px' } = data
  return (
    <CenteredContent>
    <SpeechBubble text={text} fontSize={fontSize} textAlign={textAlign} textSide={textSide} />
    <PhotoBubbleContainer>
      <IconImage src={leftImg} alt="Left" />
      <IconImage src={rightImg} alt="Right" />
    </PhotoBubbleContainer>
  </CenteredContent>
  )
}

const SpeechBubble = ({ text, textAlign, fontSize, textSide }) => (
  <SpeechBubbleText style={{ textAlign, fontSize, 'width': '400px' }} textSide={textSide}>
    {text}
    <TailBottom textSide={textSide} />
  </SpeechBubbleText>
);

const PhotoBubbleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; // Added space between the text and images
`;

const IconImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin: 0 10px;
`;

const SpeechBubbleText = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 20px;
  color: #333333;
  font-size: 16px;
  border: 1px solid grey;
  text-align: center;
  width: 90%;
  position: relative;
`;

const TailBottom = styled.div`
  position: absolute;
  bottom: -10px;
  left: ${(props) => (props.textSide === 'left' ? '25%' : '75%')}; // Adjust to shift the tail based on the text side
  transform: translateX(-50%) rotate(225deg);
  background: #FFFFFF;
  width: 20px;
  height: 20px;
  content: '';
  clip-path: polygon(0 0, 100% 0, 0 100%);
  border: 1px solid grey;
`;


export const EndOfTraining = ({setShowContinueButton}) => {
  const [puzzleStatus, setPuzzleStatus] = useState(false)
  const [videoStatus, setVideoStatus] = useState(false)

  const userId = useUserId();

  const path = getTeachNowTrainingVideoStoragePath(userId)
  const [videoUrl, videoLoading, videoError] = useUploadedVideo(userId, path)

  const [userPuzzleDoc, userPuzzlesDocLoading, userPuzzlesDocError] = useStudentPuzzleDataDoc()

  useEffect(() => {
    if (videoLoading || userPuzzlesDocLoading) {
      console.log('Data is still loading')
      return
    }
    if (!videoError && videoUrl) {
      setVideoStatus(true)
    }
    if (!userPuzzlesDocError && userPuzzleDoc.exists()) {
      const data = userPuzzleDoc.data();
      const seenPuzzles = data.seenPuzzles ? data.seenPuzzles : [];
      const numPuzzles = seenPuzzles.length;
      if (numPuzzles >= 6) {
        setPuzzleStatus(true)
      }
    }
  }, [userPuzzleDoc, videoUrl, userPuzzlesDocLoading, videoLoading, videoError])

  useEffect(() => {
    if (videoStatus && puzzleStatus) {
      setShowContinueButton(true)
    }
  }, [videoStatus, puzzleStatus])

  if (videoLoading || userPuzzlesDocLoading) {
    return <Loading />
  }

  if (puzzleStatus && videoStatus) {
    return <DualPhotoSpeechBubble
       data = {{
        text: "That's all folks! Thanks for your interest in teaching. It is a wondeful thing.",
        leftImg: '/people/juliette.jpeg',
        rightImg: '/people/ali.jpg',
        textSide: 'left',
        textAlign: 'left',
        }
       }
    />
  }

  return (
    <Container>
      <Card>
        <Title>You Haven't Quite Finished Training!</Title>
        <Description>Thanks for your interest in teaching. You haven't quite finished the training yet. See below for what you still need to do.</Description>

        <List>
          <ListItem completed={puzzleStatus}>
            {puzzleStatus ? '✔️' : '❌'} Completed All Teaching Practice
          </ListItem>
          <ListItem completed={videoStatus}>
            {videoStatus ? '✔️' : '❌'} Uploaded a video introducing yourself and setting the tone
          </ListItem>
        </List>
      </Card>
    </Container>
  )
}

export const CS106AEndOfTraining = () => {
  const [puzzleStatus, setPuzzleStatus] = useState(false)
  const [videoStatus, setVideoStatus] = useState(false)

  const userId = useUserId();

  const path = getTeachNowTrainingVideoStoragePath(userId)
  const [videoUrl, videoLoading, videoError] = useUploadedVideo(userId, path)

  const [userPuzzleDoc, userPuzzlesDocLoading, userPuzzlesDocError] = useStudentPuzzleDataDoc()

  useEffect(() => {
    if (videoLoading || userPuzzlesDocLoading) {
      console.log('Data is still loading')
      return
    }
    if (!videoError && videoUrl) {
      setVideoStatus(true)
    }
    if (!userPuzzlesDocError && userPuzzleDoc.exists()) {
      const data = userPuzzleDoc.data();
      const seenPuzzles = data.seenPuzzles ? data.seenPuzzles : [];
      const numPuzzles = seenPuzzles.length;
      if (numPuzzles >= 6) {
        setPuzzleStatus(true)
      }
    }
  }, [userPuzzleDoc, videoUrl, userPuzzlesDocLoading, videoLoading, videoError])

  // useEffect(() => {
  //   if (videoStatus && puzzleStatus) {
  //     setShowContinueButton(true)
  //   }
  // }, [videoStatus, puzzleStatus])

  if (videoLoading || userPuzzlesDocLoading) {
    return <Loading />
  }

  if (puzzleStatus && videoStatus) {
    return (
      <Container>
        <Card>
          <Title>You Have Finished Training!</Title>
          <Description>Congratulations! You have completed this part of the training. You can now start hosting 1:1 help sessions! Click the button below to try one now.</Description>

          <TeachNowButtonWrapper 
            buttonOnly={true}
          />
        </Card>
      </Container>
    )
  }

  return (
    <Container>
      <Card>
        <Title>You Haven't Quite Finished Training!</Title>
        <Description>Thanks for your interest in teaching. You haven't quite finished the training yet. See below for what you still need to do.</Description>

        <List>
          <ListItem completed={puzzleStatus}>
            {puzzleStatus ? '✔️' : '❌'} Completed All Teaching Practice
          </ListItem>
          <ListItem completed={videoStatus}>
            {videoStatus ? '✔️' : '❌'} Uploaded a video introducing yourself and setting the tone
          </ListItem>
        </List>
      </Card>
    </Container>
  )
}
