import { faPython } from "@fortawesome/free-brands-svg-icons";
import { faFileUpload, faHammer, faUserFriends, faVial } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Handout } from "cs106a/components/Handout";
import { courseInfo } from "cs106a/courseInfo";
import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaCheck, FaUserFriends, FaVial } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use-size";



export const Schedule = () => {
  return <div className="container-lg mt-4"><ScheduleInner /></div>
}

const ScheduleInner = () => {
  return <>
    <div className="pb-2 mb-2 "><h1>Schedule</h1></div>
    <ScheduleTable />
  </>
}

const ScheduleTable = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768
  const nWeeks = 10
  return <div>
    <Table responsive bordered>
      <thead>
        <tr>
          <th
            className="non-mobile-only-tc"
            style={{ width: "8%" }}
          >
            Wk
          </th>
          <th style={{ minWidth: "78" }}>Monday</th>
          <th style={{ minWidth: "78" }}>Wednesday</th>
          {!isMobile && <th style={{ minWidth: "78px" }}>Section</th>}
          <th style={{ minWidth: "78" }}>Friday</th>
        </tr>
      </thead>
      {
        Array.from({ length: nWeeks }, (_, i) => i).map((week) => {
          return <Week key={week} weekIndex={week} />
        })
      }
    </Table>
    <p>
      The schedule is subject to change by the management at any
      time.{" "}
    </p>
  </div>
}

const Week = ({ weekIndex }) => {
  const lectureSchedule = courseInfo.lectureSchedule;
  const firstCourseLecture = lectureSchedule["1"].date;
  const displayWeek = weekIndex + 1;
  // add 7 days * weekIndex to the first day
  const monday = new Date(firstCourseLecture.getTime() + weekIndex * 7 * 24 * 60 * 60 * 1000);
  const nextMonday = new Date(monday.getTime() + (7 * 24 - 1) * 60 * 60 * 1000);

  // get all lectures between monday and nextMonday. 
  const weekLectures = Object.values(lectureSchedule).filter(lecture => {
    return lecture.date >= monday && lecture.date < nextMonday;
  }
  );

  // sort weekLectures by date
  // @ts-ignore
  weekLectures.sort((a, b) => a.date - b.date);

  console.log(weekLectures)

  return (
    <tr>
      <td className="calendarWeekCell non-mobile-only-tc">{displayWeek}</td>
      <Day
        lectureData={weekLectures[0]}
        week={displayWeek}
        day={0}
      />
      <Day
        lectureData={weekLectures[1]}
        week={displayWeek}
        day={2}
      />
      {displayWeek > 1 && displayWeek < 10 ? <Section /> : <NoSection />}
      <Day
        lectureData={weekLectures[2]}
        week={displayWeek}
        day={4}
      />
    </tr>
  )
}

const Day = ({ lectureData, week, day}) => {

  console.log("Rendering Day", lectureData, week, day)

  const noLecture = "lecture" in lectureData && !lectureData.lecture;
  console.log("noLecture", noLecture)

  const dayOffset = getOffsetDate(week - 1, day);
  const dayStr = getOffsetDateStr(week - 1, day);

  var num = "";
  if(lectureData?.lectureNum){
    num = lectureData.lectureNum + ".";
  }
  const name = lectureData?.title;
  const url = noLecture ? "" : `/lecture/${lectureData.lectureNum}`;


  const backgroundColor = noLecture ? "lightsalmon" : "#fff";

  // check if day is past the current date
  const past = lectureData.date <= new Date();
  const lessionElement = <span>{num} <br className="mobile-only" />
    {name}</span>

  let hwIcon =
    lectureData.assn ? (
      <span>
        <span>
          <FontAwesomeIcon className="sidebaricon" icon={faFileUpload} />
          <br className="mobile-only" /> Assn {lectureData.assn} due
        </span>
      </span>
    ) : (
      <></>
    );



  return (
    <td className="calendarCell" style={{backgroundColor}}>
      <p className="subtleHeading" style={{backgroundColor}}>{dayStr}</p>
      {past ? <Link to={url} style={{backgroundColor}}>
        {lessionElement}
      </Link> : <span style={{ padding: 8, backgroundColor }}>{lessionElement}</span>}
      <br style={{backgroundColor}}/>
      {hwIcon}
    </td>
  );
}


const NoSection = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  if (isMobile) {
    return <></>
  }
  return (
    <td className="calendarCell" >
      <p className="subtleHeading">No Section</p>
    </td>
  );
}

const Section = (props) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  if (isMobile) {
    return <></>
  }
  return (
    <td className="calendarCell" style={{ backgroundColor: "#edefff" }}>
      <p className="subtleHeading" style={{ backgroundColor: "#edefff" }}>Section</p>
      <span style={{ backgroundColor: "#edefff" }}> {props.name}</span>
    </td>
  );
}

function getOffsetDate(weekOffset, dayOffset) {
  const lectureSchedule = courseInfo.lectureSchedule;
  const firstDay = lectureSchedule["1"].date;

  const weekOffsetInDays = weekOffset * 7;
  const dayOffsetInDays = dayOffset;
  // get the offset after firstDay
  const offset = weekOffsetInDays + dayOffsetInDays;
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const offsetTimestamp = firstDay.getTime() + (offset * oneDay);
  return new Date(offsetTimestamp);
}

function getOffsetDateStr(weekOffset, dayOffset) {
  const offsetDate = getOffsetDate(weekOffset, dayOffset)
  return getDateStr(offsetDate);
}

function getDateStr(date) {
  const formatter = new Intl.DateTimeFormat("en-US",
    { month: "short", day: "numeric" });
  const parts = formatter.formatToParts(date);
  const dayIndex = parts.findIndex(part => part.type === "day");
  const dayValue = parseInt(parts[dayIndex].value);
  const dayWithSuffix = dayValue + getOrdinalSuffix(dayValue);
  parts[dayIndex].value = dayWithSuffix;
  const formattedDate = parts.map(part => part.value).join("");

  return formattedDate;
}

function getOrdinalSuffix(day) {
  const lastDigit = day % 10; // Get the last digit of the day
  const secondLastDigit = day % 100 - lastDigit; // Get the second-last digit of the day

  // Use special suffixes for "teen" numbers (11, 12, 13)
  if (secondLastDigit === 10) {
    return "th";
  }

  // Use "st" suffix for numbers ending in 1 (except for "teen" numbers)
  if (lastDigit === 1) {
    return "st";
  }

  // Use "nd" suffix for numbers ending in 2 (except for "teen" numbers)
  if (lastDigit === 2) {
    return "nd";
  }

  // Use "rd" suffix for numbers ending in 3 (except for "teen" numbers)
  if (lastDigit === 3) {
    return "rd";
  }

  // Use "th" suffix for all other numbers
  return "th";
}


export default Schedule;