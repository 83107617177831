import { useParams } from "react-router";
import { useCourseId } from "hooks/router/useUrlParams";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useComponentSize } from "react-use-size";
import { TextbookChapter } from "./TextbookChapter";
import { TextbookSidebar } from "./components/TextbookSidebar";
import { ProSidebarProvider, useProSidebar } from "react-pro-sidebar";
import { FaBars } from "react-icons/fa";
import { TextbookSplash } from "./TextbookSplash";
import { ProfileProvider } from "contexts/ProfileContext";
import PyodideProvider from "components/pyodide/PyodideProvider";


export const TextbookPage = ({ textbookId }) => {
  const courseId = useCourseId()
  const db = getFirestore()

  const coursePath = `/textbook/${courseId}`
  const docPath = textbookId ? `${coursePath}/books/${textbookId}` : coursePath
  const textbookRef = doc(db, docPath)


  const [chapterData, textbookLoading, textbookError] = useDocumentData(textbookRef)


  if (textbookLoading) return <></>

  return <PyodideProvider>
    <ProfileProvider>
      <ProSidebarProvider>
        <TextbookView textbookId={textbookId} chapterData={chapterData} textbookPath={docPath} />
      </ProSidebarProvider>
    </ProfileProvider>
  </PyodideProvider>
}

const TextbookView = (props) => {
  const pageSize = useComponentSize();
  // this boolean represents if you need to show the
  // version of the forum where the post list is full page
  const isBroken = pageSize.width < 650;

  return <div ref={pageSize.ref} style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
    <StandardView {...props} />
  </div>
}

const StandardView = (props) => {
  const { chapterData, textbookKey } = props
  console.log(chapterData)
  console.log('StandardView', props)
  const currentChapterId = useParams()?.chapterId
  return <div className="d-flex flex-row" style={{ height: '100vh', overflow: 'hidden' }}>
    <TextbookSidebar chapterData={chapterData} textbookId={props.textbookId} />

    <div className="d-flex justify-content-center" style={{ flexGrow: 2, overflow: 'auto' }}>
      <div className="d-flex" style={{ maxWidth: 800, flexGrow: 2, width: '100%' }}>
        {currentChapterId ? (
          <TextbookChapter chapterData={chapterData} />
        ) : (
          <TextbookSplash {...props} />
        )}
      </div>
    </div>
  </div>
}


const BrokenView = ({ chapterData }) => {
  return <>
    <ToggleAside />
  </>
}

const ToggleAside = (props) => {
  const { toggleSidebar } = useProSidebar();
  return (
    <div>
      <button
        className="btn btn-outline-dark"
        onClick={() => toggleSidebar()}
      >
        <FaBars />
      </button>
    </div>
  );
};