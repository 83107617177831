/**
 * Only course admins can edit this RTE (with an edit button below the RTE)
 * Users see just the HTML rendering
 */

import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { HandoutButtonBar } from "components/richTextEditor/TipTap/buttonbars/HandoutButtonBar"
import { ProfileContext, Role } from "contexts/ProfileContext"
import { isMinimumRole } from "contexts/ProfileUtil"
import { useContext, useState } from "react"
import { FaEdit } from "react-icons/fa"

// must be in a ProfileContext

export const AdminRichTextEditor = ({ firebaseDocPath, minRole = Role.TA }) => {
  const {userData} = useContext(ProfileContext)
  
  const courseRole = userData.courseRole

  const hasPermissions = isMinimumRole(courseRole, minRole)

  const [isEditing, setIsEditing] = useState(false)
  return <div className="handout-tiptap">
  <TipTap
    firebaseDocPath={firebaseDocPath}
    editable={isEditing}
    collaborative={isEditing}
    buttonBar={HandoutButtonBar}
    showLoadingSkeleton={true}
  />
    {hasPermissions && <button className="btn btn-light" onClick={() => setIsEditing(!isEditing)}>
      {isEditing ? "Done" : <FaEdit/>}
    </button>}
  </div>
}