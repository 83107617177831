import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan1 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 1: Karel the Robot</h1>
        <hr />

        <p>It's almost time for your first CS106A section of the quarter (or maybe ever)!! In the
            first week of section, we're hoping you can get to know your section and they get to
            know each other, and that they understand why section is such a cool and important
            part of 106A. In addition, we are hoping you can get to at least one Karel problem as
            these will be helpful as students work through Assignment 1.</p>

        <h3>Getting to know your section</h3>
        <p>Feel free to spend a good chunk of time learning names and doing an icebreaker! You should
            also establish how section works and answer logistical questions about the class.
            Here are a few good things to cover:</p>
        <ul>
            <li>We'll be having IGs for every assignment, except the last one.</li>
            <li>Section attendance and participation are required.</li>              
            <li>If a student can't make a section on a given week, they can attend another section as many times as they want without asking for advance permission.</li>
            <li>CS106A has lots of help resources: LaIR, office hours, and a forum.</li>
        </ul>

        <p>All this being said, if students go down rabbit holes of detailed logistical questions,
            feel free to have them come talk to you after section or check the syllabus so that
            you leave at least ~20 minutes for section problems.</p>

        <h3>Section flow</h3>
        <ol>
            <li><b>20 min: Intros and logistics.</b></li>
            <li><b>30 min: Karel problem.</b> I'd recommending choosing one problem from
                the <Link to="/section1"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Section 1 Handout</Link> and
                taking your time to work through it. Keep in mind that students will most likely not have learned loops yet, so Hospital Karel might be the best option. Your goal is not just to solve the problem, but more importantly to
                demonstrate a good problem-solving process. Students might be eager to start writing Karel commands, but
                we want to make drawings, decompose the problem into helper functions, and write pseudocode before
                getting to any real code.</li>
        </ol>

        <h3>Section tips</h3>

        <p>I like to do as much of the problem on the board as possible, and minimize students' use of their own
            computers. It's up to you, but you're welcome to ask everyone to close their laptops during section.</p>

        <p>The Karel problems are fun to do in small groups, and this can be a good opportunity for students
            to get to know each other! After establishing what helper functions you'll need as a big group, split your section into
            groups and give every team a helper function to implement on their section of the board, before coming back
            together and discussing.</p>

        <p>Draw diagrams! Your art doesn't have to be amazing, it's just useful to visualize Karel when
            trying to solve these problems. Drawing Karel as an arrow (&gt;) instead of a robot helps :)</p>
    </>
}