import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'


export const Section2 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Section 2: Variables & Control Flow</h1>
        <hr />
        <h5><Link to="/elijah"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>
        <p>This section, we'll practice using variables and using control flow (while loops, if statements) in Python. Although we've
            left Karel behind, while loops and other control flow works the same as before. The last problem on this handout
            will also give you some practice with helper functions that take in some value - we call these values parameters!</p>

        <p>Here's the <a href={`${process.env.PUBLIC_URL}/Section2.zip`}>Section 2 starter code</a>. You'll unzip/extract this
            file and open it in PyCharm just like you did with your assignment starter code.</p>

        <h3>Tracing</h3>
        <p>This is a tracing problem - examine the code without running it. What is the role of
            the <code>SENTINEL</code> constant? How do each of the four variables (<code>a, b, x, y</code>) change each
            time the while loop executes? Overall, what is this program calculating?</p>
        <PythonHighlighter code={tracing} />

        <h3>Calculate Dog Years</h3>
        <p>Everyone knows that our furry friends age at a different rate than humans. Write a program
            that asks the user for a human age and prints the
            equivalent dog age using the fact that there are seven dog years per human year. Consider
            defining a constant <code>DOG_YEARS_PER_HUMAN_YEAR</code> for this value. You can assume
            the user types in an integer age, but not necessarily that the integer is positive. If it
            isn't, print an error message.</p>

        <p>Your program should continuously ask the user for human ages until the user types 0,
            at which the program should end.</p>

        <p>Here's a sample run:</p>

        <SyntaxHighlighter code={dog_years} />

        <h3>Finding Factors</h3>

        <p>Implement a program that asks the user to enter an integer, then prints out all the factors of
            the given number, one by one. Your function should check that the entered number is greater
            than 0. The program should keep asking for numbers until 0 (the sentinel value) is entered.</p>

        <p>Use a helper function called <code>print_factors(num)</code> that takes in the number the
            user enters <code>num</code> as a parameter
            and prints all of its factors. You can do your input validation (checking whether the number
            is negative or equal to the sentinel) in <code>main()</code>, but once you've determined that
            you want to factor this number, call the helper function!</p>

        <p>Here's a sample run:</p>

        <SyntaxHighlighter code={factors} />

        <p>As a fun challenge, after printing out all the factors, print a special message when the number is prime! How
            do you know a number is prime, based on its factors? Should this code go in <code>main()</code> or <code>print_factors(num)</code>?</p>
    </>
}

const tracing = `"""
File: mystery_calculation.py 
----------------------------
It's your job to figure out what this program does!
"""

SENTINEL = -1

def main():
    a = int(input("Enter a value for a: "))
    b = int(input("Enter a value for b: "))
    x = int(input("Enter a value for x: "))
    while x != SENTINEL:
        y = a * x + b
        print("Result for x = " + str(x) + " is " + str(y))
        x = int(input("Enter a value for x: "))
        

if __name__ == "__main__":
    main()`


const dog_years = `Enter an age in human years: -12
Sorry, please enter a positive number or 0 to exit
Enter an age in human years: 13
The age in dog years is 91
Enter an age in human years: 0   `

const factors = `Enter a number to factor: -10
Please input a positive number
Enter a number to factor: 42
1
2
3
6
7
14
21
42
Enter a number to factor: 53
1
53
Enter a number to factor: 0`