import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { useUserId } from 'hooks/user/useUserId';
import { useTeachNowStudentStatus, acceptTeachNowRequest, rejectTeachNowRequest, timeoutTeachNowRequest } from '../../firebase/realtime/TeachNow';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { logRejectionFeedbdack } from 'course/teacherPuzzles/TNFirebase';
import { TimeContext } from 'contexts/TimeContext';

const TIME_LIMIT_MS = 30000;

function generateRandomOptions() {
    const options = [
      { id: 'not-comfortable', value: 'not-comfortable', labelText: 'I am nervous or uncomfortable' },
      { id: 'no-help', value: 'no-help', labelText: "I don't need any help right now" },
      { id: 'english-ability', value: 'english-ability', labelText: "I am worried about my English" },
      { id: 'fear-of-judgement', value: 'fear-of-judgement', labelText: "I am worried what the SL will think of my code" },
      { id: 'not-good-time', value: 'not-good-time', labelText: "I want help but can't meet right now" },
    ];
    // Create a new variable that is a shuffled version of options
    const shuffledOptions = options.map(value => ({ value, sort: Math.random() }))
                            .sort((a, b) => a.sort - b.sort)
                            .map(a => a.value);


    // Programmatically create the html for each option
    return shuffledOptions.map((option) => {
      return renderOption(option.id, 'option', option.value, option.labelText);
    }).join('');
  }

  function renderOption(id, name, value, labelText) {
    return `
      <div class="input-label-pair">
        <input type="radio" id="${id}" name="${name}" value="${value}">
        <label for="${id}">${labelText}</label><br>
      </div>
    `;
  }

async function rejectHelpFeedbackSwal(nudgeId, userId, courseId) {
    Swal.fire({
      title: 'Why not?',
      html:`
        <head>
            <style>
            .rejection-feedback-options {
                text-align: left;
                margin-bottom: 15px;
            }

            .input-label-pair {
                margin-bottom: 10px;
                padding: 15px;
                border: 1px solid #ccc;
            }

        </style>
        </head>
        <body>
        <div>
            <div class="rejection-feedback-options">
            ${generateRandomOptions()}
            </div>
            <div>
            <p style='text-align:left'>Other reasons: <input type='textarea' rows='5' style='width: 100%; margin: 0; margin-top: 10px;
            border: 1px solid black;' id="swal-input2" class="swal2-input"></p>
            </div>
        </div>
        </body>`,
      allowOutsideClick: false,
      preConfirm: () => {
        const checkedOption = document.querySelector('input[name="option"]:checked');
        return {
          'rejectReasonWhyOption': checkedOption ? checkedOption.value : null,
          'rejectReasonWhyComment': document.getElementById('swal-input2').value
        }
      }
    }).then(async (result) => {
      if (result.value) {
        console.log("result.value", result.value)
        await logRejectionFeedbdack(nudgeId, result.value, courseId);
      }
    });
  }

const CustomModal= ({ show, handleClose, slDisplayName, timeJoined, uid, courseId, projId, nudgeId }) => {
    const [timer, setTimer] = useState(undefined);


    const { serverTimeDelta, getServerTimeMs } = useContext(TimeContext);

    useEffect(() => {
        if (serverTimeDelta === undefined) return;
        const countdown = setInterval(() => {
            const currentTime = getServerTimeMs();
            const timeLeft = TIME_LIMIT_MS - (currentTime - timeJoined);
            if (timeLeft <= 0) {
                clearInterval(countdown);
                handleTimeout();
                return;
            }
            setTimer((timeLeft / 1000));
        }, 200);

        return () => clearInterval(countdown);
    }, [show, timeJoined, serverTimeDelta]);

    const handleConfirm = async () => {
        console.log("Confirm clicked");
        await acceptTeachNowRequest(uid, courseId, projId); // Accept the teach now request
        handleClose(); // Close modal on confirm
        // Add your logic for what happens on confirm here
    };

    const handleTimeout = async () => {
        console.log("Timeout occurred");
        // TODO: not sure if we should reject from the front end or what
        await timeoutTeachNowRequest(uid, courseId); // Reject the teach now request
        handleClose(); // Close modal on timeout
        // Add your timeout-specific logic here
    };

    const handleReject = async () => {
        console.log("Reject clicked");
        await rejectTeachNowRequest(uid, courseId); // Reject the teach now request
        await rejectHelpFeedbackSwal(nudgeId, uid, courseId);
        handleClose(); // Close modal on reject
    }

    const progressPercentage = 100 - (TIME_LIMIT_MS - timer * 1000) / TIME_LIMIT_MS * 100;

    return (
        <Modal show={show && timer !== undefined} onHide={handleReject} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title
                    className="w-100"
                >
                    <div className="text-center">
                        Meet with {slDisplayName || "a section leader"}!
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                <div style={{textAlign: 'left'}}>
                    <p>
                        A section leader wants to meet with you! Would you like to join a 1:1 zoom meeting with them?
                    </p>
                    <p>
                        In this meeting you can:
                        <ul>
                            <li>Ask questions</li>
                            <li>Get help with this assignment</li>
                            <li>Say hello! 👋</li>
                        </ul>
                    </p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        margin: 'auto',
                        width: '80%',
                    }}>
                        <Button variant="secondary" onClick={handleReject}>
                            No thanks
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={handleConfirm}
                            style={{
                                backgroundColor: '#4087fc',
                                borderColor: '#4087fc'
                            }}
                        >
                            <img 
                                src='/zoomicon.svg'
                                width="25px"
                                className='mr-1'
                            />Join meeting
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ProgressBar 
                    now={progressPercentage} 
                    className="w-100" 
                    color='lightgray'
                /> 
            </Modal.Footer>
        </Modal>
    );
}

export const TeachNowStudentNudge = ({ projId }) => {
    const userId = useUserId();
    const { courseId } = useParams();
    const studentTeachNowState = useTeachNowStudentStatus(userId, courseId);
    const [modalOpen, setModalOpen] = useState(false);

    // Effect to open the modal when the student status is 'nudged'
    useEffect(() => {
        if(studentTeachNowState === undefined || studentTeachNowState === null) {
            setModalOpen(false);
            return;
        }
        const { studentStatus } = studentTeachNowState;
        if (studentStatus === 'nudged') {
            setModalOpen(true);
        }
    }, [studentTeachNowState]);

    const handleClose = () => {
        setModalOpen(false);
    };


    if(studentTeachNowState === undefined || studentTeachNowState === null) return null;
    console.log(studentTeachNowState, "stutnstate")

    return (
        <>
            {modalOpen && (
                <CustomModal
                    show={modalOpen}
                    slDisplayName={null}
                    timeJoined={studentTeachNowState.studentTimeJoined}
                    handleClose={handleClose}
                    uid={userId}
                    courseId={courseId}
                    projId={projId}
                    nudgeId={studentTeachNowState.nudgeId}
                />
            )}
        </>
    );
};

export default TeachNowStudentNudge;
