import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const HonorCode = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Computer Science and the Stanford Honor Code</h1>
    <hr />

    <p><i>This handout is adapted from a handout developed by Eric Roberts with modifications by Mehran Sahami and Julie Zelenski.</i></p>

    <p>Since 1921, academic conduct for students at Stanford has been governed by the Honor Code, which reads as follows:</p>

    <p>THE STANFORD UNIVERSITY HONOR CODE</p>

    <ol>
      <li>The Honor Code is an undertaking of the students, individually and collectively:</li>
      <ul><li>that they will not give or receive aid in examinations; that they will not give or receive unpermitted
        aid in class work, in the preparation of reports, or in any other work that is to be used by the instructor
        as the basis of grading;</li>
        <li>that they will do their share and take an active part in seeing to it that others as well as
          themselves uphold the spirit and letter of the Honor Code.</li>
      </ul>
      <li>The faculty on its part manifests its confidence in the honor of its students by refraining from
        proctoring examinations and from taking unusual and unreasonable precautions to prevent the forms of dishonesty
        mentioned above. The faculty will also avoid as far as practicable, academic procedures that create temptations
        to violate the Honor Code.</li>
      <li>While the faculty alone has the right and obligation to set academic requirements, the students and
        faculty will work together to establish optimal conditions for honorable academic work.</li>
    </ol>
    <p>The purpose of this handout is to make our expectations as clear as possible regarding the Honor Code.
      The basic principle under which we operate is that each of you is expected to submit your own work in
      this course. In particular, attempting to take credit for someone else's work by turning it in as your own
      constitutes plagiarism, which is a serious violation of basic academic standards.</p>

    <h3>Honor Code as it pertains to CS106A</h3>

    <p>Under the Honor Code you are obligated to follow all of the following rules in this course:</p>

    <p><b>Rule 1: You must not look at solutions or program code that are not your own.</b></p>

    <p>It is an act of plagiarism to take work that is copied or derived from the work of others and submit
      it as your own. For example, using a solution from the Internet, a solution from another student (past or present),
      a solution taken from an answer set released in past quarters, or some other source, in part or in whole,
      that is not your own work is a violation of the Honor Code. Many Honor Code infractions we see make use of
      solution code found online. The best way to steer clear of this possibility is simply to not search for solutions
      to the assignments. Moreover, looking at someone else's solution code in order to determine how to solve the
      problem yourself is also an infraction of the Honor Code. In essence, you should not be looking at someone else's
      code in order to solve the problems in this class. This is not an appropriate way to “check your work,” “get a hint,”
      or “see alternative approaches.”</p>

    <p>Additionally, you are not allowed to solicit solutions from anyone. For example, it is a violation of the
      Stanford Honor Code to ask another student to share their answers with you, to ask a tutor to share other students'
      solutions with you, or to ask for answers on sites like Stack Overflow or Chegg.
    </p>

    <p><b>Rule 2: You must not share your solutions with other students.</b></p>

    <p>In particular, you should not ask anyone to give you a copy of their code or, conversely, give your code
      to another student who asks you for it. Similarly, you should not discuss your algorithmic strategies to such
      an extent that you and your collaborators end up turning in the same code. Moreover, you are expected to take
      reasonable measures to maintain the privacy of your solutions. For example, you should not leave copies of your
      work on public computers nor post your solutions on a public website.</p>

    <p><b>Rule 3: You must indicate on your submission any assistance you received.</b></p>

    <p>If you received aid while producing your solution, you must mention who you got help from (if that person is not a section leader,
      TA, or instructor for this class) and what specifically he/she helped you with. A proper citation should specifically identify the
      source (e.g., person's name, book title, website URL, etc.) and a clear indication of how this assistance influenced your work. For
      example, you might write “I discussed the approach used for sorting numbers in the sort_numbers function with Mary Smith.” If you make
      use of such assistance without giving proper credit, you may be guilty of plagiarism.</p>

    <p>It is also important to make sure that the assistance you receive consists of general advice that does not cross the boundary
      into having someone else write the actual code or show you their code. It is fine to discuss ideas and strategies, but you
      should be careful to write your programs on your own, as indicated in Rules 1 and 2.</p>

    <p><b>Please be aware: all submissions are subject to automated plagiarism detection.</b></p>

    <p>Stanford employs powerful automated plagiarism detection tools that compare assignment submissions with other submissions
      from the current and previous quarters. The tools also compare submissions against a wide variety of online solutions. These tools
      are effective at detecting unusual resemblances in programs, which are then further examined by the course staff. The staff then
      make the determination as to whether submissions are deemed to be potential infractions of the Honor Code and referred to Stanford's
      Community Standards office.</p>

    <p><b>A Final Note on Collaboration.</b></p>

    <p>We have no desire to create a climate in which students feel as if they are under suspicion. The entire point of the Stanford Honor Code is that
      we all benefit from working in an atmosphere of mutual trust. Students who deliberately take advantage of that trust, however, poison that atmosphere
      for everyone.

      In computer science courses, it is usually appropriate to ask others - especially the course staff - for hints or about general problem-solving
      strategies and how to approach the problem set questions. In fact, we strongly encourage you to seek such assistance when you need it. <u>Discuss
        ideas together, but do the coding on your own</u>.</p>
  </>
}

// export default HonorCode