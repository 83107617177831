import { courseInfo } from "cs106a/courseInfo"
import { FaApple, FaAppleAlt, FaBook, FaCheck, FaComment, FaFlag, FaPause, FaThLarge } from "react-icons/fa"
import { Link } from "react-router-dom"

export const KeyDates = () => {
  return <>
    <p className="subtleHeading">Key Dates</p>

    <FaAppleAlt className="sidebaricon" />
    <span >Diagnostic: {courseInfo.midtermDate}</span>
    <br/>

    <FaAppleAlt className="sidebaricon" />
    <span >Final: {courseInfo.finalDate}</span>
    <br/>

    <FaFlag className="sidebaricon" />
    <span>Last Lecture: {courseInfo.lastLectureDate}</span>
    

  </>
}