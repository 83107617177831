import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan6 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 6: More Dictionaries</h1>
        <hr />

        <p>Last week, we worked on nested structures. This week, we'll get more reps with dictionaries
            (without any nesting this time) and file reading. The next homework will focus on file reading,
            nested structures, and data visualization (graphics). We hope this section is a good opportunity
            to regroup after the midterm and get some more practice with these topics before starting on HW5.</p>

        <p>Importantly, students will have just gotten their midterm grades back, and with a 68% median,
            this exam was pretty challenging. For tips on how to talk about the midterm with students who are
            concerned about their performance, check out the "Midterm Diagnostic" section at the end of this handout.
        </p>

        <h3>Section Problem Flow</h3>
        <p>
        </p>

        <ol>
            <li><b>5 min: Check in, midterm debrief.</b> There's a chance you have some students in your section
                who are feeling disappointed after the midterm. Remind them that their grade is still in their
                control - we have more assignments and extension opportunities, the CS106A contest, and the final
                exam. The midterm is only 15% of their grade (perhaps only 10% if they show improvement on the
                final and get their midterm down-weighted) so failing the midterm is truly not going to make them
                fail the class. Plus, we're curving everyone's course grades at the end of the quarter.</li>
            <li>
                <b>25 min: Recipes problem.</b> You can choose to start with either Recipes or Cryptography,
                but Recipes is nice because it involves file reading. This will give students practice
                using <code>split</code> and casting data into different types.
            </li>
            <li>
                <b>20 min: Cryptography problem.</b> It's totally fine if you don't get to this problem, or skip
                straight to it. This problem is pure dictionaries - the <code>reverse_encryption_dict</code> function
                is particularly interesting because it reverses the keys and values in a dictionary.
            </li>
        </ol >

        <h3>Section tips</h3>

        <h4>Dictionary Operations</h4>
        <p>In some ways, we're taking a step down in difficulty after the nested dictionary problem from last week.
            However, we want to really solidify common dictionary operations this week - nested dictionaries are
            dictionaries after all! Something that students find tricky is the difference between this:
        </p>
        <PythonHighlighter code={add_pair} />

        <p>And this:</p>
        <PythonHighlighter code={get_value} />

        <p>Take your time to describe what each line is doing, as dictionary syntax is new!</p>


        <h4>Midterm Diagnostic</h4>
        <p>If any of your sectionees are feeling concerned after the midterm, you can reassure them that they've
            still got opportunities to demonstrate their CS106A skills before the end of the quarter. We know this
            exam was tricky, and we know this was many students' first CS exam, so we'll down-weight the midterm scores
            of students who show significant improvement on the final!
        </p>
        <p>We'll also have some opportunities for extra credit, like the CS106A contest and assignment extensions. Even
            without extra credit, the bottom line is that failing the midterm != failing CS106A, and other course components
            will help students recover from their midterm scores. Direct anyone who might need more reassurance towards Elyse!
        </p>

    </>
}

const add_pair = `dict[key] = value`

const get_value = `value = dict[key]`
