import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const MidtermSoln = () => {

  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Midterm Solutions</h1>
    <hr />

    <p>We have released the grades for the Midterm on <a href="https://www.gradescope.com/courses/538920">Gradescope</a>. Please see Clinton's Ed post for more details about the exam.
      You must log in with your Stanford email to see your score. Solutions can be found below.</p>
    <p>
      <b>This class will be graded on a curve</b>, so a lower median doesn't mean that everyone will be receiving lower grades. This
      exam is intended to be a diagnostic, so please take a look at what went well and how you can improve, rather than
      worrying too much about the absolute score.
    </p>
    <p>We try to grade as consistently as possible, but we are human and we might make mistakes. If you feel
      like one of your problems was misgraded, please take a look at the solution handout and then file a
      regrade request on Gradescope. Regrade requests are open now and will stay open on Gradescope until
      Sunday, August 4th at 11:59pm. <b>Note that your SL cannot regrade your exam.</b> We want you to have the
      credit you deserve, but filing a formal request helps us make sure that your request goes to the
      right person. If you submit a regrade request, we do reserve the right to regrade the entire problem
      and make any necessary corrections.</p>

    <h3>Problem 1: Cartwheel Karel</h3>
    <PythonHighlighter code={karel} />

    <h3>Problem 2: Images</h3>
    <PythonHighlighter code={images} />

    <h3>Problem 3: Code Tracing</h3>
    <p>See Gradescope.</p>

    <h3>Problem 4: Processing Packets</h3>
    <PythonHighlighter code={packets} />

    <h3>Problem 5: Baby Names</h3>
    <PythonHighlighter code={baby} />
  </>
}

const karel = `# We accepted either of these, or anything to this effect.

def main():
    make_border()
    while front_is_clear():
        move_diagonal()
        if no_beepers_present():
            put_beeper()
    pick_beeper()
    turn_right()
    while front_is_clear():
        move()
    turn_around()
    while front_is_clear():
        move_diagonal()
        if no_beepers_present():
            put_beeper()
    pick_beeper()


def main():
    
    make_border() # Karel ends at (2, 2) facing east

    move_diagonal()
    # some students did front is clear here. That means they
    # will make more beepers than necessary so they will need to 
    # remove the last beeper on the wall
    while no_beepers_present(): 
        put_beeper()
        move_diagonal()

    return_to_left_corner() # Karel is at (2, 2) facing east

    turn_left()

    # move until I hit the spot without beepers. 
    # front is clear is fine here too!
    while beepers_present():
        move()
    
    turn_around()
    move() # now I'm facing south, standing on a beeper.

    # move diagonal (one step south, then one step east) so I'm standing 
    # on a empty diagonal spot
    move_diagonal() 
    while no_beepers_present():
        put_beeper()
        move_diagonal()`

const images = `def green_channel(image):
  for y in range(image.height):
    for x in range(image.width//2, image.width):
        pixel = image.get_pixel(x, y)
        pixel.blue = 0
        pixel.red = 0

b) They are equivalent because images are mutable`

const packets = `def get_num_digits(packet):
  for i in range(len(packet)):
    if not packet[i].isdigit():
        return i
  return 0

def process_packets():
  # Use a set because we only want unique data!
  verified_data = set()

  while True:
      # First sentinel!
      packet = input("Data from user: ")
      if packet == "":
          break
      num_digits = get_num_digits(packet)
      data_length = packet[: num_digits]
      data = packet[num_digits: num_digits + data_length]
      checksum = packet[num_digits + data_length: ]
      # second sentinel!
      if calculate_checksum(data) != int(checksum):
          break
      verified_data.insert(data)

    return list(verified_data)`

const baby = `def has_common_origins(name1_origins, name2_origins):
  for name in name1_origins:
    if name in name2_origins:
        return True

  return False


def calculate_similarity(name1, name2):
  total_similarity = 0
  # can replace the next 3 lines with the min function from Python! Look it up and try it
  shortest = len(name1)
  if len(name2) < shortest:
      shortest = len(name2)

  for i in range(shortest):
      if name1[i] == name2[i]:
        total_similarity += 1

  return total_similarity / shortest


def build_name_to_origin(filename):
  file = open(filename)
  name_to_origin = {}
  for line in file:
      parts = line.split(":")
      name = parts[0]
      name_to_origin[name] = parts[1].split(",")
    
  return name_to_origin


def find_most_similar(filename):
  name_to_origin = build_name_to_origin(NAMES_TO_ORIGIN_FILENAME)

  file = open(filename)
  most_similar_name_pair = None
  highest_score = -1 
  for line in file:
      parts = line.split(" ")
      name1 = parts[0]
      name2 = parts[1]

      if has_common_origins(name_to_origin[name1], name_to_origin[name2]):
          similarity = calculate_similarity(name1, name2)
          if similarity > highest_score:
              highest_score = similarity
              most_similar_name_pair = (name1, name2)
          
  return most_similar_name_pair`