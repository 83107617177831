import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"

export const FinalSoln = () => {

  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Final Exam Solutions</h1>
    <hr />

    <p>We have released the grades for the Final Exam on <a href="https://www.gradescope.com">Gradescope</a>.
      You must log in with your Stanford email to see your score. Solutions can be found below.</p>

    <h3>Problem 1: Short Answers</h3>
    <p>See Gradescope.</p>

    <h3>Problem 2: Tic Tac Toe</h3>
    <h4>2.1: Check Winner</h4>
    <PythonHighlighter code={tic_1} />
    <h4>2.2: Get Tournament Winner</h4>
    <PythonHighlighter code={tic_2} />

    <h3>Problem 3: Stock Market</h3>
    <PythonHighlighter code={stock} />

    <h3>Problem 4: Flying Money</h3>
    <h4>4.1</h4>

    <PythonHighlighter code={execute} />
    <h4>4.2</h4>
    <PythonHighlighter code={flying} />
    
  </>
}

const flying = `def main():
    
  stock_market = execute_trades()

  # Continue this function as described in the problem handout
  print("... any kind of string .. ", stock_market.get_portfolio_value() - stock_market.get_initial_investment())

  # Follow up: can you do it by sorting it just once? 
  # Also if you sorted using reverse=True, how can you achieve the same result
  # without using reverse=True and without reversing the list?  

  transactions = stock_market.get_transaction_history()

  high_to_low = sorted(transactions, reverse=True, 
                      key=lambda t: t.price_per_share * t.num_shares)
  for i in range(min(len(high_to_low), 5)):
      t = transactions[i]
      print(f"{t.ticker} at {t.price_per_share * t.num_shares}")

  low_to_high = sorted(transactions, 
                      key=lambda t: t.price_per_share * t.num_shares)
  for i in range(min(len(low_to_high), 5)):
      t = transactions[i]
      print(f"{t.ticker} at {t.price_per_share * t.num_shares}")`

const execute = `def extract_action_info(line):
  line = line.strip(string.punctuation)
  if " " in line:
    parts = line.split(" ")
    return parts[0], parts[1][1:], int(parts[2])

  index = line.find("$")
  action = line[ : index]
  line = line[index + 1: ]
  for i in range(len(line)):
    if line[i].isdigit():
      ticker = line[: i]
      num_shares = line[i:]
      return action, ticker, int(num_shares)

  return None # not required, but good practice to always return

def execute_trades():
  max_value = -1
  max_stock_market = None

  while True:
    filename = input("...")
    if filename == "":
      return max_stock_market
    
    lines = open(filename).readlines()

    stock_market = StockMarket(int(lines[0]))

    for line in lines[1:]:
      action, ticker, num_shares = extract_action_info(line)
      if action == "BUY":
        stock_market.buy_stock(ticker, num_shares)
      elif action == "SELL":
        stock_market.sell_stock(ticker, num_shares)
      else:
        break

    current_value = stock_market.get_portfolio_value()
    if current_value > max_value:
      max_stock_market = stock_market
      max_value = current_value`

const stock = `def __init__(self, amount_to_invest):
  self._balance = amount_to_invest
  self._initial_amount = amount_to_invest
  self._transactions = []
  self._portfolio = {}

def get_initial_investment(self):
  return self._initial_amount

def buy(self, ticker, num_shares):
  result = fetch_stock_price(ticker)
  if not result.status:
      return False
  cost = num_shares * result.price
  if cost > self._balance:
      return False
  self.balance -= cost
  if ticker not in self._portfolio:
      self._portfolio[ticker] = {}
  if "num_shares" not in self._portfolio[ticker]:
      self._portfolio[ticker]["num_shares"] = 0
      self._portfolio[ticker]["max_price"] = -1
  self._portfolio[ticker]["num_shares"] += num_shares
  if self._portfolio["max_price"] < result.price:
      self._portfolio[ticker]["max_price"] = result.price
    
  self._transactions.append(Transaction(ticker, num_shares, result.price))
  return True

def sell(self, ticker, num_shares):
  result = fetch_stock_price(ticker)
  if not result.status:
      return False
  if ticker not in self._portfolio:
      return False
  if self._portfolio[ticker]["num_shares"] < num_shares:
      return False
  # fine with > here too
  if self._portfolio[ticker]["max_price"] >= result.price: 
      return False
  
  self._balance += num_shares * result.price
  self._portfolio[ticker]["num_shares"] -= num_shares
  if self._portfolio[ticker]["num_shares"] == 0:
      del self._portfolio[ticker]
  
  self._transactions.append(Transaction(ticker, num_shares, result.price))
  return True

def get_portfolio_value(self):
  total_value = self.balance
  for ticker in self.portfolio:
      result = fetch_stock_price(ticker)
      total_value += self._portfolio[ticker]['num_shares'] * result.price
  return total_value

def get_transaction_history(self):
  return self._transaction_history

def get_initial_investment(self):
  return self._initial_amount

def get_companies(self):
  return list(self._portfolio.keys())`

const tic_1 = `def aligned_right_diag(board,player):
  x = board.width - 1
  for y in range(board.height):
    if board.get(x, y) != player:
      return False
    x -= 1
  return True

# Something to note: You can notice that the next 3 functions are very 
# identical and that was intentional. Once you get one right, you can get
# the other three with very minor tweaks.
def aligned_horiz(board,player):
  for y in range(board.height):
    winner = True
    for x in range(board.width):
      if board.get(x, y) != player:
        winner = False
        # We can't return here because we need to check other rows
        # Also, this is not required. Can you see why?
        break 
    if winner:
      return True
  return False

def aligned_vert(board,player):
  for y in range(board.width):
    winner = True
    for x in range(board.height):
      if board.get(x, y) != player:
        winner = False
        break
    if winner:
      return True
  return False

def aligned_left_diag(board,player):
  for y in range(board.width):
    winner = True
    for x in range(board.height):
      if x == y and board.get(x, y) != player:
        winner = False
        break
    if winner:
      return True
  return False

# In case you didn't want to follow the format of the horiz/vert functions,
# a simpler way to check if the player was aligned in the left diagonal is:
def aligned_left_diag(board,player):
  for x in range(board.width):
    if board.get(x, x) != player:
      return False
  return True

def check_tic_tac_toe_winner(board, player):
  return aligned_vert(board, player) or \
        
        aligned_horiz(board, player) or \
        
        aligned_left_diag(board, player) or \
        
        aligned_right_diag(board,player)
`
const tic_2 = `def main():
  board = get_tic_tac_toe_board()
  bracket = get_bracket()

  # (1) Under what conditions does the tournament stop?
  while len(tournament) > 1:

    # (2) remove the top two players from the tournament
    player1 = bracket.pop_left()
    player2 = bracket.pop_left()
    
    # (3) play the game between two players
    play_game(board, player1, player2)

    # (4) Check who won the match
    winner = player1
    if check_tic_tac_toe_winner(board, player2):
      winner = player2

    # Reset the board (This was not required).
    board = get_tic_tac_toe_board()
    
    # (5) Add the winner back to the bracket
    bracket.append(winner)

  # (6) print who won the match.
  print(bracket.pop_left())`