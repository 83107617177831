import { Handout } from "cs106a/components/Handout"

// @ts-ignore
import Button from "./img/interactivePrograms/button_top.png"
// @ts-ignore
import Text from "./img/interactivePrograms/text_left.png"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"


export const InteractivePrograms = () => {
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>
      Interactive Programs Reference
    </h1>
    <hr />

    <p>
      One of the most common libraries to create graphics in Python is called Tk (short for "tkinter"). Tk is
      a powerful graphics library that should be automatically installed for Windows and Mac along with Python
      when you installed it. While Tk's great, some of the functions are hard to use. For this reason, we
      provide our own small graphics library that is built on top of Tk and makes it easier to use. It's not a
      replacement for Tk - it just adds a few new functions to make certain things like drawing text easier. You
      can always explore the full Tk library if you're interested in seeing what else you can do!
    </p>

    <p>While any graphics projects we create for you will have the necessary graphics files included, you can also
      download this <a href={`${process.env.PUBLIC_URL}/graphics.zip`}>graphics</a> file, unzip it, and
      add <code>graphics.py</code> to your project folder if you're starting from scratch.
      Then, all you need to do is add <code>from graphics import Canvas</code> to the top of your code, and
      potentially <code>import time</code> if you will be doing animation and using <code>time.sleep()</code>.</p>

    <h3>
      The Mouse
    </h3>
    <p>You can get the location of the mouse at any time using <code>get_mouse_x</code> and <code>get_mouse_y</code> on
      the canvas: </p>

    <PythonHighlighter code={get_x_y} />

    <p>To make an object move to the location of the mouse, we might do: </p>

    <PythonHighlighter code={move_to_mouse} />

    <p>Note that this moves the rectangle's <i>upper left corner</i> to the location of the mouse.</p>

    <p>You can wait for the user to click using <code>wait_for_click</code>, which will pause the execution of
      your code until the user clicks the canvas:</p>

    <PythonHighlighter code={wait_click} />

    <p>To track mouse clicks on the canvas, use <code>get_new_mouse_clicks</code> in a loop:</p>

    <PythonHighlighter code={track_clicks} />

    <p>Each element of the list of clicks given to you by the canvas has an x and y coordinate that you can
      use to determine where that click occurred.</p>

    <h3>The Keyboard</h3>

    <p>To check for keyboard key presses, use <code>get_new_key_presses</code> in a loop:</p>

    <PythonHighlighter code={track_presses} />

    <p>Each element of the list given to you be a key press event. To access the name of the key that was pressed
      for each key press event, you'll use <code>.keysym</code> to get the key as a string.
      E.g. "a" will be the <code>keysym</code> for the lowercase "a" key, "A" will be if Shift+a is pressed, etc. If you are unsure
      what the <code>keysym</code> is, try writing a program that prints out the <code>keysym</code>, like this:</p>

    <PythonHighlighter code={print_presses} />

    <h3>Interactors (Buttons and Text Fields)</h3>

    <p>You can add interactors (buttons and text fields) around the edges of the canvas. These aren't put on
      the canvas, these are put around the edges of the canvas, so you don't specify coordinates like you usually
      do for graphical objects, you specify which area (top, bottom, left, right) you want the interactor.</p>

    <h4>Buttons</h4>

    <p>Create a button using the <code>create_button</code> function and specifying a side of the canvas:</p>

    <PythonHighlighter code={create_button} />

    <img src={Button} className="img-fluid mx-auto d-block" width="75%"
      alt="Blank canvas with a button that says 'Click me' at the top." />

    <p>The tk.Button component has many customization options; you can specify additional options if you'd like via keyword arguments.
      Keyword arguments are parameters you specify in a comma-separated list with the form <code>name=value</code>. Many
      Tkinter functions work this way. You can see a list of all additional options for
      buttons on the <a href="https://anzeljg.github.io/rin2/book2/2405/docs/tkinter/button.html">tk.Button Reference</a>.
      For instance, to make a button that has blue text and Courier font, you can
      use <code>fg</code> and <code>font</code> (note that this is one line, we've just split it to fit on the screen):</p>

    <PythonHighlighter code={create_button_options} />

    <p>To delete the button you have created, use <code>destroy</code> on the button variable:</p>

    <PythonHighlighter code={destroy_button} />

    <p>To check for button clicks, use <code>get_new_button_clicks</code> in a loop:</p>

    <PythonHighlighter code={track_button} />

    <p>Note that you can check which button was pressed by checking if it's <code>==</code> to the text name given to it
      on creation.</p>

    <h3>Text Fields</h3>

    <p>To create a text field into which the user can input text, use the <code>create_text_field</code> function:</p>

    <PythonHighlighter code={create_text} />

    <img src={Text} className="img-fluid mx-auto d-block" width="75%"
      alt="Blank canvas with a text entry box that says 'Enter name' above it." />

    <p>The tk.Entry component has many customization options; you can specify additional options if you'd like via keyword arguments.
      Keyword arguments are parameters you specify in a comma-separated list with the form <code>name=value</code>. Many
      Tkinter functions work this way. You can see a list of all additional options for
      buttons on the <a href="https://anzeljg.github.io/rin2/book2/2405/docs/tkinter/entry.html">tk.Entry Reference</a>.
      For instance, to make a text field that has red text and obscures what the user enters like a password field,
      you can use <code>fg</code> and <code>show</code> (note that this is one line, we've just split it to fit on the screen):</p>

    <PythonHighlighter code={create_text_options} />

    <p>To delete the text field you have created, use <code>delete_text_field</code> and specify the text
      you used when you created it:</p>

    <PythonHighlighter code={destroy_text} />

    <p>To get the text currently entered into the text field, use <code>get_text_field_text</code> and specify the
      description you used when you created it:</p>

    <PythonHighlighter code={enter_text} />

    <p>You will usually use this in combination with a button to know when the user is ready to do something - e.g.
      have them enter their name and click "Submit" to receive a welcome message.</p>

    <PythonHighlighter code={read_text} />
  </>
}

const get_x_y = `mouse_x = canvas.get_mouse_x()
mouse_y = canvas.get_mouse_y()`

const move_to_mouse = `rect = canvas.create_rectangle(0, 0, 100, 100)
...
canvas.moveto(rect, canvas.get_mouse_x(), canvas.get_mouse_y())`

const wait_click = `canvas.wait_for_click() # This line will wait for the user to click
print("You clicked!") # This code will not run until the user clicks`

const track_clicks = `while some_condition:
  # returns a list of clicks
  clicks = canvas.get_new_mouse_clicks()
  for click in clicks:
    # create a new rectangle at the location where each click occurred
    canvas.create_rectangle(click.x, click.y, click.x + 5, click.y + 5)
  canvas.update()`

const track_presses = `while some_condition:
  presses = canvas.get_new_key_presses()
  for press in presses:
    # check if up arrow was pressed
    if press.keysym == "Up":
      # move character up (assuming you have an object called character)
      canvas.move(character, 0, -5)
  canvas.update()`

const print_presses = `while True:
  presses = canvas.get_new_key_presses()
  for press in presses:
    print(press.keysym)
  canvas.update()`

const create_button = `my_button = canvas.create_button("Click me", Canvas.TOP)`

const create_button_options = `my_button = canvas.create_button("Click me", Canvas.TOP, 
    fg="blue", font="Courier")`

const destroy_button = `my_button.destroy()`

const track_button = `canvas.create_button("Click me", Canvas.TOP)
while some_condition:
  clicks = canvas.get_new_button_clicks()
  for click in clicks:
    if click == "Click me":
      print("Button clicked!")
  canvas.update()`

const create_text = `canvas.create_text_field("Enter name", Canvas.LEFT)`

const create_text_options = `canvas.create_text_field("Enter name", Canvas.LEFT, 
    fg="red", show="*")`

const destroy_text = `canvas.delete_text_field("Enter name")`

const enter_text = `entered_text = canvas.get_text_field_text("Enter name")`

const read_text = `canvas.create_text_field("Enter name", Canvas.TOP)
canvas.create_button("Submit", Canvas.TOP)
while True:
  clicks = canvas.get_new_button_clicks()
  for click in clicks:
    if click == "Submit":
      entered_text = canvas.get_text_field_text("Enter name")
      print("Hello, " + entered_text)
  canvas.update()`