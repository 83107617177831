import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

// @ts-ignore
import LicenseError from "./img/pycharmBugs/license_error.png"
// @ts-ignore
import MacDownloadDropdown from "./img/pycharmBugs/mac_download_dropdown.png"
// @ts-ignore
import DarkMode from "./img/pycharmBugs/dark_mode.png"


export const PycharmBugs = () => {
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>
      PyCharm Common Bugs/FAQs
    </h1>
    <hr />


    <div className="alert alert-info">
      <p>
        <b>Running into issues not on this page?</b> Check our discussion forum and make a new
        post if you're still stuck!
      </p>
    </div>

    <h3>
      Can I use some other version of Python?
    </h3>
    <p>
      Our strong recommendation is to use the latest stable version of Python, and this can be installed
      without removing older versions of Python already on your computer. Older versions tend to run
      into issues when installing other libraries later in the class.
    </p>

    <h3>
      Can I use an editor that isn't PyCharm?
    </h3>
    <p>
      If there's another environment that you're more comfortable with, you may use it,
      but the course staff will only provide support for issues in PyCharm. You must not
      modify any configuration files provided in assignment starter code.
    </p>

    <h3 data-toc-text="Can't open file">
      When I try to run my code, I get an error saying <code>can't open file intro.py: No such file or directory</code>! What does this mean?
    </h3>
    <p>
      This most often happens when you open the incorrect file or folder in PyCharm. In this case, make
      sure to
      open the <code>pycharm_intro</code> folder that <i>directly</i> contains <code>intro.py</code>,
      rather than <code>intro.py</code> itself or a folder containing <code>pycharm_intro</code>. On Windows computers,
      you may
      have a folder called <code>pycharm_intro</code> with another folder inside it called <code>pycharm_intro</code>.
      Make sure to open the inner folder.
    </p>
    <p>
      To fix this error, open the 'File' menu and click 'Open'. Then, navigate to the correct <code>pycharm_intro</code> folder and click on it. Now hit the 'Open' button.
    </p>
    <p>
      To verify that you are in the correct folder,
      type <code>ls</code> (that's a lowercase 'L') if you're using a Mac or <code>dir</code> if you're using Windows,
      into your terminal. This is the same place you type <code>python3 intro.py &lt;YOUR NAME HERE&gt; </code>.
      This will show you a list of all the files in that folder, and <code>intro.py</code> should
      be in this list.
    </p>
    <h3 data-toc-text="Python not found">

      I'm getting the error 'Python was not found; run without arguments to install from the Microsoft
      Store, or disable this
      shortcut from Settings &#62; Manage App Execution Aliases.'</h3>
    <p>Go into your computer settings (not PyCharm settings) and search for "Manage App Execution Aliases".
      Click on this, and then you should see a page with lot of switches for various applications. Scroll
      to the bottom, and turn off the switches for python.exe.</p>
    <p>If you're still getting this error, try using <code>python</code> instead of <code>py</code> or <code>python3</code> when you enter
      your
      command into the terminal - if this works, you should use it in your terminal commands for the
      rest of the quarter.</p>

    <h3 data-toc-text="No Python Interpeter Configured">
      I'm getting a 'No Python Interpreter configured for the project' message when I open a file! What
      should I do?
    </h3>
    <p>
      This is likely caused by an issue with how you set up the interpreter.
      To fix this, click 'Configure Python Interpreter', and set up the interpreter
      by following the instructions in the Installing Pycharm handout.
    </p>

    <h3>When I run my Karel program, some elements of Karel's world seem to be missing (walls and corners).</h3>
    <img src={DarkMode} className="img-fluid mx-auto d-block" width="70%"
      alt="Newspaper Karel popup when computer is in dark mode" />
    <br />
    <p>If Karel and the beeper appear, but you don't see any of the walls around the world,
      this might be because your computer is in dark mode. To fix this, go into your computer settings,
      and in the "Appearance" section, change the appearance to light.</p>

    <h3>
      I'm getting the error <code>couldn't recognize data in image file "./karel/icon.png"</code> when I run my code.
    </h3>
    <p>
      Check the Python version you're running by looking in the bottom right of your terminal. The latest stable version
      of Python is 3.11, so if your version number is much older (3.8), or newer (3.12), <b>close out
        of PyCharm</b> and repeat the install process for Python 3.11. Check out the top of the Installing PyCharm handout and
      complete the "Installing Python" and "Installation" sections.

    </p>

    <h3 data-toc-text="PyCharm won't open on new Mac">
      I'm on a new Mac and PyCharm won't open!
    </h3>
    <p>
      If you are using a relatively new Mac, you may need to download a different version of the community
      version of PyCharm. Un-install the version you have, and then when you redownload click the
      downloads
      dropdown and select "Apple
      Silicon":
      <img src={MacDownloadDropdown} className="img-fluid mx-auto d-block"
        width="300"
        alt="A dropdown on the PyCharm webpage that allows you to select which Mac community version to install: 'Intel or Apple Silicon'" />
    </p>

    <h3 data-toc-text="Licensing issue">
      I'm geting an unexpected popup about Licenses!
    </h3>
    <br />
    <img src={LicenseError} className="img-fluid mx-auto d-block" width="500"
      alt="Popup on a user's screen with options to activate PyCharm, start a PyCharm trial, and the option buy a license." />
    <br />
    <p>
      You might have downloaded the professional version of PyCharm, which is paid. Uninstall the version you currently
      have, and go back to the PyCharm website. This time, select the
      community version on the PyCharm site and download that version.
    </p>

    <h3 data-toc-text=" JetBrains Runtime 11 & Windows 32-bit">
      I'm getting an error window saying "This installation contains JetBrains Runtime 11
      which does not support Microsoft Windows 32-bit version"! What should I do?
    </h3>
    <p>
      Try installing <a
        href="https://download.jetbrains.com/python/pycharm-community-2018.3.7.exe?_ga=2.144731825.444478522.1586284244-1193515711.1586109339">this
        version</a>  of PyCharm instead.
    </p>


    <h3 data-toc-text="The default interactive shell is now zsh">
      I'm on a Mac and I'm getting a message saying <code>The default interactive shell is now zsh.
        To update your account to use zsh, please run `chsh -s /bin/zsh</code>. What should I do?
    </h3>
    <p>
      That's just an innocuous message, don't worry about it!
    </p>

    <h3 data-toc-text="Deprecation Warning">
      I get the following error when I try to run my code from the terminal <code> DEPRECATION WARNING: The system version of Tk is deprecated and may be removed in a future release.
        Please don't rely on it. Set TK_SILENCE_DEPRECATION=1 to suppress this warning. </code> What should I do?
    </h3>
    <p>
      Do you have the most current version of Python? Try: </p>
    <ol>
      <li>Saving your code</li>
      <li>Closing PyCharm and then updating Python to the newest version (instructions to download Python are at the top of the Installing PyCharm Handout)</li>
      <li> Reopening the project in PyCharm</li>
      <li>Re-running your code</li>
    </ol>

    <h3 data-toc-text="Upating Python">
      How do I update to the most current version of Python?
    </h3>
    <p>
      First, close out of PyCharm completely.
      You can use this <a href="https://www.python.org/downloads ">Python download link</a>. When you click this link, you will see
      a Download Python (Some Version Number) button. Click this, after open this file after it finishes downloading and follow the installer instructions.
      In PyCharm, you'll want to change your interpreter to this newer version of Python using the
      instructions in the PyCharm Installation handout.
    </p>

    <p>
      <i>
        Course FAQs will be updated as we receive questions.
      </i>
    </p>
  </>
}