
// month index starts at 0 in js
const april = 3;
const may = 4;
const june = 5;
const july = 6;
const august = 7;

export const courseInfo = {
    title: 'CS106A',
    courseCode: 'CS106A',
    quarterFullName: 'Summer 2024',
    lectureTime: '1:30pm - 2:45pm',
    midtermDate: 'July 25th, 1:30pm - 3:30pm',
    finalDate: 'August 16th, 3:30pm - 6:30pm', //8:30a - 11:30a
    staffEmail: 'cs106a-staff@lists.stanford.edu',
    lastLectureDate: 'August 13th',
    location: 'NVIDIA Auditorium',
    canvasLink: 'https://canvas.stanford.edu/courses/193262',
    locationMap: 'https://maps.app.goo.gl/uSNSovhR3en1K5US8',
    instructors: 
        {
            name: "Clinton Kwarteng",
            img: `${process.env.PUBLIC_URL}/people/clinton.jpeg`,
            email: "ckwarteng@",
            officeHours: "Tues/Thurs right after lecture in/around Huang",
            office: "Lecture Room",
            website: "https://www.linkedin.com/in/clinton-kwarteng-0a05b81a1/"
        }
    ,
    headTA: {
        name: "Ngoc Nguyen",
        img: `${process.env.PUBLIC_URL}/people/ngoc.jpeg`,
        email: "ngocng@",
        officeHours: "Thu 3pm - 4:30pm",
        office: "Durand 303",
        website: 'https://www.linkedin.com/in/ngoc-nguyen-8709b3170'
    },  
    "lectureSchedule": {
        "1": {
            "lectureNum":1,
            "title": "Welcome",
            "date": new Date(2024, june, 25, 13, 30, 0, 0)
        },
        "2": {
            "lectureNum":2,
            "title": "Control Structures",
            "date": new Date(2024, june, 27, 13, 30, 0, 0)
        },
        "3": {
            "lectureNum":3,
            "title": "Art of Problem Solving",
            "date": new Date(2024, june, 28, 13, 30, 0, 0)
        },
        "4": {
            "lectureNum":4,
            "title": "Intro to Python",
            "date": new Date(2024, july, 2, 13, 30, 0, 0)
        },
        "5": {
            "lectureNum":5,
            "title": "Arithmetic expressions",
            "date": new Date(2024, july, 5, 13, 30, 0, 0)
        },
        "6": {
            "lectureNum":6,
            "title": "Control flow revisited",
            "date": new Date(2024, july, 9, 13, 30, 0, 0),
            "assn": "1"
        },
        "7": {
            "lectureNum":7,
            "title": "Graphics",
            "date": new Date(2024, july, 11, 13, 30, 0, 0),
        },
        "8": {
            "lectureNum":8,
            "title": "Functions in Python",
            "date": new Date(2024, july, 17, 13, 30, 0, 0)
        },
        "9": {
            "lectureNum":9,
            "title": "Rock Paper Scissors",
            "date": new Date(2024, july, 16, 13, 30, 0, 0)
        },
        "10": {
            "lectureNum":10,
            "title": "Animation",
            "date": new Date(2024, july, 18, 13, 30, 0, 0),
            "assn": "2"
        },
        "11": {
            "lectureNum":11,
            "title": "Lists",
            "date": new Date(2024, july, 19, 13, 30, 0, 0)
        },
        "12": {
            "lectureNum":12,
            "title": "Images",
            "date": new Date(2024, july, 23, 13, 30, 0, 0)
        },
        "13": {
            "lectureNum":13,
            "title": "List of lists",
            "date": new Date(2024, july, 26, 13, 30, 0, 0)
        },
        "14": {
            "lectureNum":14,
            "title": "Strings",
            "date": new Date(2024, july, 30, 13, 30, 0, 0),
            "assn": "3"
        },
        "15": {
            "lectureNum":15,
            "title": "Dictionaries",
            "date": new Date(2024, august, 1, 13, 30, 0, 0)
        },
        "midterm_break": {
            "title": "Diagnostic on Tuesday",
            "date": new Date(2024, may, 6, 14, 30, 0, 0),
            "lecture": false
        },
        "16": {
            "lectureNum":16,
            "title": "Indexing and Searching",
            "date": new Date(2024, august, 2, 13, 30, 0, 0)
        },
        "17": {
            "lectureNum":17,
            "title": "Program Efficiency and Many Cool Algorithms",
            "date": new Date(2024, august, 6, 13, 30, 0, 0)
        },
        "18": {
            "lectureNum":18,
            "title": "APIs",
            "date": new Date(2024, august, 8, 13, 30, 0, 0),
            "assn": "4"
        },
        "19": {
            "lectureNum":19,
            "title": "Cool Stuff",
            "date": new Date(2024, august, 9, 13, 30, 0, 0)
        },
        "20": {
            "lectureNum":20,
            "title": "Classes",
            "date": new Date(2024, may, 17, 14, 30, 0, 0)
        },
        "21": {
            "lectureNum":21,
            "title": "Memory and Classes",
            "date": new Date(2024, may, 20, 14, 30, 0, 0)
        },
        "22": {
            "lectureNum":22,
            "title": "Tuples",
            "date": new Date(2024, may, 22, 14, 30, 0, 0)
        },
        "23": {
            "lectureNum":23,
            "title": "Searching Indexing",
            "date": new Date(2024, may, 24, 14, 30, 0, 0),
            "assn": "5"
        },
        "memorial_day": {
            "title": "Memorial Day",
            "date": new Date(2024, may, 27, 14, 30, 0, 0),
            "lecture": false
        },
        "24": {
            "lectureNum":24,
            "title": "Internet",
            "date": new Date(2024, may, 29, 14, 30, 0, 0)
        },
        "25": {
            "lectureNum":25,
            "title": "Something Cool",
            "date": new Date(2024, may, 31, 14, 30, 0, 0)
        },
        "26": {
            "lectureNum":26,
            "title": "Life After CS106A",
            "date": new Date(2024, june, 3, 14, 30, 0, 0)
        },
        "27": {
            "lectureNum":27,
            "title": "Review for Final",
            "date": new Date(2024, june, 5, 14, 30, 0, 0),
            "assn": "6"
        },
        "final": {
            "lecture":false,
            "title": "Final Exam",
            "date": new Date(2024, june, 7, 8, 30, 0, 0)
        }
    }


}
