import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

// @ts-ignore
import Ben from "./img/profileSLs/ben.jpeg"
import { Header } from "course/forum/components/Header/Header"


export const SectionLeaders = () => {
    // The navbar already exists, this is just the component bellow the nav :-)
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
  }
  
  const HandoutInner = () => {
    return <>
      <h1>Section Leaders</h1>
      <hr />
      <div>
                <h2>Ben</h2>
                <img src={Ben} alt="Ben's Profile" />
        </div>
        </>
  }
  
  