import { ProfileContext, ProfileProvider } from "contexts/ProfileContext"
import { TeachNowTrainingOuter } from "course/teacherPuzzles/TeachNowTraining"
import { useContext } from "react"

export const TeachNowCS106A = ({ }) => {

  

  return <ProfileProvider>
    <Test/>
    {/* <TeachNowTrainingOuter/> */}
  </ProfileProvider>
}

const Test = () => {
  const {userData} = useContext(ProfileContext)
  console.log(userData)
  if(!userData) return <></>
  return  <TeachNowTrainingOuter/>
}