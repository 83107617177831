import { useParams } from "react-router-dom";

export const useCourseId = () => {
  const { courseId } = useParams();

  // special case: if the courseId is "" we default to it being "cs106a"
  // this should probably only stay in the cs106a branch, but shouldn't
  // break anything if it ends up in main

  if (!courseId) {
    return "spr24";
  }

  return courseId;
};
