import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaComment, FaPython } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { courseInfo } from "cs106a/courseInfo";
import { loginWithRedirect } from "cs106a/authentication/Login";

export const CS106ANavbar = () => {
    return (
        <Navbar bg="dark" variant="dark" expand="md">
            <div className="container-lg">
                <LinkContainer className="text-light" to={"/"}>
                    <Navbar.Brand>CS106A</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <LeftNav />
                    <RightNav />
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

const LeftNav = () => {
    return (
        <Nav className="me-auto">
            <CourseDropdown />
            <LecturesDropdown />
            <AssignmentsDropdown />
            <SectionsDropdown />
            <ReadingsDropdown />
        </Nav>
    );
};

const ReadingsDropdown = () => {
    return <NavDropdown title="Readings" id="readings-dropdown">
        <NavDropdown.Item target="_blank" href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html">Karel Reader</NavDropdown.Item>
        <LinkContainer to="/textbook">
            <NavDropdown.Item>Python Reader</NavDropdown.Item></LinkContainer>
        {/*
        <LinkContainer to="/graphics">
        
            <NavDropdown.Item >Graphics Handout</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/interactive-programs">
            <NavDropdown.Item >Interactive Programs</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/notopenai">
                <NavDropdown.Item>OpenAI API</NavDropdown.Item>
            </LinkContainer>
*/}
    </NavDropdown>
}

const LecturesDropdown = () => {
    // conditionally render the lectures (so you don't have to update the nav manually)
    // you can still directly go to the lectures via a link eg cs106a.stanford.edu/lectures/2
    const lectureSchedule = courseInfo.lectureSchedule
    return (
        <NavDropdown title="Lectures" id="lectures-dropdown">
            <LinkContainer to="/lecture/1">
                <NavDropdown.Item>Intro to 106A, Karel and Control Flow</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/lecture/2">
                <NavDropdown.Item>Art of Problem Solving</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/3">
                <NavDropdown.Item>Intro to Python</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/4">
                <NavDropdown.Item>More Python</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/5">
                <NavDropdown.Item>Intro to Python, 3</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/6">
                <NavDropdown.Item>Text Processing</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/7">
                <NavDropdown.Item>More Strings</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/8">
                <NavDropdown.Item>Lists and Tuples</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/9">
                <NavDropdown.Item>Grids and Images</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/10">
                <NavDropdown.Item>Dictionaries, Sets and Files</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/11">
                <NavDropdown.Item>Nested Structures</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/12">
                <NavDropdown.Item>Nested Structures Review, Intro to APIs</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/13">
                <NavDropdown.Item>APIs, Classes</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/14">
                <NavDropdown.Item>More Classes</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/15">
                <NavDropdown.Item>Midterm Retro, Classes</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/16">
                <NavDropdown.Item>Indexing and Searching</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/17">
                <NavDropdown.Item>Program Efficiency and Many Cool Algorithms</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/18">
                <NavDropdown.Item>The Internet</NavDropdown.Item>
    </LinkContainer>
    <LinkContainer to="/lecture/19">
                <NavDropdown.Item>Cool Stuff</NavDropdown.Item>
    </LinkContainer>
    {/*
        {Object.keys(lectureSchedule).map(lectureId => {
            // extract the date
            const lectureData = lectureSchedule[lectureId]
            const lectureDate = lectureData.date
            const lectureTitle = lectureData.title

            // check if its an hour away (or less)
            if (isCloseToLecture(lectureDate)) {

                // if so, show the lecture link
                return <LinkContainer to={`/lecture/${lectureId}`} key={lectureId}>
                    <NavDropdown.Item >{lectureId}: {lectureTitle}</NavDropdown.Item>
                </LinkContainer>
            }
            return null
        })}
    */}
        
    </NavDropdown>)
};

const AssignmentsDropdown = () => {
    return (
        <NavDropdown title="Assignments" id="assignments-dropdown">
            <NavDropdown.Item href="https://forms.gle/R2RtGxSic2ZSpMdQ8">Assn 0: Who are you?</NavDropdown.Item>
            
            <LinkContainer to="/assn1">
                <NavDropdown.Item>Assn 1: Karel the Robot</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/assn2">
                <NavDropdown.Item>Assn 2: Khansole Academy & Computing Interest</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn3">
                <NavDropdown.Item>Assn 3: Sand</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn5">
                <NavDropdown.Item>Assn 4: Infinite Story</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn6">
                <NavDropdown.Item>Assn 5: Bajillion</NavDropdown.Item>
            </LinkContainer>
            {/*
            <LinkContainer to="/assn3">
                <NavDropdown.Item>Assn 3: Breakout</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/assn4">
                <NavDropdown.Item>Assn 4: Sand</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/assn6">
                <NavDropdown.Item>Assn 6: Bajillion</NavDropdown.Item>
    </LinkContainer>*/}
        </NavDropdown>
    );
};

const SectionsDropdown = () => {
    return (
        <NavDropdown title="Sections" id="sections-dropdown">
            <NavDropdown.Item href="https://cs198.stanford.edu/cs198/auth/section/SectionSignup.aspx?class=1">Section Portal</NavDropdown.Item>
            
            <LinkContainer to="/section1">
                <NavDropdown.Item>Section 1: Karel the Robot</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/section2">
                <NavDropdown.Item>Section 2: Variables & Control Flow</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/section3">
                <NavDropdown.Item>Section 3: Functions and Strings</NavDropdown.Item>
            </LinkContainer>
            
           <LinkContainer to="/section4">
                <NavDropdown.Item>Section 4: Lists of Lists and Strings</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/section5">
                <NavDropdown.Item>Section 5: Dictionaries and File Reading</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/section6">
                <NavDropdown.Item>Section 6: Classes</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/section7">
                <NavDropdown.Item>Section 7: Big-O, Sorting, and Lambdas</NavDropdown.Item>
            </LinkContainer>
            {/*
            <LinkContainer to="/final-prep">
                <NavDropdown.Item>Section 8: Final Review</NavDropdown.Item>
            </LinkContainer> 
            */}
        </NavDropdown>
    );
};

const CourseDropdown = () => {
    return (
        <NavDropdown title="Course" id="course-dropdown">
            <LinkContainer to="/syllabus">
                <NavDropdown.Item>Syllabus</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item href="https://docs.google.com/spreadsheets/d/1tucnpkyZgEmHxOJOLsQCUcbvmM1tbN_H/edit#gid=1760974437">Schedule</NavDropdown.Item>
            <LinkContainer to="/honor-code">
                <NavDropdown.Item>Honor Code</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/installing-pycharm">
                <NavDropdown.Item>Installing PyCharm</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/pycharm-bugs">
                <NavDropdown.Item>PyCharm Bugs</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/karel-pycharm">
                <NavDropdown.Item>Using Karel with PyCharm</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/style">
                <NavDropdown.Item>Style Guide</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/images">
                <NavDropdown.Item>Image Reference</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/interactive-programs">
                <NavDropdown.Item>Interactive Programs Reference</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item href="https://docs.google.com/document/d/1megUtMXHPLXPYidLtmfH2vH3xbPON_6YWy0AelumbKQ/edit">Midterm Prep Handout</NavDropdown.Item>
            <LinkContainer to="/contest">
                <NavDropdown.Item>CS106A Contest</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item href="https://docs.google.com/document/d/1mrFV0-xcT73tLHLes5Q18YUZCRxRjfFY4sAEgF6LXVg/edit">Final Prep Handout</NavDropdown.Item>
            
           
            {/*
             <LinkContainer to="/graphics">
                <NavDropdown.Item>Graphics Reference</NavDropdown.Item>
            </LinkContainer>
             <LinkContainer to="/midterm-soln">
                <NavDropdown.Item>Midterm Solutions</NavDropdown.Item>
            </LinkContainer>

            
            <LinkContainer to="/practice-midterm">
                <NavDropdown.Item>Practice Diagnostic</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/contest">
                <NavDropdown.Item>CS106A Contest</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/cs106a/teachnowhome">
                <NavDropdown.Item>TeachNow</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/final-prep">
                <NavDropdown.Item>Final Prep Handout</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/practice-final">
                <NavDropdown.Item>Practice Final and Exam Logistics</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/final-soln">
                <NavDropdown.Item>Final Solutions</NavDropdown.Item>
            </LinkContainer>
            
            <LinkContainer to="/citations">
                <NavDropdown.Item>Retractions and Retroactive Citations</NavDropdown.Item>
            </LinkContainer>
             */}
        </NavDropdown>
    );
};

const RightNav = () => {
    const navigate = useNavigate();
    return (
        <Nav className="ml-auto">
            <button
                onClick={() => window.open("https://edstem.org/us/courses/57479/discussion/")}
                className="btn btn-sm btn-dark mr-1"
                style={{ color: 'grey' }}
            >
                <FaComment size={20} />
            </button>
            <button
                onClick={() => window.open("https://ide.stanford.edu/cs106a")}
                className="btn btn-sm btn-dark "
                style={{ color: 'grey' }}
            >
                <FaPython size={20} />
            </button>
            {/* <Nav.Link href="#home"><FaThLarge/></Nav.Link> */}
            {/* Profile dropdown with logout */}
            <SignInSignOut />
        </Nav>
    );
};

const SignInSignOut = () => {
    const auth = getAuth(getApp());
    const [user, loading] = useAuthState(auth);
    if (loading) {
        return <></>;
    }
    if (!user) {
        return <Nav.Link onClick={() => loginWithRedirect()}>Sign In</Nav.Link>;
    }
    return (
        <>
            {user && (
                <NavDropdown title={getFirstName(user)} id="profile-dropdown">
                    <NavDropdown.Item onClick={() => signOut(auth)}>
                        Sign Out
                    </NavDropdown.Item>
                </NavDropdown>
            )}
        </>
    );
};

const getFirstName = (user) => {
    if (!user) return "";
    if (!user.displayName) return "Anonymous"
    return user.displayName.split(" ")[0];
}

const isCloseToLecture = (lectureDate) => {
    const now = new Date()
    const pacificDateString = lectureDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });
    const lectureDatePacificTime = new Date(pacificDateString);

    console.log(pacificDateString)

    // get local time in PT
    const nowPacificDateString = now.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });
    const nowDatePacificTime = new Date(nowPacificDateString);

    // check the diff in PT
    const diffMs = lectureDatePacificTime - nowDatePacificTime
    const diffHours = diffMs / (1000 * 60 * 60)

    // show the lecture if its less that one hour away
    return diffHours < 46
}
