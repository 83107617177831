import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

// @ts-ignore
import Welcome from "./img/pycharmInstall/welcome.png"
// @ts-ignore
import Customize from "./img/pycharmInstall/customize.png"
// @ts-ignore
import Preferences from "./img/pycharmInstall/preferences.png"
// @ts-ignore
import AddInterpreter from "./img/pycharmInstall/add_interpreter.png"
// @ts-ignore
import ApplyInterpreter from "./img/pycharmInstall/apply_interpreter.png"
// @ts-ignore
import OpenProject from "./img/pycharmInstall/open_project.png"
// @ts-ignore
import ProjectTab from "./img/pycharmInstall/project_tab.png"
// @ts-ignore
import SystemInterpreter from "./img/pycharmInstall/select_system_interpreter.png"
// @ts-ignore
import Terminal from "./img/pycharmInstall/terminal.png"
// @ts-ignore
import Terminal1 from "./img/pycharmInstall/terminal1.png"
// @ts-ignore
import Terminal2 from "./img/pycharmInstall/terminal2.png"
import { Header } from "course/forum/components/Header/Header"


export const InstallingPycharm = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  if (navigator.userAgent.indexOf('Mac OS X') != -1) {
    return <Handout element={<HandoutInnerMac />} />
  } else {
    return <Handout element={<HandoutInnerWindows />} />
  }

}

const HandoutInnerMac = () => {
  return <>
    <h1>Installing Pycharm</h1>
    <hr />
    <div className="alert alert-warning">
      <p>
        We've detected you're using a Mac.
      </p>
    </div>

    <HeaderAlert />

    <p>
      In CS 106A, you will be writing your code on your
      computer using an application called
      <a href="https://www.jetbrains.com/pycharm/"> PyCharm</a>. PyCharm is
      what is known as an IDE, or <i>Integrated Development Environment</i>,
      which means that it allows us to edit our code, browse our files, run
      our programs and debug them, all in the same place. It's one of the
      most popular Python IDEs in the world, and used extremely commonly in
      industry. This handout is intended to take you through installing
      PyCharm on your own computer.
    </p>

    <h2>Installing Python</h2>
    <p>
      In order to be able to run Python programs on your computer, you need
      to install a <i>Python Interpreter.</i> An interpreter is a program
      that is capable of reading a <code>.py</code> file that you have
      written, and translating the Python code in that file to instructions
      that your computer can easily execute. Begin by downloading Python. Select the link corresponding to
      your operating system and download the latest version of <b>Python 3</b>: <a href="https://www.python.org/downloads/macos/">
        Mac OS Downloads </a>
    </p>
    <p >
      Macs come with a version of Python installed, but this
      is an older version of Python. CS106A
      requires the use of the newest stable version of Python (currently 3.11), so make sure to follow these
      instructions even if you think you already have Python installed.
      To install Python, simply open the downloaded installer file
      and follow the default instructions.
    </p>

    <hr />

    <h2> Installing and Testing PyCharm </h2>

    <h3> Installation </h3>

    <p>
      To get started, check what chip your Mac uses and download and install the correct <b>community</b> version of PyCharm: <a target="_blank"  href="https://www.jetbrains.com/pycharm/download/download-thanks.html?platform=macM1&code=PCC">
        Apple Silicon Chip Mac Download Page</a>  OR <a target="_blank"  href="https://www.jetbrains.com/pycharm/download/download-thanks.html?platform=mac&code=PCC">
        Intel Chip Mac Download Page
      </a> 
      </p>

    <p>
      Click the black download circle. Open the downloaded <code>.dmg</code> file and drag PyCharm
      into your Applications folder.
    </p>

    <p>
      At the end of the PyCharm installation process, open PyCharm, and you'll see a welcome screen:
    </p>
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Welcome} className="w-100" alt="PyCharm Welcome Screen" />
      </div>
    </div>
    <br />

    <p id="configure_interpreter">Click the 'Customize' tab on the left and then click 'All Settings...' at
      the bottom:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Customize} className="w-100" alt="PyCharm Welcome Screen, with 'Customize' tab selected" />
      </div>
    </div>
    <br />

    <p id="setinterpreter">
      In the Preferences window, click the 'Python Interpreter' tab, and you should see a
      screen like
      this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Preferences}
          className="w-100"
          alt="PyCharm Preferences Window, in the 'Project Interpreter' Pane, with 'Show All' highlighted in the dropdown menu" />
      </div>
    </div>
    <br />

    <p>
      If you have no interpreter selected, or the interpreter is not the newest version of Python you just
      downloaded, click the drop-down and select 'Show All...'. You should then see a screen like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={AddInterpreter}
          className="img-fluid mx-auto d-block"
          alt="PyCharm Interpreter List, with the plus button in the bottom left highlighted" />
      </div>
    </div>
    <br />

    <p>
      Click the + button on the upper left, then click 'System Interpreter' in the sidebar. Depending on
      whether you've installed other versions of
      Python
      before, the latest version of Python may already be selected in the dropdown menu. If not, select
      the latest version Python that you downloaded and then click 'OK'
      and 'OK' again. I know this picture says python3.10, but yours should say whatever the latest
      version is in the dropdown menu. Please select that version:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={SystemInterpreter}
          className="img-fluid mx-auto d-block" alt="Adding the system interpreter in PyCharm" />
      </div>
    </div>
    <br />

    <p>
      You should now be on a window that looks like this (although the contents of your list might look
      different if you have a later version of Python instead of 3.10):
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={ApplyInterpreter}
          className="img-fluid mx-auto d-block"
          alt="Interpreter window after selecting system interpreter" />
      </div>
    </div>
    <br />

    <p>
      Click 'Apply' and then 'OK' again to be taken back to the welcome page. You're now ready to test
      PyCharm!
    </p>

    <hr />

    <h3> Testing PyCharm </h3>

    <p>
      PyCharm models a program as a 'project', which consists of one or more
      Python files, as well as any additional resources like images or text
      files. To get you familiar with working with and running progams in PyCharm,
      we've provided a sample project, which you can download <a href={`${process.env.PUBLIC_URL}/pycharm_intro.zip`}>
        here</a>. <b>Whenever you open projects in PyCharm,
          open the folder directly containing the files you want to edit as opposed to just
          the files themselves.</b> Upon
      opening the project, you should be greeted by a window that looks like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={OpenProject}
          className="img-fluid mx-auto d-block" alt="Blank PyCharm Window" />
      </div>
    </div>
    <br />

    <p>
      Click 'Project' in the top left or use the <code><span id="modifier">Command</span> + 1</code> shortcut
      to open the file explorer, and open <code>intro.py</code> to pull up the editor:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={ProjectTab}
          className="img-fluid mx-auto d-block" alt="PyCharm Editor" />
      </div>
    </div>
    <br />

    <p>
      You aren't required to read or understand this code (although you will be able to by the end of the
      quarter!)
      but note that this editor is where you'll be writing all your code this quarter. Now, to run your
      program, click
      'Terminal' in the bottom left corner, which will pull up a new terminal pane in your PyCharm window:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Terminal}
          className="img-fluid mx-auto d-block" alt="PyCharm Terminal" />
      </div>
    </div>
    <br />

    <p>
      We'll talk more about the terminal later in the quarter, but for now, know that
      the terminal is how you'll be running your Python programs throughout the quarter.
      To run your first Python program, type this into the terminal and press enter:
    </p>

    <div className="text-center">
      <code><span className="launcher">python3</span> intro.py</code>
    </div>
    <br />

    <p>
      You should see output that looks like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Terminal1}
          className="img-fluid mx-auto d-block" alt="First run output" />
      </div>
    </div>
    <br />

    <p>
      Now, type the following command into the terminal:
    </p>

    <div className="text-center">
      <code><span className="launcher">python3</span> intro.py &lt; YOUR NAME HERE &gt; </code>
    </div>
    <br />

    <p>
      For example, if your name is Elyse, you'd type <code><span className="launcher">python3</span> intro.py Elyse</code>. You
      can type your full name if you'd prefer. You should now see output like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Terminal2}
          className="img-fluid mx-auto d-block" alt="Second run output" />
      </div>
    </div>
    <br />


    <p>
      Congratulations! You're now done with the PyCharm setup process.
    </p>

  </>
}

const HeaderAlert = () => {
  return <div className="alert alert-info">
    <b>Running into issues?</b> Check this <Link to="/pycharm-bugs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>PyCharm Bugs Handout</Link>, and if your issue persists, post on our discussion forum or
    come to Ngoc's office hours or the special PyCharm install session after class on Friday April 5th!
  
</div>
}

const HandoutInnerWindows = () => {
  return <>
    <h1>Installing Pycharm</h1>
    <hr />
    <div className="alert alert-warning">
      <p>
        We've detected you're using a Windows machine.
      </p>
    </div>

    <HeaderAlert/>

    <p>
      In CS 106A, you will be writing your code on your
      computer using an application called
      <a href="https://www.jetbrains.com/pycharm/"> PyCharm</a>. PyCharm is
      what is known as an IDE, or <i>Integrated Development Environment</i>,
      which means that it allows us to edit our code, browse our files, run
      our programs and debug them, all in the same place. It's one of the
      most popular Python IDEs in the world, and used extremely commonly in
      industry. This handout is intended to take you through installing
      PyCharm on your own computer.
    </p>

    <h2>Installing Python</h2>
    <p>
      Before you can use PyCharm, you'll need to install a <i>Python Interpreter.</i> An interpreter is a program
      that is capable of reading a <code>.py</code> file that you have
      written, and translating the Python code in that file to instructions
      that your computer can easily execute. Begin by downloading Python. CS106A
      requires the use of the newest stable version of Python (currently 3.11), so make sure to follow these
      instructions even if you think you already have Python installed.
      To install Python, simply open the downloaded installer file
      and follow the default instructions. Select the link corresponding to
      your operating system and download the latest version of <b>Python 3</b>:
    </p>
    <ul>
      <li className="winonly">
        <a href="https://www.python.org/downloads/windows/">
          Windows Downloads
        </a>
      </li>
      <li className="winonly">
        <a href="https://www.python.org/ftp/python/3.10.1/python-3.10.1.exe">
          Windows 32-bit installer
        </a> (If you're using an older Windows computer, download this file)
      </li>
    </ul>
    <h3 data-toc-skip>Installing Python on Windows</h3>
    <p >
      Open the downloaded file. Before installing, there should be an
      option that says "Add Python VERSION_NUMBER in PATH". <b>Make sure to check this box.</b> Then, continue installing
      normally.
    </p>

    <hr />

    <h2> Installing and Testing PyCharm </h2>

    <h3> Installation </h3>

    <p>
      To get started, download and install the <b>community</b> version of PyCharm.</p>

    <p>

    </p>
    <a target="_blank" href="https://www.jetbrains.com/pycharm/download/download-thanks.html?platform=windows&code=PCC">
      Windows Download Link
    </a>
    <p>Click the black download circle. Open the downloaded <code>.exe</code> file and install
      PyCharm, using all the default options.</p>


    <p>
      At the end of the PyCharm installation process, open PyCharm, and you'll see a welcome screen:
    </p>
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Welcome} className="w-100" alt="PyCharm Welcome Screen" />
      </div>
    </div>
    <br />

    <p id="configure_interpreter">Click the 'Customize' tab on the left and then click 'All Settings...' at
      the bottom:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Customize} className="w-100" alt="PyCharm Welcome Screen, with 'Customize' tab selected" />
      </div>
    </div>
    <br />

    <p id="setinterpreter">
      In the Preferences window, click the 'Python Interpreter' tab, and you should see a
      screen like
      this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Preferences}
          className="w-100"
          alt="PyCharm Preferences Window, in the 'Project Interpreter' Pane, with 'Show All' highlighted in the dropdown menu" />
      </div>
    </div>
    <br />

    <p>
      If you have no interpreter selected, or the interpreter is not the newest version of Python you just
      downloaded, click the drop-down and select 'Show All...'. You should then see a screen like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={AddInterpreter}
          className="img-fluid mx-auto d-block"
          alt="PyCharm Interpreter List, with the plus button in the bottom left highlighted" />
      </div>
    </div>
    <br />

    <p>
      Click the + button on the upper left, then click 'System Interpreter' in the sidebar. Depending on
      whether you've installed other versions of
      Python
      before, the latest version of Python may already be selected in the dropdown menu. If not, select
      the latest version Python that you downloaded and then click 'OK'
      and 'OK' again. I know this picture says python3.10, but yours should say whatever the latest
      version is in the dropdown menu. Please select that version:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={SystemInterpreter}
          className="img-fluid mx-auto d-block" alt="Adding the system interpreter in PyCharm" />
      </div>
    </div>
    <br />

    <p>
      You should now be on a window that looks like this (although the contents of your list might look
      different if you have a later version of Python instead of 3.10):
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={ApplyInterpreter}
          className="img-fluid mx-auto d-block"
          alt="Interpreter window after selecting system interpreter" />
      </div>
    </div>
    <br />

    <p>
      Click 'Apply' and then 'OK' again to be taken back to the welcome page. You're now ready to test
      PyCharm!
    </p>

    <hr />

    <h3> Testing PyCharm </h3>

    <p>
      PyCharm models a program as a 'project', which consists of one or more
      Python files, as well as any additional resources like images or text
      files. To get you familiar with working with and running progams in PyCharm,
      we've provided a sample project, which you can download <a href={`${process.env.PUBLIC_URL}/pycharm_intro.zip`}>
        here</a>. To test out this project, and to gain familiarity with the PyCharm
      environment, download the sample project (on Windows, you'll
      need to manually unzip it by opening it in Windows Explorer and selecting 'Extract All') and open it in PyCharm (using
      the 'open' option on the first screen). <b>Whenever you open projects in PyCharm,
        open the folder directly containing the files you want to edit as opposed to just
        the files themselves.</b> Upon
      opening the project, you should be greeted by a window that looks like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={OpenProject}
          className="img-fluid mx-auto d-block" alt="Blank PyCharm Window" />
      </div>
    </div>
    <br />

    <p>
      Click 'Project' in the top left or use the <code><span id="modifier">Command</span> + 1</code> shortcut
      to open the file explorer, and open <code>intro.py</code> to pull up the editor:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={ProjectTab}
          className="img-fluid mx-auto d-block" alt="PyCharm Editor" />
      </div>
    </div>
    <br />

    <p>
      You aren't required to read or understand this code (although you will be able to by the end of the
      quarter!)
      but note that this editor is where you'll be writing all your code this quarter. Now, to run your
      program, click
      'Terminal' in the bottom left corner, which will pull up a new terminal pane in your PyCharm window:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Terminal}
          className="img-fluid mx-auto d-block" alt="PyCharm Terminal" />
      </div>
    </div>
    <br />

    <p>
      We'll talk more about the terminal later in the quarter, but for now, know that
      the terminal is how you'll be running your Python programs throughout the quarter.
      To run your first Python program, type this into the terminal and press
      enter:
    </p>

    <div className="text-center">
      <code><span className="launcher">py</span> intro.py</code>
    </div>
    <br />

    <p>
      If you get an error along the lines of <code>py is not recognized</code>, you might
      try <code>python intro.py</code> instead. You should see output that looks like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Terminal1}
          className="img-fluid mx-auto d-block" alt="First run output" />
      </div>
    </div>
    <br />

    <p>
      Now, type the following command into the terminal:
    </p>

    <div className="text-center">
      <code><span className="launcher">py</span> intro.py &lt; YOUR NAME HERE &gt; </code>
    </div>
    <br />

    <p>
      For example, if your name is Elyse, you'd type <code><span className="launcher">py</span> intro.py Elyse</code>. You
      can type your full name if you'd prefer. You should now see output like this:
    </p>

    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <img src={Terminal2}
          className="img-fluid mx-auto d-block" alt="Second run output" />
      </div>
    </div>
    <br />


    <p>
      Congratulations! You're now done with the PyCharm setup process.
    </p>

  </>
}

export default InstallingPycharm