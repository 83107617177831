import { FaBook, FaCheck, FaComment, FaPause, FaThLarge } from "react-icons/fa"
import { Link } from "react-router-dom"

export const Values = () => {
  return <>
  <p className="subtleHeading">Course Values</p>
    <p>Everyone is welcome. Intellectual joy. Be kind. Be humane. Social connection. Learn by doing. Thrill of building. Adapt to new contexts.</p>
    

  </>
}