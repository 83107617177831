import { useIsMobile } from "utils/general";
import { TeachNowTrainingSlide } from "./SlideTeachNowTraining";
import { TeachNowTrainingProvider } from "./TeachNowTrainingContext";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { isMentor, isSectionLeader, isAdmin, isATypeOfStudent, isStaffRole, isExternalStudent } from "contexts/ProfileUtil";
import Gate from "contexts/Gate";
import { Navigate } from "react-router";
import { useCourseId } from "hooks/router/useUrlParams";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import NoAccess from "components/errors/NoAccess";

const EXTERNAL_STUDENT = 'externalstudent'


export const TeachNowTrainingOuter = () => {
    // If the screen is too small, don't render the component. We are requiring users to be on desktop for this
    const isMobile = useIsMobile();
    if(isMobile) return <div>Mobile not supported. For this task you need a bigger screen.</div>

    // get the current navigation location
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    // const armId = pathSegments[2] ? pathSegments[2] : 'teacherPuzzles'
    const slideId = pathSegments[3]; // can be undefined

    const courseId = useCourseId();

    const { userData } = useContext(ProfileContext)

    const isStaff = isStaffRole(userData.courseRole)

    const is106AStudent = isExternalStudent(userData.courseRole);
    const showStudentTraining = isATypeOfStudent(userData.courseRole) || isStaff;
    const isTeachingTeam = isMentor(userData.courseRole) || isSectionLeader(userData.courseRole)

    let armId;
    if (is106AStudent) {
        armId = 'cs106aStudentTraining';
    } else if (showStudentTraining) {
        armId = 'studentTraining';
    } else if (isTeachingTeam) {
        armId = 'teachingTeamTraining';
    } 

    if (!armId) {
        return <NoAccess />
    }


    return <TeachNowTrainingProvider
            startSlideId={slideId}
            userArm={armId}
            >
        <TeachNowTrainingSlide />
    </TeachNowTrainingProvider>
}

