import Loading from "react-loading";
import { useAuthState } from "react-firebase-hooks/auth";
import { CS106ANavbar } from "cs106a/components/Navbar";
import { Handout } from "cs106a/components/Handout";
import { GoogleAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";

export const LoginWrapper = ({ component: Component, ...props }) => {
  const [user, loading] = useAuthState(getAuth());
  if (loading) {
    return <Loading />;
  }
  if (!user) {
    loginWithRedirect();
    return <Loading />;
  }
  return <Component user={user} />;
};

// this is a page if someone requests to log in
export const LoggedIn = () => {
  const [user, loading] = useAuthState(getAuth());
  if (loading) {
    return <Loading />;
  }
  const msg = user ? "Logged in as " + user?.displayName : "Logging in...";
  return (
    <>
      <CS106ANavbar />
      <Handout element={<h1>{msg}</h1>} />
    </>
  );
};

export const loginWithRedirect = () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    hd: "stanford.edu",
    auth_type: "reauthenticate",
    prompt: "select_account",
  });
  const auth = getAuth();
  signInWithRedirect(auth, provider);
};
