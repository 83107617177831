import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'

export const LessonPlan7 = () => {
    // this is what makes it look like a PDF
    return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
    return <>
        <h1>Lesson Plan 7</h1>
        <hr />

        <p>This week we'll be practicing some last topics of the course, which <b>are fair game for the final exam</b>. We'll also
            make some time for <a href="https://forms.gle/sarteRw3PoZ8YWKS8">End of Quarter Evaluations</a> - this is
            your sectionees' chance to submit feedback on section and LaIR! Next week (week 9) will be our final section, with
            final exam review.
        </p>
        <p>See the bottom of this handout for a note on the <b>CS106A Contest</b>.</p>

        <h3>Section Problem Flow</h3>

        <ol>
            <li><b>15 min: End of quarter eval.</b> You should spend the first 15 minutes of section having students fill
                out the end of quarter evaluation - hopefully most people finish in 15 minutes. If they need more time, they can
                just finish and submit after section. This will be silent, and you can reassure them that feedback is anonymous.</li>
            <li>
                <b>15 min: Sorting and tuples.</b> Students will sort lists (even lists of tuples) using a specific function as a
                key. For example, to sort a list of strings by length, we can use the <code>len</code> function:

                <PythonHighlighter code={sorting1} />

                <p>This works with functions students define as well:</p>

                <PythonHighlighter code={sorting2} />
            </li>
            <li>
                <b>20 min: Circle class.</b> If you haven't seen classes in Python before, check
                out <a href="https://cs106a.stanford.edu/lecture/20">last Friday's slides</a>. We'll create a simple
                class representing a circle and a few methods that get information about the circle.
            </li>
        </ol >

        <h3>Section tips</h3>

        <h4>Creative Classes</h4>
        <p> If you'd like to get more creative with your classes problem, you can have your section implement
            another type of class. Here are a few classes you might implement (and some useful methods for each):</p>

        <ul>
            <li>Pizza class (<code>take_slice</code>, <code>get_topping_list</code>, <code>add_topping</code>)</li>
            <li>Sink class (<code>add_dish</code>, <code>get_dish_count</code>, <code>wash</code>)</li>
            <li>Class class (<code>add_student</code>, <code>get_student_id</code>, <code>get_roster_list</code>)</li>
        </ul>


        <h4>CS106A Contest</h4>
        <p>Some students have been wondering whether they should enter the CS106A Contest, given that we're only awarding two winning
            prizes. We will be awarding a larger number of runner-up prizes, which will give students a grade boost something
            like making one of their assignment grades a +. Anyone who is looking to have some fun and challenge themselves
            with a more open-ended project should certainly enter, but here's what I'd tell students who are unsure:
        </p>
        <ul>
            <li>If the midterm didn't go well, and the student seems to be having difficulty with the course material, they shouldn't use the
                contest as a way to save their grade. Just in terms of point values, the assignments and final exam are going to be
                worth more than the contest grade boost (unless they are one of the top two entries), so they should focus
                on turning in solid work for HW5 and HW6 and studying for the final before completing this optional work.
            </li>
            <li>If the midterm didn't go well, but the student is feeling good about their Python skills outside of the testing
                environment, the contest could be a way for them to get some recognition. The contest is a great chance for
                students to challenge themselves, but again, it should not take time away from the required parts of the course.
            </li>
            <li> If the student's feeling good about CS106A thus far and wants to put in some time to create something really
                awesome, the CS106A Contest is the place for them to show us when they can do!
            </li>
        </ul>

        <p>Additionally, students cannot re-submit previous extensions to the contest. They should have already gotten any
            credit for those extensions when they submitted them, so we are not going to give additional credit.
        </p>

    </>
}
const sorting1 = `>>> lst = ['have', 'a', 'great', 'section']
>>> sorted(lst, key=len)
['a', 'have', 'great', 'section']`

const sorting2 = `>>> def second_char(str):
...     return str[1]
... 
>>> lst = ['#hey', '$there', '@cs106a']
>>> sorted(lst, key=second_char)
['@cs106a', '#hey', '$there']`

const add_pair = `dict[key] = value`

const get_value = `value = dict[key]`
