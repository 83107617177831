import { Handout } from "cs106a/components/Handout"
import { useEffect, useRef } from "react";
import { useComponentSize, useWindowSize } from "react-use-size";

const formHeightExpanded = 3500
const formHeightMobile = 3900

export const Assn0 = () => {
    const url = 'https://forms.gle/3wpWjDH491gAnSSZ8'

    window.location.replace(url);
    
    
    return <></>
}
