import { CS106ANavbar } from "cs106a/components/Navbar"
import { useComponentSize } from "react-use-size"
import { Footer } from "../components/Footer"
import { courseInfo } from "../courseInfo"
import { Announcements } from "./components/Announcements"
import { KeyDates } from "./components/KeyDates"
import { Resources } from "./components/Resources"
import { TeachingTeam } from "./components/TeachingTeam"
import { TodoList } from "./components/TodoList"
import { Values } from "./components/Values"

export const DesktopLanding = () => {
  const rightSideSize = useComponentSize()
  console.log(rightSideSize)
  const leftSideSize = useComponentSize()
  return <>
    <CS106ANavbar/>
    <div className="container-lg">
      {/* <!-- Home page header --> */}
      <div className="row" id="indexHead">
        <div className="col mt-4 title-section">
          <img style={{width:'85px'}} className="float-left mr-3" src={`${process.env.PUBLIC_URL}/stanford2.gif`}></img>
          <div id="headerText " style={{ paddingBottom: '15px' }}>
            <div className="title">CS106A: Programming Methodologies</div>
            <div style={slightlySmallStyle}>{courseInfo.quarterFullName}</div>
            <div style={slightlySmallStyle}>
              Tuesday, Thursday, Friday {courseInfo.lectureTime} in-person in <a target="_blank" href={courseInfo.locationMap}>{courseInfo.location}</a>

            </div>
          </div>
        </div>
      </div>



      {/* <div className="alert alert-primary"><b>Under Construction:</b> We are excited for a new quarter. We are building new tools for you!</div> */}


      <div className="d-flex justify-content-between pt-3">
        
        {/* <!-- make this sticky-top when announcements get long --> */}
        <div className="cs106a-landing-left-col" style={getColStyle(leftSideSize)}>
        <div ref={leftSideSize.ref}>
          <div className="left-col-inner">
            <TodoList/>
            <hr />
            <TeachingTeam/>
            <hr />
            <div className="h-0"></div>
          </div>
        </div>
        </div>
        <div className="announcement-col">
          <div className="announcement-col-inner">
            {/* <!-- Newsfeed --> */}
            <Announcements/>
            <Footer/>
          </div>
        </div>
        <div className="cs106a-landing-right-col" style={getColStyle(rightSideSize)}>
          <div ref={rightSideSize.ref}>
          <Resources/>
          <hr />
          <KeyDates/>
          <hr />
          <Values/>
          <hr />
          </div>
        </div>
      </div>
    </div>
  </>
}

const slightlySmallStyle = {
  fontSize: '.9em'
}

const getColStyle = (colSize) => {
  const windowHeight = window.innerHeight
  const navHeight = 0
  const colHeight = colSize.height
  const top = Math.min(navHeight, windowHeight - colHeight)
  return {
    height:colHeight,
    top:`${top}px`,
  }
}