import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Handout } from "cs106a/components/Handout"
import { Link } from 'react-router-dom'
// @ts-ignore
import Five from "./img/practiceFinal/5.png"
// @ts-ignore
import Eight from "./img/practiceFinal/8.png"
// @ts-ignore
import Twelve from "./img/practiceFinal/12.png"



export const PracticeFinal = () => {
  // The navbar already exists, this is just the component bellow the nav :-)
  // this is what makes it look like a PDF
  return <Handout element={<HandoutInner />} />
}

const HandoutInner = () => {
  return <>
    <h1>Practice Final Exam</h1>
    <hr />
    <h5><Link to="/practice-final-soln"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Solutions</Link></h5>


    <p>This handout is intended to give you practice solving problems that are comparable in difficulty
      to those which will appear on the final exam. Our final exam will be on <b>Friday, June 7th at 8:30am.</b></p>

    <p>Here are the room assignments (same as the Diagnostic exam):
    </p>
    <ul>
      <li>First names A-P in Hewlett 200</li>
      <li>First names Q-Z in Hewlett 201</li>
    </ul>

    <h3>Resources</h3>

    <p>You may bring in up to 10 pages of notes, double sided, to reference during the exam. This will allow you to bring
      in the same reference from the midterm, if you like, as well as some additional notes. You are welcome to print
      or handwrite these notes, and you may include lecture slides, handouts, or whatever content you'd like in
      these notes (within the honor code).</p>

    <p>You may not use a laptop or any other devices during the exam, unless
      you have academic accommodations to use such devices.
    </p>

    <p>Here are some resources you might reference as you study:</p>
    <p>
      <ul>
        <li> <a href="https://drive.google.com/file/d/10RB48bZivXMLwrqnaXln_igDnSBf0tw9/view?usp=sharing">Final reference</a> - a list of CS106A functions, created by our own Kieran Barrett</li>
        <li> <Link to="/final-prep"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Final prep handout</Link> - a bank of practice problems from each category</li>
        <li> <a href="https://cs.stanford.edu/people/nick/py/">Nick Parlante's Python guide</a></li>
        <li> <Link to="/graphics"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Graphics reference</Link></li>
        <li> <Link to="/interactive-programs"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Interactive programs reference</Link></li>
        <li> <Link to="/images"><span className="fa fa-file mr-sm-1" aria-hidden="true"></span>Image reference</Link></li>
      </ul>
    </p>

    <h3>Format</h3>

    <p>The exam is on paper, and you will write your answers on the exam using a pencil or pen. Please
      bring a writing implement to use during the exam. You will have 3 hours to complete the exam.</p>

    <h3>Coverage</h3>
    <p>The final exam covers all material we've seen in lecture and assignments. The exam will be cumulative. However,
      the following topics will <b>NOT</b> appear on the final exam:
    </p>

    <ul>
      <li>Karel</li>
      <li>Tuples</li>
      <li>The internet and servers</li>
      <li>List comprehensions and matplotlib</li>
      <li>You won't need to write your own classes, but you should be able to understand and follow the logic in programs that use Classes {"("}i.e. tracing{")"}.</li>

    </ul>

    <h3>General Instructions</h3>

    <p>Make your best attempt to answer each of the questions included in the exam. Make sure to include any
      work that you wish to be considered for partial credit, even if it is not compilable code. However, we will not
      grade multiple attempts at a solution, so please only provide one solution to each problem. Each question is
      marked with the number of points assigned to that problem. The total number of points is 180. We intend
      for the number of points to be roughly comparable to the number of minutes you should spend on that problem.
      You can also write helper functions, if that helps you solve the problem.</p>

    <p>Unless otherwise indicated as part of the instructions for a specific problem, comments will not be required
      on the exam. Uncommented code that gets the job done will be sufficient for full credit on the problem.</p>

    <h2>Practice Exam</h2>
    <h2>Problem 1: Sandcastles</h2>
        <h3>A. Classes Tracing</h3>
        <p>Recall the Dictionary class we coded up together in lecture: </p>
        <SyntaxHighlighter code={classes_dict} />
        <p>Trace this program and write what the program prints.</p>
        <SyntaxHighlighter code={classes_tracing} />
        <h3>B. Lists</h3>
        <p>Given a list of integers, create and return a new list with each value halved (divided by 2).</p>
        <p>Example:</p>
        <SyntaxHighlighter code={lists_1}/>
        <p>Write the code to achieve this.</p>
        <SyntaxHighlighter code={lists_2}/>
        <h3>C. Dictionaries</h3>
        <p>You are given a JSON string representing a dictionary where the keys are names of people and the values are their favorite fruits. Write a function to load the JSON string and return the count of how many people have a specific fruit as their favorite. The specific fruit is passed in as the parameter <code>fruit</code>.</p>
        <SyntaxHighlighter code={dict}/>

    <h2>Problem 2: Lists (20 Points)</h2>

    <p>The company DataScrambler.com has hired you to write a function called interleave_lists, which has the following
      header: <code>def interleave_lists(list1, list2)</code>.</p>

    <p>The interleave_lists function is passed two lists of integers (<code>list1</code> and <code>list2</code>), and should return a new list of
      integers that interleaves the elements (numbers) from <code>list1</code> and <code>list2</code>. The result of
      interleaving <code>list1</code> and <code>list2</code> is simply a list of integers that contains (in order)
      alternating elements (numbers) from <code>list1</code> and <code>list2</code> (starting
      with a number from <code>list1</code>). If one of the lists passed in to the function is longer than the other,
      then any remaining
      numbers from the longer list which are not already included in the interleaved list are simply added (in order)
      to the end of the result.</p>

    <p>For the examples we give below, say we have the following three lists:</p>

    <SyntaxHighlighter code={three_lists} />

    <p>Calling <code>interleave_lists(first, second) should return:</code></p>

    <SyntaxHighlighter code={first_second} />

    <p>Note that the interleaved lists that is returned starts with the value 1 from the list first, then 10
      from the list second, then 2 from the list first, then 20 from the list second, and so on. Since the
      list first is longer than the list second, after the values 1, 2, 3 and 4 from the list first have been
      interleaved with the values 10, 20, 30 and 40 from the list second, the remaining elements of the lists
      first (5, 6, 7) are simply added to the end of the resulting interleaved list.</p>

    <p>Here are two additional examples of what should be returned from your interleave_list function.
      Calling <code>interleave_lists(second, third)</code> should return:</p>

    <SyntaxHighlighter code={second_third} />

    <p>And, calling <code>interleave_lists(third, first)</code> should return:</p>

    <SyntaxHighlighter code={third_first} />


    <p>If one of the lists passed into your function is an “empty” list (<code>[]</code>), you should still perform an
      interleaving, but the empty list will contribute nothing to the result. If you are passed two empty lists, your
      function should return an empty list.</p>

    <p>Write your <code>interleave_lists</code> function in the starter code below. Note that for this problem, you don't
      need to write a complete program - you just need to implement the <code>interleave_lists</code> function. Feel free
      to define any additional functions that you would like to help you solve this problem.</p>

    <SyntaxHighlighter code={lists_starter} />

    <h2>Problem 3: Strings (20 Points)</h2>

    <p>Computers have gotten very good at correcting our writing mistakes. One form of such correction is making sure
      that sentences we write are properly capitalized. For example, given a string that contains some text, we
      want to make sure that the first letter of every sentence is capitalized and all other letters are lower case.</p>

    <p>For example, consider the string: <br /> <code>'almost done with finals? yah!!! ** so HAPPY. have a GoOd BrEaK!'</code></p>

    <p>The properly capitalized version of this string would be: <br /><code>'Almost done with finals? Yah!!! ** So happy. Have a good break!'</code></p>

    <p>To implement this functionality, your job is to write a function <code>fix_capitalization(str)</code> that takes a
      string called <code>str</code> as its argument and returns a new string which is the properly capitalized version
      of the original string passed in.</p>

    <p>The rules for proper capitalization are as follows:</p>

    <ul>
      <li>The first letter of the string is capitalized (uppercase).</li>
      <li>The first letter after each end-of-sentence punctuation mark should be capitalized.
        For our purposes, we'll consider period ('.'), exclamation point ('!') and question mark ('?') as the only
        three characters that can be used as punctuation to end sentences, so you should not consider any other
        end-of-sentence punctuation.</li>
      <li>All letters that are not capitalized (according to the rules above) should be lower case.</li>
      <li>Characters which are not letters should remain unchanged.</li>
    </ul>

    <p>For example, if you call <code>fix_capitalization("STANFORD ROCKS!!")</code>, your function should return:</p>

    <SyntaxHighlighter code={stan_rocks} />

    <p>Similarly, if you call <code>fix_capitalization("*almost done!! * that's AwESoME?! yes, IT IS.")</code> your function should return:</p>

    <SyntaxHighlighter code={awesome} />

    <p>As you can see above, we make the first letter at the beginning of the string or after a punctuation mark uppercase
      even when there are (an arbitrary number of) intervening non-letter characters (such as "almost done!!" and " that's" in
      the second example above, which are properly capitalized as "Almost done!!" and " That's", respectively, regardless
      of the intervening non-letter characters).</p>

    <p>In writing your solution, you should keep in mind that you do not need to write a complete program. All you need
      is the definition of the function <code>fix_capitalization</code> that returns the desired result. You may write any
      additional functions that may help you solve this problem, but you are not required to decompose your solution.
      Write your solution to this problem on the next page.</p>

    <SyntaxHighlighter code={string_starter} />

    <h2>Problem 4: Graphics (25 Points)</h2>

    <p>To get more artistic with your programming, you decide to write a function called <code>draw_sketch</code> that is passed a
      graphics canvas and draws a simple line sketch “artwork” on that canvas. Your function should first asking the user
      (on the console) for the “Number of lines in the drawing:”. You can assume the user will always enter a positive integer
      value. You should then create a drawing on the canvas which is produced by drawing lines with randomly determined
      starting and ending points, where the ending point of each line in the drawing becomes the starting point for the
      subsequent line, so all the lines in the drawing are connected. The starting/ending points for each line can appear
      anywhere on the canvas, which has size (in pixels) specified by the constants <code>CANVAS_WIDTH</code> and <code>CANVAS_HEIGHT</code>.
      The figure below shows an example of an output of this program where the user asked for 5 lines in the drawing.</p>

    <img src={Five} className="img-fluid mx-auto d-block" width="70%"
      alt="Drawing with 5 lines" />

    <p>Note that there is also a colored circle (both the fill and outline of the circle are colored) centered at the end
      point of each line in the drawing. This is to add more artistic flair to the drawing. The colors used for the circles
      are determined by the constant COLORS, which is a list of color names (strings), as shown below.
      <br />

      <code>COLORS = ['red', 'orange', 'yellow', 'green', 'blue', 'violet']</code></p>

    <p>The colors for the circles you draw should cycle through the <code>COLORS</code> list, starting with the color red for the starting
      point of the first line, then the color orange for the starting point of the second line (which is also the ending point
      of the first line), and so on. After drawing a circle that is violet, the next circle drawn should be red again,
      since the colors are being cycled through. Each colored circle should appear underneath the two lines that are
      connected at that point in the drawing, though it is fine if the circle appears in front of lines that came
      earlier in the drawing. The radius (in pixels) of the circle is determined by the constant <code>RADIUS</code>.</p>

    <p>Two additional program runs are shown in the figures below, which have 8 and 12 lines, respectively, to show
      the cycling of colors in the circles. Note that while the endpoints of all the lines should appear on the graphics
      canvas, it is fine if the circles drawn get clipped if they are too close to the edge of the canvas.</p>

    <div className="img-fluid mx-auto d-block">
      <img src={Eight} width="50%"
        alt="Drawing with 8 lines" />
      <img src={Twelve} width="50%"
        alt="Drawing with 12 lines" />
    </div>
    <br />

    <p>Write your function <code>draw_sketch</code> below. Note that for this problem you can assume that there is a
      a <code>main</code> function that has already created a canvas with the dimensions <code>CANVAS_WIDTH</code> and <code>CANVAS_HEIGHT</code>,
      and is passing that canvas to your <code>draw_sketch</code> function. So, you just need to implement
      the <code>draw_sketch</code> function. Feel free to define any additional functions that you would like to help you
      solve this problem.</p>

    <SyntaxHighlighter code={graphics_starter} />

    <h2>Problem 5: File Processing (25 Points)</h2>

    <p>You are given a text file containing information about a group of people's best friends. The file is structured so
      that each line contains the name of a particular person as well as the name of their best friend.
      You can assume that people's names are unique (e.g., anytime a name like “Pat” appears, it is always referring
      the same person named “Pat”). And to keep things simple, you can also assume that each name is just a single
      token representing the first name of the person. Also, each person has at most one best friend. A sample
      data file might look as follows:</p>

    <p><code>data.txt</code></p>

    <SyntaxHighlighter code={data} />

    <p>The file above indicates that Bob's best friend is Alice, Chelsea's best friend is Bob, etc. Note that not everyone is
      required to have a best friend (for example, Eduardo's best friend is Don, but Don doesn't have a best friend in the file).
      And best friends are not necessarily reciprocal (Bob's best friend is Alice, but Alice's best friend is not Bob).</p>

    <p>You think it would be interesting to determine if there is a “best friend loop” in such data. A “best friend loop” occurs
      when you start at some person, then find their best friend, then find that person's best friend, etc. until you get
      back to the starting person. For example, the data above contains a “best friend loop” since we could start with Bob,
      then link to Alice (Bob's best friend), then link to Chelsea (Alice's best friend), and then link back to Bob (Chelsea's
      best friend).</p>

    <p>Your job is to write a function <code>contains_loop</code> that is passed the name of a file (string) containing the best
      friend data and determines if the data contains a “best friend loop”. Your function should return True if the data
      in the file in contains at least one “best friend loop” and False otherwise (i.e., return False when there are no best
      friend loops).</p>

    <p><i>Hint: consider reading the file into a dictionary (where the key is a person's name and the value is the name of that
      person's best friend) and use that dictionary to help you determine if a loop exists.</i></p>

    <p>To provide another example, the file <code>noloops.txt</code> below does not contain any “best friend loops”, so your
      function should return False if the filename "noloops.txt" were passed to it.</p>

    <p><code>noloops.txt</code></p>

    <SyntaxHighlighter code={no_loops} />

    <p>And, it is fine for someone to be their own best friend, which would constitute a “best friend loop”. This is
      shown in the file <code>loveyourself.txt</code> below (the “best friend loop” is simply starting with Pat and
      then linking back to Pat). So, your function should return <code>True</code> in this case.</p>

    <p><code>loveyourself.txt</code></p>

    <SyntaxHighlighter code={self_love} />

    <p>Here's your starter code:</p>

    <SyntaxHighlighter code={file_starter} />

    <h2>Problem 6: Using Data Structures (30 Points)</h2>

    <p>Realizing that the US National Debt is now over $30 trillion, you decide to create the notion of “Big Numbers”,
      allowing you to represent (and add) arbritrarily large positive integer values. You are going to represent
      such a large number as a list of digits. For example, the number 16,381,245 could be represented using a
      list where each entry of the list is a single digit, as follows:</p>

    <SyntaxHighlighter code={digit_list} />

    <p>To allow you to more easily add such numbers, it would be useful to store the digits of the “Big Number” backwards,
      so that the ones digit is always in position 0, the tens digit is always in position 1, the hundreds digit is always
      in position 2, etc. So, the list representing 16,381,245 would actually look as follows (with indexes shown below
      the list):</p>

    <SyntaxHighlighter code={digit_indices} />

    <p>The list should only have as many elements as the number of digits in the number it represents. So, if we had a list
      called <code>bignum1</code> representing the number 9,564 and wanted to add to it another list
      called <code>bignum2</code> representing 867, we should (like you did in elementary school) add the ones digits
      (which are respectively both at index 0), then add the tens digits (at index 1), etc. to get the resulting sum 10,431,
      shown in the variable result below:</p>

    <SyntaxHighlighter code={add} />

    <p>Note that the resulting sum can have more digits than either of the original numbers added together.</p>

    <p>Your job is to implement a function named <code>add_bignums</code> that is passed two “Big Numbers” (i.e., lists of
      digits in backwards order, as discussed above) and returns a new list representing the “Big Number” (i.e. list of digits
      in backwards order) that represents the sum of the two numbers passed in, using the algorithm described above to add the
      numbers digit-by-digit, starting at the ones digit). Your function should not modify the two lists that are passed into it.</p>

    <p>For example, say your function is called with lists (as defined above) representing the numbers 9,564 and 867 as
      “Big Numbers”, <code>add_bignums([4, 6, 5, 9], [7, 6, 8])</code> the function should return:</p>

    <SyntaxHighlighter code={list_result} />

    <p>Which would represent the value 10,431 as a “Big Number” (i.e., list of digits in backwards order).</p>

    <p>In writing your solution, you should keep in mind that you do not need to write a complete program. All you need
      is the definition of the function add_bignums that returns the desired result. You may write any additional functions
      that may help you solve this problem, but you are not required to decompose your solution. Write your solution
      to this problem on the next page.</p>


    <SyntaxHighlighter code={structures_starter} />

    <h2>Problem 7: Full Program (30 Points)</h2>

    <p>We'll write a program that simulates a game of dice between two players. Each round, both players "roll" a single
      die by randomly generating an integer between 1 and 6, inclusive. If one of the players rolls a number two or
      more than the other player's number, they win that round. We'll end the game after one player wins two rounds in a row,
      and then announce the winner of the game.
    </p>

    <p>Here's a sample run of this game:</p>

    <SyntaxHighlighter code={die_game_run} />

    <p>Write code in the <code>main</code> function to implement this program. You are welcome to decompose your solution into
      helper functions if this helps you solve the problem.</p>

    <SyntaxHighlighter code={program_starter} />

    {/*<h3>Problem 7: Classes (30 Points)</h3>

    <p>We want to build a class in Python to handle a box office ticket management system that keeps track of tickets
      sold for shows at the Bing Concert Hall (referred to as just “Bing” here). For the purposes of this problem, we
      will assume that Bing has 500 seats that are numbered 1 to 500. Thus, seats are simply designated by integers from
      1 to 500. We want to design a class, where we can create an object that allows a user to: </p>

    <ul>
      <li>Determine if there are still tickets available for sale from the box office.</li>
      <li>Get (purchase) the next available ticket from the box office, if there are tickets available.</li>
      <li>Return a previously purchased ticket to the box office (which would make it available for someone
        else to potentially get/buy in the future).</li>
    </ul>

    <p>With this idea in mind, we want to define a class called <code>TicketSystem</code> which has a constructor as well as
      three methods (“method” is just another name for a function that is in a class), defined as follows:</p>

    <ul>
      <li><code>has_tickets()</code> - this method returns True if there are tickets still available for purchase, which
        means that either there are still unsold seats or there are tickets which have been returned and not yet sold again
        (or both). If no tickets are available, the method returns False.</li>
      <li><code>next_ticket()</code> -  if there are tickets available, this method returns an integer indicating the seat designation
        of the next available ticket. If no tickets are available, the method returns -1 since there are no valid available
        seats.</li>
      <li><code>return_ticket(ticket)</code> - this method is passed a valid (previously purchased) ticket (i.e., integer
        designating a seat). This ticket is considered returned to the box office and is once again available for sale (i.e.,
        some future call to <code>next_ticket</code> could return this ticket). This method does not need to do error
        checking on the ticket passed in - you can assume that the ticket will always be a valid number for a seat that was
        previously sold.</li>
    </ul>

    <p>Note that the box office chooses to sell tickets in a particular order, as follows:</p>

    <ul>
      <li>If any tickets for seats have been returned, sell those tickets first in the order in which they were returned,
        before selling any other available seats.</li>
      <li>If there are no previously sold tickets that have been returned to sell again, then sell seats in order by section
        number (first) and by seat number (second). So, the first ticket sold would be 1, followed by 2, then 3, etc. Thus,
        500 would be the last ticket to be sold (not counting returned tickets). As an example, say we sell tickets 1, 2, 3, and
        4. Then, ticket 2 is returned, and then ticket 1 is returned. The next ticket we will sell will be 2, followed by 1.
        After that (assuming no other ticket has been returned), we would then sell ticket 5 next.</li>
    </ul>

    <p>We give you the outline of the class <code>TicketSystem</code> and the methods specified in the class below. Your task is to
      write the implementation (body) of each of the methods in the class <code>TicketSystem</code>. Write your answer below.
      Note that you do not have to write a program that creates objects based on your class. We just want you to define the
      methods in the class.</p>

    <SyntaxHighlighter code={class_starter} />*/}
  </>
}

const classes_dict = `INITIAL_SIZE = 10000

"""
    A simple implementation of the Python Dictionary. This version
    captures the main good idea underneath a dictionary. Keep track
    of a huge list and use a hash to go from key to index.
    """

class Dictionary:

    def __init__(self):
        self.data = []
        for i in range(INITIAL_SIZE):
            self.data.append(None)

    def set(self, key, value):
        index = self.key_to_index(key)
        self.data[index] = value

    def get(self, key):
        index = self.key_to_index(key)
        return self.data[index]

    def key_to_index(self, key):
        data_size = len(self.data)
        hash_value = hash(key)
        return hash_value % data_size`

const classes_tracing = `def main():
favorite_colors = Dictionary()

favorite_colors.set("Mehran", "Blue")
favorite_colors.set("Chris", "Green")

update_colors(favorite_colors)

print(favorite_colors.get("Mehran"))   
print(favorite_colors.get("Chris"))         
print(favorite_colors.get("Elyse"))     

def update_colors(colors):
colors.set("Mehran", "Purple")
colors = Dictionary()
colors.set("Chris", "Orange")
colors.set("Mehran", "Blue")
colors.set("Elyse", "Yellow")

if __name__ == '__main__':
main()`

const lists_1 = `# Input
scores = [80, 90, 100, 70, 85]

# Returned output
[40.0, 45.0, 50.0, 35.0, 42.5]`

const lists_2 = `def halve_scores(scores):
    # Your code here`


const dict = `import json

# Example usage:
favorites_json = '{"Chris": "papaya", "Arisa": "papaya", "Mehran": "toast", "Iddah": "banana", "Benji": "banana"}'
fruit = “papaya”
print(count_favorite_fruit(favorites_json, fruit)) #Prints 2


def count_favorite_fruit(favorites_json, fruit):
    # Your code here`

const three_lists = `first = [1, 2, 3, 4, 5, 6, 7] 

second = [10, 20, 30, 40]   

third = [100]  `

const first_second = `[1, 10, 2, 20, 3, 30, 4, 40, 5, 6, 7]`

const second_third = `[10, 100, 20, 30, 40]`

const third_first = `[100, 1, 2, 3, 4, 5, 6, 7]`

const lists_starter = `def interleave_lists(list1, list2):









`

const stan_rocks = `"Stanford rocks!!"`

const awesome = `"*Almost done!! * That's awesome?! Yes, it is."`

const string_starter = `def fix_capitalization(str):









`

const graphics_starter = `import tkinter
import random

CANVAS_WIDTH = 500      # Width of drawing canvas in pixels
CANVAS_HEIGHT = 300     # Height of drawing canvas in pixels
COLORS = ['red', 'orange', 'yellow', 'green', 'blue', 'violet']
RADIUS = 5

def main():
  canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT)
  draw_sketch(canvas)
  tkinter.mainloop()

# You should implement the draw_sketch function below
def draw_sketch(canvas):









`

const data = `Bob Alice
Chelsea Bob
Eduardo Don
Alice Chelsea`

const no_loops = `Eric Fran
Fran Helen
Gary Helen`

const self_love = `Pat Pat`

const file_starter = `def contains_loop(filename):









`

const digit_list = `[1, 6, 3, 8, 1, 2, 4, 5]`

const digit_indices = `[5, 4, 2, 1, 8, 3, 6, 1]
 0  1  2  3  4  5  6  7`

const add = `bignum1 [4, 6, 5, 9]
bignum2 [7, 6, 8]
result  [1, 3, 4, 0, 1]`

const list_result = `[1, 3, 4, 0, 1]`

const structures_starter = `def add_bignums(bignum1, bignum2):









`

const die_game_run = `Player 1 rolled 3
Player 2 rolled 4
No winner
Player 1 rolled 5
Player 2 rolled 3
Player 1 wins round
Player 1 rolled 4
Player 2 rolled 1
Player 1 wins round
Game over, Winner is Player 1`

const program_starter = `import random

def main():









if __name__ == '__main__':
    main()`

const class_starter = `# Number of seats available in ticketing system
  NUM_SEATS = 500
  
  class TicketSystem:
  
    def __init__(self):
  
  
  
  
  
    def has_ticket(self):
  
  
  
  
  
    def next_ticket(self):
  
  
  
  
  
    def return_ticket(self, ticket):
    
    
    
    
    `