import { Skeleton, Spin } from "antd";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DraftPost, Post } from "../../firebase/ForumDataModels";
import { PostEntry } from "../PostEntry/PostEntry";
import Gate from "../../../../contexts/Gate";

import "./PostList.css";
import { ProfileContext } from "contexts/ProfileContext";
import { FaChevronDown, FaChevronRight, FaPlus, FaSlidersH } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { FiltersContext } from "course/forum/FiltersContext";
import { PostContext } from "course/forum/pages/forum/PostContext";
import { ForumContext } from "course/forum/ForumContext";


function PostSubList(props: {
  posts: Post[] | DraftPost[];
  currentPostId: string | undefined;
  isDrafts?: boolean;
  limit?: number;
}): ReactElement {

  const { posts, currentPostId, limit, isDrafts } = props;
  const isLimit = (!!limit);
  const initialPosts = isLimit ? posts.slice(0, limit) : posts
  const [filteredPosts, setFilteredPosts] = useState<Post[] | DraftPost[]>(initialPosts);
  const [postCount, setPostCount] = useState(isLimit ? limit : posts.length);

  const addMorePosts = () => {
    setFilteredPosts(oldPosts => {
      const newLimit = oldPosts.length + limit;
      return posts.slice(0, newLimit);
    })
    setPostCount(oldCount => oldCount + limit)
  }

  useEffect(() => {
    setFilteredPosts(isLimit ? posts.slice(0, postCount) : posts)
  }, [posts])



  const ViewMoreButton = () => {
    return <div className="w-100 d-flex justify-content-center">
      <button className="border-0 bg-white font-small" onClick={addMorePosts} >
        <FaPlus /> View More
      </button>
    </div>
  }

  return (
    <div>
      {filteredPosts.map((post: any, idx: number) => (
          <PostEntry
            data={post}
            active={currentPostId === post.id}
            isDraft={isDrafts}
          />
      ))}
      {limit && filteredPosts.length < posts.length && (<ViewMoreButton />)}
    </div>
  );
}




export const PostsDropdown = ({ children, title, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);
  const ref = useRef(null);

  return (
    <div ref={ref} className="w-100 text-align-start border">
      <button aria-label="click to close collection" className="border-0 text-align-start d-flex justify-content-between w-100 align-items-center bg-light" onClick={() => setOpen(!open)}><span>{title}</span> {open ? <FaChevronDown /> : <FaChevronRight />} </button>
      {open && children}
    </div>
  );
}

export function PostList(props: {
  currentPostId: string | undefined;
}) {
  const { currentPostId } = props;
  const postListRef = useRef(null);
  const { userData, loading: userIsLoading } = useContext(ProfileContext);
  const {drafts, announcements, posts, postsData} = useContext(PostContext);
  const { tagFilters, hideResolved, setShowFilters, toggleHideResolved, flaggedOnly } = useContext(FiltersContext);
  const { isReporting } = useContext(ForumContext)
  // keep track of where the component is on the page so we can scroll
  // correctly
  const [offsetPixels, setOffsetPixels] = useState(0);


  const isStaff = Gate.hasStaffRole(userData);
  const initOptions = isStaff ? [
    {
      label: "Unresolved",
      value: "resolved"
    }
  ] : []




  useEffect(() => {
    const height = postListRef.current?.clientHeight;
    if (height && height < window.innerHeight) {
      if (postsData.hasNextPage) {
        postsData.fetchNextPage();
      }
    }
  }, [postListRef.current?.clientHeight]);


  useEffect(() => {
    if (tagFilters.includes("resolved") && hideResolved) {
      toggleHideResolved()
    }

  }, [tagFilters])

  // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
  const handleRect = useCallback((node) => {
    setOffsetPixels(node?.getBoundingClientRect().top);
  }, []);

  if (
    postsData.isLoading ||
    userIsLoading
  ) {
    return <Skeleton/>
  }

  if (!posts) {
    return <p>No posts!</p>;
  }

  const handleScroll = (e: any) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 20;
    if (atBottom) {
      postsData.fetchNextPage();
    }
  };

  // this makes sure that the post list scrolls correctly
  const postEntriesStyle = {
    height: `calc(100vh - ${offsetPixels}px)`,
  };

  const canFilter = !isReporting || isStaff;

  return (
    <div
      ref={handleRect}
      className="postEntries"
      onScroll={handleScroll}
      style={postEntriesStyle}
    >
      <PostsDropdown title="Your Drafts">
        <PostSubList
          posts={drafts}
          currentPostId={currentPostId}
          isDrafts={true}
        />
      </PostsDropdown>
    { !isReporting && <PostsDropdown title="Announcements" defaultOpen={true}>
        <PostSubList
          posts={announcements}
          currentPostId={currentPostId}
          limit={3}
          isDrafts={false}
        />
      </PostsDropdown>}

      <div className="w-100 text-align-start border">
        <div className="border-0 bg-light text-align-start d-flex justify-content-between w-100 align-items-center">
          <span className="ml-1">Posts</span> {canFilter && <button onClick={() => setShowFilters(true)} className="btn btn-sm btn-light" aria-label="Click to view filters"><FaSlidersH /></button>}
        </div>
      </div>

      <div ref={postListRef}>
        <PostSubList
          posts={posts}
          currentPostId={currentPostId}
          isDrafts={false}
        />
      </div>
      <div className="bottom">
        {postsData.hasNextPage && postsData.isFetching ? <Spin /> : <p>You've reached the end!</p>}
      </div>


    </div>
  );
}
