import { KarelWorld } from "components/pyodide/KarelLib/KarelWorld";
import { KarelTimeSlider } from "ide/RightPane/CanvasPane";
import { CodeExampleContext } from "./CodeExampleContext";
import { useContext, useEffect, useRef, useState } from "react";
import { MAX_CORNER_SIZE } from "components/pyodide/KarelLib/util";
import { XTermTerminal } from "ide/TerminalPane/Terminal/XTermTerminal";
import { TerminalView } from "ide/TerminalPane/Terminal/XTermModel";
import { useComponentSize } from "react-use-size";
import { Canvas } from "ide/RightPane/Canvas";
import { EX_TYPES } from "./RunnableLesson";
import { useIsMobile } from "utils/general";
import { createTableData } from "ide/LeftCols/StepperView";
import { FaShapes, FaTable, FaTerminal } from "react-icons/fa";



export const Window = ({ type }) => {

    switch (type) {
        case EX_TYPES.KAREL:
            return <KarelWindow />
        case EX_TYPES.CONSOLE:
            return <ConsoleWindow />
        case EX_TYPES.GRAPHICS:
            return <GraphicsWindow />
        default:
            return <div></div>
    }
}







export const KarelWindow = () => {

    const { worldState, karelSleepTime, setKarelSleepTime, startState, setWorldState, stepList, stepPtr } = useContext(CodeExampleContext)
    const isMobile = useIsMobile();
    useEffect(() => {
        if (stepList.length > 0) {
            const step = stepList[stepPtr]
            const karel = step.karel
            if (karel) {
                setWorldState(karel.state)
            }
        }

    }, [stepPtr])

    if (!startState) return <div></div>

    const worldWidth = Math.max(3, startState.nCols) * MAX_CORNER_SIZE


    if(isMobile) {

        return <div
            style={{ paddingLeft: "10px", backgroundColor: "white", width: worldWidth, zIndex: 10 }}
        >
            <div className="d-flex justify-content-between align-items-center">
                <h4>World</h4>
                <div className="mr-2">
                    <KarelTimeSlider
                        karelSleepTime={karelSleepTime}
                        setKarelSleepTime={setKarelSleepTime}
                    />
                </div>
            </div>
            <KarelWorld worldState={worldState} />

        </div>
    }


    return (
        <div
            style={{ paddingLeft: "10px", backgroundColor: "white", width: worldWidth, zIndex: 10 }}
        >
            <h4>World</h4>
            <KarelWorld worldState={worldState} />
            <div className='d-flex justify-content-center w-100 p-2'>
                <KarelTimeSlider
                    karelSleepTime={karelSleepTime}
                    setKarelSleepTime={setKarelSleepTime}
                />
            </div>

        </div>
    )
}



export const ConsoleWindow = () => {
    const { isStepping } = useContext(CodeExampleContext);
    const containerRef = useRef(null);
    const isMobile = useIsMobile();
    const [stepperData, setStepperData] = useState([]);
    const { stepList, stepPtr, stepLogs, terminalRef } = useContext(CodeExampleContext)
    const [currStack, setStack] = useState({})
    const [currScope, setScope] = useState("")
    const [viewingVariables, setViewingVariables] = useState(false)


    useEffect(() => {
        if (stepList.length > 0) {
            const step = stepList[stepPtr]
            if (!step) return;
            const logptr = step.logptr
            if (logptr > 0) {
                setStepperData((_) => stepLogs.slice(0, logptr))
            } else {
                setStepperData([])
            }
            const locals = step.locals
            const codenm = step.codenm
            setStack((_) => locals)
            setScope(codenm)

        }
    }, [stepPtr])


    useEffect(() => {
        // Initialize the TerminalView
        terminalRef.current = new TerminalView(
            "%",
            () => { terminalRef.current.handleStdout('You need to hit the Run button to run this file.')},
            () => { console.log('On end'); },
            () => { console.log('Raise stop'); },
        );

        terminalRef.current.initTerm();

        // Assign the ref to the context if needed

        return () => {
            // Dispose of the terminal instance on component unmount
            terminalRef.current.dispose();
        };
    }, []);

    useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.updateCols(containerRef.current.offsetWidth);
            terminalRef.current.updateRows(containerRef.current.offsetHeight);
        }
    }, [terminalRef.current]);

    useEffect(() => {
        const handleResize = entries => {
            for (let entry of entries) {
                // entry.contentRect contains the new size
                terminalRef.current.updateCols(entry.contentRect.width);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            // Cleanup observer when component unmounts or ref changes
            resizeObserver.disconnect();
        };
    }, []);


    const containerDivClass = isMobile ? "mb-5" : ""
    const height = isMobile ? "200px" : "200px"


    const SwapTerminalButton = () => {
        return (
            <button 
                className="btn btn-sm" 
                onClick={() => setViewingVariables(!viewingVariables)}
            >
                {viewingVariables ? <FaTerminal/> : <FaTable/>}
            </button>
        )
    }

    if(isMobile) {
        return (
        <div
            ref={containerRef}
            className={containerDivClass}
            style={{ width: '100%', height: height }}>
            <h4>Console {isStepping ? <SwapTerminalButton/>: null} </h4>
            <div className={isStepping && viewingVariables ? "visually-hidden" : "h-100"}>
                <SimpleStyledTerminal stepperData={stepperData} />
            </div>
            <div className={isStepping && viewingVariables ?  "": "visually-hidden"}>
                <VariblesList stack={currStack} scope={currScope} />
            </div>
        </div>
        )
    }

    return (
        <div
            ref={containerRef}
            className={containerDivClass}
            style={{ width: '100%', height: height, marginBottom: '50px' }}>
            {/* <h4>Console</h4> */}
            <SimpleStyledTerminal stepperData={stepperData} />
            {
                isStepping ?
                <VariblesList stack={currStack} scope={currScope} /> : null
            }
        </div>
    );
};

export const GraphicsWindow = () => {
    const { setGraphicsState, stepPtr, stepList, isStepping } = useContext(CodeExampleContext);
    const [currStack, setStack] = useState({})
    const [currScope, setScope] = useState("")
    const [viewingVariables, setViewingVariables] = useState(false)

    const isMobile = useIsMobile();

    useEffect(() => {
        if (stepList.length > 0) {
            const step = stepList[stepPtr]
            const graphics = step.graphics
            if (graphics) {
                setGraphicsState(graphics)
            }
            const locals = step.locals
            const codenm = step.codenm
            setStack((_) => locals)
            setScope(codenm)
        }
    }, [stepPtr])


    const SwapTerminalButton = () => {
        return (
            <button 
                className="btn btn-sm" 
                onClick={() => setViewingVariables(!viewingVariables)}
            >
                {viewingVariables ? <FaShapes/> : <FaTable/>}
            </button>
        )
    }

    
    if(isMobile) {
        return (
            <div style={{ width: '100%'}}>
                <div>
                    <h4>Graphics {isStepping ? <SwapTerminalButton/> : null }</h4>
                    <div className={isStepping && viewingVariables ? "visually-hidden" : "h-100"}>
                        <Canvas />
                    </div>
                    <div className={isStepping && viewingVariables ?  "": "visually-hidden"}>
                        <VariblesList stack={currStack} scope={currScope} style={{
                            height: "200px",
                            overflowY: "auto",
                            overflowX: "scroll",
                            backgroundColor: "white",
                            borderRadius: "10px"
                        
                        }}/>
                    </div>
                </div>
            </div>
        );
    }





    return (
        <div style={{ width: '100%'}}>
            <div>
                <h4>Graphics</h4>
                <Canvas />
                {
                    isStepping ?
                    <VariblesList stack={currStack} scope={currScope} /> : null
                }
            </div>
        </div>
    );
}



const VariblesList = ({ stack, scope, style={} }) => {
    // stack is an map of key values
    const [tableData, setTableData] = useState(createTableData(stack))


    useEffect(() => {
        setTableData((_) => createTableData(stack))
    }, [stack])

    return (
        <div style={style}>
            <strong>Current Scope: <span className="badge badge-primary ">{scope==="mainApp" ? "global" : scope}</span></strong>
            <table
                className="table table-sm"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
            >
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map(({
                        variable,
                        value
                    }) => {
                        return (
                            <tr key={variable}>
                                <td>{variable}</td>
                                <td>{value}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )

}





export const SimpleStyledTerminal = ({ stepperData}) => {
    const { isStepping } = useContext(CodeExampleContext);



    return (
        <div
                style={{
                    backgroundColor: "#2d2e2c",
                    minHeight: "100%",
                }}
            >
                <div 
                className={`p-1 ${isStepping ? "h-0" : "d-none h-100"}`}
                >
                    <div
                        style={{
                            fontFamily: "Menlo",
                            fontSize: "14px",
                            color: "white",
                            lineHeight: "1rem",
                        }}
                    >
                        <div>% python main.py</div>
                        {stepperData.map((line) => {
                            return <div>{line}</div>;
                        })}
                    </div>
                </div>
                <div
                    id="terminal"
                    style={{marginBottom: "20px"}}
                    className={`p-1 ${isStepping ? "d-none h-100" : "h-0"}`}
                ></div>
            </div>
    )

}